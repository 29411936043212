import React from 'react'
import propTypes from "prop-types"
import { Box } from "@mui/material"

const HideCursor = ({ width="20vh", height="20vh", zIndex=3, top, left, onClick = () => {} }) => {
    return (
        <Box
            position= "absolute"
            width={width}
            height= {height}
            zIndex={zIndex}
            top={top}
            left={left}
            style={{cursor: "none"}}
            onClick={onClick} />
    )
}

export default HideCursor

HideCursor.propTypes = {
    width: propTypes.oneOfType([
        propTypes.string,
        propTypes.number
    ]),
    height: propTypes.oneOfType([
        propTypes.string,
        propTypes.number
    ]),
    zIndex: propTypes.number,
    top: propTypes.oneOfType([
        propTypes.string,
        propTypes.number
    ]).isRequired,
    left: propTypes.oneOfType([
        propTypes.string,
        propTypes.number
    ]).isRequired,
}