import { useEffect, useState } from 'react'
import firebaseApp from '../../firebase/config'
import { getFirestore, doc, getDoc, setDoc, deleteDoc } from 'firebase/firestore'

const useRecipeFromDB = (recipeId, recipeCollection = "recipe2") => {
    const [recipeJSON, setRecipeJSON] = useState({})
    const [loading, setLoading] = useState(true)
    const [saving, setSaving] = useState(false)

    useEffect(() => {
        setLoading(true)
        if (!recipeId) return undefined
        const fetchData = async () => {
            const db = getFirestore(firebaseApp)
            const docRef = doc(db, recipeCollection, recipeId)
            const data = await getDoc(docRef)
            const r = data.data()
            setRecipeJSON(r)
            setLoading(false)
        }
        fetchData()
    }, [recipeId, recipeCollection])

    const save = (recipeEditedValidJSON, onSave = () => { }, onError = () => { }) => {
        setSaving(true)
        const setData = async () => {
            const db = getFirestore(firebaseApp)
            const docRef = doc(db, recipeCollection, recipeId)
            try {
                await setDoc(docRef, recipeEditedValidJSON)
                onSave()
            } catch (err) {
                onError(err)
            }
            setSaving(false)
        }
        setData()
    }
    const deleteRecipe = (onDelete = () => { }, onError = () => { }) => {
        setSaving(true)
        const deleteData = async () => {
            const db = getFirestore(firebaseApp)
            const docRef = doc(db, recipeCollection, recipeId)
            try {
                await deleteDoc(docRef)
                onDelete()
            } catch (err) {
                onError(err)
            }
            setSaving(false)
        }
        deleteData()
    }
    return [recipeJSON, loading, save, saving, deleteRecipe]
}

export default useRecipeFromDB