import React, { useState, Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { Box, Button, Typography, Dialog } from '@mui/material'
import { v4 as uuid } from 'uuid'
import JsonEditorDisplay from './JsonEditorDisplay'
import PropertySeparateEdit from './PropertySeparateEdit'
import DynamicSlide from '../dynamicSlide/DynamicSlide'
import onMenuClick from './contextMenuItems'

const ArrayEditor = ({ value, onChange, oneLevelPropertySplit = false, preview = false, slides = null,
    getTitle = (el, index) => {
        if ("name" in el)
            return el.name + " (" + index.toString() + ")"
        if ("label" in el)
            return el.label + " (" + index.toString() + ")"
        if ("id" in el)
            return el.id + " (" + index.toString() + ")"
        return index
    },
    elementSchema = undefined
}) => {
    const [vizDialog, setVizDialog] = useState(value.map(() => false))
    useEffect(() => {
        setVizDialog(value.map(() => false))
    }, [value])
    const onArrayElementChange = (change, index) => {
        onChange(value.map((element, i) => i === index ? change : element))
    }
    const onAddArrayElement = () => { onChange([...value, { id: uuid() }]) }
    const onDeleteElement = index => { onChange(value.filter((element, i) => i !== index)) }
    const onMoveUp = (e, index) => {
        e.stopPropagation()
        var ret = Array.from(value)
        const temp = ret[index]
        ret[index] = ret[index - 1]
        ret[index - 1] = temp
        onChange(ret)
    }
    const onMoveDown = (e, index) => {
        e.stopPropagation()
        var ret = Array.from(value)
        const temp = ret[index]
        ret[index] = ret[index + 1]
        ret[index + 1] = temp
        onChange(ret)
    }
    const onPreview = (e, index) => {
        e.stopPropagation()
        setVizDialog(old => old.map((status, i) => index === i ? true : status))
    }
    return (
        <Box>
            {value.map((element, index) => (
                <Fragment key={index}>
                    <Accordion >
                        <AccordionSummary>
                            <Box
                                display="flex"
                                alignItems="center"
                                width="100%"
                            >
                                <Button variant="contained" color="secondary" onClick={() => onDeleteElement(index)}>{"Del"}</Button>
                                <Button disabled={index === 0} variant="contained" color="primary" onClick={e => onMoveUp(e, index)}>{"^"}</Button>
                                <Button disabled={index === value.length - 1} variant="contained" color="primary" onClick={e => onMoveDown(e, index)}>{"v"}</Button>
                                <Typography style={{ minWidth: "25%" }} align="center">{getTitle(element, index)}</Typography>
                                {preview ? <Button variant="contained" color="secondary" onClick={e => onPreview(e, index)} style={{ marginLeft: "auto" }}>Preview</Button> : <></>}
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            {oneLevelPropertySplit ?
                                <PropertySeparateEdit
                                    value={element}
                                    onChange={e => onArrayElementChange(e, index)}
                                /> :
                                <JsonEditorDisplay
                                    value={element}
                                    onChange={e => onArrayElementChange(e, index)}
                                    schema={elementSchema}
                                    onCreateMenu={(items, node) => onMenuClick(items, node, element, e => onArrayElementChange(e, index))}
                                />
                            }
                        </AccordionDetails>
                    </Accordion>
                    <Dialog
                        open={vizDialog[index]}
                        onClose={() => setVizDialog(old => old.map((status, i) => index === i ? false : status))}
                        maxWidth="lg"
                        fullWidth
                    >
                        <DynamicSlide
                            type={element.type}
                            slideData={element?.data?.ingredientIds ?  (() => slides.filter(slide => slide.ingredientIds.toString() === element.data.ingredientIds.toString())
                                                                                            .pop().data)() : element.data}                                                                                           
                            isCurrent
                        />
                    </Dialog>
                </Fragment>
            ))
            }
            <Button variant="contained" color="primary" onClick={onAddArrayElement}>+</Button>
        </Box>
    )
}

ArrayEditor.propTypes = {
    value: PropTypes.array,
    onChange: PropTypes.func,
    oneLevelPropertySplit: PropTypes.bool,
    visualization: PropTypes.bool
}

export default ArrayEditor
