import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Box, Checkbox } from '@mui/material'

const GridBox = ({ mainBox, sideBox = null, addToCartClick, image, checkedBox, commonId, displayCheckBox }) => {
  const { text } = mainBox

  return <>
    {mainBox ?
      <Box
        display="flex"
        justifyContent="center"
        position="relative"
      >
        <img
          src={image}
          style={{ maxWidth: "20vh", maxHeight: "20vh", position: "absolute", left: "3vh", top: "-10vh" }}
          alt={text}
        />
        <Box
          borderRadius="20px"
          bgcolor="#FFD189"
          width="80%"
          height="10.51vh"
          marginLeft="5vh"
          display="flex"
          alignItems="center"
          flexDirection="row"
          justifyContent="end"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            marginRight="3vh"
          >
            <Box display="flex">
              {sideBox?.text ? <Typography style={{ fontSize: "3.1vh", fontWeight: 600 }}>{sideBox.text}</Typography> : null}
              {sideBox?.sidetext ?
                <Typography style={{ fontSize: "3.1vh", fontWeight: 600, marginLeft: "1vh" }}>{sideBox.sidetext}</Typography> : null
              }
            </Box>
            {sideBox?.subtext ?
              <Typography style={{ fontSize: "2.63vh" }}>{sideBox.subtext}</Typography> : null
            }
          </Box>
          <Box>
            {displayCheckBox ? <Box width={30} height={30} bgcolor="white" borderRadius="6px" display="flex" alignItems="center" justifyContent="center" marginRight="3vh">
               <Checkbox style={{ transform: "scale(1.5)", color: "#5ab33d" }} value={commonId} onClick={addToCartClick} checked={checkedBox.includes(commonId) ? true : false} />              
            </Box> : null}
          </Box>
        </Box>
      </Box> : null
    }
  </>;
}

GridBox.propTypes = {
  mainBox: PropTypes.shape({ 
    text: PropTypes.string.isRequired, 
    subtext: PropTypes.string, }),
  sideBox: PropTypes.shape({ 
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), 
    sidetext: PropTypes.string,
    subtext: PropTypes.string })

}

export default GridBox
