import React from 'react';
import { Box, Button } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';

const btnStyle = {
    textTransform: "none",
    zIndex: 2,
    border: "4px solid #187fba",
    borderRadius: 4,
    padding: "10px 20px",
    color: "#187fba",
    backgroundColor: "#ffffff",
}

const Recenter = ({ completedSteps, btnFunctions, currentSlideIndex, allR1Steps }) => {
    const [r1Step, setR1Step] = useState({
        currStep: undefined,
        nextStep: undefined
    })

    useEffect(() => {
        if (allR1Steps.includes(currentSlideIndex) &&
            (r1Step.currStep === completedSteps.at(-1) || !r1Step.currStep) &&
            (!completedSteps.includes(currentSlideIndex))) {
            const currStepIndex = allR1Steps.indexOf(currentSlideIndex)
            const nextStepIndex = currStepIndex === allR1Steps.length ? undefined : currStepIndex + 1
            setR1Step({
                currStep: currentSlideIndex,
                nextStep: allR1Steps[nextStepIndex]
            })
        }
    }, [allR1Steps, r1Step, currentSlideIndex, completedSteps])
    const recenter = () => {
        const completedStep = completedSteps.at(-1) || -1
        btnFunctions("moveByIndex", { slideIndex: completedStep + 1 })
    }

    if (r1Step.currStep && ((completedSteps.length === 0 && currentSlideIndex > r1Step.currStep + 1) ||
        (completedSteps.length > 0 &&
            (completedSteps.length <= allR1Steps.length) &&
            (currentSlideIndex < completedSteps.at(-1) || currentSlideIndex > (completedSteps.at(-1) !== r1Step.currStep ? r1Step.currStep : r1Step.nextStep))) ||
        (!(r1Step.nextStep) && (currentSlideIndex > r1Step.currStep)) ||
        (completedSteps.length > allR1Steps.length && currentSlideIndex < r1Step.currStep + 1))) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                width="100%"
                position="absolute"
                style={{ bottom: 80 }}
            >
                <Button style={btnStyle} onClick={recenter}>Recenter</Button>
            </Box>
        )
    }
    else {
        return <></>
    }
}

export default Recenter 