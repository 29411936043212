import React from "react";
import ObjectEditor from "./ObjectEditor";
import { splitPath, getGeneratedData, appendNew, deleteAField } from "./utils"
import PropTypes from "prop-types";

const IngredientsPropertiesEdit = ({ data, setData, schema }) => {
    function editData(newData, position, path) {
        const arrPath = splitPath(path) // arrPath keeps track of the path of a key  
        // e.x{first: "hello", second: {third: "word"}} path of third is /first/    second/third
        // arrPath = ["first", "second", "third"]
        const generatedData = getGeneratedData(newData, data, schema, position, arrPath)
        setData(generatedData)
    }

    function addNewField(path) {
        const arrPath = splitPath(path)
        const dataWithAppendedField = appendNew(data, arrPath, schema)
        setData(dataWithAppendedField)
    }

    function deleteField(path, pos, removeEmptyField) {
        const arrPath = splitPath(path)
        const dataWithDeletedField = deleteAField(data, schema, pos, arrPath, removeEmptyField)
        setData(dataWithDeletedField)
    }

    return (
        <ObjectEditor
            data={data}
            schema={schema}
            editData={editData}
            path={"/"}
            position={-1}
            addNewField={addNewField}
            deleteField={deleteField}
        />
    )
}

IngredientsPropertiesEdit.propTypes = {
    data: PropTypes.object,
    setData: PropTypes.func,
    schema: PropTypes.object
}

export default IngredientsPropertiesEdit