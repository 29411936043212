//1.
import React, { useEffect, useState, createContext } from "react"
import firebaseApp from "./config"
import { getAuth, onAuthStateChanged } from "firebase/auth"

//2.
export const AuthContext = createContext()

//3.
export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(true)
    const authStateCallback = u => {
        if (u)
            setUser(u)
        setLoading(false)
    }

    useEffect(() => {
        onAuthStateChanged(getAuth(firebaseApp), authStateCallback)
    }, [])

    return (
        <AuthContext.Provider value={{ user, loading }}>{children}</AuthContext.Provider>
    )
}
