import React, { useContext } from 'react'
import { Button } from '@mui/material'
import { AuthContext } from '../../firebase/context'
import { getAuth, signOut } from 'firebase/auth'

const SignOut = () => {
    const { user } = useContext(AuthContext)
    return user && (
        <div>
            <Button onClick={() => signOut(getAuth())}>Sign Out</Button>
        </div>
    )
}

SignOut.propTypes = {
}

export default SignOut
