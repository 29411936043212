import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import PrepItem from './elements/PrepItem'

const PrepDisplay = ({ prepItems, dispatch }) => {
    return (
        <Grid
            container
            style={{ height: "100%", width: "100%", padding: "30px" }}
            alignContent="stretch"
            justifyContent="space-around"
        >
            {prepItems.map((prepItem => <PrepItem key={prepItem.title} {...prepItem} dispatch={dispatch} />))}
        </Grid>
    );
}

PrepDisplay.propTypes = {
    prepItems: PropTypes.arrayOf(PropTypes.object)
}

export default PrepDisplay
