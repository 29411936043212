import React from "react";
import StepsDescription from "./StepsDescription";
import PropTypes from 'prop-types';
import CapDisplay from "./CapDisplay";
import SettingsDisplay from "./SettingsDisplay";
import R1Buttons from "./R1Buttons";
import { titles } from "../../utils/experience2/typesVariable";
import { createSettings, createSettingsWithScaleModel, getTime } from "../../utils/experience2/stepsInstruction";
import AccordionDisplay, { TimerProgressBar } from "./AccordianDisplay";
import { Box } from "@mui/system";
import RecipeTimer from "./RecipeTimer";
import ParallelPrepDisplay from "./ParallelPrepDisplay";

const TimerInstructionType = ({ parameters, title, status, type, expanded, changeExpanded, dispatch, subStepId, hardwareStateMemo }) => {
    const r1Settings = createSettings(parameters?.settings)
    return (<Box
        style={{ display: "flex", flexDirection: "column", gap: 20 }}
    >
        {parameters?.cap !== "" && parameters?.cap ? <StepsDescription
            status={status}
            details={< CapDisplay cap={parameters.cap} status={status} />}
        /> : null}
        <AccordionDisplay
            title={title || titles[type]}
            details={<>
                <SettingsDisplay settings={r1Settings && r1Settings?.settings} dispatch={dispatch} subStepId={subStepId} />
                {status === "in-progress" &&
                    <>
                        <TimerProgressBar totalTime={r1Settings?.totalTime} />
                        <RecipeTimer />
                        <ParallelPrepDisplay {...parameters?.parallelPrep} dispatch={dispatch} />
                    </>
                }
            </>}
            openAccordion={status !== "completed" || (expanded?.id === subStepId && expanded?.expand)}
            setExpanded={changeExpanded}
            status={status}
            button={<R1Buttons
                dispatch={dispatch}
                btFirstConnect={hardwareStateMemo?.btFirstConnect}
                stepName={title} subStepId={subStepId}
                progress={status}
                buttonStatus={parameters?.status}
            />}
        />
    </Box>)
}

TimerInstructionType.propTypes = {
    parameters: PropTypes.object,
    title: PropTypes.string,
    status: PropTypes.string,
    type: PropTypes.string,
    stepId: PropTypes.string,
    changeExpanded: PropTypes.func,
    expanded: PropTypes.object,
    dispatch: PropTypes.func
}

export default TimerInstructionType