import React from 'react'
import PropTypes from 'prop-types'
import { Chip, MenuItem, Select, Typography } from '@mui/material'
import useRecipeListFromDB from '../utils/RecipeBackEnd/loadRecipeList'

function defaultGetActiveStatusFromRecipe(recipe) {
    return recipe?.metadata?.inactive === true ? false : true
}

const RecipeDropDown = ({
    allowChangeRecipe = true,
    onRecipeSelect = () => { },
    onClose = () => { },
    collection = "recipe",
    checkFor = ["slides", "slides2"],
    dataForNewRecipe = null,
    getNameFromRecipe = r => r.name,
    getActiveStatusFromRecipe = defaultGetActiveStatusFromRecipe
}) => {
    const { recipeList, saveNew, loading } = useRecipeListFromDB(collection, dataForNewRecipe, getNameFromRecipe)
    const checkForValid = recipe => {
        if (typeof recipe === 'object' && recipe !== null && !Array.isArray(recipe)) {
            const keys = Object.keys(recipe)
            return checkFor.some(checks => keys.some(k => checks === k))
        }
        return false
    }
    const onSelectChange = ({ target }) => {
        const { value } = target
        if (value === "newR") {
            saveNew(newId => onRecipeSelect(newId))
            return
        }
        onRecipeSelect(value)
    }
    return (
        <Select
            onChange={onSelectChange}
            value=""
            variant="filled"
            renderValue={() => <Typography>Select Recipe Here</Typography>}
            displayEmpty
            disabled={!allowChangeRecipe || loading}
            onClose={onClose}
        >
            {recipeList.map(recipe => (
                <MenuItem
                    key={recipe.id}
                    value={recipe.id}
                    disabled={!checkForValid(recipe)}
                >
                    {getNameFromRecipe(recipe)}
                    <Chip
                        label={getActiveStatusFromRecipe(recipe) ? "Active" : "Inactive"}
                        color={getActiveStatusFromRecipe(recipe) ? "primary" : "secondary"}
                    />
                </MenuItem>
            ))}
            <MenuItem value="newR" disabled={!dataForNewRecipe}>Create New</MenuItem>
        </Select>
    )
}

RecipeDropDown.propTypes = {
    onRecipeSelect: PropTypes.func,
    allowChangeRecipe: PropTypes.bool,
    onClose: PropTypes.func,
    checkFor: PropTypes.array,
    collection: PropTypes.string,
    dataForNewRecipe: PropTypes.object,
}

export default RecipeDropDown
