import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import CircleButton from './CircleButton'

const CookingProgressBar = ({ cookPhaseData, activeStepId, heightOfRoundIconButtons, changeSlideOnClick }) => {
    return (
        <Box
            display="flex"
            alignItems="center"
            marginLeft={1}
            marginRight={1}
            maxWidth={1280 / 2}
        >
            {cookPhaseData.map(({ id, label, thumbnail, slideIndex }) => <CircleButton
                heightOfRoundIconButtons={heightOfRoundIconButtons}
                key={id}
                imageUrl={thumbnail}
                labelText={label}
                showLabel={id === activeStepId}
                onClick={() => changeSlideOnClick(id, slideIndex)}
            />)}
        </Box>
    )
}

CookingProgressBar.propTypes = {
    cookPhaseData: PropTypes.arrayOf(PropTypes.object),
    activeStepId: PropTypes.string,
    heightOfRoundIconButtons: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default CookingProgressBar
