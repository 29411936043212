import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Box } from '@mui/material'
import InstructionStep from './InstructionStep'

const instructionSetStyleData = {
    leftSpace: "3%",
    topDistance: "27%",
    width: "40%",
    maxHeight: "500px"
}

const InstructionSet = ({ instructions = [], setReact1Settings, setScaleSettings, stepCompleted }) => {
    const { leftSpace, topDistance, width, maxHeight } = instructionSetStyleData
    return (
        <Box
            position="absolute"
            left={leftSpace}
            top={topDistance}
            width={width}
            maxHeight={maxHeight}
        >
            <Grid container direction="column" spacing={2} alignItems="flex-start" justifyContent="center">
                {instructions.map(instruction => (
                    <Grid item key={JSON.stringify(instruction.steps)} style={{ width: "100%" }}>
                        <InstructionStep
                            icon={instruction.icon}
                            steps={instruction.steps}
                            setReact1Settings={setReact1Settings}
                            setScaleSettings={setScaleSettings}
                            stepCompleted={stepCompleted}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

InstructionSet.propTypes = {
    instructions: PropTypes.arrayOf(PropTypes.object),
    setReact1Settings: PropTypes.func,
}

export default InstructionSet
