import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Typography } from "@mui/material";
import React from "react";
import Type from "./Type";
import PropTypes from "prop-types";
import ChipInput from "../../utils/ChipInput";

const AccordionCeption = ({ children, title }) => {
    return (<Accordion>
        <AccordionSummary><Typography variant="h6">{title ? title : "Click to expand"}</Typography></AccordionSummary>
        <AccordionDetails>
            <Box display="flex" flexDirection="column" style={{ gap: 15 }}>
                {children}
            </Box>
        </AccordionDetails>
    </Accordion>)
}

const AddBtn = ({ onClick = () => { } }) => {
    return (
        <Button onClick={onClick}>
            Add
        </Button>
    )
}

const DelBtn = ({ onClick = () => { } }) => {
    return (
        <Button onClick={onClick}>
            Delete
        </Button>
    )
}

const ArrayEditor = ({ data, schema, editData, path, addNewField, deleteField, name }) => {
    switch (schema.type) {
        case "string": return (
            <ChipInput
                value={data}
                onAdd={value => {
                    const index = data[0] === "" ? 0 : data.length
                    addNewField(path)
                    editData(value, index, path)
                }}
                onDelete={(data, index) => deleteField(path, index, true)} />
        )
        case "object": return (
            <>
                <AccordionCeption title={name}>
                    {data.map((item, index) => <AccordionCeption key={`${item}${index}`}><Type data={item} schema={schema} editData={editData} path={path} position={index} addNewField={addNewField} delBtn={<DelBtn onClick={() => deleteField(path, index)} />} deleteField={deleteField} /></AccordionCeption>)}
                </AccordionCeption>
                <AddBtn onClick={() => addNewField(path)} />
            </>)
        default: return null
    }
}

ArrayEditor.propTypes = {
    data: PropTypes.object,
    schema: PropTypes.object,
    path: PropTypes.string,
    addNewField: PropTypes.func,
    deleteField: PropTypes.func
}

export default ArrayEditor