import React, { useContext, useEffect, useState } from 'react'
import { TimerContext } from '../../../pages/TimerContext'
import { Typography } from '@mui/material'
import './TimerStyle.css'

const getTimeString = time => {
    const timeSec = time % 60
    const timeSecString = timeSec < 10 ? "0" + timeSec.toString() : timeSec.toString()
    return Math.floor(time / 60) + ":" + timeSecString
}

const Time = ({ time, className }) => {
    return (
        <Typography className={className}>{getTimeString(time)}</Typography>
    )
}

const RunningTime = ({ setMoveToNext, autoSlide }) => {
    const { currentTime } = useContext(TimerContext)
    useEffect(() => {
        if (currentTime === 0 && autoSlide)
            setMoveToNext(true)
    }, [currentTime])
    return (<Time time={currentTime} className={currentTime < 11 ? "red-timer" : "green-timer"} />)
}

const TimerDisplayUI = ({ status, r1Time, completedSteps, currentSlide, autoSlide = false, runningTime = false, moveToNextFn = () => {} }) => {
    const lastStep = [...completedSteps].pop()
    const [moveToNext, setMoveToNext] = useState(false)

    useEffect(() => {
        if (moveToNext && status === "idle") {
            setMoveToNext(false)
            moveToNextFn()
        }
    }, [moveToNext,moveToNextFn, status])
    
    const displayRunningTimer = lastStep === currentSlide - 1 || lastStep === currentSlide || lastStep === currentSlide - 2 || runningTime
    if (["operation", "cooldown", "starting"].includes(status) && displayRunningTimer)
        return (<RunningTime setMoveToNext={setMoveToNext} autoSlide={autoSlide}/>)
    else 
        return (<Time time={r1Time} className={r1Time < 11 ? "red-timer" : "green-timer"} />)
}

export default TimerDisplayUI