import React, { useState } from 'react'
import PropTypes from 'prop-types'
import CallToActionButton from './CallToActionButton'
import { Typography, Box } from '@mui/material'
import DynamicSlide from '../DynamicSlide'
import PopUpDialog from '../../PopUpDialog'

const weightProcess = weight => {
    if (weight < 1000) return weight.toString() + " g"
    return (Math.round(weight / 100) / 10).toString() + " kg"

}

const PrepItem = ({ title, qty, weightOption = 0, endProductImage, endProductDescription, onExpand, dispatch, weightImage}) => {
    const [dialogForDetails, setDialogForDetails] = useState(false)
    const onClickWeight = text => dispatch({type: "SET-SCALESETTINGS", data: {text, img: weightImage}})
    return <>
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            borderRadius="20px"
            width="23%"
            bgcolor="#F9DDD4"
            justifyContent="space-around"
        >
            <Box
                display="flex"
                height="20%"
                alignItems="center"
            >
                <Typography variant="h3" align="center">{title}</Typography>
            </Box>
            <Typography variant="h4" style={{ height: "10%" }}>{qty}</Typography>
            {!weightOption ? <Box style={{ height: "10%" }} /> :
                <CallToActionButton
                    text={weightProcess(weightOption)}
                    iconType="scales"
                    style={{ height: "10%" }}
                    onClick={() => onClickWeight(weightProcess(weightOption))}
                />
            }<Box
                height="40%"
                width="240px"
                style={{
                    backgroundImage: `url(${endProductImage})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center"
                }}
            />
            <Typography variant="h5" style={{ height: "10%" }} align="center">{endProductDescription}</Typography>
            <CallToActionButton
                onClick={() => setDialogForDetails(true)}
                text="How"
            />
        </Box>
        <PopUpDialog
            dialogOpen={dialogForDetails}
            onClickBack={() => setDialogForDetails(false)}
            componentInDialog={<DynamicSlide
                type={onExpand.type}
                slideData={onExpand.data}
                isCurrent={dialogForDetails}
                dispatch={dispatch}
            />}
        />
    </>;
}

PrepItem.propTypes = {
    title: PropTypes.string.isRequired,
    qty: PropTypes.string,
    weightOption: PropTypes.number,
    endProductDescription: PropTypes.string,
    endProductImage: PropTypes.string,
    onExpand: PropTypes.shape({ id: PropTypes.string, type: PropTypes.string, data: PropTypes.object })
}

export default PrepItem
