import React, { useState } from 'react'
import PropTypes from 'prop-types'
import JsonEditorDisplay from './JsonEditorDisplay'
import { Typography, Button } from '@mui/material'

const defaultValue = { delete: "This Exmaple" }

const LoadRecipeFromJSON = ({ setJson = () => { } }) => {
    const [loadJson, setLoadJson] = useState(defaultValue)
    return (
        <>
            <Typography variant="h4">Load Recipe (BE VERY CAREFUL)</Typography>
            <JsonEditorDisplay value={loadJson} onChange={setLoadJson} />
            <Button disabled={defaultValue === loadJson} onClick={() => setJson(loadJson)}>Save</Button>
        </>
    )
}

LoadRecipeFromJSON.propTypes = {
    setJson: PropTypes.func
}

export default LoadRecipeFromJSON
