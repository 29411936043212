import React, { useContext } from 'react'
import useRecipeFromDB from '../../utils/RecipeBackEnd/loadRecipe'
import useRecipeListFromDB from '../../utils/RecipeBackEnd/loadRecipeList'
import {v4 as uuid} from "uuid"
import { Button, DialogContent, Typography } from '@mui/material'
import { AuthContext } from '../../firebase/context'

const DuplicateRecipe = ({recipeId, setDuplicateOpen}) => {
  const [recipeJSON]  = useRecipeFromDB(recipeId, "newRecipeJson")
  const { user } = useContext(AuthContext)
  const duplicateRecipe = {...recipeJSON, 
    metadata: {...recipeJSON?.metadata, 
      name: `${recipeJSON?.metadata?.name} Copy`, 
      active: false,
      author: user.displayName,
      editor: user.displayName,
    },
    steps: recipeJSON?.steps?.map((step) => {
      return {...step, id: uuid(), subSteps: step?.subSteps?.map((subStep) => {
        return {...subStep, id: uuid()}
      })}
    })
  }
  const {saveNew, refresh} = useRecipeListFromDB("newRecipeJson", duplicateRecipe, r => r?.metadata?.name)

  const handleSave = () => {
    saveNew()
    refresh()
    setDuplicateOpen(false)
  }

  return (
    <>
      <DialogContent>
        <Typography>DuplicateRecipe</Typography>
      </DialogContent>
      <Button variant="contained" color="secondary" onClick={handleSave}>Save Recipe</Button>
    </>
  )
}

export default DuplicateRecipe