import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import Experience2Card from './Experience2Card'
import Recipe2Card from './Recipe2Card'
import RecipeCard2 from './RecipeCard2'
import { useHistory } from 'react-router-dom'

const AccordionForExperiment = ({ title, children, expanded = false }) => {
    return (
        <Accordion
            style={{ margin: "2vh 0" }}
            defaultExpanded={expanded}
        >
            <AccordionSummary>
                <Typography
                    variant="h5"
                    style={{ fontWeight: 600 }}>
                    {title}
                </Typography>
            </AccordionSummary>
            <AccordionDetails
                style={{ display: "flex", justifyContent: "center" }}>
                {children}
            </AccordionDetails>
        </Accordion>
    )
}

const RecipeListByExperiance = ({ recipeList, title, experiance, manualMode = false, expanded = false, displayType }) => {
    const history = useHistory()
    const onCardClickExp1 = recipeId => displayType === "INGREDIENTS_ONLY" ? history.push("/ingredients/" + recipeId) :
        history.push("/test/" + recipeId)
    if (recipeList && recipeList.length > 0)
        return (
            <AccordionForExperiment title={title} expanded={expanded}>
                <Grid container spacing={3}>
                    {recipeList.map((recipe, i) => (
                        <Grid xs={6} item key={recipe?.id || i}>
                            {experiance === "1.0" ?
                                <Recipe2Card recipeJson={recipe} onCardClick={() => onCardClickExp1(recipe.id)} /> :
                                <Experience2Card
                                    onCardClick={() => {
                                        const currPath = displayType === "INGREDIENTS_ONLY" ? history.push("/ingredients/" + recipe.id) : `experience2/${recipe.id}`
                                        history.push(currPath)
                                    }}
                                    url={recipe.metadata.url}
                                    name={recipe.metadata.name}
                                    categories={recipe.metadata.categories}
                                    tags={recipe.metadata.tags}
                                    totalTime={Number(recipe.metadata.prepTime) + Number(recipe.metadata.cookTime)}
                                />
                            }
                        </Grid>
                    ))}
                    {manualMode &&
                        <Grid xs={6} item>
                            <RecipeCard2
                                time={30}
                                recipeImage={"https://res.cloudinary.com/prodjour/image/upload/v1633257170/General/ManualMode_xi2mho.jpg"}
                                name="Manual Operation"
                                onClick={() => history.push(experiance === "1.0" ? "/drivehardware/" : "/experience2/drivehardware/")}
                            />
                        </Grid>
                    }
                </Grid>
            </AccordionForExperiment>
        )
    return null
}

RecipeListByExperiance.propTypes = {
    recipeList: PropTypes.arrayOf(PropTypes.object).isRequired,
    title: PropTypes.string.isRequired,
    manualMode: PropTypes.bool,
    expanded: PropTypes.bool,
    experiance: PropTypes.string,
    displayType: PropTypes.string.isRequired
}

export default RecipeListByExperiance