import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { endSession } from '../../utils/logging'
import EmptyCards from './EmptyCards'
import RecipeListByExperiance from './RecipeListByExperiance'
import { GlobalSettingsContext } from '../../utils/globalSettings/globalSettingsContext'
import { useFirestoreDoc } from '../../firebase/useFirebase'


const useUserSpecifcRecipe = () => {
    const { globalSettings: { user } } = useContext(GlobalSettingsContext)
    const { data, loading } = useFirestoreDoc("users", user)
    const baseReturn = { name: "any", recipes: [], loading, userTags: [] }
    if (user === "any" || !data)
        return { ...baseReturn, loading: false }
    if (loading)
        return { ...baseReturn }
    return {
        ...baseReturn,
        name: data.nickName,
        recipes: data.recipesForUser,
        userTags: data?.customUserTags ? data.customUserTags : []
    }
}

const RecipeList = ({ displayType = "LINK_TO_RECIPE", recipeListExp1, loading, experimentalRecipes,
    experimentalRecipes2, experience2Recipe }) => {
    const history = useHistory()
    const { name: userName,
        recipes: userSpecificRecipes,
        loading: userRecipesLoading,
        userTags } = useUserSpecifcRecipe()
    const userRecipes = experience2Recipe.filter(r => userSpecificRecipes.includes(r.id))

    useEffect(() => {
        const currPath = sessionStorage.getItem("currPath")
        const sessionId = sessionStorage.getItem("sessionId")
        if (currPath && currPath.includes("experience2"))
            endSession(sessionId)
        sessionStorage.setItem("currPath", history.location.pathname)
    }, [endSession, history])

    if (loading || userRecipesLoading)
        return <Box
            style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                flexWrap: "wrap"
            }}
        >
            <EmptyCards />
        </Box>

    return (
        <>
            <RecipeListByExperiance
                recipeList={userRecipes}
                displayType={displayType}
                title={userName + "'s Recipes"}
                expanded
                experiance="2.0"
            />
            {
                userTags.map(tag => <RecipeListByExperiance
                    recipeList={experience2Recipe.filter(r => {
                        const tagArray = r?.metadata?.tags
                        return Array.isArray(tagArray) ? tagArray.includes(tag) : false
                    })}
                    displayType={displayType}
                    title={tag + " Recipes"}
                    experiance="2.0"
                    key={tag}
                />)
            }
            <RecipeListByExperiance
                recipeList={experience2Recipe}
                manualMode
                displayType={displayType}
                title="All Recipes"
                experiance="2.0"
                expanded={userName === "any"}
            />
            <RecipeListByExperiance
                recipeList={experimentalRecipes2}
                displayType={displayType}
                title="Experimental Recipes"
                experiance="2.0"
            />
            <RecipeListByExperiance
                recipeList={experimentalRecipes}
                displayType={displayType}
                title="Alternate Experience Recipes"
                experiance="1.0"
            />
            <RecipeListByExperiance
                recipeList={recipeListExp1}
                displayType={displayType}
                title="Experience 1.0"
                experiance="1.0"
                manualMode
            />
        </>
    )
}

RecipeList.propTypes = {
    displayType: PropTypes.oneOf(["LINK_TO_RECIPE", "INGREDIENTS_ONLY"]),
    recipeList: PropTypes.arrayOf(PropTypes.object),
    loading: PropTypes.bool,
    experimentalRecipes: PropTypes.arrayOf(PropTypes.object)
}

export default RecipeList
