import { v4 as uuid } from "uuid"

export const getname = r => r?.metadata?.name

export const collectionName = "recipe2"
export const collectionName2 = "newRecipeJson"

export const blankRecipe = {
    "metadata": {
        "name": "New Recipe",
        "totalTime": 20,
        "inactive": true
    },
    "mainPage": {
        "visualization": [
            {
                "id": uuid(),
                "type": "SingleFocus",
                "data": {
                    "text": "This is the MainSlide"
                }
            }
        ]
    },
    "healthInfo": {
        "visualization": [
            {
                "id": uuid(),
                "type": "ContentSlide",
                "data": {
                    "url": "gs://cookclub-app.appspot.com/Marinara/Slide3.PNG"
                }
            }
        ]
    },
    "ingredients": {
        "list": [
            {
                "id": "1",
                "name": "Choice Name",
                "default": "1b",
                "optionSet": [
                    {
                        "id": "1a",
                        "name": "Option 1",
                        "qty": {
                            "amount": 200,
                            "unit": "g"
                        },
                        "subdiscription": "opt 1",
                        "image": "https://res.cloudinary.com/prodjour/image/upload/v1628081317/Marinara/PennePasta_qftbyj.png"
                    },
                    {
                        "id": "1b",
                        "name": "Option 2",
                        "qty": {
                            "amount": 200,
                            "unit": "ml"
                        },
                        "subdiscription": "opt 2",
                        "image": "https://res.cloudinary.com/prodjour/image/upload/v1628088610/Marinara/Spaghetti_c9cats.png"
                    }
                ]
            },
            {
                "id": "2",
                "name": "Tomato",
                "default": "2",
                "optionSet": [
                    {
                        "id": "2",
                        "name": "Tomato",
                        "qty": {
                            "amount": 800,
                            "unit": "g",
                            "secondary": "10 x 🍅"
                        },
                        "image": "https://res.cloudinary.com/prodjour/image/upload/v1628081414/Marinara/Tomato_wxxq6n.png"
                    }
                ]
            },
            {
                "id": "3",
                "name": "Garlic",
                "default": "3",
                "optionSet": [
                    {
                        "id": "3",
                        "name": "Garlic",
                        "subdiscription": "peeled",
                        "qty": {
                            "amount": 10,
                            "unit": "cloves",
                            "secondary": "12g"
                        },
                        "image": "https://res.cloudinary.com/prodjour/image/upload/v1628081317/Marinara/Garlic_ggocuy.png"
                    }
                ]
            },
            {
                "id": "4",
                "name": "Olive Oil",
                "default": "4",
                "optionSet": [
                    {
                        "id": "4",
                        "name": "Olive Oil",
                        "qty": {
                            "amount": 2,
                            "unit": "tbsp",
                            "secondary": "26g"
                        },
                        "image": "https://res.cloudinary.com/prodjour/image/upload/v1628081317/Marinara/OliveOil_or8utd.png"
                    }
                ]
            },
            {
                "id": "5",
                "name": "Onion",
                "default": "5",
                "optionSet": [
                    {
                        "id": "5",
                        "name": "Onion",
                        "qty": {
                            "amount": 30,
                            "unit": "g",
                            "secondary": "1/2 x 🧅"
                        },
                        "image": "https://res.cloudinary.com/prodjour/image/upload/v1628081317/Marinara/Onion_p6gxs0.png"
                    }
                ]
            },
            {
                "id": "6",
                "name": "Cheese",
                "default": "6",
                "optionSet": [
                    {
                        "id": "6",
                        "name": "Cheese",
                        "qty": {
                            "amount": 25,
                            "unit": "g"
                        },
                        "image": "https://res.cloudinary.com/prodjour/image/upload/v1628081414/Marinara/Cheese_k43xzd.png"
                    }
                ]
            },
            {
                "id": "7",
                "name": "Salt and Pepper",
                "default": "7",
                "optionSet": [
                    {
                        "id": "7",
                        "name": "Salt and Pepper",
                        "qty": "",
                        "image": "https://res.cloudinary.com/prodjour/image/upload/v1629113426/Marinara/Salt_and_Pepper_ngjedq.png"
                    }
                ]
            },
            {
                "id": "8",
                "name": "Basil",
                "default": "8",
                "optionSet": [
                    {
                        "id": "8",
                        "name": "Basil",
                        "qty": {
                            "amount": 15,
                            "unit": "leaves"
                        },
                        "image": "https://res.cloudinary.com/prodjour/image/upload/v1629113426/Marinara/Basil_tvcvk6.png"
                    }
                ]
            },
            {
                "id": "9",
                "name": "Sliced Olives",
                "default": "9",
                "optionSet": [
                    {
                        "id": "9",
                        "name": "Sliced Olives",
                        "qty": {
                            "amount": 20,
                            "unit": "slices"
                        },
                        "image": "https://res.cloudinary.com/prodjour/image/upload/v1629113427/Marinara/Sliced_Olives_x6ulzv.png"
                    }
                ]
            },
            {
                "id": "10",
                "name": "Jalapenos",
                "default": "10",
                "optionSet": [
                    {
                        "id": "10",
                        "name": "Jalapenos",
                        "qty": {
                            "amount": 5,
                            "unit": "slices"
                        },
                        "image": "https://res.cloudinary.com/prodjour/image/upload/v1629113426/Marinara/Jalapenos_aglk75.png"
                    }
                ]
            },
            {
                "id": "11",
                "name": "Tomato Ketchup",
                "default": "11",
                "optionSet": [
                    {
                        "id": "11",
                        "name": "Tomato Ketchup",
                        "qty": {
                            "amount": 1,
                            "unit": "tsp"
                        },
                        "image": "https://res.cloudinary.com/prodjour/image/upload/v1629113426/Marinara/Tomato_Ketchup_ezgkew.png"
                    }
                ]
            },
            {
                "id": "12",
                "name": "Oregano",
                "default": "12a",
                "optionSet": [
                    {
                        "id": "12a",
                        "name": "Oregano",
                        "qty": {
                            "amount": 1,
                            "unit": "tsp"
                        },
                        "image": "https://res.cloudinary.com/prodjour/image/upload/v1629113426/Marinara/Oregano_yne2t9.png"
                    }
                ]
            },
            {
                "id": "13",
                "name": "Carrot",
                "default": "13",
                "optionSet": [
                    {
                        "id": "13",
                        "name": "Carrot",
                        "qty": {
                            "amount": 30,
                            "unit": "g",
                            "secondary": "1/2 x 🥕"
                        },
                        "image": "https://res.cloudinary.com/prodjour/image/upload/v1629113426/Marinara/Carrot_b0d5ll.png"
                    }
                ]
            },
            {
                "id": "14",
                "name": "Soy Sauce",
                "default": "14",
                "optionSet": [
                    {
                        "id": "14",
                        "name": "Soy Sauce",
                        "qty": {
                            "amount": 1,
                            "unit": "tsp"
                        },
                        "image": "https://res.cloudinary.com/prodjour/image/upload/v1629113426/Marinara/Soy_Sauce_j6dqbr.png"
                    }
                ]
            },
            {
                "id": "15",
                "name": "Sugar",
                "default": "15",
                "optionSet": [
                    {
                        "id": "15",
                        "name": "Sugar",
                        "qty": {
                            "amount": 1,
                            "unit": "tsp"
                        },
                        "image": "https://res.cloudinary.com/prodjour/image/upload/v1629113426/Marinara/Sugar_mi8f6k.png"
                    }
                ]
            }
        ],
        "visualization": [
            {
                "type": "Ingredients6",
                "data": {
                    "title": "Ingredients",
                    "ingredientIds": [
                        "1",
                        "2",
                        "3",
                        "4",
                        "5",
                        "6"
                    ]
                }
            },
            {
                "id": uuid(),
                "type": "ContentSlide",
                "data": {
                    "url": "gs://cookclub-app.appspot.com/Marinara/Slide7.JPG"
                }
            }
        ]
    },
    "equipment": {
        "visualization": [
            {
                "id": uuid(),
                "type": "ContentSlide",
                "data": {
                    "url": "gs://cookclub-app.appspot.com/Marinara/Slide8.JPG"
                }
            }
        ]
    },
    "summary": {
        "visualization": [
            {
                "id": uuid(),
                "type": "ContentSlide",
                "data": {
                    "url": "https://res.cloudinary.com/dnz21rg8q/image/upload/v1629178683/marinara/Trailer_nwtomm.jpg"
                }
            }
        ]
    },
    "process": [
        {
            "label": "Process 1",
            "thumbnail": "https://res.cloudinary.com/prodjour/image/upload/v1627999893/Marinara/MahekSauce_cecim8.png",
            "id": uuid(),
            "visualization": [
                {
                    "id": uuid(),
                    "type": "InstructionFeed",
                    "data": {
                        "title": "Boil water for tomatoes",
                        "instructions": [
                            {
                                "icon": "add",
                                "steps": [
                                    {
                                        "text": "Water 💧",
                                        "action": {
                                            "type": "weight",
                                            "text": "1L"
                                        }
                                    }
                                ]
                            },
                            {
                                "icon": "boil",
                                "steps": [
                                    {
                                        "text": "Boil",
                                        "action": {
                                            "type": "timer",
                                            "text": "6mins",
                                            "data": {
                                                "initMin": 6,
                                                "beep": true,
                                                "label": "Boil Water"
                                            }
                                        }
                                    }
                                ]
                            }
                        ],
                        "backgroundMedia": {
                            "url": "gs://cookclub-app.appspot.com/Ingredients/Videos/WaterBoiling.mp4",
                            "offsetData": {
                                "left": "15%",
                                "heightZoom": "120%",
                                "widthZoom": "110%"
                            }
                        },
                        "flame": "high"
                    }
                },
                {
                    "id": uuid(),
                    "type": "PrepDisplay",
                    "data": {
                        "prepItems": [
                            {
                                "title": "Onion",
                                "qty": "½ x 🧅",
                                "weightOption": 35,
                                "endProductImage": "https://res.cloudinary.com/prodjour/image/upload/v1628065025/Marinara/DicedOnion_fk2rzu.png",
                                "endProductDescription": "Dice",
                                "onExpand": {
                                    "id": "f3bc44af-5d44-42fe-8ea3-80ae453af3cc",
                                    "type": "InstructionFeed",
                                    "data": {
                                        "title": "Prep: Onions",
                                        "instructions": [
                                            {
                                                "icon": "cut",
                                                "steps": [
                                                    {
                                                        "text": "½ Onion 🧅",
                                                        "action": {
                                                            "type": "weight",
                                                            "text": "30g"
                                                        }
                                                    }
                                                ]
                                            }
                                        ],
                                        "backgroundMedia": {
                                            "url": "gs://cookclub-app.appspot.com/Marinara/ChopOnions.mp4",
                                            "offsetData": {
                                                "left": "15%",
                                                "heightZoom": "130%",
                                                "widthZoom": "100%"
                                            },
                                            "trim": {
                                                "startTime": 13.802,
                                                "duration": 3.2
                                            }
                                        },
                                        "note": {
                                            "text": "Onions add sweetness to the sauce",
                                            "location": "bottom-left"
                                        }
                                    }
                                }
                            },
                            {
                                "title": "Garlic",
                                "qty": "10 cloves",
                                "weightOption": 12,
                                "endProductImage": "https://res.cloudinary.com/prodjour/image/upload/v1628065025/Marinara/DicedGarlic_orr0te.png",
                                "endProductDescription": "Dice",
                                "onExpand": {
                                    "id": "8d1830f9-6f10-40c0-87c8-3081f87779ed",
                                    "type": "InstructionFeed",
                                    "data": {
                                        "title": "Prep: Garlic",
                                        "instructions": [
                                            {
                                                "icon": "cut",
                                                "steps": [
                                                    {
                                                        "text": "10 cloves of garlic"
                                                    }
                                                ]
                                            }
                                        ],
                                        "backgroundMedia": {
                                            "url": "gs://cookclub-app.appspot.com/Ingredients/Videos/Garlic.mp4",
                                            "offsetData": {
                                                "left": "0%",
                                                "heightZoom": "115%",
                                                "widthZoom": "80%"
                                            }
                                        }
                                    }
                                }
                            },
                            {
                                "title": "Tomato",
                                "qty": "10 🍅",
                                "weightOption": 1000,
                                "endProductImage": "https://res.cloudinary.com/prodjour/image/upload/v1628065025/Marinara/SlitTomato_phyojy.png",
                                "endProductDescription": "Slit Shallow Cross",
                                "onExpand": {
                                    "id": "e8414c03-f931-4ea6-ade7-93096a623adb",
                                    "type": "ContentSlide",
                                    "data": {
                                        "url": "gs://cookclub-app.appspot.com/Marinara/Slide14.JPG"
                                    }
                                }
                            },
                            {
                                "title": "Basil",
                                "qty": "5 leaves",
                                "weightOption": 10,
                                "endProductImage": "https://res.cloudinary.com/prodjour/image/upload/v1628065025/Marinara/DicedBasil_wxel4r.png",
                                "endProductDescription": "Dice",
                                "onExpand": {
                                    "id": "f23eb374-c370-4447-8e4b-82e845e1a84c",
                                    "type": "ContentSlide",
                                    "data": {
                                        "url": "gs://cookclub-app.appspot.com/Marinara/Slide16.JPG"
                                    }
                                }
                            }
                        ]
                    }
                },
                {
                    "id": uuid(),
                    "type": "ContentSlide",
                    "data": {
                        "url": "https://res.cloudinary.com/prodjour/image/upload/v1628077703/Marinara/Slide19_rhjusz.png"
                    }
                }
            ]
        },
        {
            "label": "Phase 2",
            "thumbnail": "https://res.cloudinary.com/prodjour/image/upload/v1627999891/Marinara/CookPasta_xn0tnh.png",
            "id": uuid(),
            "visualization": [
                {
                    "id": uuid(),
                    "type": "ContentSlide",
                    "data": {
                        "url": "https://res.cloudinary.com/dnz21rg8q/image/upload/v1629180430/marinara/PastaAlDente_Section_zw1q3q.jpg"
                    }
                }
            ]
        }
    ]
}