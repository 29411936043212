import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const DeleteRecipe = ({onDelete, handleCloseDeleteMessage, openDeleteMessage}) => {
  return (
    <Box>
      <Dialog onClose={handleCloseDeleteMessage} open={openDeleteMessage}>
        <DialogTitle>Are you sure ?</DialogTitle>
        <DialogContent>
          <Button
            onClick={onDelete}
            variant="contained"
            style={{
              backgroundColor: "red",
              color: "white",
              marginRight: "20px",
            }}
          >
            Yes
          </Button>
          <Button
            onClick={handleCloseDeleteMessage}
            variant="contained"
            style={{ backgroundColor: "green", color: "white" }}
          >
            No
          </Button>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default DeleteRecipe;
