import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@mui/material'
import {
    mdiClockOutline,
    mdiClockTimeOneOutline,
    mdiClockTimeTwoOutline,
    mdiClockTimeThreeOutline,
    mdiClockTimeFourOutline,
    mdiClockTimeFiveOutline,
    mdiClockTimeSixOutline,
    mdiClockTimeSevenOutline,
    mdiClockTimeEightOutline,
    mdiClockTimeNineOutline,
    mdiClockTimeTenOutline,
    mdiClockTimeElevenOutline,
    mdiClockTimeTwelveOutline
} from '@mdi/js'
import Icon from '@mdi/react'

const iconForTime = mins => {
    if (!mins) return mdiClockOutline
    if (mins <= 5) return mdiClockTimeOneOutline
    if (mins <= 10) return mdiClockTimeTwoOutline
    if (mins <= 15) return mdiClockTimeThreeOutline
    if (mins <= 20) return mdiClockTimeFourOutline
    if (mins <= 25) return mdiClockTimeFiveOutline
    if (mins <= 30) return mdiClockTimeSixOutline
    if (mins <= 35) return mdiClockTimeSevenOutline
    if (mins <= 40) return mdiClockTimeEightOutline
    if (mins <= 45) return mdiClockTimeNineOutline
    if (mins <= 50) return mdiClockTimeTenOutline
    if (mins <= 55) return mdiClockTimeElevenOutline
    if (mins <= 60) return mdiClockTimeTwelveOutline
    return mdiClockOutline
}

const TimeWithClockIcon = ({ timeInMin, size }) => {
    return (
        <Grid justifyContent="flex-end" container alignItems="center" spacing={1}>
            <Grid item><Icon path={iconForTime(timeInMin)} size={size} color="green" /></Grid>
            <Grid item> <Typography> {timeInMin} mins</Typography></Grid>
        </Grid>
    );
}

TimeWithClockIcon.propTypes = {
    timeInMin: PropTypes.number.isRequired,
    size: PropTypes.number
}

TimeWithClockIcon.defaultProps = {
    size: 1
}

export default TimeWithClockIcon
