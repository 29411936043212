export const startSession = (sessionID = "default_id",
    deviceID = "default_device_ID",
    userID = "default_user",
    recipeID = "default_ID",
    startTime = Date.now()) => {
    console.log("start session logged")
}

export const endSession = (sessionID = "default_id",
    completionCode = "default_completion_code",
    CookingExperienceRating = "-1",
    foodExperienceRating = "-1",
    endTime = Date.now()) => {
    console.log("end session logged")
}

export const logEvents = (sessionID = "default_id",
    eventType = "default_event_type",
    eventTags = ["default_tag_1", "default_tag_2"],
    json = { speedDefault: 20 },
    timeStamp = Date.now()) => {
    console.log("events logged")
}