//HELP WANTED - HELP ME WRITE THIS BETTER!!!!!!!!!!!!!!!!!

/**
 * needs another property to keep track of array depth - currently doesnt work with > 2 nesting
 * @param {string} newData 
 * @param {object} data 
 * @param {number} position - keeps track of index in array that needs to be updated
 * @param {string} path - keeps track of the property's path that has been updated by user
 * @param {boolean} edit - checks if the field is to be updated or not
 * @returns 
 */

export const deleteAField = (data, schema, position, path, removeEmptyField, edit) => {
    const [currLocation, ...rest] = path
    if (schema.type === "string" || schema.type === "boolean" || schema.type === "number") {
        return data
    }
    if (schema.type === "array" && edit && removeEmptyField) {
        const arrData = data.reduce((old, next, i) => (i !== position) ? [...old, next] : old, [])
        return arrData
    }
    if (schema.type === "array" && edit) {
        const arrData = data.length > 1 ? data.reduce((old, next, i) => (i !== position) ? [...old, next] : old, []) : data
        return arrData
    }
    if (schema.type === "array") {
        const traversedData = []
        data.forEach((i, index) => {
            if (index !== position) traversedData.push(i)
            else {
                traversedData.push(deleteAField(i, schema.items, position, path, removeEmptyField, edit))
            }
        })
        return traversedData
    }

    const { properties } = schema
    const keys = Object.keys(properties)
    const traversedData = {};

    keys.forEach(i => {
        traversedData[i] = deleteAField(data[i], properties[i], position, rest, removeEmptyField, i === currLocation && rest.length === 0)
    })
    return traversedData

}

export const getGeneratedData = (newData, data, schema, position, path, edit) => {
    const [currLocation, ...rest] = path
    if ((schema.type === "string" || schema.type === "boolean" || schema.type === "number") && edit) {
        return newData
    }
    if (schema.type === "array" && edit) {
        const arrData = [...data]
        arrData[position] = newData
        return arrData
    }
    if (schema.type === "array" && position !== -1) {
        const traversedData = []
        data.forEach((i, index) => {
            if (index !== position) traversedData.push(i)
            else {
                traversedData.push(getGeneratedData(newData, i, schema.items, position, path, edit))
            }
        })
        return traversedData
    }
    if (schema.type === "string" || schema.type === "number" || schema.type === "boolean" || schema.type === "array") {
        return data
    }

    const { properties } = schema
    const keys = Object.keys(properties)
    const traversedData = {};

    keys.forEach(i => {
        traversedData[i] = getGeneratedData(newData, data[i], properties[i], position, rest, i === currLocation && rest.length === 0)
    })

    return traversedData
}

export const createNewData = (schema) => {
    if (schema.type === "string" || schema.type === "number" || schema.type === "boolean") return ""
    if (schema.type === "array") {
        const transversedData = []
        transversedData.push(createNewData(schema.items))
        if (transversedData[0] === "")
            return []
        return transversedData
    }
    const { properties } = schema
    const key = Object.keys(properties)
    const traversedData = {};

    key.forEach(i => {
        traversedData[i] = createNewData(properties[i])
    })
    return traversedData
}

export const appendNew = (data, path, schema, edit) => {
    const [currLocation, ...rest] = path

    if (schema.type === "string" || schema.type === "boolean" || schema.type === "number") {
        return data
    }
    if (schema.type === "array" && edit) {
        const generateData = createNewData(schema)
        return [...data, ...generateData]
    }
    if (schema.type === "array") {
        appendNew(data, rest, schema.items, edit)
        return data
    }

    const { properties } = schema
    const keys = Object.keys(properties)
    const traversedData = {};

    keys.forEach(i => {
        traversedData[i] = appendNew(data[i], rest, properties[i], i === currLocation && rest.length === 0)
    })

    return traversedData
}

export const checkData = (schema, data, propName) => {
    if (!data) return createNewData(schema)
    else if (schema.type === "string" || schema.type === "boolean" || schema.type === "number") return data
    else if (schema.type === "array") {
        const transversedData = data.map(i => checkData(schema.items, i, propName))
        return transversedData
    }

    const { properties } = schema
    const key = Object.keys(properties)
    const traversedData = {};

    key.forEach(i => {
        traversedData[i] = checkData(properties[i], data[i], propName)
    })
    return traversedData
}

// export const convertOnSave = (schema, data) => {
//     if (schema.type === "string" || schema.type === "boolean") return data
//     if (schema.type === "number") return Number(data)
//     if (schema.type === "array") {
//         const transversedData = data.map(i => convertOnSave(schema.items, i))
//         return transversedData
//     }
//     const { properties } = schema
//     const key = Object.keys(properties)
//     const traversedData = {};

//     key.forEach(i => {
//         traversedData[i] = convertOnSave(properties[i], data[i])
//     })

//     return traversedData
// }

export const splitPath = (path) => {
    const arrPath = path.split("/")
    arrPath.pop()
    arrPath.shift()
    return arrPath
}

export const fetchEdamamdata = (dataString) => {
    const url = `https://api.edamam.com/api/food-database/v2/parser?app_id=24c30d8c&app_key=%20a2cbb14635359e5ce6229116f41c5970%09&ingr=${dataString}&nutrition-type=cooking`
    return fetch(url, {
        headers: {
            'Accept': 'application/json'
        }
    })
        .then(data => data.json())
        .then(parsedData => {
            const data = parsedData.parsed.map(i => ({
                calorie: i.food.nutrients.ENERC_KCAL,
                fat: i.food.nutrients.FAT,
                carbs: i.food.nutrients.CHOCDF,
                protein: i.food.nutrients.PROCNT
            }))
            return data[0]
        })
        .catch(err => console.log(err))
}