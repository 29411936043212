import React, { useContext, useState } from "react"
import { HardwareContext } from "./HardwareTasks"
import WeightDisplay from "../appBar/WeightDisplay";
import PopUpDialog from "../PopUpDialog";
import { Button, SvgIcon, Typography, Box } from "@mui/material";
import PropTypes from "prop-types";
import { logEvents } from "../../utils/logging";
import { ReactComponent as WeighIcon } from "../../svg/weigh-icon.svg"

const WeightBtn = ({ weight, btnClass, iconSize = 28, iconWidth = "40%", icon = WeighIcon, nutrientInfo=null, img=null }) => {
    const { weight: reading, connectToHardware, connected } = useContext(HardwareContext)
    const [popScale, setPopScale] = useState(false)

    const toggleScale = (e) => {
        e.stopPropagation()
        if (connected) {
            const sessionId = sessionStorage.getItem("sessionId")
            logEvents(sessionId)
            setPopScale(old => !old)
        } else {
            connectToHardware()
        }
    }

    return (<>
        <Button
            className={btnClass}
            onClick={toggleScale}
        >
            <SvgIcon component={icon} viewBox="0 0 32.153 30.878" style={{ fontSize: iconSize, width: iconWidth }} />
            {weight ? <Typography variant="body1" style={{ marginLeft: 10, width: "60%", textAlign: "center" }}>{weight}</Typography> : null}
        </Button>
        <PopUpDialog
            dialogOpen={popScale}
            onClickBack={toggleScale}
            componentInDialog={<WeightDisplay reading={reading} text={weight || ""} nutrientInfo={nutrientInfo} img={img} />}
        />
    </>)
}

WeightBtn.propTypes = {
    weight: PropTypes.string,
    btnClass: PropTypes.string
}

export default WeightBtn