import React, { useState, useEffect } from 'react'
import { Box, Button, Typography, LinearProgress } from '@mui/material'
import { animated, useSpring } from 'react-spring'
import ScaleAnimation from './ScaleAnimation'
import TriangleBox from './TriangleBox'
import "../../css/weighingScale.css"
import MacrosGraph from './MacrosGraph'

const commonBtnStyle = { borderRadius: "2vh", fontSize: "4vh", textTransform: "none", padding: "2vh" }
const scaleImg = 'https://res.cloudinary.com/akshadasingh/image/upload/v1641813765/weighing-scale_iokk6k.png'

const WeightDisplay = ({ reading, text, img = "", nutrientInfo=null }) => {
    const [scaleProperties, setScaleProperties] = useState({
        tare: 0,
        add: 0,
        prevAdd: 0,
        displayedPrevAdd: null,
        displayedAdd: null,
        mode: "default",
    })
    const [transition, setTransition] = useState(false)

    const [styleDisplayAdd, setStyleDisplayAdd] = useSpring(() => ({
        from: { y: 50 },
        onRest: () => {
            setScaleProperties(old => ({ ...old, displayedPrevAdd: null, displayedAdd: old.add }))
            setTransition(false)
        }
    }))
    
    const setNutrientInfo = nutrientInfo || {carbs: 0, fat: 0, protein: 0, calorie: 0}
    const { carbs, fat, protein, calorie } = setNutrientInfo
    const newReading = reading - scaleProperties.tare
    const carbQty = Math.round(Math.abs((carbs/100)*newReading))
    const fatQty = Math.round(Math.abs((fat/100)*newReading))
    const proteinQty = Math.round(Math.abs((protein/100)*newReading))
    const calorieQty = Math.round(Math.abs((calorie/100)*newReading))

    const AnimatedTypography = animated(Typography)

    useEffect(() => {
        (transition) ?
            setStyleDisplayAdd({ y: 220, config: { duration: 700 } }) :
            setStyleDisplayAdd({ y: 50, config: { duration: 0 } })
    }, [transition])

    const onAdd = e => {
        setScaleProperties(old => ({
            ...old,
            tare: reading,
            add: old.add + (reading - old.tare),
            displayedPrevAdd: `${reading - old.tare} g`,
            mode: "add",
        }))
        setTransition(true)
    }

    const onTare = e => {
        setScaleProperties(old => ({ ...old, tare: reading, mode: "tare" }))
    }

    const onClear = e => {
        setScaleProperties(old => ({ ...old, displayedAdd: null, add: 0 }))
    }

    return (
        <Box className="parent" onClick={e => e.stopPropagation()}>
            <ScaleAnimation reading={(scaleProperties.mode === "add" && reading - scaleProperties.tare == 0) ?
                Math.ceil((scaleProperties.add) / 10) :
                Math.ceil((reading - scaleProperties.tare) / 10)} src={scaleImg} />
            <Box style={{ zIndex: 2 }}>
                <Typography
                    variant="h1"
                    style={{ fontWeight: 600 }}
                    align="center"
                >
                    {`${reading - scaleProperties.tare} g`}
                </Typography>
                <AnimatedTypography
                    variant="h1"
                    style={{ top: "7.8%", position: "absolute", fontWeight: 600, ...styleDisplayAdd }}
                >
                    {scaleProperties.displayedPrevAdd}
                </AnimatedTypography>
                <Box height="5vh" display="flex" flexDirection="row" alignItems={"flex-start"} sx={{marginBottom: 15}}>
                    {img && <img src={img} style={{ height: "8vh", width: "8vh" }} />}
                    {text === "" ? null : <Typography
                        variant="h3"
                        align="center"
                        style={{ fontWeight: 600, color: "#636363", lineHeight: 1.5, marginLeft: 20 }}
                    >
                        {text}
                    </Typography>
                    }
                </Box>
            </Box>
            <TriangleBox height={25}/>
            <Box
                style={{ height: "20%" }}
                display="flex"
                alignItems="center"
            >
                {<AnimatedTypography
                    variant="h1"
                    style={{ fontWeight: 600, marginLeft: 100 }}
                >
                    {scaleProperties.displayedAdd !== null ? `${scaleProperties.displayedAdd} g` : null}
                </AnimatedTypography>}
                {scaleProperties.displayedAdd !== null ?
                    <Button
                        style={{ color: "#ff0000", textDecoration: "underline", ...commonBtnStyle }}
                        onClick={onClear}
                    >
                        Clear
                    </Button> : null}
            </Box>
            <Button
                    style={{ border: "solid 2px #000000", color: "#000000", ...commonBtnStyle }}
                    onClick={onTare}
            >
                Bring to zero
            </Button>
            <Box display={"flex"} flexDirection={"row"} alignItems="flex-start" style={{width: "100vw"}}>
                <Box display="flex" alignItems={"center"} flexDirection={"column"}>
                    <Typography variant="h4">Calories</Typography>
                    <Typography variant="h3">{Math.round(calorieQty)}</Typography>
                </Box >
                <MacrosGraph data={setNutrientInfo} macros={{carbQty, fatQty, proteinQty, calorieQty}}/>
            </Box>
                {/* <Button
                    style={{ backgroundColor: "#3F51B5", color: "#FFFFFF", ...commonBtnStyle }}
                    onClick={onAdd}
                >
                    Add +
                </Button> */}
        </Box>
    )
}

export default WeightDisplay