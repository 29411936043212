import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Box } from '@mui/material'

const ListBox = ({ mainBox, sideBox = null, image }) => {
  const { text } = mainBox
  return <>
    {mainBox ?
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          borderRadius="20px"
          bgcolor="#FFD189"
          height="10.82vh"
          width="100%"
          margin="1.88vh 4.64vh"
        >
          <Box
          width="15.46vh">
              <img
              src={image}
              style={{ maxWidth: "100%", maxHeight: "15.46vh" }}
              alt={text}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            width="100%"
            alignItems="center"
            justifyContent="space-between"
            marginLeft="1vh"
          >
            <Typography
              align="start"
              style={{ color: "black", fontSize: "5.1vh", fontWeight: 600 }}>
              {text}
            </Typography>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              padding="0 3vh">
              {sideBox?.text ? <Typography
                style={{ fontSize: "3.4vh", fontWeight: 600 }}
              >
              {sideBox.text}
              </Typography> : null}
              {sideBox?.sidetext ?
                <Typography style={{ marginLeft: "1vh", fontSize: "3.4vh", fontWeight: 600 }}>{sideBox.sidetext}</Typography> : null
              }
              {sideBox?.subtext ?
                <Typography style={{ fontSize: "3.4vh", marginLeft: "2vh" }}>/ {sideBox.subtext}</Typography> : null
              }
            </Box>
          </Box>
        </Box>
      : null
    }
  </>;
}

ListBox.propTypes = {
    mainBox: PropTypes.shape({
      text: PropTypes.string.isRequired,
      subtext: PropTypes.string, }),
    sideBox: PropTypes.shape({
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      sidetext: PropTypes.string, subtext: PropTypes.string })
}

export default ListBox