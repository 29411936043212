import React from "react";
import StepsDescription from "./StepsDescription";
import PropTypes from 'prop-types';
import PrepButton from "./PrepButton";
import InstructionBox from "./InstructionBox";

const ExtraPrepType = ({ parameters, title, status, type }) => {
    return (
        <StepsDescription
            title={title}
            status={status}
            details={<InstructionBox text={parameters?.text} />}
            button={parameters?.item?.prep ? <PrepButton ingredientId={parameters?.item?.ingredientId}
                prepId={parameters?.item?.prep?.prepId}
                amount={parameters?.item?.amount}
            /> : null}
        />
    )
}

ExtraPrepType.propTypes = {
    parameters: PropTypes.object,
    title: PropTypes.string,
    status: PropTypes.string,
    type: PropTypes.string
}

export default ExtraPrepType