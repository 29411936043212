import { Dialog, Box, DialogContent } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles(() => ({
    root: {
        // "& .MuiPaper-root": {
        //     backgroundColor: "#0000",
        // },
        "& .MuiPaper-elevation24": {
            boxShadow: "none",
        },
        "& .MuiDialog-paper": {
            borderRadius: 20,
            padding: "40px 0"
        },
        "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
            marginLeft: 114,
            overflow: "hidden"
        }
    },
    bigBox: {
        "& .MuiDialog-paperWidthSm ": {
            width: "1040vw !important"
        },
        "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
            maxWidth: "1040vw",
        }
    },
    smallBox: {
        "& .MuiDialog-paperWidthSm ": {
            width: "800vw !important"
        },
        "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
            maxWidth: "800vw",
        }

    }

}));

const PopUpModal = ({ children, open, onClose = () => { }, size = "big" }) => {
    const classes = useStyles()
    return (<Dialog
        open={open}
        onClose={onClose}
        className={size === "big" ? `${classes.root} ${classes.bigBox}` : `${classes.root} ${classes.smallBox}`}
    >
        {children}
    </Dialog>)

}

export default PopUpModal