import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Box } from '@mui/material'
import ActionButtonSet from './elements/ActionButtonSet'
import ProcessTitleSlide from './ProcessTitleSlide'
import TimerDisplayUI from './elements/TimerDisplayUI'

const getR1Settings = (type, data) => {
    if (type === "basic")
        return data?.time
    return data.reduce((prev, next) => ({ time: prev.time + next.time })).time  
}

const SingleFocus = ({ text, align = "center", actionButtons = [], setReact1Settings = () => { }, floatingBtnsFunctions = () => {}, processTitleIds, slideId, singleFocusTimer = false, completedSteps = [], currentSlide, react1Settings = {}, hardwareState }) => {
    const r1Time = Object.keys(react1Settings).length > 0 ? getR1Settings(react1Settings?.type,react1Settings?.data) : 0
    const displayZero = completedSteps.includes(currentSlide - 1) || completedSteps.filter(i => i === currentSlide - 2 || i === currentSlide).length > 0
    return (
        <>
            {processTitleIds ? <ProcessTitleSlide
                text={text}
                processTitleIds={processTitleIds}
                slideId={slideId}
                actionButtons={actionButtons}
                floatingBtnsFunctions={floatingBtnsFunctions}
            /> :
                <Box
                    position="absolute"
                    height="100%"
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Typography variant="h2" align={align}>{text}</Typography>
                    {singleFocusTimer ? <TimerDisplayUI
                        r1Time={displayZero ? 0 : r1Time}
                        status={hardwareState}
                        completedSteps={completedSteps}
                        currentSlide={currentSlide}
                        runningTime
                    /> : null}
                </Box>}
            <ActionButtonSet actionButtons={actionButtons} setReact1Settings={setReact1Settings} floatingBtnsFunctions={floatingBtnsFunctions} />
        </>
    )
}

SingleFocus.propTypes = {
    text: PropTypes.string,
    singleFocusTimer: PropTypes.bool,
}

export default SingleFocus
