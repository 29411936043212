import { TextField, Button, List, ListItem, ListItemText, CircularProgress, Chip } from '@mui/material'
import React, { useState } from 'react'
import PropTypes from 'prop-types'

const RecipeJsonDisplay = ({
  idNameObject: displayRecipesList,
  onClickRecipeName,
  onAddNewRecipe,
  onDuplicateNewRecipe,
  saving,
  loading,
  type
}) => {
  const [searchItem, setSearchItem] = useState(null)
  function charIsLetter(char) {
    if (typeof char !== 'string') {
      return false;
    }
    return char.toLowerCase() !== char.toUpperCase();
  }
  const displayList = charIsLetter(searchItem?.replace(/\s+/g, '').trim()) ?
    displayRecipesList?.filter(i =>
      i?.name.replace(/\s+/g, '').trim()
        .toLowerCase().includes(
          searchItem?.replace(/\s+/g, ''
          ).trim().toLowerCase()))
    : displayRecipesList

  if (loading)
    return <CircularProgress />
  return <>
    <TextField autoFocus={true} label="search" value={searchItem} onChange={(e) => setSearchItem(e.target.value)} />
    <List style={{ overflowY: "scroll" }}>
      {displayList.map(recipe =>
        <ListItem button key={recipe.id} onClick={() => onClickRecipeName(recipe.id)}>
          <ListItemText primary={recipe.name} />{recipe.isActive && <Chip label="Active" color="primary" />}
        </ListItem>
      )}
    </List>
    {type !== "duplicate" &&
      <>
        <Button disabled={saving} color="primary" onClick={onAddNewRecipe} variant="contained">Add</Button>
        <Button disabled={saving} color="secondary" onClick={onDuplicateNewRecipe} variant="outlined">Duplicate</Button>
      </>
    }
  </>
}
RecipeJsonDisplay.propTypes = {
  displayRecipesList: PropTypes.array,
  onClickRecipeName: PropTypes.func,
  onAddNewRecipe: PropTypes.func,
  onDuplicateNewRecipe: PropTypes.func,
  saving: PropTypes.bool,
  loading: PropTypes.bool,
  type: PropTypes.string
}

export default RecipeJsonDisplay