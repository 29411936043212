import React, { useState } from "react";
import useRecipeJsonDB from "../components/newRecipeJson/NewRecipeDB";
import { useHistory } from 'react-router-dom'
import { Box, Grid } from '@mui/material'
import Experience2Card from "../components/recipeSelect/Experience2Card";
import HardwareBar from "../components/recipeSteps/HardwareBar";
import { HardwareTask } from "../components/recipeSteps/HardwareTasks";
import SecureArea from "../components/authentication/SecureArea";
import RecipeDisplay4 from "./RecipeDisplay4";

const Experience2Listing = () => {
    const { newRecipe, loading } = useRecipeJsonDB()
    const history = useHistory()
    const [id, setId] = useState(undefined)

    return (
        <SecureArea>
            <HardwareTask>
                <Box display="flex" height="100vh" width="100vw">
                    <HardwareBar />
                    <Box style={{
                        height: "100%",
                        overflowY: "scroll",
                        width: "100%"
                    }}>
                        {id ?
                            <RecipeDisplay4 recipeId={id} path="2" /> :
                            <Box style={{ boxSizing: "border-box", padding: "20px 50px" }}>
                                {!loading ?
                                    <Grid container spacing={3}>
                                        {newRecipe.map((recipe, i) => (
                                            <Grid xs={12} sm={6} item key={recipe?.id || i}>
                                                <Experience2Card
                                                    onCardClick={() => {
                                                        const currPath = `/experience2/${recipe.id}`
                                                        history.replace(currPath)
                                                        setId(recipe.id)
                                                    }}
                                                    url={recipe.metadata.url}
                                                    name={recipe.metadata.name}
                                                    totalTime={recipe.metadata.prepTime + recipe.metadata.cookTime} />                                    </Grid>
                                        ))}
                                    </Grid> :
                                    null}
                            </Box>}
                    </Box>
                </Box>
            </HardwareTask>
        </SecureArea >
    )
}

export default Experience2Listing