import React, { createContext, useState } from 'react'

export const FilterContext = createContext();

export const FilterProvider = (props) => {
  const [options, setOptions] = useState({
    categories: [],
    tags: [],
  })
  
  return (
    <FilterContext.Provider value={[options, setOptions]}>
      {props.children}
    </FilterContext.Provider>
  )
}
