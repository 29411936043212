import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Typography } from '@mui/material'
import CustomSlider from './CustomSlider'

const split = ["25%", "25%", "50%"]
const getTimeString = time => {
    const timeSec = time % 60
    const timeSecString = timeSec < 10 ? "0" + timeSec.toString() : timeSec.toString()
    return Math.floor(time / 60) + ":" + timeSecString
}

const getTimeDisplayString = (time, state, timeLeft) => {
    if (state === "idle")
        return getTimeString(time)
    //if (state === "operation")
    //  return "⏳" + getTimeString(timeLeft)
    return getTimeString(time)
}

const checkRange = (num, min, max) => {
    return (num - min) * (max - num) >= 0
}

const changeDelta = (mins) => {
    if (checkRange(mins, 0, 2)) {
        return 1
    }
    else if (checkRange(mins, 3, 4)) {
        return 2
    }
    else if (checkRange(mins, 5, 7)) {
        return 5
    }
    else if (checkRange(mins, 8, 10)) {
        return 10
    }
    else if (checkRange(mins, 11, 14)) {
        return 30
    }
    else {
        return 60
    }
}

const userTemp = (temp) => {
    if (checkRange(temp, 20, 30))
        return "Default"
    else if (checkRange(temp, 30, 70))
        return "Heating"
    else if (checkRange(temp, 71, 79))
        return "Slow Cooking"
    else if (checkRange(temp, 80, 99))
        return "Cooking"
    else if (checkRange(temp, 100, 109))
        return "Boiling"
    else if (checkRange(temp, 110, 120))
        return "Saute"
    else
        return ""
}

const userSpeed = (speed) => {
    if (checkRange(speed, 0, 0))
        return "Idle"
    else if (checkRange(speed, 1, 2))
        return "Mixing"
    else if (checkRange(speed, 3, 4))
        return "Chunky cuts"
    else if (checkRange(speed, 5, 7))
        return "Fine cuts"
    else if (checkRange(speed, 8, 10))
        return "Mincing"
    else if (checkRange(speed, 11, 12))
        return "Grinding"
    else
        return ""
}

const TimeSettingDisplay = ({ time, disableSettings, setTime = () => { }, timeLeft, hardwareStatus, startAnimation, animationDuration, animationDelay, change, selectedSlider, transform }) => {
    const timeDisplay = getTimeDisplayString(time, hardwareStatus, timeLeft)
    const deltaTime = 1
    const lowerLimitTime = 0
    const upperLimitTime = 3659
    const mins = Math.floor(time / 60)
    const deltaTimeWithKnob = useMemo(() => changeDelta(mins), [mins])

    useEffect(() => {
        change({ delta: deltaTimeWithKnob })
    }, [deltaTimeWithKnob, change])

    const scale = startAnimation ? { transform: "scale(0.90)", padding: "0" } : {}
    const setTimeSlider = () => change({ sliderName: "time", delta: deltaTimeWithKnob, upperLimit: upperLimitTime, lowerLimit: lowerLimitTime })
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            height="100%"
            width="33%"
            onClick={setTimeSlider}
        >
            <Box style={startAnimation ? { transform: transform, transitionDuration: "1s" } : {}}>
                <Box
                    textAlign="center"
                    style={startAnimation ? { transform: "translateX(-100vw)", transitionDuration: `${animationDuration}s`, transitionDelay: `${animationDelay}s` } : {}}
                >
                    <img
                        src="https://res.cloudinary.com/akshadasingh/image/upload/v1634904011/wall-clock_fcanii.svg"
                        style={{ padding: "1vh", width: "11vh", height: "11vh", ...scale }}
                        alt="time"
                    />
                    {startAnimation ? null : <Typography variant="h3">Time</Typography>}
                    <Typography variant="h3" style={{ ...scale }}>{timeDisplay}</Typography>
                    <Box height="5vh" />
                </Box>
            </Box>
            {!startAnimation ? <Box
                width="90%"
                display="flex"
                alignItems="center"
                flexDirection="column"
                justifyContent="space-evenly"
                style={{ height: split[2] }}
            >
                <Box
                    display="flex"
                    width="100%"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Button disabled={time < 60 || disableSettings} onClick={() => setTime(time - 60)}><Typography variant="h3">-</Typography></Button>
                    <Typography variant="h5">min</Typography>
                    <Button disabled={disableSettings} onClick={() => setTime(time + 60)}><Typography variant="h4">+</Typography></Button>
                </Box>
                <CustomSlider
                    value={time}
                    min={lowerLimitTime}
                    max={upperLimitTime}
                    step={deltaTime}
                    onChange={(e, value) => setTime(value)}
                    onFocus={setTimeSlider}
                    valueLabelFormat={x => x}
                    disabled={disableSettings}
                    selected={selectedSlider === "time" && !disableSettings}
                    styles={disableSettings ? {} : { color: "#0E3B53" }}
                />
                <Box
                    display="flex"
                    width="100%"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Button disabled={time < 1 || disableSettings} onClick={() => setTime(time - 1)}><Typography variant="h3">-</Typography></Button>
                    <Typography variant="h5">sec</Typography>
                    <Button disabled={disableSettings} onClick={() => setTime(time + 1)}><Typography variant="h4">+</Typography></Button>
                </Box>
            </Box> : null}
        </Box>
    )
}

const TempSettingDisplay = ({ temperature, setTemperature, disableSettings, startAnimation, animationDuration, animationDelay, change, selectedSlider, transform }) => {
    const deltaTemp = 5
    const upperLimitTemp = 120
    const lowerLimitTemp = 20
    const userTempSettings = userTemp(temperature)

    const scale = startAnimation ? { transform: "scale(0.90)", padding: "0" } : {}
    const setTempSlider = () => change({ sliderName: "temperature", delta: deltaTemp, upperLimit: upperLimitTemp, lowerLimit: lowerLimitTemp })

    return (<Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        height="100%"
        width="33%"
        onClick={e => {
            e.stopPropagation()
            setTempSlider()
        }}
        style={{ cursor: "none" }}
    >
        <Box
            style={startAnimation ? { transform: transform, transitionDuration: "1s" } : {}}
        >
            <Box
                textAlign="center"
                style={startAnimation ? { transform: "translateX(-100vw)", transitionDuration: `${animationDuration}s`, transitionDelay: `${animationDelay}s` } : {}}
            >
                <img
                    src="https://res.cloudinary.com/akshadasingh/image/upload/v1634904006/thermometer_zhvwgj.svg"
                    style={{ padding: "1vh", width: "11vh", height: "11vh", ...scale }}
                    alt="temperature"
                />
                {startAnimation ? null : <Typography variant="h3">Temperature</Typography>}
                <Typography variant="h3" style={{ ...scale }}>{`${temperature}°C`}</Typography>
                <Typography variant="h4" style={{ color: "#8F8F8F", ...scale }}>{`${userTempSettings}`}</Typography>
            </Box>
        </Box>
        {!startAnimation ? <Box
            width="90%"
            display="flex"
            alignItems="center"
            height={split[2]}
        >
            <CustomSlider
                value={temperature}
                min={lowerLimitTemp}
                max={upperLimitTemp}
                step={deltaTemp}
                onChange={(e, value) => setTemperature(value)}
                onFocus={setTempSlider}
                valueLabelFormat={x => x + "°C"}
                valueLabelDisplay="on"
                style={{ height: split[3], cursor: "none" }}
                disabled={disableSettings}
                selected={selectedSlider === "temperature" && !disableSettings}
                styles={disableSettings ? {} : { backgroundImage: "linear-gradient(0.25turn, #F7FF00, #FB8900,#FF0000)", color: "#0E3B53" }}
            />
        </Box> : null}
    </Box>)
}

const SpeedSettingDisplay = ({ speed, setSpeed, disableSettings, startAnimation, animationDuration, animationDelay, change, selectedSlider, transform }) => {
    const textForSpeed = speed > 12 ? "Stir" : speed
    const deltaSpeed = 1
    const lowerLimitSpeed = 0
    const upperLimitSpeed = 13
    const userSpeedSettings = userSpeed(speed)

    const scale = startAnimation ? { transform: "scale(0.90)", padding: "0" } : {}

    const setSpeedSlider = () => change({ sliderName: "speed", delta: deltaSpeed, upperLimit: upperLimitSpeed, lowerLimit: lowerLimitSpeed })

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            height="100%"
            width="33%"
            onClick={setSpeedSlider}
        >
            <Box
                textAlign="center"
                style={startAnimation ? { transform: transform, transitionDuration: "1s" } : {}}
            >
                <Box
                    textAlign="center"
                    style={startAnimation ? { transform: "translateX(-100vw)", transitionDuration: `${animationDuration}s`, transitionDelay: `${animationDelay}s` } : {}}
                >
                    <img
                        src="https://res.cloudinary.com/akshadasingh/image/upload/v1634904008/speedometer_irorhd.svg"
                        style={{ padding: "1vh", width: "11vh", height: "11vh", ...scale }}
                        alt="speed"
                    />
                    {startAnimation ? null : <Typography variant="h3">Speed</Typography>}
                    <Typography variant="h3" style={{ ...scale }}>{`${textForSpeed}`}</Typography>
                    {userSpeedSettings ? <Typography variant="h4" style={{ color: "#8F8F8F", ...scale }}>{`${userSpeedSettings}`}</Typography> :
                        <Box height="5vh" />}
                </Box>
            </Box>
            {!startAnimation ? <Box
                width="90%"
                display="flex"
                alignItems="center"
                height={split[2]}
            >
                <CustomSlider
                    value={speed}
                    min={lowerLimitSpeed}
                    max={upperLimitSpeed}
                    step={deltaSpeed}
                    getAriaValueText={() => speed}
                    onChange={(e, value) => setSpeed(value)}
                    onFocus={setSpeedSlider}
                    valueLabelFormat={x => x}
                    valueLabelDisplay="on"
                    style={{ height: split[3] }}
                    disabled={disableSettings}
                    selected={selectedSlider === "speed" && !disableSettings}
                    styles={disableSettings ? {} : { color: "#0E3B53" }}
                />
            </Box> : null}
        </Box>
    )
}

const TimeTempSpeed = ({ ttsSettings, setTtsSettings, sendFreeze, hardwareTimeLeft, hardwareState, startAnimation, startingR1Time, transform, settingsIndex }) => {
    const animationDuration = Math.ceil(startingR1Time / 3)
    const [slider, setSlider] = useState({})

    const change = useCallback((newSlider) => {
        setSlider(old => ({ ...old, ...newSlider }))
    }, [])

    const changeSlider = (e) => {
        switch (slider?.sliderName) {
            case "time": {
                if (e.deltaY >= 0 && ttsSettings.time < slider?.upperLimit)
                    setTtsSettings(old => ({ ...old, time: old.time + slider?.delta }), settingsIndex)
                else if (e.deltaY < 0 && ttsSettings.time > slider?.lowerLimit)
                    setTtsSettings(old => ({ ...old, time: old.time - slider?.delta }), settingsIndex)
                break
            }
            case "temperature": {
                if (e.deltaY >= 0 && ttsSettings.temperature < slider?.upperLimit)
                    setTtsSettings(old => ({ ...old, temperature: old.temperature + slider?.delta }), settingsIndex)
                else if (e.deltaY < 0 && ttsSettings.temperature > slider?.lowerLimit)
                    setTtsSettings(old => ({ ...old, temperature: old.temperature - slider?.delta }), settingsIndex)
                break
            }
            case "speed": {
                if (e.deltaY >= 0 && ttsSettings.speed < slider?.upperLimit)
                    setTtsSettings(old => ({ ...old, speed: old.speed + slider?.delta }), settingsIndex)
                else if (e.deltaY < 0 && ttsSettings.speed > slider?.lowerLimit)
                    setTtsSettings(old => ({ ...old, speed: old.speed - slider?.delta }), settingsIndex)
                break
            }
            default: return
        }
    }

    return (
        <Box
            width="100%"
            height="70vh"
            display="flex"
            justifyContent="space-evenly"
            alignItems="center"
            margin="2vh 0"
            onWheel={sendFreeze ? () => { } : changeSlider}
        >
            <TimeSettingDisplay
                time={ttsSettings.time}
                setTime={newVal => {
                    setTtsSettings(old => ({ ...old, time: newVal }), settingsIndex)
                }}
                disableSettings={sendFreeze}
                timeLeft={hardwareTimeLeft}
                hardwareStatus={hardwareState.status}
                startAnimation={startAnimation && settingsIndex === 0}
                animationDuration={animationDuration}
                animationDelay={1}
                change={change}
                selectedSlider={slider?.sliderName || null}
                transform={transform}
            />
            <TempSettingDisplay
                temperature={ttsSettings.temperature}
                setTemperature={newVal => {
                    setTtsSettings(old => ({ ...old, temperature: newVal }), settingsIndex)
                }}
                disableSettings={sendFreeze}
                startAnimation={startAnimation && settingsIndex === 0}
                animationDuration={animationDuration}
                animationDelay={animationDuration + 1}
                change={change}
                selectedSlider={slider?.sliderName || null}
                transform={transform}
            />
            <SpeedSettingDisplay
                speed={ttsSettings.speed}
                setSpeed={newVal => {
                    setTtsSettings(old => ({ ...old, speed: newVal }), settingsIndex)
                }}
                disableSettings={sendFreeze}
                startAnimation={startAnimation && settingsIndex === 0}
                animationDuration={animationDuration}
                animationDelay={2 * animationDuration}
                change={change}
                selectedSlider={slider?.sliderName || null}
                transform={transform}
            />
        </Box>
    )
}

TimeTempSpeed.propTypes = {
    ttsSettings: PropTypes.instanceOf(Object),
}

export default TimeTempSpeed
