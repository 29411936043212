import { Box, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import IngredientDetail from "./IngredientDetail";
import "../../css/ingredients.css"
import PropTypes from "prop-types";
import { VEGETABLES, MASALA, PANTRY } from "../../utils/experience2/typesVariable";
import IngredientsClass from "../recipeSteps/IngredientsClass";
import useScrollFeature from "../recipeSteps/ScrollFeature";
import { getSortedIngredients } from "../../utils/experience2/ingredientsUtil";

const refIndex = {
    [VEGETABLES]: 0,
    [MASALA]: 1,
    [PANTRY]: 2
}

const offsetForScroll = 180
const offsetForButtonSelection = 230

const DottedLine = () => {
    return (<Box className="dotted-line" />)
}

const VegetablesPantryDisplay = ({ items, displayDottedLine = true, display, ingredientsOnly = false, setNewRecipe }) => {
    if (display) {
        return (<>
            <Box padding="28px 0">
                <IngredientDetail data={items} ingredientsOnly={ingredientsOnly} setNewRecipe={setNewRecipe} />
            </Box>
            {displayDottedLine ? <DottedLine /> : null}
        </>)
    }
    return null
}

const PowderSpicesDisplay = ({ display, items, title, ingredientsOnly = false, setNewRecipe }) => {
    if (display) {
        return (<>
            <Typography variant="button">{title}</Typography>
            <IngredientDetail data={items} ingredientsOnly={ingredientsOnly} setNewRecipe={setNewRecipe} />
        </>)
    }
    return null
}

const WholeSpicesDisplay = ({ display, title, items, ingredientsOnly = false, setNewRecipe }) => {
    if (display) {
        return (<>
            <Typography variant="button">{title}</Typography>
            <IngredientDetail data={items} ingredientsOnly={ingredientsOnly} setNewRecipe={setNewRecipe} />
        </>)
    }
    return null
}

const SpicesDisplay = ({ display, children, displayDottedLine = true }) => {
    if (display) {
        return (<>
            <Box padding="3% 0">
                {children}
            </Box>
            {displayDottedLine ? <DottedLine /> : null}
        </>)
    }
    return null
}

const IngredientsList = ({ items, ingredientsOnly = false, ingredientsOnlyStyle = "", height = 90, setNewRecipe }) => {
    const [displayItems, setDisplayItems] = useState(null)
    const [selectBtn, setSelectBtn] = useState(VEGETABLES)
    const { createParentRef, createChildrenRef, scroll, childrenRef } = useScrollFeature()

    const changeSelectedBtn = () => {
        const scrollPosition = document.querySelector("#scroll").scrollTop + offsetForButtonSelection

        const vegetableIndex = refIndex[VEGETABLES]
        const spicesIndex = refIndex[MASALA]
        const pantryIndex = refIndex[PANTRY]

        const vegOffset = childrenRef.current[vegetableIndex]?.offsetTop
        const spicesOffset = childrenRef.current[spicesIndex]?.offsetTop
        const pantryOffset = childrenRef.current[pantryIndex]?.offsetTop

        if (scrollPosition >= vegOffset && scrollPosition < spicesOffset && selectBtn !== VEGETABLES)
            setSelectBtn(VEGETABLES)
        else if (scrollPosition >= spicesOffset && scrollPosition < pantryOffset && selectBtn !== MASALA)
            setSelectBtn(MASALA)
        else if (scrollPosition >= pantryOffset && selectBtn !== PANTRY)
            setSelectBtn(PANTRY)
    }

    useEffect(() => {
        const newItems = getSortedIngredients(items)
        if (newItems)
            setDisplayItems(newItems)
    }, [items, getSortedIngredients])

    useEffect(() => {
        if (displayItems?.vegetables && displayItems.vegetables.length > 0)
            setSelectBtn(VEGETABLES)
        else if (displayItems?.spices && (displayItems.spices.whole.length > 0 || displayItems.spices.powder.length > 0))
            setSelectBtn(MASALA)
        else if (displayItems?.pantry && displayItems.pantry.length > 0)
            setSelectBtn(PANTRY)
    }, [displayItems])

    return (
        <Box height={`${height}vh`} id="scroll" onScroll={changeSelectedBtn} style={{ overflowX: "scroll" }} ref={el => createParentRef(el)}>
            <Box className="ingredients-btn-parent" >
                <IngredientsClass
                    display={displayItems?.vegetables ?
                        displayItems.vegetables.length > 0 :
                        true}
                    value={VEGETABLES}
                    onClickBtn={() => scroll(refIndex[VEGETABLES], -offsetForScroll, "smooth")}
                    text={VEGETABLES}
                    btnClass={selectBtn === VEGETABLES ?
                        "ingredient-btn green-btn add-border" :
                        "ingredient-btn default-btn"}
                />
                <IngredientsClass
                    display={displayItems?.spices ?
                        displayItems.spices.whole.length > 0 || displayItems.spices.powder.length > 0 :
                        true}
                    value={MASALA}
                    onClickBtn={() => scroll(refIndex[MASALA], -offsetForScroll, "smooth")}
                    text={MASALA}
                    btnClass={selectBtn === MASALA ?
                        "ingredient-btn yellow-btn add-border" :
                        "ingredient-btn default-btn"}
                />
                <IngredientsClass
                    display={displayItems?.pantry ?
                        displayItems.pantry.length > 0 :
                        true}
                    value={PANTRY}
                    onClickBtn={() => scroll(refIndex[PANTRY], -offsetForScroll, "smooth")}
                    text={PANTRY}
                    btnClass={selectBtn === PANTRY ?
                        "ingredient-btn brown-btn add-border" :
                        "ingredient-btn default-btn"}
                />
            </Box>
            <Box className={`ingredient-separator-parent ingredient-list-parent ${ingredientsOnlyStyle}`}>
                <Box ref={el => createChildrenRef(el, refIndex[VEGETABLES])}>
                    <VegetablesPantryDisplay
                        items={displayItems?.vegetables}
                        display={displayItems?.vegetables ?
                            displayItems.vegetables.length > 0 :
                            true}
                        ingredientsOnly={ingredientsOnly}
                        setNewRecipe={setNewRecipe}
                    />
                </Box>
                <Box ref={el => createChildrenRef(el, refIndex[MASALA])}>
                    <SpicesDisplay
                        display={displayItems?.spices ?
                            displayItems.spices.whole.length > 0 || displayItems.spices.powder.length > 0 :
                            true}
                    >
                        <PowderSpicesDisplay
                            display={displayItems?.spices?.powder.length > 0}
                            title="Powder Spices"
                            items={displayItems?.spices?.powder}
                            ingredientsOnly={ingredientsOnly}
                            setNewRecipe={setNewRecipe}
                        />
                        <WholeSpicesDisplay
                            display={displayItems?.spices?.whole.length > 0}
                            title="Whole Spices"
                            items={displayItems?.spices?.whole}
                            ingredientsOnly={ingredientsOnly}
                            setNewRecipe={setNewRecipe}
                        />
                    </SpicesDisplay>
                </Box>
                <Box ref={el => createChildrenRef(el, refIndex[PANTRY])}>
                    <VegetablesPantryDisplay
                        items={displayItems?.pantry}
                        display={displayItems?.pantry ?
                            displayItems.pantry.length > 0 :
                            true}
                        displayDottedLine={false}
                        ingredientsOnly={ingredientsOnly}
                        setNewRecipe={setNewRecipe}
                    />
                </Box>
            </Box>
        </Box>
    )
}

IngredientsList.propTypes = {
    items: PropTypes.object
}

export default IngredientsList