import { Box, Stack, Grid, Card, CardContent, Button, Typography, IconButton } from "@mui/material";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteIcon from '@mui/icons-material/Delete'
import React from "react";
import SubStepRender from "./SubStepRender";
import RenderInputField from "./RenderInputField"
import AddIcon from '@mui/icons-material/Add'
import {v4 as uuid} from "uuid"
import { defaultSubStep } from "./DropdownOptions";
import { Draggable } from "react-beautiful-dnd";

const subStepSchema = {
  type: "object",
  properties: {
    name: {
      type: "string",
      title: "NAME",
      description: "Name of the step",
    },
    id: { type: "string", title: "Id", description: "Id of the step" },
    description: { type: "string", title: "DESCRIPTION", description: "description of the step" },
  }
}

const StepEdit = ({ subStepData, onStepPropertyChange, index, localRecipeJson=[] }) => {
  const { properties } = subStepSchema

  // onSubStepChange -> came from arrayAccordionRender for each step[0],[1],[2]
  // changes substeps array 
  const onPropertyChange = (newVal) => {
    onStepPropertyChange({ ...subStepData, subSteps: newVal })
  }
  // Changes input for id, name, description of steps
  const onInputChange = (e, props, type) => {
    onStepPropertyChange({ ...subStepData, [props]: e.target.value })
  }

  const addSubStep = () => {
    const newSubStep = {...defaultSubStep, id: uuid()}
    onStepPropertyChange({...subStepData, subSteps: [...subStepData.subSteps, newSubStep]}) 
  } 

  const onDelSubStep = (subStep) => {
    onStepPropertyChange({...subStepData, subSteps: subStepData.subSteps.filter((i) => i!== subStep)})
  }

  const onMoveUp = (e, index) => {
    e.stopPropagation()
    var ret = Array.from(subStepData.subSteps)
    const temp = ret[index]
    ret[index] = ret[index - 1]
    ret[index - 1] = temp
    onStepPropertyChange({...subStepData, subSteps: ret})
  }

  const onMoveDown = (e, index) => {
      e.stopPropagation()
      var ret = Array.from(subStepData.subSteps)
      const temp = ret[index]
      ret[index] = ret[index + 1]
      ret[index + 1] = temp
      onStepPropertyChange({...subStepData, subSteps: ret})
  }


  return (
        <Grid container>
          {Object.keys(properties).map((property) => (
            <Grid item xs={4} key={property}>
              <Box display={"flex"} >
                <RenderInputField
                  schemaProperties={properties[property]}
                  objectData={subStepData[property]}
                  onInputChange={(e) => onInputChange(e, property, properties[property].type)}
                />
              </Box>
            </Grid>
          ))}
          <Grid container rowSpacing={2}>
            {subStepData.subSteps.map((subStep, index) =>
            <Draggable draggableId={subStep.id} index={index} key={subStep.id}>
              {(provided, snapshot) => (
                <Grid item xs={12} key={subStep.id}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  ref={provided.innerRef}
                >
                  <Card>
                    <CardContent>
                      <Stack direction={"row"} justifyContent={"space-between"}>
                        <Stack direction={"row"} spacing={2}>
                          <Typography variant="h5">{`Sub-step #${index + 1} ${subStep.type}`}</Typography>
                          <IconButton
                            disabled={index===0} 
                            color="success" 
                          >
                            <ArrowUpwardIcon 
                              onClick={(e) => onMoveUp(e, index)}
                            />
                          </IconButton>
                          <IconButton
                            disabled={index===subStepData.subSteps.length-1} 
                            color="error" 
                          >
                            <ArrowDownwardIcon 
                              onClick={(e) => onMoveDown(e, index)}
                            />
                          </IconButton>
                        </Stack>
                        <Button onClick={() => onDelSubStep(subStep)}><DeleteIcon color="secondary"/></Button>
                      </Stack>
                      <SubStepRender
                        localRecipeJson={localRecipeJson}
                        subStepData={subStep}
                        onSubStepChange={newValue => onPropertyChange(subStepData.subSteps.map((subStep, i) => i === index ? newValue : subStep))}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              )}
            </Draggable>
            )}
            <Button onClick={addSubStep}><AddIcon /></Button>
          </Grid>
        </Grid>
  )
}

export default StepEdit;
