import { useEffect, useState } from 'react'
import firebaseApp from "../../firebase/config"
import { getFirestore, collection, getDocs, query, orderBy, addDoc, setDoc, doc, deleteDoc } from 'firebase/firestore'


const db = getFirestore(firebaseApp)

const useRecipeJsonDB = (collectionName = "newRecipeJson") => {
  const [newRecipe, setNewRecipe] = useState([])
  const [saving, setSaving] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    setLoading(true)
    const fetchRecipe = async () => {
      try {
        const data = await getDocs(query(collection(db, collectionName), orderBy("metadata.name")))
        const r = data.docs.map(doc => ({ ...doc.data(), id: doc.id }))
        setNewRecipe(r)
      }
      catch (err) {
        setError(err)
      }
      setLoading(false)
    }
    fetchRecipe()

  }, [collectionName])

  const saveNewRecipe = async (newData, id = null, callback = () => { }) => {
    const recipeCollectionRef = collection(db, collectionName)
    setSaving(true)

    try {
      if (id === 'something') {
        const data = await addDoc(recipeCollectionRef, newData)
      } else {
        const data = await setDoc(doc(db, collectionName, id), newData)
      }
    } catch (err) {
      setError(err.message)
    }
    setSaving(false)
  }


  const deleteNewRecipe = async (id, callback = () => { }) => {
    const recipeCollectionRef = doc(db, collectionName, id)

    try {
      const data = await deleteDoc(recipeCollectionRef)
    } catch (err) {
      setError(err.message)
    }
  }

  return { saveNewRecipe, deleteNewRecipe, newRecipe, error, saving, loading }

}

export default useRecipeJsonDB
