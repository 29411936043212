export const subStepsType = [
  {
    id: "",
    type: "addIngredients",
    name: "",
    parameters: {
      items: [
        {
          ingredientId: "L2eBXrU6zZKpi3C0jdCG",
          amount: { qty: 1, unit: "tbsp" },
          scaleModel: { type: "linear", parameters: {} },
        },
      ],
    },
  },
  {
    type: "saute",
    id: "",
    name: "",
    parameters: {
      settings: [{
        temperature: "90",
        time: 180,
      }],
      cap: "on",
    },
    suggestion: "wait",
  },
  {
    type: "cut",
    id: "",
    name: "",
    parameters: {
      settings: [{ time: 10, temperature: "20", speed: 10 }],
      cap: "on",
    },
  },
  {
    type: "scrape",
    id: "",
    name: "",
    parameters: {},
  },
  {
    type: "cook",
    id: "",
    name: "",
    parameters: {
      settings: [{ time: 900, temperature: "100", speed: 1 }],
      cap: "on",
    },
    suggestion: "wait",
  },
  {
    type: "boil",
    id: "",
    name: "",
    parameters: {
      settings: [{
        temperature: "120",
        speed: 0,
        time: 240,
      }],
      cap: "on",
    },
    suggestion: "wait",
  },
  {
    type: "mix",
    id: "",
    name: "",
    parameters: {
      settings: [{
        time: 60,
        speed: 1,
        temperature: "120",
      }],
      cap: "on",
    },
    suggestion: "wait",
  },
  {
    type: "imgUtility",
    id: "",
    name: "",
    parameters: {
      title: "CAP ON",
      url: "https://res.cloudinary.com/swateaaa/image/upload/v1648104217/upload-for-json/cap-on_dyrzog.png",
      style: {},
      type: "",
      weigh: 0,
      settings: [{
        time: 0,
        temperature: "",
        speed: 0,
      }],
    },
  },
];

export const initialObject = {
  metadata: {
    name: "New Recipe",
    prepTime: 10,
    cookTime: 25,
    active: false,
    description: "A super fast recipe",
  },
  steps: [
    {
      name: "Cook Recipe",
      id: "",
      subSteps: [
        {
          type: "scrape",
          id: "",
          name: "",
          parameters: {},
        },
      ],
    },
  ],
};

export const defaultStep = {
  name: "Cook Recipe",
  id: "",
  subSteps: [
    {
      type: "saute",
      id: "",
      name: "",
      parameters: {
        settings: [{
          temperature: "90",
          time: 180,
        }],
        cap: "on",
      },
      suggestion: "wait",
    }
  ]
}

export const defaultSubStep = {
  type: "saute",
  id: "",
  name: "",
  parameters: {
    settings: [{
      temperature: "90",
      time: 180,
      speed: 0
    }],
    cap: "on",
  },
  suggestion: "wait",
}

export const defaultSettings = [{
  temperature: "90",
  time: 180,
  speed: 0
}]