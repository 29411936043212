import { Box } from "@mui/material";
import React from "react";
import Label from "./Label";
import Type from "./Type";
import PropTypes from "prop-types";

const ObjectEditor = ({ data, schema, editData, path, position, property, addNewField = null, delBtn = null, deleteField = null }) => {
    const { properties: schemaProperties } = schema
    if (!data || !schemaProperties) return null
    return (<>
        {Object.keys(schemaProperties).map((i, index) => <Box key={`${i}${index}`} display="flex" style={{ gap: 10 }} alignItems="center">
            <Label label={schemaProperties[i].title ? schemaProperties[i].title : i} />
            <Type
                title={schemaProperties[i].title ? schemaProperties[i].title : i}
                description={schemaProperties[i]?.description}
                data={data[i]}
                schema={schemaProperties[i]}
                editData={editData}
                path={`${path}${i}/`}
                position={position}
                addNewField={addNewField}
                deleteField={deleteField}
            />
        </Box>)}
        {delBtn}
    </>)
}

ObjectEditor.propTypes = {
    data: PropTypes.object,
    schema: PropTypes.object,
    path: PropTypes.string,
    position: PropTypes.number,
    addNewField: PropTypes.func,
    delBtn: PropTypes.element,
    deleteField: PropTypes.func
}

export default ObjectEditor