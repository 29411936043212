import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import SignIn from './SignIn'
import { AuthContext } from '../../firebase/context'

const SecureArea = ({ children, adminOnly, landscapeWarning = true, signInMessage = adminOnly ? "This Page is Admin Access Only" : "Please Login using your gmail account" }) => {
    const { user, loading } = useContext(AuthContext)
    const adminEmailList = ["mahekmody@gmail.com", "calibremohit@gmail.com"]
    const adminValidate = /[a-zA-Z]+@reactlabs.ai/g; // give access to name@reactlabs.ai
    if (user) {
        if (!adminOnly || adminEmailList.includes(user.email) || adminValidate.test(user.email)) {
            return children
        }
        return <SignIn
            adminOnly={adminOnly}
            signInDisable
            landscapeWarning={landscapeWarning}
            signInMessage={signInMessage}
        />
    }
    return <SignIn
        adminOnly={adminOnly}
        landscapeWarning={landscapeWarning}
        signInMessage={signInMessage}
        loading={loading}
    />
}

SecureArea.propTypes = {
    adminOnly: PropTypes.bool
}

SecureArea.defaultProps = {
    adminOnly: false
}

export default SecureArea