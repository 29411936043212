import React from 'react'
import PropTypes from 'prop-types'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import 'swiper/swiper-bundle.min.css'
import { EffectFade, Keyboard, Virtual, Navigation, Pagination } from 'swiper'

const CaroselAbsraction = ({ slides, onSlideChange, setSwiperInstance, pagination = false, navigation = false, allowTouchMove = true, effect = "slide" }) => {
    return (
        <Swiper
            keyboard={{ enabled: true }}
            onSlideChange={onSlideChange}
            onSwiper={setSwiperInstance}
            virtual={{ addSlidesAfter: 4, addSlidesBefore: 2 }}
            modules={[Keyboard, Virtual, Pagination, Navigation, EffectFade]}
            pagination={pagination}
            navigation={navigation}
            allowTouchMove={allowTouchMove}
            effect={effect === "fade" ? "fade" : 'slide'}
            fadeEffect={effect === "fade" ? {
                crossFade: true
            } : undefined}
        >
            {
                slides.map((slide, index) => <SwiperSlide key={index} virtualIndex={index}>
                    {({ isActive }) => slide(isActive)}
                </SwiperSlide>)
            }
        </Swiper >
    )
}

CaroselAbsraction.propTypes = {
    slides: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.element])).isRequired,
    onSlideChange: PropTypes.func,
    setSwiperInstance: PropTypes.func,
}

export default CaroselAbsraction
