import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import ContentSlide from '../../ContentSlide'
import { initSettings } from '../../../utils/settings'

const OffsetMedia = ({ url, playmedia,
    offsetData: { top = "0%", left = "0%", heightZoom = "100%", widthZoom = "100%" } = { top: "0%", left: "0%", heightZoom: "100%", widthZoom: "100%" },
    trim = null,
    mute = false,
    loop = false
}) => {
    const outboxStyle = {
        position: "absolute",
        overflow: "hidden",
        height: "100%",
        width: "100%"
    }
    const innerboxStyle = {
        position: "absolute",
        top: top,
        left: left,
        overflow: "hidden",
        height: heightZoom,
        width: widthZoom
    }
    const trimData = trim ? { startTime: trim.startTime, duration: trim.duration } : {}
    return (
        <Box style={outboxStyle}>
            <Box style={innerboxStyle}>
                <ContentSlide
                    slide={{ url: url, ...trimData }}
                    isCurrentSlide={playmedia}
                    settings={{ ...initSettings, videoMuted: mute, videoLoop: loop }}
                />
            </Box>
        </Box>
    )
}

OffsetMedia.propTypes = {
    url: PropTypes.string.isRequired,
    offsetData: PropTypes.object.isRequired
}

export default OffsetMedia
