import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography, Box } from '@mui/material'
import CallToActionButton from './CallToActionButton'
import getIconUrl, { iconList } from '../../../utils/iconList'

const instructionStyleData = {
    iconHeight: 45,
    iconPadding: "10px",
    rounding: "10px"
}

const callToActionIconToIconMap = {
    weight: "scales",
    timer: "timer",
    react1: "react1"
}

const getIconfromActionIcon = x => x in callToActionIconToIconMap ? callToActionIconToIconMap[x] : "question"

const StepCallToAction = ({ type, text, data, setReact1Settings, setScaleSettings }) => {
    const onReact1SettingsSet = () => setReact1Settings({ type: "basic", data: { time: data?.time, speed: data?.speed, temperature: data?.temperature } })
    const onWeightSet = textForScales => setScaleSettings({text: textForScales})
    const iconType = getIconfromActionIcon(type)
    const onClick = () => {
        if (iconType === "react1") {
            if (data)
                onReact1SettingsSet()
        }
        else if (iconType === "scales") {
            onWeightSet(text)
        }
    }
    return <CallToActionButton
        iconType={iconType}
        text={text}
        onClick={onClick}
    />
}

const InstructionStepLine = ({ text, action, setReact1Settings, setScaleSettings }) => {
    return <Box display="flex" justifyContent="space-between" alignItems="center" key={text}>
        <Typography variant="h4">{text}</Typography>
        {action ? <StepCallToAction
            type={action?.type}
            text={action.text}
            // img={action?.}
            data={action.data}
            setReact1Settings={setReact1Settings}
            setScaleSettings={setScaleSettings}
        /> : null}
    </Box>
}

const InstructionStep = ({ icon, steps, setReact1Settings, setScaleSettings, stepCompleted}) => {
    const { iconHeight, iconPadding, rounding } = instructionStyleData
    return (
        <Box
            borderRadius="undefinedpx"
            bgcolor="#7DC2E9"
        >
            <Grid spacing={0} container alignItems="center" alignContent="center" justifyContent="flex-start">
                <Grid item >
                    <img
                        src={icon === "add" &&  stepCompleted ? getIconUrl("tick") : getIconUrl(icon)}
                        alt={icon}
                        width={iconHeight}
                        style={{ margin: iconPadding }}
                    />
                </Grid>
                <Grid container item xs={10} direction="column">
                    {steps.map(({ action = null, text }) => <InstructionStepLine
                        text={text}
                        action={action}
                        key={text}
                        setReact1Settings={setReact1Settings}
                        setScaleSettings={setScaleSettings}
                    />)}
                </Grid>
            </Grid>
        </Box>
    );
}

InstructionStep.propTypes = {
    icon: PropTypes.oneOf(iconList),
    steps: PropTypes.arrayOf(PropTypes.shape({ text: PropTypes.string, action: PropTypes.object })),
}

export default InstructionStep
