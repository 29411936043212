import {
  Card, CardContent
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import ListContent from "../../utils/homepage/ListContent";
import ListImage from "../../utils/homepage/ListImage";

const RecipeCard2 = ({
  recipeImage,
  time,
  name,
  tags = [],
  categories=[],
  onClick = () => { },
  loading = false
}) => {
  return (
    <Card onClick={onClick}>
      <ListImage image={recipeImage} loading={loading} />
      <CardContent>
        <ListContent time={time} title={name} tags={tags} categories={categories} loading={loading} />
      </CardContent>
    </Card>
  );
};

RecipeCard2.propTypes = {
  recipeImage: PropTypes.string.isRequired,
  time: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  tags: PropTypes.array,
  categories: PropTypes.array,
  onClick: PropTypes.func,
};

export default RecipeCard2;
