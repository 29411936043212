import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Chip, Box, TextField, Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

const TextFieldWithAddButton = ({ onAdd, placeholder = "" }) => {
    const [textFieldText, setTextFieldText] = useState(placeholder)
    const onAddButtonClick = () => {
        if (textFieldText === "") return
        onAdd(textFieldText)
        setTextFieldText("")
    }
    return <Box display="flex">
        <TextField value={textFieldText} onChange={e => setTextFieldText(e.target.value)} size="small" />
        <Button color="primary" variant="contained" onClick={onAddButtonClick}><AddIcon /></Button>
    </Box>

}

const ChipInput = ({ value, onAdd, onDelete, placeholder = "" }) => {
    return (
        <Box display="flex" alignItems="center">
            {value.map((chipString, index) => <Chip key={chipString} label={chipString} onDelete={() => { onDelete(chipString, index) }} />)}
            <TextFieldWithAddButton onAdd={onAdd} placeholder={placeholder} />
        </Box>
    )
}

ChipInput.propTypes = {
    value: PropTypes.arrayOf(PropTypes.string)
}


export default ChipInput