import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Typography, ButtonBase } from '@mui/material'
import TimeTempSpeed from './elements/TimeTempSpeed'
import CapDisplay from './CapDisplay'
import CaroselAbsraction from '../../utils/CaroselAbsraction'
import AddStepBtn from './elements/AddStepBtn'
import DeleteStepBtn from './elements/DeleteStepBtn'

const SimpleSliderLayout = ({ hardwareTimeLeft, hardwareSettings, setHardwareSettings, hardwareState, onSend, onStop, connectUI, connected, connectToSerial, modeOfOperation, displayedTimer }) => {
    const sendFreeze = hardwareState.status !== "idle"
    const hardwareSettingsCopy = hardwareSettings?.type === "basic" ? [hardwareSettings?.data] : hardwareSettings?.data
    const ttsSettings = i => hardwareSettings?.type === "basic" ? hardwareSettings?.data : hardwareSettings?.data[i]
    const startAnimation = hardwareState.status === "starting" && !connectUI ? true : false
    const { temperature, speed } = ttsSettings(0)
    const wakeTime = 2
    const startTime = 0.5
    const bufferTime = 2
    const startingR1Time = Math.ceil(wakeTime + startTime + (temperature - 19) * 0.1 + speed * 0.1 + bufferTime)

    const [currentSerialIndex, setCurrentSerialIndex] = useState(0)
    const [instructionSwiperInstance, setInstructionSwiperInstance] = useState({ activeIndex: 0 })

    const onSlideChange = () => setCurrentSerialIndex(instructionSwiperInstance?.activeIndex)

    const prevInstructionLength = useRef(0)

    useEffect(() => {
        const settingLength = hardwareSettings.data.length || 1
        if (settingLength - prevInstructionLength.current > 0 && prevInstructionLength.current)
            instructionSwiperInstance.slideNext(500)

        prevInstructionLength.current = hardwareSettings.data.length || 1
    }, [hardwareSettings])


    const setTtsSettings = (newTts, index) => {
        if (hardwareSettings?.type === "basic") {
            setHardwareSettings(old => ({ ...old, data: newTts(old.data) }))
            return
        }
        setHardwareSettings(old => {
            const currentData = old.data[index]
            const changedCurrentData = newTts(currentData)
            const changedData = old.data.map((items, i) => {
                if (index === i) {
                    return changedCurrentData
                }
                return items
            })
            return { ...old, data: [...changedData] }
        })
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            margin="0 10vh"
            height="90vh"
            onMouseDown={!sendFreeze ?
                (e) => {
                    e.stopPropagation()
                    if (e.button === 1)
                        onSend(startingR1Time)
                    else
                        return
                } :
                () => { }
            }
            alignItems="center"
        >
            {modeOfOperation === "manual" &&
                hardwareSettings.type === "serial" &&
                !((["operation", "cooldown", "starting"]).includes(hardwareState.status)) ?
                <DeleteStepBtn
                    setHardwareSettings={setHardwareSettings}
                    currentSerialIndex={currentSerialIndex}
                />
                : null}
            {startAnimation ? <CapDisplay capSettings={hardwareSettings?.cap} /> : null}
            <Box style={{ width: "100%" }}>
                {connected &&
                    (["operation", "cooldown", "starting"]).includes(hardwareState.status) &&
                    modeOfOperation === "manual" ? displayedTimer :
                    <CaroselAbsraction
                        navigation={!startAnimation}
                        allowTouchMove={false}
                        onSlideChange={onSlideChange}
                        setSwiperInstance={setInstructionSwiperInstance}
                        slides={hardwareSettingsCopy.map((data, i) => (() => <TimeTempSpeed
                            ttsSettings={(() => ttsSettings(i))()}
                            setTtsSettings={setTtsSettings}
                            sendFreeze={sendFreeze}
                            hardwareState={hardwareState}
                            hardwareTimeLeft={hardwareTimeLeft}
                            startAnimation={startAnimation}
                            startingR1Time={startingR1Time}
                            settingsIndex={i}
                        />))} />}
            </Box>
            <Box
                display="flex"
                alignItems="center"
                width="100%"
                justifyContent="space-evenly"
            >
                <ButtonBase style={{
                    width: "50%",
                    height: "100px",
                    backgroundColor: "white",
                    border: "4px solid #FB0134",
                    borderRadius: 20,
                    margin: "0 2vh",
                }} onClick={onStop}><Typography variant="h5" style={{ color: "black", fontWeight: 600 }}>Stop</Typography></ButtonBase>
                {!startAnimation ? <ButtonBase style={{
                    width: "50%",
                    height: "100px",
                    backgroundColor: sendFreeze ? "rgba(0, 0, 0, 0.26)" : "#3f51b5",
                    borderRadius: 20,
                    margin: "0 2vh",
                }}
                    disabled={sendFreeze}
                    onClick={() => onSend(startingR1Time)}
                >
                    <Typography variant="h5" style={{ color: "white", fontWeight: 600 }}>{hardwareSettings.type === "serial" ? `Start (${hardwareSettings.data.length} instructions)` : "Start"}</Typography>
                </ButtonBase>
                    : null}
            </Box>
            {connectUI ?
                <Box
                    display="flex"
                    justifyContent="flex-start"
                    style={{ width: "100%" }}
                >
                    <Box
                        display="flex"
                        style={{ margin: "2vh 0 0 5vh" }}
                    >
                        <Button disabled={connected} variant="contained" color="primary" onClick={connectToSerial} style={{ padding: "1vh 2vh", fontSize: "4vh", textTransform: "none" }}>Connect</Button>
                        <Button disabled variant="contained" color="secondary" onClick={() => { }} style={{ padding: "1vh 2vh", fontSize: "4vh", textTransform: "none" }}>Disconnect</Button>
                    </Box>
                </Box> : <></>
            }
            {modeOfOperation === "manual" &&
                currentSerialIndex === (hardwareSettings.type === "serial" ? hardwareSettings.data.length - 1 : 0) &&
                !(["operation", "cooldown", "starting"]).includes(hardwareState.status) ?
                <AddStepBtn setHardwareSettings={setHardwareSettings} /> : null}
            <Box
                width="100%"
                height="40px"
            />
        </Box >
    )
}

SimpleSliderLayout.propTypes = {
    hardwareSettings: PropTypes.instanceOf(Object).isRequired,
    setHardwareSettings: PropTypes.func.isRequired
}

export default SimpleSliderLayout