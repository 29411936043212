import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary'
import ArrayEditor from './ArrayEditor';
import PropertySeparateEdit from './PropertySeparateEdit';

const editOrder = ["metadata", "mainPage", "healthInfo", "ingredients", "equipment", "summary", "process"]

const RecipeEditUI2 = ({ json, setJson }) => {
    const onChange = (newJson, propId) => {
        setJson({ ...json, [propId]: newJson })
    }
    return (
        <>
            {editOrder.map(property =>
                <Accordion key={property} >
                    <AccordionSummary>
                        <Typography variant="h6">{property}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {property === "process" ? <ArrayEditor
                            oneLevelPropertySplit
                            value={json[property]}
                            onChange={e => onChange(e, property)}
                        /> :
                            <PropertySeparateEdit jsonProperty={property} value={json[property]} onChange={e => onChange(e, property)} />
                        }
                    </AccordionDetails>
                </Accordion>)}
        </>
    )
}

RecipeEditUI2.propTypes = {
    json: PropTypes.object,
    setJson: PropTypes.func
}

export default RecipeEditUI2
