import React, { useEffect, useState } from 'react'
import { getFirestore, collection, addDoc, getDocs, setDoc, doc, deleteDoc, query, getDoc } from 'firebase/firestore'
import firebaseApp from './config'

const db = getFirestore(firebaseApp)

export const useFirestoreDoc = (collectionName, documentId) => {
    const [data, setdata] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    useEffect(() => {
        setLoading(true)
        const fetchData = async () => {
            try {
                const retdocs = await getDoc(doc(db, collectionName, documentId))
                if (retdocs.exists())
                    setdata(retdocs.data())
                else {
                    setdata(null)
                    setError({ message: "Document Does not Exist" })
                }
            }
            catch (err) {
                setError(err)
            }
            setLoading(false)
        }
        fetchData()
    }, [collectionName, documentId])

    return { data, loading, error }
}

const useFirestore = (collectionName) => {
    const [data, setdata] = useState([])
    const [refreshTrigger, setRefreshTrigger] = useState(true)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    function refresh() {
        setRefreshTrigger(!refreshTrigger)
    }

    useEffect(() => {
        setLoading(true)
        const fetchData = async () => {
            try {
                const retdocs = await getDocs(query(collection(db, collectionName)))
                setdata(retdocs.docs.map(doc => ({ ...doc.data(), id: doc.id })))
            }
            catch (err) {
                setError(err)
            }
            setLoading(false)
        }
        fetchData()
    }, [collectionName, refreshTrigger])

    const saveNewData = async (newData, callback = () => { }) => {
        const docRef = collection(db, collectionName)
        try {
            const data = await addDoc(docRef, newData)
            callback(data.id)
        }
        catch (err) {
            console.log(err)
            setError(err)
        }
    }

    const updateData = async (newData, id) => {
        const docRef = doc(db, collectionName, id)
        try {
            await setDoc(docRef, newData)
        }
        catch (err) {
            setError(err)
        }
    }

    return { data, loading, refresh, error, saveNewData, updateData }
}

export default useFirestore