import React from 'react';
import CloseBox from '../../dynamicSlide/elements/CloseBox'

import { Box } from '@mui/material'

const DeleteStepBtn = ({ setHardwareSettings, currentSerialIndex }) => {
    const onDelete = () => {
        setHardwareSettings(old => {
            const oldData = old?.data
            const newData = [...oldData.slice(0, currentSerialIndex), ...oldData.slice(currentSerialIndex + 1, old.length)]
            if (newData.length === 1)
                return { ...old[0], type: "basic", data: newData[0] }
            return { ...old, data: newData }
        })
    }
    return (<Box style={{ position: "absolute", top: "2vh", right: "2vh" }}>
        <CloseBox size="medium" onClose={onDelete} />
    </Box>
    )
}

export default DeleteStepBtn