import React from 'react'
import { Box, Typography } from '@mui/material'
import GridBox from './GridBox'
import PropTypes from 'prop-types'

const GridView = ({ image, name, qty, subdiscription, onClickHandler, addToCartClick, boxStyle, checkedBox, commonId, displayCheckBox }) => {
    return (
        <Box 
            display="flex"
            flexDirection="column"
            style={boxStyle}
            onClick={onClickHandler}
        >
            <Box
                display="flex"
                height="10.5vh"
                width="50vh"
                justifyContent="center"
                flexDirection="column"               
            >
                <Typography style={{ color: "black", fontSize: "4.17vh", fontWeight: 600, marginLeft: "24.5vh", lineHeight: 1 }}>
                        {name}
                </Typography>
                <Typography style={{ color: "black", fontSize: "3.17vh", fontWeight: 400, marginLeft: "24.5vh", lineHeight: 1}}>
                        {subdiscription ? <>({subdiscription})</>: null}
                </Typography>
            </Box>
            {qty ? <GridBox
                        mainBox={{ text: name, subtext: subdiscription }}
                        sideBox={{ text: qty.amount, sidetext: qty.unit, subtext: qty.secondary }}
                        image={image}
                        addToCartClick={addToCartClick}
                        checkedBox={checkedBox}
                        commonId={commonId}
                        displayCheckBox={displayCheckBox}
                    />
                : <GridBox
                            mainBox={{ text: name, subtext: subdiscription }}
                            addToCartClick={addToCartClick}
                            image={image}
                            checkedBox={checkedBox}
                            commonId={commonId}
                            displayCheckBox={displayCheckBox}
                        />
                    }
        </Box >
    )
}

GridView.propTypes = {
    image: PropTypes.string,
    name: PropTypes.string,
    qty: PropTypes.shape({
        amount: PropTypes.number,
        unit: PropTypes.string,
        secondary: PropTypes.string
    }),
    subdiscription: PropTypes.string,
    onClickHandler: PropTypes.func,
    addToCartClick: PropTypes.func,
    boxStyle: PropTypes.shape({
        backgroundImage: PropTypes.string,
        zIndex: PropTypes.number,
        borderRadius: PropTypes.number,
        height: PropTypes.string
    }),
    checkedBox: PropTypes.bool,
    commonId: PropTypes.string,
    displayCheckBox: PropTypes.bool,    
}

export default GridView