import React from 'react';
import PropTypes from 'prop-types'

const CapDisplay = ({capSettings}) => {
    if (capSettings !== "no-cap")
        return (
            <img src={capSettings === "on" ? 
            "https://res.cloudinary.com/akshadasingh/image/upload/v1638795814/ezgif.com-gif-maker_4_rkofom.gif": 
            "https://res.cloudinary.com/akshadasingh/image/upload/v1638795816/ezgif.com-gif-maker_3_xaa4k0.gif"}
            style={{ position: "absolute", bottom: "30vh", width:"70vh", height: "40vh"}}/>
        )
    else 
        return <></>
}

CapDisplay.propTypes = {
    capSettings: PropTypes.bool,
}

export default CapDisplay