import React from 'react'
import PropTypes from 'prop-types'
import { ButtonBase } from '@mui/material'
import { mdiCloseCircle } from '@mdi/js'
import Icon from '@mdi/react'

const sizeMap = {
    small: 24,
    medium: 48,
    large: 72
}

const CloseBox = ({ size = "small", onClose = () => { } }) => {
    const generatedDimentions = {
        width: sizeMap[size].toString() + "px",
        height: sizeMap[size].toString() + "px",
        top: (-sizeMap[size] / 3).toString() + "px",
        right: (-sizeMap[size] / 3).toString() + "px"
    }
    return (
        <ButtonBase onClick={onClose} style={{ position: "absolute", ...generatedDimentions }}>
            <Icon path={mdiCloseCircle} />
        </ButtonBase>
    )
}

CloseBox.propTypes = {
    onClose: PropTypes.func,
    size: PropTypes.oneOf(Object.keys(sizeMap)),
}

export default CloseBox
