import { Box, Button, Typography } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react"
import StepsLabel from "./StepsLabel";
import "../../css/steps.css"
import { useReducer } from "react";
import DynamicSubStep from "./DynamicSubStep"
import { reducer } from "./reducerUtil";
import PropTypes from "prop-types";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { getIndexOfSteps } from "../../utils/experience2/stepsInstruction";
import useScrollFeature from "./ScrollFeature";
import useSubStepScrollFeature from "./subStepScrollFeature";

const getLabelParentClassName = (status, view, subStepProgress) => {
    let className = "description description-not-completed"
    if (subStepProgress) className = `${className} in-progress-color`
    if (status === "completed") className = `${className} description-completed`
    if (view === "wide") className = `${className} instruction-reduced-view`
    return className
}

const Steps = ({ steps, hardwareStateMemo, subStepWidth }) => {
    const initialState = {
        recipeData: steps,
        view: subStepWidth,
        inFocusSubstepId: null,
    }
    const [{ recipeData, view, inFocusSubstepId }, dispatch] = useReducer(reducer, initialState)
    const [rotateArrow, setRotateArrow] = useState(false)
    const { scroll, createParentRef, createChildrenRef, triggerScroll, subStepScrollBehavior } = useScrollFeature()

    useEffect(() => {
        dispatch({ type: "SET_INITIAL_DATA", data: { steps, subStepWidth } })
    }, [steps])

    const { subSteps: activeSubSteps, status, description, id } = recipeData.find(step => step.active)
    const subStepIndex = getIndexOfSteps(activeSubSteps, inFocusSubstepId)

    const { createSubStepParentRef, createSubStepChildrenRef } = useSubStepScrollFeature(triggerScroll, subStepIndex, -225, subStepScrollBehavior)

    return (
        <Box
            height="89vh"
            display="flex"
            justifyContent="space-between"
        >
            <Box
                height="100%"
                display="flex"
                className={view === "default" ? "label-full-view" : "label-reduced-view"}
            >
                <StepsLabel
                    steps={recipeData}
                    recipeDispatch={dispatch}
                    displayStepNames={view === "default"}
                    scroll={scroll}
                    createParentRef={createParentRef}
                    createChildrenRef={createChildrenRef}
                />
                <Box>
                    <Button onClick={() => {
                        dispatch({ type: "CHANGE_VIEW" })
                        setRotateArrow(old => !old)
                    }}
                        variant=""
                        sx={{ padding: 0 }}
                    >
                        <DoubleArrowIcon style={{
                            transform: rotateArrow ? "" : "rotate(-180deg)",
                            fontSize: 50,
                            zIndex: 2,
                            transition: "all 0.25s ease"
                        }} />
                    </Button>
                </Box>
            </Box>
            <Box
                className={getLabelParentClassName(status, view, activeSubSteps.find(i => ["paused", "in-progress"].includes(i?.progress)))}
            >
                <Box
                    ref={el => createSubStepParentRef(el)}
                    className="instruction"
                >
                    <Typography variant="body2">{description}</Typography>
                    {activeSubSteps.map(({ type, parameters, id, progress, name }, index) =>
                        <Box ref={el => createSubStepChildrenRef(el, index)}>
                            <DynamicSubStep
                                key={id}
                                type={type}
                                subStepId={id}
                                name={name}
                                parameters={parameters}
                                dispatch={dispatch}
                                status={progress}
                                hardwareStateMemo={hardwareStateMemo}
                            />
                        </Box>
                    )
                    }
                </Box >
            </Box >
        </Box >
    )
}

Steps.propTypes = {
    steps: PropTypes.array
}

export default Steps