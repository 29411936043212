import { Box, Button, Checkbox, FormControl, TextField, Input, InputLabel, MenuItem, Paper, Select, Typography } from '@mui/material'
import ChipInput from '../../utils/ChipInput';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import React, { useEffect, useState } from 'react'


const MetadataUI = ({ recipeJsonProps, saveMetadata }) => {
  const [metadata, setMetadata] = useState({})
  const [errorTotalTime, setErrorTotalTime] = useState(false)
  const [errorPrepTime, setErrorPrepTime] = useState(false)

  useEffect(() => {
    if (recipeJsonProps) {
      setMetadata(recipeJsonProps)
    }

  }, [recipeJsonProps])

  const handleAddCategories = (category, field) => {
    setMetadata({
      ...metadata,
      [field]: metadata[field] ? [...metadata[field], category] : [category],
    })
  }

  const handleDeleteCategories = (category, field) => {
    setMetadata({
      ...metadata,
      [field]: metadata[field].filter((item) => item !== category),
    })
  }

  /* Metadata Checkbox */
  const handleActiveField = (field) => {
    switch (field) {
      case "inactive":
        setMetadata({
          ...metadata,
          inactive: !metadata.inactive,
        });
        break;
      case "pin":
        metadata.pin ? setMetadata({ ...metadata, pin: !metadata.pin })
          :
          setMetadata({ ...metadata, pin: true });
        break;
      case "experimental":
        metadata.experimental ? setMetadata({ ...metadata, experimental: !metadata.experimental })
          :
          setMetadata({ ...metadata, experimental: true });
        break;
      default:
        throw Error();
    }
  }



  return (
    <Box>
      <Box display="flex" flex={1} alignItems="center">
        <InputLabel>Name</InputLabel>&nbsp;
        <Input
          onChange={(e) => setMetadata({ ...metadata, name: (e.target.value) })}
          defaultValue={Object.keys(metadata).length !== 0 ? metadata.name : recipeJsonProps.name ? recipeJsonProps.name : ''}
          style={{ width: 400 }}
        />
      </Box>
      <Typography>
        Inactive:
        <Checkbox
          onChange={() => handleActiveField("inactive")}
          checked={metadata.hasOwnProperty('inactive') ? metadata.inactive : recipeJsonProps.inactive ? recipeJsonProps.inactive : false}
        />
      </Typography>
      <Typography>
        Pin:{" "}
        <Checkbox
          onChange={() => handleActiveField("pin")}
          checked={metadata.hasOwnProperty('pin') ? metadata.pin : recipeJsonProps.pin ? recipeJsonProps.pin : false}
        />
      </Typography>
      <Typography>
        Experimental:
        <Checkbox
          onChange={() => handleActiveField("experimental")}
          checked={metadata.hasOwnProperty('experimental') ? metadata.experimental : recipeJsonProps.experimental ? recipeJsonProps.experimental : false}
        />
      </Typography>
      <Paper
        style={{ display: "flex", flexDirection: "column", flexWrap: "wrap" }}
      >
        <Typography>Tags: </Typography>
        {Object.keys(metadata).length !== 0 && metadata.hasOwnProperty('tags') ?
          <ChipInput
            value={metadata.tags ? metadata.tags : recipeJsonProps.tags}
            onAdd={(category) => handleAddCategories(category, "tags")}
            onDelete={(category) => handleDeleteCategories(category, "tags")}
          />
          :
          <ChipInput
            value={[""]}
            onAdd={(category) => handleAddCategories(category, "tags")}
            onDelete={(category) => handleDeleteCategories(category, "tags")}
          />
        }
      </Paper>
      <Paper
        style={{ display: "flex", flexDirection: "column", flexWrap: "wrap" }}
      >
        <Typography>Categories: </Typography>
        <br />
        {Object.keys(metadata).length !== 0 && metadata.hasOwnProperty('categories') ?
          <ChipInput
            value={metadata.categories ? metadata.categories : recipeJsonProps.categories}
            onAdd={(category) => handleAddCategories(category, "categories")}
            onDelete={(category) => handleDeleteCategories(category, "categories")}
          />
          :
          <ChipInput
            value={[""]}
            onAdd={(category) => handleAddCategories(category, "categories")}
            onDelete={(category) => handleDeleteCategories(category, "categories")}
          />

        }

      </Paper>
      <Paper>
        <InputLabel>CookTime:</InputLabel>&nbsp;
        <TextField
          error={errorTotalTime}
          variant="outlined"
          helperText={errorTotalTime ? "Please enter a number." : ""}
          onChange={(e) => {
            setMetadata({ ...metadata, totalTime: parseInt(e.target.value) })
            setErrorTotalTime(isNaN(metadata["totalTime"]))
          }}
          defaultValue={`${metadata.totalTime ? metadata.totalTime : recipeJsonProps.totalTime} (in minutes)`}
        />
      </Paper>
      <Paper>
        <InputLabel>PrepTime:</InputLabel>&nbsp;
        <TextField
          error={errorPrepTime}
          variant="outlined"
          helperText={errorPrepTime ? "Please enter a number." : ""}
          onChange={(e) => {
            setMetadata({ ...metadata, prepTime: parseInt(e.target.value) })
            setErrorPrepTime(isNaN(metadata["prepTime"]))
          }}
          defaultValue={`${metadata.prepTime ? metadata.prepTime : recipeJsonProps.prepTime} (in minutes)`}
        />
      </Paper>

      <Button
        variant="contained"
        color="primary"
        style={{ marginRight: 10 }}
        onClick={() => saveMetadata(metadata)}
      >Save the changes
      </Button>
      <Accordion style={{ width: "90vw" }}>
        <AccordionSummary>
          <Typography variant="h6" color="secondary">
            + Add New Field  (HANDLE WITH CAUTION)
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AddNewField
            recipeJsonProps={recipeJsonProps}
            saveMetadata={saveMetadata}
            metadata={metadata}
            setMetadata={setMetadata}
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

const AddNewField = ({ saveMetadata, metadata, setMetadata }) => {
  // make 2 InputField for (props, value) and Typography to update accordingly
  const [field, setField] = useState("");
  const [props, setProps] = useState("");
  const [type, setType] = useState("string");


  // Needs to trigger this everytime by clicking on resetting the field
  const handleProps = (props) => {
    switch (type) {
      case "array":
        setProps([props])
        saveMetadata({ ...metadata, [field]: [props] })
        break
      case "string":
        setProps(props)
        saveMetadata({ ...metadata, [field]: props })
        break
      case "number":
        setProps(parseInt(props))
        saveMetadata({ ...metadata, [field]: parseInt(props) })
        break
      case "boolean":
        (props).toLowerCase() === "true" ? setProps(true) : setProps(false)
        saveMetadata({ ...metadata, [field]: props })
        break
      default: return null
    }
  }

  return (
    <Box display="flex" flexDirection="column">
      <TextField
        label="Field"
        variant="outlined"
        onChange={(e) => setField(e.target.value)}
      />
      <br />
      <TextField
        label="Props"
        variant="outlined"
        helperText={type ? `Enter a ${type}` : ""}
        onChange={(e) => setProps(e.target.value)}
      />
      <FormControl >
        <InputLabel id="demo-simple-select-label">Type</InputLabel>
        <Select
          value={type}
          onChange={(e) => setType(e.target.value)}
        >
          <MenuItem value={"string"}>String</MenuItem>
          <MenuItem value={"array"}>Array</MenuItem>
          <MenuItem value={"number"}>Number</MenuItem>
          <MenuItem value={"boolean"}>Boolean</MenuItem>
        </Select>
      </FormControl>
      <Button
        variant="contained"
        onClick={() => handleProps(props)}
      >
        Add New Props
      </Button>
    </Box>
  )
}

export default MetadataUI