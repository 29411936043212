import { ButtonBase, Grid, Typography } from '@mui/material'
import React from 'react'
import getIconUrl, { iconList } from '../utils/iconList'

const IconShowroom = () => {
    return (
        <Grid container alignItems="center" justifyContent="center">
            {iconList.map(iconName => <Grid item xs={3} key={iconName}>
                <ButtonBase>
                    <img src={getIconUrl(iconName)} alt={iconName} width={40} />
                    <Typography>{iconName}</Typography>
                </ButtonBase>
            </Grid>)}
        </Grid>
    );
}

export default IconShowroom
