import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'

const sizeMap = {
    "small": "80%",
    "medium": "85%",
    "large": "90%"
}

const spaceMap = {
    "none": 0,
    "small": 1,
    "medium": 2,
    "large": 3
}

const CircleButton = ({
    heightOfRoundIconButtons,
    imageUrl = null,
    onClick = () => { },
    labelText = "",
    showLabel = false,
    imageSize = "medium",
    spacing = "medium",
    imageAltText = "?"
}) => {
    const scaleingFactor = sizeMap[imageSize]
    const spacingFactor = spaceMap[spacing]
    return (
        <Box
            marginLeft={spacingFactor}
            marginRight={spacingFactor}
            display="flex"
            alignItems="center"
            onClick={onClick}
        >
            <Box
                borderRadius="50%"
                height={heightOfRoundIconButtons}
                width={heightOfRoundIconButtons}
                bgcolor="#F0F5D0"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                {imageUrl ?
                    <Box
                        borderRadius="50%"
                        height={scaleingFactor}
                        width={scaleingFactor}
                        style={{
                            backgroundImage: `url(${imageUrl})`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center"
                        }}
                    /> : <Box
                        borderRadius="50%"
                        height={scaleingFactor}
                        width={scaleingFactor}
                    >
                        <Typography variant="h6">{imageAltText}</Typography>
                    </Box>
                }
            </Box>
            {
                showLabel ?
                    <Box
                        bgcolor="#0E3B53"
                        borderRadius="10px"
                        maxWidth="350px"
                        paddingLeft={1}
                        paddingRight={1}
                    >
                        <Typography
                            variant="h5"
                            noWrap
                            style={{ color: "#FFFFFF" }}
                        >{labelText}</Typography>
                    </Box> : <></>
            }
        </Box >
    );
}

CircleButton.propTypes = {
    heightOfRoundIconButtons: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    imageUrl: PropTypes.string,
    imageSize: PropTypes.oneOf(Object.keys(sizeMap)),
    spacing: PropTypes.oneOf(Object.keys(spaceMap)),
    onClick: PropTypes.func,
    labelText: PropTypes.string,
    showLabel: PropTypes.bool,
    imageAltText: PropTypes.string
}

export default CircleButton
