import { Button, Typography, Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { HardwareContext } from "./HardwareTasks";
import PropTypes from 'prop-types';
import { logEvents } from "../../utils/logging";
import R1BtnPopUp from "./R1BtnPopUp";

const R1Buttons = ({ dispatch, progress, buttonStatus, subStepId, stepName, btFirstConnect }) => {
    const { stateOfHardware, connected, triggerR1, pauseR1, resumeR1, forceStop } = useContext(HardwareContext)
    const [popUp, setPopUp] = useState(false)
    const [ showSkippedStepDialog, setShowSkippedStepDialog ] = useState(false)

    function onClickResume(type) {
        dispatch({ type: "CHANGE_PROGRESS_TO_IN_PROGRESS", data: { subStepId, resumeR1 } })
        changePopUpState()
    }

    function onClickCurrentStep(type) {
        dispatch({type: "GO_TO_CURRENT_STEP"})
        if (type === "dialogBoxCurrentStep") {
            setShowSkippedStepDialog(false)
        }
    }

    function onClickStop() {
        forceStop()
        changePopUpState()
    }

    function onClickSkip() {
        dispatch({ type: "ON_SKIP_BUTTON", data: { triggerR1, subStepId}})
        setShowSkippedStepDialog(false)
    }

    const handleTriggerRunSteps = () => {
        if (["start", "resume", "completed"].includes(buttonStatus)) {
            setSettings()
        } else {
            setShowSkippedStepDialog(true)
        }
    }

    const setSettings = () => {
        const sessionId = sessionStorage.getItem("sessionId")
        logEvents(sessionId)
        dispatch({ type: "TRIGGER_R1", data: { triggerR1, subStepId } })
    }

    function changePopUpState() {
        setPopUp(old => !old)
    }

    const closeOnClickOutside = () => {
        if (progress !== "paused-on-disconnect")
            dispatch({ type: "CHANGE_PROGRESS_TO_PAUSED", data: { subStepId } })
        changePopUpState()
    }

    useEffect(() => {
        if (!connected) {
            dispatch({ type: "CHANGE_PROGRESS_TO_PAUSED_ON_DISCONNECT" })
            if (btFirstConnect)
                setPopUp(true)
        }
    }, [connected, btFirstConnect])

    useEffect(() => {
        if (stateOfHardware.status === "idle")
            dispatch({ type: "ON_SUBSTEPS_COMPLETION" })
    }, [stateOfHardware.status])

    switch (progress) {
        case "in-progress": {
            return (<>
                <Button
                    className="r1-btn pause-btn"
                    onClick={() => {
                        pauseR1()
                        changePopUpState()
                    }}
                >
                    Pause
                </Button>
                <R1BtnPopUp
                    open={popUp}
                    title={"Paused"}
                    btnTitle={"Stop"}
                    btnStyle={"stop-btn"}
                    description={<>
                        <Typography variant="body2">
                            {`The R1 has been paused on "${stepName}".`}
                        </Typography>
                        <Typography variant="body2">
                            Tap on Resume to continue cooking.
                        </Typography></>}
                    btn={<Button
                        className={!connected ? "r1-btn dialog-btn-dimension disable-r1" : "r1-btn dialog-btn-dimension"}
                        variant="contained"
                        onClick={onClickResume}
                        style={{ boxShadow: "none" }}
                        disabled={!connected}
                    >
                        <Typography variant="button">Resume</Typography>
                    </Button>}
                    onClickStop={onClickStop}
                    closeOnClickOutside={closeOnClickOutside}
                    disableBtn={!connected}
                />
            </>)
        }
        case "paused-on-disconnect": {
            return (<>
                <Typography variant="button">R1 is disconnected. Connect and then start R1.</Typography>
                <Box display="flex" justifyContent="space-between" width="100%">
                    <Button
                        className={!connected ? "r1-btn non-dialog-resume-stop-dimension disabled-r1" : "r1-btn non-dialog-resume-stop-dimension"}
                        variant="contained"
                        onClick={setSettings}
                        style={{ boxShadow: "none" }}
                        disabled={!connected}
                    >
                        <Typography variant="button">Re-start R1</Typography>
                    </Button>
                    <Button
                        className="r1-btn stop-btn non-dialog-resume-stop-dimension"
                        onClick={onClickStop}
                        disabled={!connected}
                    >
                        <Typography variant="button">Stop</Typography>
                    </Button>
                </Box>
                <R1BtnPopUp
                    open={popUp}
                    title={"Disconnected"}
                    description={<Typography variant="body2">
                        R1 is disconnected. Connect and then start R1.
                    </Typography>}
                    btn={<Button
                        className={!connected ? "r1-btn dialog-btn-dimension disable-r1" : "r1-btn dialog-btn-dimension"}
                        variant="contained"
                        onClick={setSettings}
                        style={{ boxShadow: "none" }}
                        disabled={!connected}
                    >
                        <Typography variant="button">Re-start R1</Typography>
                    </Button>}
                    onClickStop={onClickStop}
                    closeOnClickOutside={closeOnClickOutside}
                    disableBtn={!connected}
                />
            </>)
        }
        case "paused": {
            return (<>
                <Typography variant="button">Paused, please press Resume to resume cooking.</Typography>
                <Box display="flex" justifyContent="space-between" width="100%">
                    <Button
                        className={!connected ? "r1-btn non-dialog-resume-stop-dimension disabled-r1" : "r1-btn non-dialog-resume-stop-dimension"}
                        variant="contained"
                        onClick={onClickResume}
                        style={{ boxShadow: "none" }}
                        disabled={!connected}
                    >
                        <Typography variant="button">Resume</Typography>
                    </Button>
                    <Button
                        className="r1-btn stop-btn non-dialog-resume-stop-dimension"
                        onClick={onClickStop}
                        disabled={!connected}
                    >
                        <Typography variant="button">Stop</Typography>
                    </Button>
                </Box>
            </>)
        }
        default: {
            return (
                <>
                <Button
                    className={!connected || !(["idle", "Paused"].includes(stateOfHardware.status)) ? "start-btn r1-btn disabled-r1" : "start-btn r1-btn"}
                    disabled={!connected || !(["idle", "Paused"].includes(stateOfHardware.status))}
                    onClick={handleTriggerRunSteps}
                >
                    <Typography variant="button">Start R1</Typography>
                </Button>
                <R1BtnPopUp
                    open={showSkippedStepDialog}
                    title={"Skipped a Step"}
                    btnTitle={"Skip It"}
                    btnStyle={"skip-btn"}
                    description={<>
                        <Typography variant="body2">
                            {`You seemed to have skipped some instructions in the "${stepName}".`}
                        </Typography>
                        <Typography variant="body2">
                        {`"${stepName}" step.`}
                        </Typography></>}
                    btn={<Button
                        className={!connected ? "r1-btn dialog-btn-dimension disable-r1" : "r1-btn dialog-btn-dimension"}
                        variant="contained"
                        onClick={() => onClickCurrentStep("dialogBoxCurrentStep")}
                        style={{ boxShadow: "none" }}
                        disabled={!connected}
                    >
                        <Typography variant="button">Go To Step</Typography>
                    </Button>}
                    onClickStop={onClickSkip}
                    closeOnClickOutside={() => setShowSkippedStepDialog(false)}
                    disableBtn={!connected}
                />
            </>)
        }
    }
}

R1Buttons.propTypes = {
    dispatch: PropTypes.func,
    inProgress: PropTypes.bool,
    stepId: PropTypes.string
}

export default R1Buttons