import React from "react";
import { Typography, Button, SvgIcon } from "@mui/material";
import { ReactComponent as Increase } from "../../svg/addIcon.svg";
import { ReactComponent as Decrease } from "../../svg/minusIcon.svg";
import PropTypes from 'prop-types'

const ServingBtn = ({ changeServing, serving, IconSize = 50, textVariant = "h3", disableIncrease, disableDecrease }) => {
    return (<>
        <Button
            variant=""
            onClick={() => changeServing("decrease")}
            disabled={disableDecrease}
        >
            <SvgIcon component={Decrease} viewBox="0 0 61.315 66.703" style={{ fontSize: IconSize }} />
        </Button>
        <Typography variant={textVariant}>{serving}</Typography>
        <Button
            variant=""
            disabled={disableIncrease}
            onClick={() => changeServing("increase")}
        >
            <SvgIcon component={Increase} viewBox="0 0 61.315 66" style={{ fontSize: IconSize }} />
        </Button>
    </>)
}

ServingBtn.propTypes = {
    changeServing: PropTypes.func,
    serving: PropTypes.string,
    size: PropTypes.number,
    textVariant: PropTypes.string
}

export default ServingBtn