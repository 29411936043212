import React from 'react'
import { Box, Typography, LinearProgress } from '@mui/material'

const Starting = () => {
    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="90vh"
        >
            <Typography>Starting....</Typography>
            <LinearProgress />
            <Typography>Typiclaly take 5-10 secs</Typography>
        </Box>
    )
}

Starting.propTypes = {

}

export default Starting
