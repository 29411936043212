//1. import the dependencies
import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"

//2. Initialize app with the config vars
const firebaseApp = initializeApp({
    apiKey: "AIzaSyByeWnCbHK0VQQ0bLiYaXPbI_4sFA2jL1Q",
    authDomain: "cookclub-app.firebaseapp.com",
    projectId: "cookclub-app",
    storageBucket: "cookclub-app.appspot.com",
    messagingSenderId: "701347102292",
    appId: "1:701347102292:web:0349a325ac6182ab7277c1",
    measurementId: "G-NN8GR5E6LJ"
});

export const firebaseAnalytics = getAnalytics()

//3. export it for use
export default firebaseApp;
