import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ButtonBase, Typography } from '@mui/material'
import CloseBox from './CloseBox'
import DynamicSlide from '../DynamicSlide'
import PopUpDialog from '../../PopUpDialog'

const defaultStyle = {
    width: "100%",
    backgroundColor: "#FFA096",
    padding: "1em",
    boxShadow: "5px 5px 7px rgba(33,33,33,.7)",
    borderRadius: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: "4px"
}

const ActionButton = ({ type, hardwareSettings, text, onExpand, closeBox = false, setReact1Settings, floatingBtnFunction = () => { }, style = { }, floatingBtnFunctionArg, visibilityOfText="visible", action }) => {
    const [showButton, setShowButton] = useState(true)
    const [dialogForDetails, setDialogForDetails] = useState(false)
    const onClick = () => {
        if (type === "r1")
            setReact1Settings({ ...hardwareSettings, dialogOpen: true })
        else if (type === "floating")
            floatingBtnFunction(action, floatingBtnFunctionArg)
        else
            setDialogForDetails(true)
    }
    const image = type === "r1" ? "https://res.cloudinary.com/prodjour/image/upload/v1630327255/ReactLogos/react-icon-3pt_puizyn.png" :
        "https://res.cloudinary.com/prodjour/image/upload/v1628700704/icons/Confused_jckued.png"
    return showButton ? (
        <>
            <ButtonBase onClick={onClick} style={type === "floating" ? style : defaultStyle}>
                {closeBox ? <CloseBox onClose={() => setShowButton(false)} /> : <></>}
                {type !== "floating" ? <img
                    src={image}
                    alt="confused"
                    width="18%"
                /> : null}
                <Typography align="left" variant="h5" style={{ paddingLeft: "0.5em", visibility: visibilityOfText }}>{text}</Typography>
            </ButtonBase >
            <PopUpDialog
                dialogOpen={dialogForDetails}
                onClickBack={() => setDialogForDetails(false)}
                componentInDialog={<DynamicSlide
                    type={onExpand.type}
                    slideData={onExpand.data}
                    isCurrent={dialogForDetails}
                />}
            />
        </>
    ) : <></>
}

ActionButton.propTypes = {
    text: PropTypes.string,
    location: PropTypes.string,
    closeBox: PropTypes.bool,
    onExpand: PropTypes.object,
    hardwareSettings: PropTypes.object.isRequired,
}

export default ActionButton
