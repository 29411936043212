import { useEffect, useState } from "react";
import useIngredientsDB from "../../utils/hardware/ingredientsDB"

export const useGenerateIngredients = () => {
    const { ingredients, loading } = useIngredientsDB();
    const [ data, setData] = useState(undefined)
     
    useEffect(() => {
        if (ingredients.length > 0) {
            const ingredientsObj = ingredients.reduce((prev, next) => ({...prev,
                [next.id]: {...next, preps: next.preps.reduce((p, n) => ({...p, [n.prepId]: n }), {} )} // preps will eventually be an object
            }), {})
            setData(ingredientsObj)
        }
    }, [ingredients])

    return {data, loading}
}