import { useEffect, useState } from 'react'
import firebaseApp from '../../firebase/config'
import { getFirestore, collection, getDocs, addDoc } from 'firebase/firestore'

const useRecipeListFromDB = (
    collectionName = "recipe",
    newEntryDefault = {},
    getNameFromRecipe = r => r.name
) => {
    const [recipeList, setRecipeList] = useState([])
    const [sortedRecipeList, setSortedRecipeList] = useState([])
    const [loading, setLoading] = useState(true)
    const [saving, setSaving] = useState(false)
    const [errorMessage, setErrorMessage] = useState("No Error")
    const [refreshTrigger, setRefreshTrigger] = useState(true)

    function refresh() {
        setRefreshTrigger(!refreshTrigger)
    }

    useEffect(() => {
        setLoading(true)
        const fetchData = async () => {
            const db = getFirestore(firebaseApp)
            try {
                const data = await getDocs(collection(db, collectionName))
                const r = data.docs.map(doc => { return { ...doc.data(), id: doc.id } })
                setRecipeList(r)
            } catch (err) {
                setErrorMessage(err)
            }
            setLoading(false)
        }
        fetchData()
    }, [collectionName, refreshTrigger])

    useEffect(() => {
        const pinRecipe = (recipes) => {
            const pinnedRecipe = recipes.filter(item => item?.metadata?.pin)
            const unpinnedRecipes = recipes.filter(item => !item?.metadata?.pin)
            return [...pinnedRecipe, ...unpinnedRecipes]
        }
        const sortedR = recipeList.sort((a, b) => getNameFromRecipe(a).localeCompare(getNameFromRecipe(b)))
        const finalRecipes = pinRecipe(recipeList)
        setSortedRecipeList(finalRecipes)
    }, [recipeList])

    const saveNew = async (callback) => {
        setSaving(true)
        const saveData = async () => {
            try {
                const docRef = collection(getFirestore(), collectionName)
                const newID = await addDoc(docRef, newEntryDefault)
                callback(newID.id)
                refresh()
            }
            catch (err) {
                setErrorMessage(err)
            }
            setSaving(false)
        }
        saveData()
    }

    return { recipeList: sortedRecipeList, saveNew: saveNew, error: errorMessage, loading: loading, saving: saving, refresh: refresh }
}

export default useRecipeListFromDB