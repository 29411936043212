import React from 'react'
import PropTypes from 'prop-types'
import RecipeCard2 from './RecipeCard2'

const Recipe2Card = ({ recipeJson, onCardClick }) => {
    const getThumbnail = () => {
        if (recipeJson?.metadata?.thumbnail)
            return recipeJson.metadata.thumbnail
        if (recipeJson?.mainPage?.visualization[0].data?.url)
            return recipeJson.mainPage.visualization[0].data.url
        return "https://res.cloudinary.com/prodjour/image/upload/c_scale,h_" + (400) + "/v1621114765/TestPDF/Loading_fdzzw3.jpg"
    }
    const { metadata } = recipeJson
    if (!metadata) return <></>
    const { name, totalTime, inactive } = metadata
    if (inactive) return <></>
    return (
        <RecipeCard2
            recipeImage={getThumbnail()}
            name={name}
            time={totalTime ? totalTime : 30}
            onClick={onCardClick}
        />
    )
}

Recipe2Card.propTypes = {
    recipeJson: PropTypes.object.isRequired,
    onCardClick: PropTypes.func.isRequired,
}

export default Recipe2Card
