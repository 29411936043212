import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import { CircularProgress, Grid, Typography, Snackbar } from "@mui/material";
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from '../../firebase/context';
import useRecipeFromDB from "../../utils/RecipeBackEnd/loadRecipe";
import DeleteRecipe from "./DeleteRecipe";
import MetadataEdit from "./MetadataEdit";
import StepsEdit from "./StepsEdit";

const RecipeEditor2 = ({ recipeId, onDeleteCallback = () => { } }) => {
  const [localRecipeJson, setLocalRecipeJson] = useState({});
  const [openDeleteMessage, setOpenDeleteMessage] = useState(false)
  const [recipeJSON, loading, save, saving, deleteRecipe] = useRecipeFromDB(
    recipeId,
    "newRecipeJson"
  )
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const { user } = useContext(AuthContext)
  const time = Date();
  useEffect(() => {
    setLocalRecipeJson(recipeJSON);
  }, [recipeJSON])
  const { metadata, steps } = localRecipeJson
  const onJsonPropertyChange = (newJson, propId) => {
    if (propId === "metadata") {
      setLocalRecipeJson({
        ...localRecipeJson, [propId]: {
          ...newJson, editor: user.displayName,
          lastEditedAt: time
        }
      })
    } else {
      setLocalRecipeJson({
        ...localRecipeJson,
        metadata: { ...metadata, editor: user.displayName, lastEditedAt: time },
        [propId]: newJson
      });
    }
  }

  const onSave = () => {
    save(localRecipeJson)
    setOpenSnackBar(true)
  }

  const onDelete = () => {
    deleteRecipe()
    handleCloseDeleteMessage(false)
    onDeleteCallback(recipeId)
  }

  const handleOpenDeleteMessage = () => {
    setOpenDeleteMessage(true)
  }

  const handleCloseDeleteMessage = () => {
    setOpenDeleteMessage(false)
  }

  if (!recipeId) return <Typography>No Recipe Selected</Typography>
  if (loading) return <CircularProgress />
  return (<Grid container rowSpacing={2}>
    <Grid item>
      <Typography variant="h6">
        {metadata?.name ? metadata.name : "No Name Defined in Metadata"}
      </Typography>
    </Grid>
    <Grid item>
      <MetadataEdit
        objectData={metadata}
        onMetadataChange={newValue => onJsonPropertyChange(newValue, "metadata")}
      />
    </Grid>
    <Grid item>
      <StepsEdit
        localRecipeJson={localRecipeJson}
        stepsData={steps}
        onStepsArrayChange={newValue => onJsonPropertyChange(newValue, "steps")}
      />
    </Grid>
    <SpeedDial
      ariaLabel="SpeedDial basic example"
      sx={{ position: "fixed", left: "auto", right: "20px", top: "auto", bottom: "20px" }}
      icon={<SpeedDialIcon />}
    >
      <SpeedDialAction
        key={"save"}
        icon={saving ? <CircularProgress color="secondary"><SaveIcon /></CircularProgress> : <SaveIcon onClick={onSave} />}
        tooltipTitle={"save"}
      />
      <SpeedDialAction
        key={"display"}
        icon={saving ? <SlideshowIcon /> :
          <Link to={`/experience2/${recipeId}`} target="_blank" style={{ "text-decoration": "none" }}>
            <SlideshowIcon />
          </Link>
        }
        tooltipTitle={"display"}
      />
      <SpeedDialAction
        key={"delete"}
        icon={saving ? <DeleteIcon /> : <DeleteIcon onClick={handleOpenDeleteMessage} />}
        tooltipTitle={"delete"}
      />
    </SpeedDial>
    <DeleteRecipe
      onDelete={onDelete}
      handleCloseDeleteMessage={handleCloseDeleteMessage}
      openDeleteMessage={openDeleteMessage}
    />
    {saving && <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={openSnackBar}
      message="The Recipe is Saving!!"
    />}
  </Grid>
  );
};

export default RecipeEditor2;
