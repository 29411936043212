import React from 'react'
import PropTypes from 'prop-types'
import { Button, TextField } from '@mui/material'
import RefreshIcon from '@mui/icons-material/Refresh'
import { v4 as uuid } from 'uuid'

const IdInput = ({ id, setId }) => {
    return (<>
        <TextField
            value={id} disabled
            size="small" label="ID"
            sx={{ width: "70%" }}
        />
        <Button onClick={() => setId(uuid())}><RefreshIcon /></Button>
    </>
    )
}

IdInput.propTypes = {
    id: PropTypes.string.isRequired,
    setId: PropTypes.func
}

export default IdInput