import React from 'react'
import { Box, Typography } from '@mui/material'
import ActionButtonSet from './elements/ActionButtonSet'
import PropTypes from 'prop-types'

const selectedSectionStyle = {
    backgroundColor: "#bee1f4",
    borderRadius: "1vh",
    fontWeight: 600
}

const unselectedSectionStyle = {
    color: "#636363"
}

const ProcessTitleSlide = ({ text, processTitleIds, slideId, floatingBtnsFunctions = () => {}, actionButtons = [] }) => {
    return (
        <>
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-evenly"
                height="100%"
            >
                {text.map((item, index) => <Typography
                    key={processTitleIds[index]}
                    variant="h2"
                    style={processTitleIds[index] === slideId ? selectedSectionStyle : unselectedSectionStyle}
                    align="center"
                >
                    {item}
                </Typography>)}
            </Box>
            <ActionButtonSet actionButtons={actionButtons} floatingBtnsFunctions={floatingBtnsFunctions} />
        </>)
}

export default ProcessTitleSlide

ProcessTitleSlide.propTypes = {
    text: PropTypes.arrayOf(PropTypes.string),
    processTitleIds: PropTypes.arrayOf(PropTypes.string),
    slideId: PropTypes.string,
}