import React, {useEffect, useState} from 'react';
import {Box} from '@mui/material';
import { animated, useSpring, config } from 'react-spring'

const ScaleAnimation = ({reading, src}) => {
    const [rotation, setRotation] = useState(0)

    const styles = useSpring({
        to: { rotateZ: rotation },
        config: config.slow,
    })

    useEffect(() => {
        const mult = -1
        setRotation( mult * Math.ceil(reading))
    }, [reading])

    return (
        <Box style={{position: "absolute", zIndex: 0, height: "40vh", top: 0, left: 0, width: "100%" }}>
                    <Box style={{position: "relative", height: "100%", width: "100%", overflow: "hidden"}}>
                    <animated.img style={{width: "100%", position: "absolute", bottom:"0", ...styles}} src={src} />
                </Box>
        </Box>
    )
}

export default ScaleAnimation