import React from 'react'
import PropTypes from 'prop-types'
import { Button, Typography, Box, CircularProgress } from '@mui/material'
import SignOut from "./SignOut"
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth'

const SignIn = ({ adminOnly,
    signInDisable = false,
    signInMessage,
    landscapeWarning,
    loading }) => {
    const signInWithGoogle = () => {
        const provider = new GoogleAuthProvider()
        signInWithPopup(getAuth(), provider)
    }

    return (
        <Box style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", backgroundColor: "#ECECEC", height: "100vh", alignItems: "center" }}>
            <Box>
                <Typography variant="h3" align="center">{process.env.VERCEL_ENV === "production" ? "Welcome to" : "BETA"}</Typography>
                <Typography variant="h2" align="center" style={{ fontWeight: 600, color: "#385723", lineHeight: 1.5 }}>Cookclub.ai</Typography>
            </Box>
            <Box >
                <Box style={{ background: "url(https://res.cloudinary.com/akshadasingh/image/upload/v1640607100/reactlogin_gmgjs4.gif) no-repeat center", height: "30vh" }} />
                <Typography variant="body1" style={{ color: "#737373" }}>{signInMessage}</Typography>
            </Box>
            {!loading ?
                <Box>
                    <Button
                        onClick={signInWithGoogle}
                        variant="contained"
                        style={{ padding: "1vh 19vh", borderRadius: "3vh", backgroundColor: "#00704A", color: "white", textTransform: "none" }}
                        disabled={signInDisable}
                    >Log in</Button>
                    {adminOnly && <SignOut />}
                </Box> : <CircularProgress />
            }
            {landscapeWarning ?
                <Typography variant="body1" align="center" style={{ color: "#737373" }} >Make sure you are in landscape orientation</Typography> :
                null
            }
            <Box style={{ background: "url(https://res.cloudinary.com/akshadasingh/image/upload/v1640609056/react_ril9ly.png) no-repeat center / contain", height: "3vh", width: "10vh" }} />
        </Box>
    )
}

SignIn.propTypes = {
    adminOnly: PropTypes.bool,
    signInDisable: PropTypes.bool
}

export default SignIn
