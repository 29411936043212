import { Button, Box, Typography } from "@mui/material";
import React from "react";
import PopUpModal from "./PopUpModal";

const R1BtnPopUp = ({ open, btn, title, description, onClickStop, stepName = "", closeOnClickOutside, disableBtn, btnTitle, btnStyle }) => {
    return (
        <PopUpModal
            open={open}
            onClose={closeOnClickOutside}
            size="small"
        >
            <Box
                style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    justifyContent: "space-around",
                    alignItems: "center"
                }}
            >
                <Typography variant="h5">{title}</Typography>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                >
                    {description}
                </Box>
                <Box
                    display="flex"
                    gap="20px"
                    justifyContent="center"
                >
                    {btn}
                    <Button
                        className={`r1-btn ${btnStyle} dialog-btn-dimension`}
                        onClick={onClickStop}
                        disabled={disableBtn}
                    >
                        <Typography variant="button">{btnTitle}</Typography>
                    </Button>
                </Box>
            </Box>
        </PopUpModal>
    )
}

export default R1BtnPopUp