import React, { useContext, useEffect, useState } from "react";
import IngredientsList from "../components/ingredients2/IngredientsList";
import { HardwareTask } from "../components/recipeSteps/HardwareTasks";
import Steps from "../components/recipeSteps/Steps";
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import { Keyboard } from "swiper";
import { addActiveToSubSteps, changeRecipeOnServing } from "../utils/experience2/stepsInstruction";
import { getAllAllergens, getTotalMacros, getAllRecipeIngredients } from "../utils/experience2/ingredientsUtil"
import { IngredientsContext, IngredientsFromDB } from "../components/ingredients2/IngredientsFromDB";
import { useParams } from "react-router-dom";
import SecureArea from "../components/authentication/SecureArea";
import TitleSlide from "../components/TitleSlide";
import { logEvents } from "../utils/logging";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { v4 as uuid } from 'uuid'
import { startSession } from "../utils/logging";
import { Box, Button, ThemeProvider, StyledEngineProvider, Typography } from "@mui/material";
import useRecipeJsonDB from "../components/newRecipeJson/NewRecipeDB";
import HardwareBar from "../components/recipeSteps/HardwareBar";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ccTheme from '../theme/Experience2Theme';
import Loader from "./Loader";

const defaultServe = 2

const PageTitle = ({ title, onClickBack = () => { }, onClickNext = () => { }, displayNextBtn = true, children }) => {
    return (
        <Box display="flex" flexDirection="column">
            <Box style={{
                padding: "1%",
                display: "flex",
                backgroundColor: "#ffffff",
                zIndex: 2,
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                boxSizing: "border-box",
                height: "11vh"
            }
            } >
                <Button
                    style={{
                        color: "black",
                        padding: 0
                    }}
                    onClick={onClickBack}
                    size="large">
                    <ArrowBackIcon style={{ fontSize: 50 }} />
                </Button>
                <Typography variant="h4" style={{ textAlign: "center" }}>{title}</Typography>
                {displayNextBtn ? <Button
                    color="primary"
                    variant="contained"
                    style={{
                        borderRadius: 10,
                        width: "10%",
                        boxShadow: "none"
                    }}
                    onClick={onClickNext}
                    size="large">
                    <ArrowBackIcon style={{ fontSize: 50, transform: "rotate(180deg)" }} />
                </Button> :
                    <Box width="10%" />}
            </Box >
            {children}
        </Box >
    );
}

const Experience2 = ({ recipe, hardwareStateMemo, loading }) => {
    const [swiperInstance, setSwiperInstance] = useState(null)
    const [serving, setServing] = useState(defaultServe)
    const [newRecipe, setNewRecipe] = useState(null)
    const { ingredients, loading: ingredientsLoading } = useContext(IngredientsContext)

    useEffect(() => {
        setNewRecipe(old => changeRecipeOnServing(old, serving))
    }, [serving, changeRecipeOnServing])

    useEffect(() => {
        if (!loading)
            setNewRecipe(recipe)
    }, [recipe, loading])

    const allIngredientsInRecipe = getAllRecipeIngredients(newRecipe?.steps, ingredients)

    return (<>
        {(!ingredientsLoading && newRecipe) ? <Swiper
            keyboard={{ enabled: true }}
            modules={[Keyboard]}
            onTransitionEnd={() => {
                const sessionId = sessionStorage.getItem("sessionId")
                logEvents(sessionId)
            }}
            onSwiper={swiper => setSwiperInstance(swiper)}
            allowTouchMove={false}
        >
            <SwiperSlide>
                <TitleSlide
                    {...recipe?.metadata}
                    setServing={setServing}
                    serving={serving}
                    onStartCooking={() => swiperInstance.slideNext()}
                    allergens={getAllAllergens(allIngredientsInRecipe)}
                    totalMacros={getTotalMacros(allIngredientsInRecipe)}
                />
            </SwiperSlide>
            <SwiperSlide>
                <PageTitle title="Ingredients"
                    onClickBack={() => swiperInstance.slidePrev()}
                    onClickNext={() => swiperInstance.slideNext()}
                >
                    <IngredientsList items={allIngredientsInRecipe} setNewRecipe={setNewRecipe} />
                </PageTitle>
            </SwiperSlide>
            <SwiperSlide>
                <PageTitle
                    title={recipe?.metadata?.name}
                    onClickBack={() => swiperInstance.slidePrev()}
                    displayNextBtn={false}
                >
                    <Steps steps={addActiveToSubSteps(newRecipe.steps)} hardwareStateMemo={hardwareStateMemo} subStepWidth={newRecipe?.metadata?.subStepWidth || "default"} />
                </PageTitle>
            </SwiperSlide>
        </Swiper> : <Box style={{ width: "100%" }}>
            <Loader />
        </Box>}
    </>)
}

const ExperiencePick = ({ children, path, setHardwareStateMemo, hardwareStateMemo }) => {
    return <>
        {path === "2" ? <IngredientsFromDB>
            {children}
        </IngredientsFromDB> :
            <HardwareTask>
                <SecureArea>
                    <IngredientsFromDB>
                        <StyledEngineProvider injectFirst>
                            <ThemeProvider theme={ccTheme}>
                                <Box display="flex" height="100vh">
                                    <HardwareBar setHardwareStateMemo={setHardwareStateMemo} hardwareStateMemo={hardwareStateMemo} />
                                    {children}
                                </Box>
                            </ThemeProvider>
                        </StyledEngineProvider>
                    </IngredientsFromDB>
                </SecureArea >
            </HardwareTask>}
    </>;
}

const RecipeDisplay4 = ({ recipeId, path }) => {
    const { id } = useParams()
    const location = useLocation()
    const { newRecipe, loading } = useRecipeJsonDB()
    const recipe = newRecipe.find(i => i.id === id || recipeId)
    const [hardwareStateMemo, setHardwareStateMemo] = useState({
        btFirstConnect: false,
        weighTare: 0,
    })

    useEffect(() => {
        const sessionId = uuid()
        const currPath = location.pathname
        sessionStorage.setItem("sessionId", sessionId)
        sessionStorage.setItem("currPath", currPath)
        startSession(sessionId, undefined, undefined, id)
    }, [id])

    return (
        <ExperiencePick path={path} setHardwareStateMemo={setHardwareStateMemo} hardwareStateMemo={hardwareStateMemo}>
            <Experience2 recipe={recipe} hardwareStateMemo={hardwareStateMemo} loading={loading} />
        </ExperiencePick>
    )
}

export default RecipeDisplay4