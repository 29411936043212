import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import SimpleSliderLayout from './hardwareUI/SimpleSliderLayout'
import Starting from './hardwareUI/Starting'

const areEqual = (prevProps, nextProps) => {
    if (prevProps.hardwareState.status === nextProps.hardwareState.status && prevProps.connected === nextProps.connected
        && prevProps.connectUI === nextProps.connectUI && prevProps.starting === nextProps.starting)
        return true
    return false
}

const HardwareCore = React.memo(({
    connectToSerial,
    sendHardwareInstruction,
    forceStopHardware,
    hardwareState,
    connected,
    connectUI = true,
    hardwareTimeLeft,
    starting = false,
    settings,
    isStartR1Slide = false,
    setCompletedSteps,
    modeOfOperation = "not-manual",
    displayedTimer = null,
}) => {
    const [hardwareSettings, setHardwareSettings] = useState(settings)
    useEffect(() => {
        setHardwareSettings(settings)
    }, [settings])

    const onSend = (startingR1Time) => {
        if (isStartR1Slide)
            setCompletedSteps()
        sendHardwareInstruction(hardwareSettings, startingR1Time)
    }


    if (starting)
        return (
            <Starting />
        )
    return (
        <SimpleSliderLayout
            hardwareSettings={hardwareSettings}
            setHardwareSettings={setHardwareSettings}
            connectToSerial={connectToSerial}
            connectUI={connectUI}
            connected={connected}
            onSend={onSend}
            onStop={forceStopHardware}
            hardwareState={hardwareState}
            hardwareTimeLeft={hardwareTimeLeft}
            modeOfOperation={modeOfOperation}
            displayedTimer={displayedTimer}
        />
    )
}, areEqual)

HardwareCore.propTypes = {
    sendHardwareInstruction: PropTypes.func.isRequired,
    forceStopHardware: PropTypes.func.isRequired,
    connectToSerial: PropTypes.func.isRequired,
    hardwareState: PropTypes.array,
    connected: PropTypes.bool,
    connectUI: PropTypes.bool,
    starting: PropTypes.bool,
    settings: PropTypes.object.isRequired,
}

export default HardwareCore