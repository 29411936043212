import React from 'react'
import { Box } from '@mui/material'
import ListBox from './ListBox'
import PropTypes from 'prop-types'

const ListView = ({ image, name, qty, subdiscription, onClickHandler, boxStyle }) => {
    
    return (
        <Box 
            display="flex"
            flexDirection="row"
            alignItems="end"
            style={boxStyle}
            onClick={onClickHandler}>
                {qty ?
                    <ListBox
                        mainBox={{ text: name, subtext: subdiscription }}
                        sideBox={{ text: qty.amount, sidetext: qty.unit, subtext: qty.secondary }}
                        image={image}
                        /> : <ListBox
                                mainBox={{ text: name, subtext: subdiscription }}
                                image={image}
                            />
                        }
        </Box >
    )
}

ListView.propTypes = {
    image: PropTypes.string,
    name: PropTypes.string,
    qty: PropTypes.shape({
        amount: PropTypes.number,
        unit: PropTypes.string,
        secondary: PropTypes.string
    }),
    subdiscription: PropTypes.string,
    onClickHandler: PropTypes.func,
    boxStyle: PropTypes.shape({
        backgroundImage: PropTypes.string,
        zIndex: PropTypes.number,
        borderRadius: PropTypes.number,
        height: PropTypes.string
    }),  
}

export default ListView