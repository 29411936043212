import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Typography } from '@mui/material'
import CircleButton from './CircleButton'
import CookingProgressBar from './CookingProgressBar'
import ETAGraphic from './ETAGraphic'
import FlameGraphic from './FlameGraphic'
import HardwareGraphic from './HardwareGraphic'

const styleData = {
    heightOfBar: "10vh",
    heightOfRoundIconButtons: "9vh",
    heightOfButton: 100,
    widhtOfButton: 150
}

const Divider = () => (
    <Box
        width={4}
        bgcolor="#F0F5D0"
        height="75%"
    />
)

const NextButton = ({ onClick, r1Button = false }) => {
    const { heightOfButton, widhtOfButton } = styleData
    return (
        <Box
            position="fixed"
            bottom={0}
            bgcolor={r1Button ? "#f50057" : "#7D891D"}
            height={heightOfButton}
            width={widhtOfButton}
            right={0}
            borderRadius="20px"
            zIndex={6}
        >
            <Button
                style={{ width: "100%", height: "100%", borderRadius: 20 }}
                onClick={onClick}
            >
                {r1Button ? <img
                    src="https://res.cloudinary.com/prodjour/image/upload/v1630327255/ReactLogos/react-icon-3pt_puizyn.png"
                    alt="R1"
                    width="70%"
                /> :
                    <Typography variant="h1" style={{ color: "#FFFFFF" }}>{r1Button ? "Start" : "➡"}</Typography>
                }
            </Button>
        </Box >
    );
}

const AppBottomBar = ({
    dispatch,
    etaInMin = 10,
    cookingPhaseData,
    flameStatus = "off",
    react1Settings,
    isStartR1Slide,
    displayNext,
    slideNext,
    moveByIndex,
    popUpR1Settings,
    popUpWeight,
    scaleSettings
}) => {
    const { heightOfBar, heightOfRoundIconButtons } = styleData
    const changeSlideOnClick = (id, slideIndex) => moveByIndex(slideIndex)
    const onClickNextButton = () => isStartR1Slide ? dispatch({ type: "SET-R1SETTINGS", data: isStartR1Slide }) : slideNext()

    useEffect(() => {
        const isR1Slide = isStartR1Slide ? true : false
        dispatch({ type: "SET-RECENTER", isR1Slide })
    }, [isStartR1Slide])

    return (
        <>
            <FlameGraphic flameStatus={flameStatus} heightOfBar={heightOfBar} />
            <Box
                position="fixed"
                bottom={0}
                bgcolor="#7D891D"
                height={heightOfBar}
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
            >
                <CircleButton
                    heightOfRoundIconButtons={heightOfRoundIconButtons}
                    imageUrl="https://res.cloudinary.com/prodjour/image/upload/v1627984236/icons/Nutrition_wilmco.png"
                    onClick={() => dispatch({ type: "TOGGLE-INGREDIENT" })}
                />
                <CircleButton
                    heightOfRoundIconButtons={heightOfRoundIconButtons}
                    imageUrl="https://res.cloudinary.com/prodjour/image/upload/v1627990953/icons/Equipment_j7dp4v.png"
                    onClick={() => dispatch({ type: "TOGGLE-EQUIPMENT" })}
                />
                <Divider />
                <CookingProgressBar
                    cookPhaseData={cookingPhaseData?.cookingInfo}
                    activeStepId={cookingPhaseData?.cookingState}
                    heightOfRoundIconButtons={heightOfRoundIconButtons}
                    changeSlideOnClick={changeSlideOnClick}
                />
                <Divider />
                <HardwareGraphic
                    react1Settings={react1Settings}
                    moveToNextSlide={slideNext}
                    isStartR1Slide={isStartR1Slide}
                    dispatch={dispatch}
                    popUpR1Settings={popUpR1Settings}
                    popUpWeight={popUpWeight}
                    scaleSettings={scaleSettings}
                />
                <Divider />
                <ETAGraphic etaInMin={etaInMin} />
            </Box >
            {displayNext ? <NextButton onClick={onClickNextButton} r1Button={isStartR1Slide} /> : null}
        </>
    )
}

AppBottomBar.propTypes = {
    onClickNext: PropTypes.func,
    etaInMin: PropTypes.number,
    cookingPhaseData: PropTypes.array,
    flameStatus: PropTypes.oneOf(["off", "low", "medium", "high"]),
    cookingPhaseState: PropTypes.string,
    react1Settings: PropTypes.object.isRequired,
    hardwarePayload: PropTypes.object.isRequired,
    dispatch: PropTypes.func,
    moveByIndex: PropTypes.func,
}

export default AppBottomBar
