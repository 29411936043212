import { Typography, Box, } from "@mui/material";
import React from "react";
import WeightBtn from "./WeightBtn";
import MediaPlayer from "./MediaPlayer";
import PropTypes from 'prop-types'
import PopUpModal from "./PopUpModal";

const PrepModal = ({ open, onClose, ingredient, prep, weight }) => {
    return (<PopUpModal
        open={open}
        onClose={onClose}
    >
        <Box className="prep-content-parent">
            <Box className="prep-text-content">
                <Box>
                    <Typography variant="h5">
                        {`${ingredient?.displayName} ${weight?.defaultVal ? `x${weight?.defaultVal}` : ""}`}
                    </Typography>
                    <Typography variant="body2" textAlign="center">
                        {prep?.displayName ? prep.displayName : ""}
                    </Typography>
                </Box>
                <img src={prep?.image?.postPrepImage} />
                {weight?.displayBtn ? <WeightBtn weight={weight?.value} btnClass="prep-ing-btn dialog-weigh-btn-dimension" nutrientInfo={ingredient?.nutrientInfo} img={ingredient?.image} /> :
                    <Typography variant="h5">{weight?.value}</Typography>
                }
            </Box>
            <Box style={{ width: 577, height: 490 }}>
                <MediaPlayer
                    url={prep?.howMedia}
                    muted={prep?.mute === "" ? undefined : prep?.mute}
                    duration={prep?.duration}
                    startTime={prep?.startTime}
                    loop={prep?.loop === "" ? undefined : prep?.loop}
                />
            </Box>
        </Box>
    </PopUpModal >)
}

PrepModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    ingredient: PropTypes.object,//change to shape
    prep: PropTypes.object,
    weight: PropTypes.shape({
        displayBtn: PropTypes.bool,
        value: PropTypes.string
    }),
    media: PropTypes.shape({
        howMedia: PropTypes.string,
        duration: PropTypes.number,
        startTime: PropTypes.number,
        videoLoop: PropTypes.bool,
        muted: PropTypes.bool
    })
}

export default PrepModal