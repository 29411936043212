import { SvgIcon, Box, Button, Slider, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as Blade } from '../../svg/blade.svg';
import { ReactComponent as Temperature } from '../../svg/temperature.svg';
import { ReactComponent as Time } from '../../svg/time.svg';
import { getTime } from "../../utils/experience2/stepsInstruction";

const SettingsEdit = ({ setting, setSettings, disabled = false }) => {
    const { time, temperature, speed } = setting
    const deltaTemp = 5
    const upperLimitTemp = 120
    const lowerLimitTemp = 20

    const deltaTime = 1
    const lowerLimitTime = 0
    const upperLimitTime = 3659

    const deltaSpeed = 1
    const lowerLimitSpeed = 0
    const upperLimitSpeed = 13

    return (
        <Box display="flex" style={{ gap: "50px", padding: 20 }}>
            <Box display="flex" flexDirection="column" alignItems="center" style={{ gap: "50px", width: 350 }}>
                <SvgIcon component={Temperature} style={{ fontSize: 40 }} viewBox="0 0 23.014 29.873" />
                <Slider
                    color="secondary"
                    value={temperature}
                    min={lowerLimitTemp}
                    max={upperLimitTemp}
                    step={deltaTemp}
                    onChange={(e, value) => {
                        const settings = { ...setting, temperature: value }
                        setSettings(settings)
                    }}
                    valueLabelFormat={x => x + "°C"}
                    valueLabelDisplay="on"
                    disabled={disabled}
                />
            </Box>
            <Box display="flex" alignItems="center" flexDirection="column" style={{ gap: "50px" }}>
                <SvgIcon component={Time} style={{ fontSize: 40 }} viewBox="0 0 27.646 27.646" />
                <Box display="flex" flexDirection="column" style={{ gap: "10px", width: "350px" }}>
                    <Box
                        display="flex"
                        width="100%"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Button color="secondary" disabled={time < 1 || disabled} onClick={() => setSettings({ ...setting, time: time - 60 })}><Typography variant="h3">-</Typography></Button>
                        <Typography variant="h5">min</Typography>
                        <Button color="secondary" disabled={time > 3659 || disabled} onClick={() => setSettings({ ...setting, time: time + 60 })}><Typography variant="h4">+</Typography></Button>
                    </Box>
                    <Slider
                        color="secondary"
                        value={time}
                        min={lowerLimitTime}
                        max={upperLimitTime}
                        step={deltaTime}
                        onChange={(e, value) => {
                            const settings = { ...setting, time: value }
                            setSettings(settings)
                        }}
                        valueLabelDisplay="on"
                        valueLabelFormat={value => getTime(value)}
                        disabled={disabled}
                    />
                    <Box
                        display="flex"
                        width="100%"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Button color="secondary" disabled={time < 1 || disabled} onClick={() => setSettings({ ...setting, time: time - 1 })}><Typography variant="h3">-</Typography></Button>
                        <Typography variant="h5">sec</Typography>
                        <Button color="secondary" disabled={time > 3659 || disabled} onClick={() => setSettings({ ...setting, time: time + 1 })}><Typography variant="h4">+</Typography></Button>
                    </Box>
                </Box>
            </Box>
            <Box display="flex" alignItems="center" flexDirection="column" style={{ gap: "50px", width: 350 }}>
                <SvgIcon component={Blade} viewBox="0 0 23.643 23.066" style={{ fontSize: 40 }} />
                <Slider
                    color="secondary"
                    value={speed}
                    min={lowerLimitSpeed}
                    max={upperLimitSpeed}
                    step={deltaSpeed}
                    onChange={(e, value) => {
                        const settings = { ...setting, speed: value }
                        setSettings(settings)
                    }}
                    valueLabelDisplay="on"
                    disabled={disabled}
                />
            </Box>
        </Box>
    )
}

export default SettingsEdit