import React from 'react'
import './App.css'
import ccTheme from './theme/CookClubTheme'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import { AuthProvider } from "./firebase/context"
import NotFound404 from './pages/NotFound404';
import RecipeListing from './pages/RecipeListing';
import InputRecipeJson from './pages/InputRecipeJson';
import TestHardwareConnect from './pages/TestHardwareConnect';
import DirectHardwareDrive from './pages/DirectHardwareDrive';
import RecipeDisp from './pages/RecipeDisp';
import IngredientsEditor from './pages/IngredientsEditor';
import NewBluetoothTest from './pages/NewBluetoothTest';
import RecipeDisplay4 from './pages/RecipeDisplay4';
import NewRecipeJson from './pages/NewRecipeJson';
import Experience2Listing from './pages/Experience2Listing'
import HardwareDrive2 from './pages/HardwareDrive2'
import { GlobalSettingsProvider } from './utils/globalSettings/globalSettingsContext';
import AdminPage from './pages/AdminPage';

function App() {
  return (
    <div className="App">
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={ccTheme}>
          <AuthProvider>
            <GlobalSettingsProvider>
              <Router>
                <Switch>
                  <Route exact path="/">
                    <RecipeListing />
                  </Route>
                  <Route exact path="/experience2">
                    <Experience2Listing />
                  </Route>
                  <Route path="/experience2/driveHardware" >
                    <HardwareDrive2 hardwarebar />
                  </Route>
                  <Route path="/experience2/:id">
                    <RecipeDisplay4 />
                  </Route>
                  <Route path="/recipeadmin/:recipeId">
                    <InputRecipeJson />
                  </Route>
                  <Route path="/recipeadmin">
                    <InputRecipeJson />
                  </Route>
                  <Route path="/recipedisplay">
                    <RecipeListing />
                  </Route>
                  <Route path="/recipelist">
                    <RecipeListing ingredientsOnly />
                  </Route>
                  <Route path="/test/:recipeId">
                    <RecipeDisp />
                  </Route>
                  <Route path="/ingredients/:recipeId">
                    <RecipeDisp ingredientsOnly />
                  </Route>
                  <Route path="/test">
                    <RecipeDisp adminOnly />
                  </Route>
                  <Route path="/testjson/:recipeId">
                    <InputRecipeJson />
                  </Route>
                  <Route path="/testhardware">
                    <TestHardwareConnect />
                  </Route>
                  <Route path="/drivehardware">
                    <DirectHardwareDrive />
                  </Route>
                  <Route exact path="/ingredientsadmin" >
                    <IngredientsEditor />
                  </Route>
                  <Route path="/ingredientsadmin/:id" >
                    <IngredientsEditor />
                  </Route>
                  <Route path="/newjson/:recipeId">
                    <NewRecipeJson />
                  </Route>
                  <Route path="/newjson">
                    <NewRecipeJson />
                  </Route>
                  <Route path="/bluetest" >
                    <NewBluetoothTest />
                  </Route>
                  <Route path="/admin" >
                    <AdminPage />
                  </Route>
                  <Route>
                    <NotFound404 />
                  </Route>
                </Switch>
              </Router>
            </GlobalSettingsProvider>
          </AuthProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </div >
  );
}

export default App;
