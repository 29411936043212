import { Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

const Label = ({ label }) => {
    return (
        <Typography>{label}</Typography>
    )
}

Label.propTypes = {
    label: PropTypes.string
}

export default Label