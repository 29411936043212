import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import {
    Drawer, IconButton, List, ListItem, TextField, Button, Typography, CircularProgress,
    Select, MenuItem
} from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings';
import { GlobalSettingsContext } from './globalSettingsContext';
import useFirestore from '../../firebase/useFirebase';

const GlobalSettingsButton = ({ }) => {
    const { globalSettings, resetSettings, setGlobalSettings } = useContext(GlobalSettingsContext)
    const [drawerOpen, setDrawerOpen] = useState(false)
    const { data: devices, loading: deviceListLoading } = useFirestore("devices")
    const { data: users, loading: userListLoading } = useFirestore("users")

    function onSelectDevice({ target }) {
        const deviceObj = devices.find(d => d.id === target.value)
        const newDeviceName = deviceObj ? deviceObj.displayName : "any"
        setGlobalSettings({ ...globalSettings, device: newDeviceName })
    }

    function onSelectUser({ target }) {
        setGlobalSettings({ ...globalSettings, user: target.value })
    }

    function getUserFromId(userId) {
        if (userId === "any")
            return { nickName: "Any", recipesForUser: [] }
        if (userListLoading)
            return { nickName: "Loading", recipesForUser: [] }
        return users.find(u => u.id === userId)
    }

    function getDeviceIdFromDeviceName(deviceName) {
        const deviceObj = devices.find(d => d.displayName === deviceName)
        if (deviceObj)
            return deviceObj.id
        return "any"
    }

    function getUserIdFromUserName(userName) {
        const userObj = users.find(u => u.nickName === userName)
        if (userObj)
            return userObj.id
        return "any"
    }

    return (<>
        <IconButton onClick={() => setDrawerOpen(true)}>
            <SettingsIcon />
        </IconButton>
        <Drawer open={drawerOpen} onClose={() => setDrawerOpen(false)} anchor="right">
            <List>
                <ListItem>
                    <TextField value={globalSettings.deviceId} disabled />
                </ListItem>
                <ListItem>
                    {deviceListLoading ? <CircularProgress /> :
                        <Select value={getDeviceIdFromDeviceName(globalSettings.device)} onChange={onSelectDevice}>
                            <MenuItem value="any"><Typography>Any</Typography></MenuItem>
                            {devices.map(({ displayName, id }) => <MenuItem key={id} value={id}><Typography>{displayName}</Typography></MenuItem>)}
                        </Select>
                    }
                </ListItem>
                <ListItem>
                    {userListLoading ? <CircularProgress /> :
                        <Select value={globalSettings.user} onChange={onSelectUser}>
                            <MenuItem value="any"><Typography>Any</Typography></MenuItem>
                            {users.map(({ nickName, id }) => <MenuItem key={id} value={id}><Typography>{nickName}</Typography></MenuItem>)}
                        </Select>
                    }
                </ListItem>
                <ListItem>
                    <Button color="secondary" onClick={resetSettings}><Typography>Reset</Typography></Button>
                </ListItem>
            </List>
        </Drawer>
    </>)
}

GlobalSettingsButton.propTypes = {}

export default GlobalSettingsButton