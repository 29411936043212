const recipeJSONChecks = json => { 
    const [ingredientCheck, ingredientErr] =  checkForIngredient(json.ingredients)
    const [processCheck, processErr] = checkForProcess(json.process)
    const err = [ingredientErr, processErr].filter( i => i !== "OK" )
    return [ingredientCheck && processCheck, err.length ? err : "OK"]
}

const checkForIngredient = ingredients => {
    const visualization = ingredients?.visualization
    const list = ingredients?.list
    if (!visualization)
        return [false, "no visualization slides in ingredients"]
    if (!list)
        return [false, "no list of Ingredients in ingredients"]
    const listOfIds = list.map(item => item.id)
    const visIds = visualization.reduce((ret, { type, data }) => type === "Ingredients6" ? [...ret, ...data.ingredientIds] : ret, [])
    const allIn = visIds.every(id => listOfIds.includes(id))
    return [allIn, allIn ? "OK" : "Ingredient ID present in Visualization not in List"]
}

const checkForProcess = process => {
    const processSlideTitleCheck = (() => {
        for (let i=0; i<process.length; i++) {
            if (process[i].visualization[0].type !== "ProcessTitleSlide")
                return false
        }
        return true
    })()
    return [processSlideTitleCheck, processSlideTitleCheck ? "OK" : "First slide of process should be of the type ProcessTitleSlide"]
}

export default recipeJSONChecks