import React from "react";
import StepsDescription from "./StepsDescription";
import PropTypes from 'prop-types';
import { titles } from "../../utils/experience2/typesVariable";
import MediaPlayer from "./MediaPlayer";
import { Box } from "@mui/material";

const UrlUtilityType = ({ parameters, title, status, type }) => {
    return (

        <StepsDescription
            title={title || titles[type]}
            status={status}
            details={<MediaPlayer
                url={parameters?.url}
                muted={parameters?.muted}
                duration={parameters?.duration}
                startTime={parameters?.startTime}
                loop={parameters?.loop}
            />}
        />
    )
}

UrlUtilityType.propTypes = {
    parameters: PropTypes.object,
    title: PropTypes.string,
    status: PropTypes.string,
    type: PropTypes.string
}

export default UrlUtilityType