import React, { useEffect } from "react";
import { useContext } from "react";
import { HardwareContext } from "./HardwareTasks";
import { Button, SvgIcon } from '@mui/material'
import { ReactComponent as ConnectedIcon } from "../../svg/bluetooth-connect-line.svg"
import { ReactComponent as ConnectIcon } from "../../svg/bluetooth-on.svg"

const ConnectButton = ({ setHardwareStateMemo = () => { } }) => {
    const { connectToHardware, connected, disconnect } = useContext(HardwareContext)

    useEffect(() => {
        if (connected)
            setHardwareStateMemo(old => ({ ...old, btFirstConnect: true }))
    }, [connected])

    return (<>
        {!connected ? <Button
            variant=""
            onClick={connectToHardware}
        >
            <SvgIcon component={ConnectIcon} viewBox="0 0 27.881 41.91" style={{ fontSize: 60 }} />
        </Button> :
            <Button
                variant=""
                onClick={disconnect}
            >
                <SvgIcon component={ConnectedIcon} viewBox="0 0 35.93 35.93" style={{ fontSize: 60 }} />
            </Button>
        }
    </>)
}

export default ConnectButton