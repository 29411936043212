import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import SecureArea from '../components/authentication/SecureArea'
import { Container, Snackbar, Box, Button, IconButton, Typography, Dialog, Select, MenuItem } from '@mui/material'
import RecipeDisplay3 from './RecipeDisplay3'
import useRecipeFromDB from '../utils/RecipeBackEnd/loadRecipe'
import RecipeEditUI2 from '../components/recipeEditor/RecipeEditUI2'
import Icon from "@mdi/react"
import { mdiCloseThick } from '@mdi/js'
import RecipeDropDown from '../components/RecipeDropDown'
import SnippetsOfTypes from '../components/recipeEditor/SnippetsOfTypes'
import { blankRecipe, getname, collectionName } from '../utils/RecipeBackEnd/recipe2Structure'
import { v4 as uuid } from 'uuid'
import recipeJSONChecks from '../recipies/recipe2JsonChecks'
import LoadRecipeFromJSON from '../components/recipeEditor/LoadRecipeFromJSON'
import IconShowroom from '../components/IconShowroom'
import DuplicateRecipe from '../components/recipeEditor/DuplicateRecipe'
import { useParams } from "react-router-dom"

const InputRecipeJson = ({ defaultRecipeId = null, deafultRecipeCollection = collectionName }) => {
    const { recipeId: urlRecipeId } = useParams()
    const [json, setJson] = useState({})
    const [loadRecipeExperiance, setLoadRecipeExperiance] = useState(false)
    const [activeRecipeId, setActiveRecipeId] = useState(urlRecipeId ? urlRecipeId : defaultRecipeId)
    const [snackBarStatus, setSnackBarStatus] = useState({ open: false, message: "" })
    const [dialogOpen, setDialogOpen] = useState(false)
    const [iconDialogOpen, setIconDialogOpen] = useState(false)
    const [loadDialogOpen, setLoadDialogOpen] = useState(false)
    const [activeRecipeCollection, setActiveRecipeCollection] = useState(deafultRecipeCollection)
    const [recipeJSON, loading, save, saving, deleteRecipe] = useRecipeFromDB(activeRecipeId, activeRecipeCollection)
    const [, , saveDeleted] = useRecipeFromDB(activeRecipeId, "deletedRecipe")
    const [duplicateRecipeOpen, setDuplicateRecipeOpen] = useState(false)

    useEffect(() => {
        setJson(recipeJSON)
    }, [recipeJSON])
    useEffect(() => {
        window.history.replaceState(null, "", "/testjson/" + activeRecipeId)
    }, [activeRecipeId])
    const onDump = () => {
        setSnackBarStatus({ open: true, message: "json Copied to ClipBoard" })
        navigator.clipboard.writeText(JSON.stringify(json, null, 2))
    }
    const onGenerateUUID = () => {
        setSnackBarStatus({ open: true, message: "new ID copied to Clipboard" })
        navigator.clipboard.writeText(uuid())
    }
    const onLoad = () => {
        const [okToLoad, err] = recipeJSONChecks(json)
        if (!okToLoad) {
            setSnackBarStatus({ open: true, message: err })
            return
        }
        setLoadRecipeExperiance(true)
    }
    const onSave = () => {
        const [okToLoad, err] = recipeJSONChecks(json)
        if (!okToLoad) {
            setSnackBarStatus({ open: true, message: err })
            return
        }
        save(
            json,
            () => setSnackBarStatus({ open: true, message: "Recipe Saved" }),
            e => setSnackBarStatus({ open: true, message: e.message })
        )
    }
    const onDelete = () => {
        saveDeleted(
            json,
            () => setSnackBarStatus({ open: true, message: "Tasted" }),
            e => setSnackBarStatus({ open: true, message: e.message })
        )
        deleteRecipe(
            () => setSnackBarStatus({ open: true, message: "Deleted" }),
            e => setSnackBarStatus({ open: true, message: e.message })
        )
        setActiveRecipeId(null)
    }
    const onChageCollection = ({ target }) => {
        setActiveRecipeId(null)
        setActiveRecipeCollection(target.value)
    }
    const collectionPairs = { recipe2: "Production", recipeStaging: "Staging" }
    if (!loadRecipeExperiance) {
        return (
            <SecureArea adminOnly>
                <Container>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-start"
                    >
                        <Select
                            onChange={onChageCollection}
                            value={activeRecipeCollection}
                            variant="filled"
                            renderValue={() => collectionPairs[activeRecipeCollection]}
                            displayEmpty
                            onClose={() => { }}
                        >
                            {Object.keys(collectionPairs).map(key => <MenuItem
                                value={key}
                                key={key}
                            >
                                {collectionPairs[key]}
                            </MenuItem>
                            )}
                        </Select>
                        <RecipeDropDown
                            collection={activeRecipeCollection}
                            checkFor={["process", "metadata"]}
                            onRecipeSelect={r => setActiveRecipeId(r)}
                            getNameFromRecipe={getname}
                            dataForNewRecipe={blankRecipe}
                        />
                        <Typography>{json?.metadata?.name}</Typography>
                    </Box>
                    <Box
                        height="88vh"
                        width="100%"
                        overflow="scroll"
                    >
                        {Object.keys(json).length === 0 || loading ? <></> :
                            <RecipeEditUI2 json={json} setJson={setJson} />
                        }
                    </Box>
                    <Button disabled={loading || saving} color="primary" onClick={onLoad}>Load</Button>
                    <Button disabled={loading || saving} color="primary" onClick={onSave}>Save</Button>
                    <Button disabled={loading || saving} color="primary" onClick={onDump}>Dump</Button>
                    <Button disabled={loading || saving} color="primary" onClick={() => setLoadDialogOpen(true)}>Load From JSON</Button>
                    <Button color="primary" onClick={onGenerateUUID}>Generate ID</Button>
                    <Button color="primary" onClick={() => setDialogOpen(true)}>Snippets</Button>
                    <Button color="primary" onClick={() => setIconDialogOpen(true)}>Icons</Button>
                    <Button color="primary" onClick={() => setDuplicateRecipeOpen(true)}>Duplicate</Button>
                    <Button disabled={loading || saving} color="secondary" onClick={onDelete} style={{ float: "right" }}>Delete</Button>
                    <Dialog
                        open={dialogOpen}
                        onClose={() => setDialogOpen(false)}
                        maxWidth="lg"
                        fullWidth
                    >
                        <SnippetsOfTypes />
                    </Dialog>
                    <Dialog
                        open={loadDialogOpen}
                        onClose={() => setLoadDialogOpen(false)}
                        maxWidth="lg"
                        fullWidth
                    >
                        <LoadRecipeFromJSON setJson={setJson} />
                    </Dialog>
                    <Dialog
                        open={iconDialogOpen}
                        onClose={() => setIconDialogOpen(false)}
                        maxWidth="lg"
                        fullWidth
                    >
                        <IconShowroom />
                    </Dialog>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        open={snackBarStatus.open}
                        autoHideDuration={6000}
                        onClose={() => setSnackBarStatus({ ...snackBarStatus, open: false })}
                        message={snackBarStatus.message}
                        action={
                            <IconButton size="small" aria-label="close" color="inherit" onClick={() => setSnackBarStatus({ ...snackBarStatus, open: false })}>
                                <Icon path={mdiCloseThick} />
                            </IconButton>
                        }
                    />
                    <Dialog
                        open={duplicateRecipeOpen}
                        onClose={() => setDuplicateRecipeOpen(false)}
                    >
                        <DuplicateRecipe
                            collection={collectionName}
                            setJson={setJson}
                            getNameFromRecipe={getname}
                        />
                    </Dialog>
                </Container>
            </SecureArea>
        )
    }
    return (
        <RecipeDisplay3 adminOnly recipeJson={json} />
    )
}

InputRecipeJson.propTypes = {
    recipeId: PropTypes.string
}

export default InputRecipeJson
