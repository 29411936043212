export const timerInstructions = ["cut", "saute", "cook", "mix", "boil", "roast", "clean"]
export const nonTimerInstruction = ["scrape", "strain"]
export const description = ["addIngredients", "addIngredientsWithAlternate"]
export const extraPrep = ["setAside"]
export const imgUtility = ["imgUtility"]
export const urlUtility = "urlUtility"
export const criticalStep = "criticalStep"
export const ratingCollector = "ratingCollector"
export const titles = {
    cut: "R1 is cutting",
    saute: "R1 is sauteing",
    scrape: "Scrape the walls of R1!",
    addIngredients: "Add ingredients to R1",
    cook: "R1 is cooking"
}
export const noWeighingUnit = ["tsp", "tbsp"]
export const VEGETABLES = "Vegetables"
export const MASALA = "Masalas"
export const PANTRY = "Pantry"
export const videos = {
    scrape: "https://res.cloudinary.com/dgakjrw8j/video/upload/v1638273599/Chicken%20Keema/Scrapping_scarpping_scrapping_dbu6ww.mp4#t=0",
}
export const allTypes = [...timerInstructions, ...nonTimerInstruction, ...description, ...imgUtility, urlUtility, criticalStep, ratingCollector]