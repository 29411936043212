import { Accordion, AccordionDetails, AccordionSummary, Typography, Box } from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';
import React, { useContext } from "react";
import PropTypes from 'prop-types'
import { HardwareContext } from "./HardwareTasks";
import makeStyles from "@mui/styles/makeStyles";


export const TimerProgressBar = ({ totalTime }) => {
    const { time } = useContext(HardwareContext)
    const timerProgress = Math.sign(time) && 100 - Math.abs((time / totalTime) * 100)

    return (
        <Box style={{ width: "100%" }}>
            <LinearProgress sx={{ height: "20px", borderRadius: "10px", backgroundColor: "#D3D3D3" }} color="success" variant="determinate" value={timerProgress} />
        </Box>
    )
}
const AccordionDisplay = ({ title, details, button = null, openAccordion, setExpanded, status }) => {
    const color = status === "completed" ? "#B5D1C5" : "#ffffff"

    return (
        <Accordion expanded={openAccordion} onChange={setExpanded} style={{ borderRadius: "15px", backgroundColor: color, boxShadow: "none", margin: 0 }} >
            <AccordionSummary>
                <Box className="instruction-accordion left-align" style={{ flexDirection: "row", alignItems: "center" }}>
                    <Typography variant="h5">{title}</Typography>
                </Box>
            </AccordionSummary>
            <AccordionDetails >
                <Box className="instruction-accordion center-align" >
                    {details}
                    {button}
                </Box>
            </AccordionDetails>
        </Accordion >
    )
}

AccordionDisplay.prototype = {
    title: PropTypes.string,
    details: PropTypes.element,
    button: PropTypes.element
}

export default AccordionDisplay