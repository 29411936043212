import {Grid, TextField} from "@mui/material"
import R1SerialSettings from "../customInputs/R1SerialSettings"
import React from 'react'

const defaultValue = {
  title: "CAP ON",
  url: "https://res.cloudinary.com/swateaaa/image/upload/v1648104217/upload-for-json/cap-on_dyrzog.png",
  style: {},
  type: "",
  weigh: 0,
  settings: [{
    time: 0,
    temperature: "",
    speed: 0,
  }],
}

const ImgUtilityRender = ({parameters, setParameters}) => {
  const fields = parameters !== undefined && parameters!== {} ? parameters : defaultValue
  const onInputChange = (e, props, fieldType="string") => {
    if (fieldType === "number") {
      setParameters({...fields, [props]: e.target.valueAsNumber})
    } else {
      setParameters({...fields, [props]: e.target.value})
    }
  }
  const {settings, ...rest} = fields
  const setSettings = newSettings => setParameters({...fields, settings: newSettings})
  return (
    <Grid container>
      <TextField value={fields.title} label={"Title"} onChange={(e) => onInputChange(e, "title")} />
      <TextField value={fields.url} label={"Image Url"} onChange={(e) => onInputChange(e, "url")}/>
      <TextField value={fields.type} label={"Type"} onChange={(e) => onInputChange(e, "type")}/>
      <TextField value={fields.weigh} label={"Weigh"} type="number" onChange={(e) => onInputChange(e, "weigh", "number")}/>
      <Grid item xs={10}>
          <R1SerialSettings settings={settings} setSettings={setSettings} />
      </Grid>
    </Grid>
    
  )
}

export default ImgUtilityRender