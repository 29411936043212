import React, { useState, useEffect } from 'react'
import SecureArea from '../components/authentication/SecureArea'
import HardwareCore from '../components/HardwareCore'
import { Box, Dialog, DialogContent } from '@mui/material'
import useHardware from '../utils/hardware/hardwareState'
import WeightUI from '../components/appBar/WeightUI'
import Timer from '../components/appBar/Timer'

const settings = { type: "basic", data: { time: 20, temperature: 40, speed: 2 } }

const TimerSlide = ({ status, lastTime }) => {
    const [timerKey, setTimerKey] = useState(0)
    useEffect(() => {
        if (status === "operation")
            setTimerKey(old => old + 1)
    }, [status])
    return (<Box
        display="flex"
        justifyContent="center">
        <Timer
            timerKey={timerKey}
            duration={lastTime ? lastTime : 0}
            isPlaying={status === "operation"}
            size={400}
            style={{
                color: "#4cb828",
                fontSize: "30vh",
                fontWeight: "700" 
            }}
        />
    </Box>)
}

const DirectHardwareDrive = () => {
    const { triggerR1, forceStop, connectToHardware, stateOfHardware, connected, weight } = useHardware()
    const [popUp, setPopUp] = useState(false)
    const onSendInstruction = (r1Settings) => {
        triggerR1(r1Settings)
    }

    return (
        <SecureArea>
            {<HardwareCore
                    connectToSerial={connectToHardware}
                    sendHardwareInstruction={onSendInstruction}
                    forceStopHardware={forceStop}
                    hardwareState={stateOfHardware}
                    connected={connected}
                    settings={settings}
                    modeOfOperation="manual"
                    displayedTimer={<TimerSlide status={stateOfHardware.status} lastTime={stateOfHardware.lastTime}/>}
                />}
            <Box
                display="flex"
                justifyContent="center"
            >

                <WeightUI
                    weight={weight}
                    popUpStatus={popUp}
                    setScaleSettings={() => setPopUp(old => !old)}
                    text=""
                    style={{ width: "10vh" }}
                />
            </Box>
        </SecureArea>
    )
}

DirectHardwareDrive.propTypes = {

}

export default DirectHardwareDrive