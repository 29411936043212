import { Box, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Timer from "../appBar/Timer";
import { HardwareContext } from "./HardwareTasks";
import { getDuration } from "../../utils/experience2/stepsInstruction";
import PropTypes from "prop-types";

const getTimeString = time => {
    const timeSec = time % 60
    const timeSecString = timeSec < 10 ? "0" + timeSec.toString() : timeSec.toString()
    return Math.floor(time / 60) + ":" + timeSecString
}

const RecipeTimer = ({ size = 200, fontSize = 100, active = false, initialTime, timerStroke = false }) => {
    const { stateOfHardware, time } = useContext(HardwareContext)

    const { status } = stateOfHardware

    return (
        <Box className="timer">
            <Typography style={{ fontSize, color: "#00704A" }}>{status === "operation" ? getTimeString(time) : getTimeString(0)}</Typography>
        </Box>
    )
}

RecipeTimer.propTypes = {
    size: PropTypes.number,
    active: PropTypes.bool,
    initialTime: PropTypes.number
}

export default RecipeTimer