import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, MenuItem, Select, TextField, Typography, Button, InputLabel } from '@mui/material'
import { Add } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete'
import ScaleModel from './ScaleModel'

const R1SimpleSettings = ({ time, temperature, speed, scaleModel,
    setTime = () => { },
    setTemperature = () => { },
    setSpeed = () => { },
    setScaleModel = () => { },
    onDelete = () => { },
    showTime = true,
    showTemperature = true,
    showSpeed = true
}) => {
    const [deleteDisable, setDeleteDisable] = useState(false)
    const onDeleteLine = () => {
        setDeleteDisable(true)
        onDelete()
    }
    return (
        <Grid container alignItems="center">
            {showTemperature &&
                <Grid item xs={3}>
                    <InputLabel id="demo-simple-select-standard-label">Temperature</InputLabel>
                    <Select
                        value={temperature}
                        onChange={e => setTemperature(e.target.value)}
                        size="small"
                    >
                        <MenuItem value="20">No Heating</MenuItem>
                        <MenuItem value="40">40C</MenuItem>
                        <MenuItem value="50">50C</MenuItem>
                        <MenuItem value="60">60C</MenuItem>
                        <MenuItem value="70">70C</MenuItem>
                        <MenuItem value="80">80C</MenuItem>
                        <MenuItem value="90">90C</MenuItem>
                        <MenuItem value="95">95C</MenuItem>
                        <MenuItem value="100">100C</MenuItem>
                        <MenuItem value="105">105C</MenuItem>
                        <MenuItem value="110">110C</MenuItem>
                        <MenuItem value="115">115C</MenuItem>
                        <MenuItem value="120">120C</MenuItem>
                        <MenuItem value="125">125C</MenuItem>
                        <MenuItem value="130">130C</MenuItem>
                    </Select>
                </Grid>
            }
            {showSpeed &&
                <Grid item xs={3}>
                    <InputLabel id="demo-simple-select-standard-label">Speed</InputLabel>
                    <Select
                        value={speed}
                        onChange={e => setSpeed(e.target.value)}
                        size="small"
                    >
                        <MenuItem value={0}>0</MenuItem>
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                        <MenuItem value={9}>9</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={11}>11</MenuItem>
                        <MenuItem value={12}>12</MenuItem>
                        <MenuItem value={13}>Stir</MenuItem>
                        <MenuItem value={14}>Stir 2</MenuItem>
                    </Select>
                </Grid>
            }{showTime &&
                <Grid container item xs={4} alignItems="center">
                    <Grid item xs={6}>
                        <TextField
                            label="Seconds"
                            fullWidth
                            value={time}
                            onChange={e => setTime(e.target.valueAsNumber)}
                            type="number"
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography>{`${Math.floor(time / 60)} mins ${time % 60} secs`}</Typography>
                    </Grid>
                </Grid>
            }
            <Grid item xs={1}>
                <Button disabled={deleteDisable} onClick={onDeleteLine}><DeleteIcon color="secondary" /></Button>
            </Grid>
            <Grid item xs={12}>
                <ScaleModel scaleModel={scaleModel} setScaleModel={setScaleModel} />
            </Grid>
        </Grid>
    )
}

const R1SerialSettings = ({ settings = [], setSettings, showSpeed = true, showTemperature = true, showTime = true }) => {
    const setTtsAtIndex = (newTts, index) => setSettings(settings.map((set, i) => i === index ? newTts : set))
    const addTts = () => setSettings([...settings, { 
        time: 10, 
        temperature: "20", 
        speed: 13, 
        scaleModel: {
            type: "linear",
            parameters: {
                scaleFactor: 0
            }
        } }])
    const onDeleteAtIndex = index => setSettings(settings.filter((e, i) => i !== index))
    return (<>
        {settings.map((tts, index) => <R1SimpleSettings
            time={tts.time}
            temperature={tts.temperature}
            speed={tts.speed}
            scaleModel={tts.scaleModel}
            setTime={newTime => setTtsAtIndex({ ...tts, time: newTime }, index)}
            setTemperature={newTemp => setTtsAtIndex({ ...tts, temperature: newTemp }, index)}
            setSpeed={newSpeed => setTtsAtIndex({ ...tts, speed: newSpeed }, index)}
            setScaleModel={newScaleModel => setTtsAtIndex({...tts, scaleModel: newScaleModel}, index)}
            onDelete={() => onDeleteAtIndex(index)}
            showSpeed={showSpeed}
            showTemperature={showTemperature}
            showTime={showTime}
        />)}
        <Button onClick={addTts}><Add /></Button>
    </>)
}

R1SerialSettings.propTypes = {
    settings: PropTypes.arrayOf(PropTypes.object).isRequired,
    setSettings: PropTypes.func
}

export default R1SerialSettings