import React from 'react'
import { Box, Typography } from '@mui/material'
import JsonEditorDisplay from './JsonEditorDisplay'

const typeDefaults = [
    {
        name: "Instruction Slide",
        snippet: {
            "data": {
                "title": "Title of Slide",
                "note": {
                    "text": "This goes onto the Note"
                },
                "instructions": [
                    {
                        "icon": "add",
                        "steps": [
                            {
                                "text": "First Intruction"
                            },
                            {
                                "text": "Second Instrcution"
                            }
                        ]
                    },
                    {
                        "icon": "blend",
                        "steps": [
                            {
                                "text": "Third Instruction"
                            }
                        ]
                    }
                ],
                "backgroundMedia": {
                    "url": "gs://cookclub-app.appspot.com/Ingredients/SpecificVideos/onionsAndGarlic_mp4.mp4",
                    "offsetData": {
                        "heightZoom": "100%",
                        "top": "-00%",
                        "left": "0%",
                        "widthZoom": "100%"
                    },
                    "mute": true
                }
            },
            "type": "InstructionFeed",
            "id": "a820631b-29fc-43ef-8ef1-94316ee20e26"
        }
    },
    {
        name: "actionbutton for R1",
        snippet: {
            "actionButtons": [
                {
                    "type": "r1",
                    "text": "Start R1",
                    "hardwareSettings": {
                        "data": {
                            "temperature": 100,
                            "speed": 1,
                            "time": 480
                        },
                        "type": "basic"
                    }
                }
            ]
        }
    },
    {
        name: "Ingredient List",
        snippet: {
            "id": "1",
            "name": "Pasta",
            "default": "1b",
            "optionSet": [
                {
                    "qty": {
                        "unit": "g",
                        "amount": 200
                    },
                    "id": "1a",
                    "image": "https://res.cloudinary.com/prodjour/image/upload/v1628081317/Marinara/PennePasta_qftbyj.png",
                    "name": "Penne",
                    "subdiscription": "Pasta"
                },
                {
                    "id": "1b",
                    "subdiscription": "Pasta",
                    "image": "https://res.cloudinary.com/prodjour/image/upload/v1628088610/Marinara/Spaghetti_c9cats.png",
                    "name": "Spaghetti",
                    "qty": {
                        "unit": "g",
                        "amount": 200
                    }
                }
            ]
        }
    }
]

const SnippetsOfTypes = () => {
    return (
        <Box
            display="flex"
            flexDirection="column"
        >
            {typeDefaults.map(({ name, snippet }) => <Box
                key={name}
                height="400px"
                flex="0 0 33.33%"
            >
                <Typography>{name}</Typography>
                <JsonEditorDisplay value={snippet} saveButton={false} title={name} />
            </Box>)}
        </Box>
    )
}

SnippetsOfTypes.propTypes = {

}

export default SnippetsOfTypes
