import { CircularProgress, Container } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState, useContext } from 'react'
import SecureArea from '../components/authentication/SecureArea'
import Categories from '../components/recipeList/Categories'
import Navbar from '../components/recipeList/Navbar'
import TabBars from '../components/recipeList/TabBars'
import ToolBars from '../components/recipeList/ToolBars'
import RecipeList from '../components/recipeSelect/RecipeList'
import { FilterProvider } from '../utils/homepage/FilterContext'
import useRecipeListFromDB from '../utils/RecipeBackEnd/loadRecipeList'
import { collectionName, getname } from '../utils/RecipeBackEnd/recipe2Structure'
import useRecipeJsonDB from '../components/newRecipeJson/NewRecipeDB'
import { GlobalSettingsContext } from '../utils/globalSettings/globalSettingsContext'
import { useFirestoreDoc } from '../firebase/useFirebase'

const isRecipeActive = recipe => {
    const { metadata } = recipe
    if (!metadata) return false
    const { inactive } = metadata
    if (inactive) return false
    return true
}

const RecipeListing = ({ ingredientsOnly = false }) => {
    const recipeCollection = process.env.REACT_APP_VERCEL_DEPLOY_MODE === "PREVIEW" ? "recipeStaging" : collectionName
    const { recipeList } = useRecipeListFromDB(recipeCollection, {}, getname) // 1.0
    const { newRecipe: recipeForExp2, loading } = useRecipeJsonDB() // 2.0

    const [displayRecipeList, setDisplayRecipeList] = useState(recipeForExp2)

    const onFilterChange = (filteredRecipe) => {
        setDisplayRecipeList(filteredRecipe)
    }
    const exp2ActiveRecipes = recipeForExp2.filter(({ metadata }) => metadata?.active)

    useEffect(() => {
        setDisplayRecipeList(exp2ActiveRecipes)
    }, [recipeForExp2])

    if (loading) <CircularProgress />


    return (
        <SecureArea signInMessage={ingredientsOnly ? "One place to get all ingredients for the recipes you like." : undefined} landscapeWarning={ingredientsOnly ? false : undefined}>
            <Container>
                <Navbar />
                <FilterProvider>
                    {false && <>
                        <Categories filterRecipeList={onFilterChange} />
                        <ToolBars filterRecipeList={onFilterChange} />
                    </>}
                    <RecipeList
                        experimentalRecipes={recipeList.filter(({ metadata }) => metadata?.experimental)}
                        experimentalRecipes2={recipeForExp2.filter(({ metadata }) => !metadata?.active)}
                        recipeListExp1={recipeList.filter(({ metadata }) => !metadata?.inactive)}
                        loading={loading}
                        experience2Recipe={displayRecipeList}
                        displayType={ingredientsOnly ? "INGREDIENTS_ONLY" : "LINK_TO_RECIPE"}
                    />
                    <TabBars onFilterChange={onFilterChange} />
                </FilterProvider>
            </Container>
        </SecureArea>
    )
}

RecipeListing.propTypes = {
    ingredientsOnly: PropTypes.bool
}

export default RecipeListing
