import { Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React from "react";

const defaultIcontype = "star5"

const RatingCollector = ({ parameters, setParameters }) => {
    const defaultParameters = { iconType: defaultIcontype, ...parameters } //assumed that maker is always making some change to save star5

    const onChangeRating = (newVal) => {
        setParameters({ ...defaultParameters, ...newVal })
    }

    return (<Grid container>
        <Grid item xs={4}>
            <TextField
                label="Rating type"
                value={parameters?.ratingType}
                type="string"
                fullWidth
                onChange={e => onChangeRating({ ratingType: e.target.value })}
            />
        </Grid>
        <Grid item xs={4}>
            <TextField
                label={"Display text"}
                value={parameters?.displayText}
                type="string"
                fullWidth
                onChange={e => onChangeRating({ displayText: e.target.value })}
            />
        </Grid>
        <Grid item xs={4}>
            <InputLabel >Icon type</InputLabel>
            <Select
                defaultValue={defaultIcontype}
                value={parameters?.iconType}
                onChange={e => onChangeRating({ iconType: e.target.value })}
            >
                <MenuItem value="star5">star5</MenuItem>
                <MenuItem value="star10">star10</MenuItem>
                <MenuItem value="heart5">heart5</MenuItem>
                <MenuItem value="heart10">heart10</MenuItem>
                <MenuItem value="satisfaction">satisfaction</MenuItem>
                <MenuItem value="thumbs">thumbs</MenuItem>
            </Select>
        </Grid>
    </Grid>)
}

export default RatingCollector