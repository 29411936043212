import React, { useContext, useState } from "react"
import PrepModal from "./PrepModal"
import { Button } from "@mui/material";
import { IngredientsContext } from '../ingredients2/IngredientsFromDB';
import { getWeighingScaleValue } from "../../utils/experience2/ingredientsUtil"
import PropTypes from 'prop-types';

const PrepButton = ({ ingredientId, prepId, amount }) => {
    const { ingredients } = useContext(IngredientsContext)
    const [toggleModal, setToggleModal] = useState(false)
    const ingredient = ingredients ? ingredients[ingredientId] : undefined
    const prep = ingredient?.preps ? ingredient.preps[prepId] : undefined
    return (<>
        <Button onClick={() => setToggleModal(old => !old)}>Prep instruction</Button>
        <PrepModal
            open={toggleModal}
            onClose={() => setToggleModal(old => !old)}
            ingredient={ingredient}
            weight={getWeighingScaleValue(ingredient?.conversion, "g", amount?.qty, amount?.unit)}
            prep={prep}
        />
    </>)
}

PrepButton.propTypes = {
    ingredientId: PropTypes.string,
    prepId: PropTypes.string,
    amount: PropTypes.shape({
        qty: PropTypes.number,
        unit: PropTypes.string
    })
}

export default PrepButton