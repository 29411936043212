import { useEffect, useState } from 'react'
import { getStorage, ref, getDownloadURL } from "firebase/storage"

const imageLoadingUrl = "https://res.cloudinary.com/prodjour/image/upload/c_scale,h_" + (400) + "/v1621114765/TestPDF/Loading_fdzzw3.jpg"

export const resolveGSUrl = async url => {
    if (url.trim().split(":")[0] === 'gs') {
        const storage = getStorage()
        try {
            const url1 = await getDownloadURL(ref(storage, url))
            return url1
        } catch (err) {
            console.log("Error in resolveGSurl for : " + url)
        }
    }
    return url
}

const useFireStoreLink = (url, loadingUrl = imageLoadingUrl) => {
    const [returnUrl, setReturnUrl] = useState(loadingUrl)
    useEffect(() => {
        setReturnUrl(resolveGSUrl(url))
    }, [url])
    return returnUrl
}

export default useFireStoreLink