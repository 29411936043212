import { iconList } from "../../utils/iconList"

const onExpandSchema = {
    type: "object",
    properties: {
        type: {
            type: "string",
            description: "Type of Slide to Create",
            enum: ["ContentSlide", "Ingredients6", "InstructionFeed", "directSlide", "PrepDisplay", "SingleFocus"]
        },
        data: { type: "object" }
    },
    required: ["type"]
}

const instructionSchema = {
    type: "object",
    properties: {
        time: { type: "number", min: 0, max: 5000 },
        temperature: { type: "number", min: 25, max: 150 },
        speed: { type: "number", min: 0, max: 10 }
    },
    required: ["time", "temperature", "speed"],
    additionalProperties: false
}

const actionButtonsSchema = {
    type: "array", items: {
        type: "object",
        properties: {
            type: { type: "string", enum: ["r1", "slide", "floating"] },
            icon: { type: "string" },
            text: { type: "string" }  
        },
        required: ["text"],
        allOf:[{
            if: {
                properties: {
                    type: { const: "r1" }
                }
            },
            then: {
                properties: {
                    location: { type: "string" },
                    timerText: { type: "string" },
                    timerInstruction: { type: "string", enum: ["Continue", "Wait"]},
                    slideNext: {type: "boolean"},
                    onExpand: onExpandSchema,
                    hardwareSettings: {
                        type: "object",
                        properties: {
                            type: { type: "string", enum: ["basic", "serial"] },
                            cap: { type: "string", enum: ["on", "off"]},
                            data: {
                                type: ["object", "array"],
                                properties: {
                                    time: { type: "number", min: 0, max: 5000 },
                                    temperature: { type: "number", min: 25, max: 150 },
                                    speed: { type: "number", min: 0, max: 10 },
                                },
                                required: ["time", "temperature", "speed"],
                                additionalProperties: false
                            }
                        },
                        required: ["type", "data"],
                        additionalProperties: false,
                        allOf: [{
                            if: {
                                properties: {
                                    type: { const: "basic" }
                                }
                            },
                            then: {
                                properties: {
                                    data: instructionSchema
                                }
                            }
                        }, {
                            if: {
                                properties: {
                                    type: { const: "serial" }
                                }
                            },
                            then: {
                                properties: {
                                    data: {
                                        type: "array",
                                        minItems: 1,
                                        items: instructionSchema
                                    }
                                }
                            }
                        }]
                    }
                }
            }
        }, {
            if: {
                properties: {
                    type: {const: "floating"}
                }
            },
            then: {
                properties: {
                    positionX: { type: "number" },
                    positionY: { type: "number" },
                    height: { type: "number" },
                    width: { type: "number" },
                    action : { type: "string", enum: ["openIngredients", "openEquipment", "slideNext", "moveToSlideById", "runR1"] },
                    padding: { type: "number" },
                    visibility: {type: "string"},
                    functionArg: { type: ["string", "number", "object"] },
                },
                required: ["action", "positionX", "positionY"]
            }
        }]
    }
}



const singleFocusSchema = {
    data: {
        type: "object",
        description: "Single Focus Slide Data",
        properties: {
            text: { type: "string" },
            align: { type: "string", enum: ["left", "center", "right"] },
            actionButtons: actionButtonsSchema,
            timer: { type: "boolean" },
        },
        required: ["text"],
        additionalProperties: false
    }
}

const contentSlideSchema = {
    data: {
        type: "object",
        description: "Content Slide Data",
        properties: {
            url: { type: "string" },
            actionButtons: actionButtonsSchema 
        },
        required: ["url"],
        additionalProperties: false
    }
}

const ingredients6Schema = {
    data: {
        type: "object",
        description: "Ingredient Slide Data",
        properties: {
            title: { type: "string" },
            ingredientIds: {
                type: "array",
                items: { type: "string" },
                minItems: 1,
                maxItems: 6,
                uniqueItems: true
            },
        },
        required: ["ingredientIds"],
        additionalProperties: false
    }
}

const prepDisplaySchema = {
    data: {
        type: "object",
        description: "Prep Display Slide Data",
        properties: {
            prepItems: {
                type: "array",
                items: {
                    type: "object",
                    properties: {
                        title: { type: "string" },
                        qty: { type: "string" },
                        endProductImage: { type: "string" },
                        onExpand: onExpandSchema,
                        weightOption: { type: "number" },
                        endProductDescription: { type: "string" },
                        weightImage: { type: "string" }
                    },
                    required: ["title", "endProductImage"],
                    additionalProperties: false
                },
                minItems: 1,
                maxItems: 4
            }
        },
        required: ["prepItems"],
        additionalProperties: false
    }
}

const instructionFeedSchema = {
    data: {
        type: "object",
        description: "Instruction Slide Data",
        properties: {
            instructions: {
                type: "array",
                items: {
                    type: "object",
                    properties: {
                        icon: {
                            type: "string",
                            enum: iconList
                        },
                        steps: {
                            type: "array", items: {
                                type: "object",
                                properties: {
                                    text: { type: "string" },
                                    action: {
                                        type: "object",
                                        properties: {
                                            text: { type: "string" },
                                            type: {
                                                type: "string",
                                                enum: ["weight", "react1", "timer"]
                                            },
                                            data: { type: "object" }
                                        },
                                        required: ["text"],
                                        additionalProperties: false,
                                        allOf: [
                                            {
                                                if: { properties: { type: { const: "react1" } } },
                                                then: {
                                                    properties: {
                                                        data: {
                                                            type: "object",
                                                            properties: {
                                                                time: { type: "number", min: 1, max: 5000 },
                                                                speed: { type: "number", min: 0, max: 15 },
                                                                temperature: { type: "number", min: 20, max: 150 }
                                                            },
                                                            required: ["time", "speed", "temperature"],
                                                            additionalProperties: false
                                                        }
                                                    }
                                                }
                                            }
                                        ]
                                    }
                                },
                                required: ["text"],
                                additionalProperties: false
                            }
                        }
                    },
                    required: ["steps"],
                    additionalProperties: false
                }
            },
            title: { type: "string" },
            backgroundMedia: {
                type: "object",
                properties: {
                    url: { type: "string" },
                    offsetData: {
                        type: "object",
                        properties: {
                            widthZoom: { type: "string" },
                            left: { type: "string" },
                            top: { type: "string" },
                            heightZoom: { type: "string" }
                        },
                        additionalProperties: false
                    },
                    trim: { type: "object", properties: { startTime: { type: "number" }, duration: { type: "number" } } },
                    mute: { type: "boolean" },
                    loop: { type: "boolean" }
                },
                required: ["url"],
                additionalProperties: false
            },
            flame: { type: "string", enum: ["off", "low", "high", "medium"] },
            note: {
                type: "object", properties: {
                    text: { type: "string" },
                    location: { type: "string" }
                }, required: ["text"],
                additionalProperties: false
            },
            actionButtons: actionButtonsSchema,
            centerText: {
                type: "string"
            }
        },
        required: ["instructions"],
        additionalProperties: false
    }
}


const processTitleSlideSchema = {
    data: {
        type: "object",
        description: "Single Focus Slide Data",
        properties: {
            text: { type: "string" },
            align: { type: "string", enum: ["left", "center", "right"] },
            actionButtons: actionButtonsSchema,
        },
        required: ["text"],
        additionalProperties: false
    }
}


export const vizualizationSchema = {
    title: "Visulization Schema",
    description: "This is isualization Schema",
    type: "object",
    properties: {
        type: {
            type: "string",
            description: "Type of Slide to Create",
            enum: ["ContentSlide", "Ingredients6", "InstructionFeed", "directSlide", "PrepDisplay", "SingleFocus", "ProcessTitleSlide"]
        },
        timeOnSlide: {
            type: "number",
            description: "Amount of time in Mintures likely spent on slide",
            minimum: 0
        },
        inactive: { type: "boolean" },
        data: { type: "object" },
        id: { type: "string" }
    },
    required: ["type"],
    additionalProperties: false,
    allOf: [{
        if: {
            properties: {
                type: { const: "SingleFocus" }
            }
        },
        then: {
            properties: singleFocusSchema
        }
    }, {
        if: {
            properties: {
                type: { const: "InstructionFeed" }
            }
        },
        then: {
            properties: instructionFeedSchema
        }
    }, {
        if: {
            properties: {
                type: { const: "ContentSlide" }
            }
        },
        then: {
            properties: contentSlideSchema
        }
    }, {
        if: {
            properties: {
                type: { const: "PrepDisplay" }
            }
        },
        then: {
            properties: prepDisplaySchema
        }
    }, {
        if: {
            properties: {
                type: { const: "Ingredients6" }
            }
        },
        then: {
            properties: ingredients6Schema
        }
    }, {
        if: {
            properties: {
                type: { const: "ProcessTitleSlide"}
            }
        },
        then: {
            properties: processTitleSlideSchema
        }
    }
    ]
}

export const ingredientSchema = {
    title: "Ingredient Schema",
    description: "This is Ingredient Schema",
    type: "object",
    properties: {
        name: {
            type: "string",
            description: "Group Name for Alternatives"
        },
        id: {
            type: "string",
            description: "UniqueId for the ingredient"
        },
        optional: {
            type: "boolean"
        },
        optionSet: {
            type: "array",
            description: "Data About the slides",
            minItems: 1,
            uniqueItems: true,
            items: {
                type: "object",
                title: "Options for Ingredient",
                properties: {
                    id: {
                        type: "string",
                        description: "UniqueId for the option (matches to default)"
                    },
                    image: {
                        type: "string",
                        description: "url to the image"
                    },
                    name: {
                        type: "string",
                        description: "Name that is deisplayed"
                    },
                    qty: {
                        type: "object",
                        title: "Quantity Information",
                        properties: {
                            amount: { type: "number" },
                            secondary: { type: "string" },
                            unit: { type: "string" }
                        },
                        required: ["amount", "unit"],
                        additionalProperties: false
                    },
                    subdiscription: {
                        type: "string"
                    }
                },
                required: ["name", "id", "image"],
                additionalProperties: false
            }
        },
        default: {
            type: "string",
            description: "Default ingredient from optoinSet"
        }
    },
    required: ["name", "id", "optionSet"],
    additionalProperties: false
}