import React, { useState } from 'react'
import PropTypes from 'prop-types'
import TitleStrip from './elements/TitleStrip'
import { Box, Button, ButtonGroup, Typography } from '@mui/material'
import Icon from '@mdi/react'
import IngredientCard from './elements/IngredientCard'
import { mdiViewHeadline, mdiDotsGrid } from '@mdi/js'
import CaroselAbsraction from '../../utils/CaroselAbsraction'

const gridViewStyle = {
    display: "flex",
    flexWrap: "wrap",
    paddingTop: "15vh",
    width: "100%",
    height: "74vh",
}

const listViewStyle = {
    width: "100%",
    display: "flex",
    position: "absolute",
    flexDirection: "column",
    overflowY: "scroll",
    height: "calc(100% - 10vh)",
    margin: "11.38vh 0 3.76vh 0",
    paddingRight: "10vh"
}

const getGridIngredients = (mainIngredients, optionalIngredients) => {
    const mainIngredientsClusterSix = [...Array(Math.ceil(mainIngredients.length / 6))].map((data, i) => { return { data: mainIngredients.slice(i * 6, i * 6 + 6) } })
    const optionalIngredientsClusterSix = [...Array(Math.ceil(optionalIngredients.length / 6))].map((data, i) => { return { data: optionalIngredients.slice(i * 6, i * 6 + 6) } })
    const indexOfOptionalData = mainIngredientsClusterSix.length

    return [[...mainIngredientsClusterSix, ...optionalIngredientsClusterSix], indexOfOptionalData]
}


const Ingredients6 = ({
    ingredientsData,
    titleText = "Ingredients",
    changeIngredientDefault = () => { },
    defaultView = { layout: "grid", ingredient: "grid" },
    showButtons = true,
    selectedIngredientsId,
    addSelectedIngredients = () => {},
    removeSelectedIngredients = () => {},
}) => {
    const [view, setView] = useState(defaultView)
    const [checkedBox, setCheckedBox] = useState([])

    const optionalData = ingredientsData.filter(({ optional = false }) => optional)
    const requiredData = ingredientsData.filter(({ optional = false }) => !optional)
    const [gridData, indexOfOptionalGridData] = getGridIngredients(requiredData, optionalData)
    const indexOfOptionalData = requiredData.length

    const addToCartClick = (e) => {
        (e.target.checked) ?
            addSelectedIngredients(e.target.value)
        :
            removeSelectedIngredients(e.target.value)
    }

    const optionalForList = <Box
        display="flex"
    >
        <Typography
            variant="h3"
            style={{ margin: "1.84vh 0 0 4.64vh", fontWeight: 600 }}>
            Optional
        </Typography>
        <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            margin="1.84vh 4.64vh 0 2vh"
            width="100%"
        >
            <Box style={{ height: "1vh", width: "100%", borderRadius: "2vh", background: "black" }} />
        </Box>
    </Box>

    const optionalForGrid = <Typography
        variant="h3"
        style={{ marginTop: "10vh", marginLeft: "9vh", fontWeight: 600, position: "absolute", top: 0 }}
    >
        Optional
    </Typography>
    
    return (
        <>
            {showButtons ?
                <ButtonGroup
                    variant="text"
                    ariaLabel="text button group"
                    style={{ zIndex: 3, position: "absolute", right: 0 }}
                >
                    <Button
                        onClick={() => setView({ layout: "grid", ingredient: "grid" })}
                        disabled={view.layout === "grid"}
                    >
                        <Icon
                            path={mdiDotsGrid}
                            width="7.20vh"
                            height="7.20vh"
                        />
                    </Button>
                    <Button
                        onClick={() => setView({ layout: "list", ingredient: "list" })}
                        disabled={view.layout === "list"}
                    >
                        <Icon
                            path={mdiViewHeadline}
                            width="7.20vh"
                            height="7.20vh"
                        />
                    </Button>
                </ButtonGroup> : null
            }
            {titleText === "" ? null :
                <TitleStrip
                    slideTitle={titleText}
                    location="high"
                />
            }
            {view.layout === "grid" ?
                <CaroselAbsraction
                    pagination={true}
                    slides={gridData.map(({ data }, index) => (isActive =>
                    (<Box style={gridViewStyle}>
                        {index === indexOfOptionalGridData && optionalForGrid}
                        {data.map((items) => <Box
                            key={items.id}
                        >
                            <IngredientCard
                                ingredientsInfo={items.optionSet}
                                commonId={items.id}
                                currentSelection={items.default}
                                onSelect={(newId) => changeIngredientDefault({ id: items.id, ingredientId: newId })}
                                addToCartClick={addToCartClick}
                                checkedBox={selectedIngredientsId}
                                isCurrent={isActive}
                                view={view.ingredient}
                            />
                        </Box>)}
                    </Box>)))} />
                :
                <Box
                    style={listViewStyle}>{[...requiredData, ...optionalData].map((data, index) => <Box
                        key={data.id}
                    >
                        {index === indexOfOptionalData && optionalForList}
                        <IngredientCard
                            ingredientsInfo={data.optionSet}
                            currentSelection={data.default}
                            onSelect={(newId) => changeIngredientDefault({ id: data.id, ingredientId: newId })}
                            addToCartClick={addToCartClick}
                            setCheckedBox={setCheckedBox}
                            checkedBox={checkedBox}
                            view={view.ingredient}
                        />
                    </Box>
                    )}
                </Box>}
        </>
    )
}

Ingredients6.propTypes = {
    ingredientsData: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.string,
        default: PropTypes.string,
        optionSet: PropTypes.instanceOf(Array)
    })).isRequired,
    defaultView: PropTypes.shape({ layout: PropTypes.oneOf("grid", "list"), ingredient: PropTypes.oneOf("grid", "list") }),
    showButtons: PropTypes.bool,
}

export default Ingredients6
