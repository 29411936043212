import { v4 as uuid } from "uuid"

const pathChecker = (checkWhat, checkAgainst) => {
    // 1 - perfect match [a,b] | [a,b] 
    // 2 - checkWhat is subset of checkAgainst [a] | [a,b]
    // 3 - checkAgainst is subset of checkWhat [a,b] | [a]
    // 0 - no match [a,c] | [a,d]
    // -1 - both empty [] | []
    const [cwFirstElement, ...cwRest] = checkWhat
    const [caFirstElement, ...caRest] = checkAgainst
    if (cwFirstElement || cwFirstElement === 0)
        if (caFirstElement || caFirstElement === 0) {
            if (cwFirstElement === caFirstElement || cwFirstElement === "xit" || caFirstElement === "xit") {
                const deepCheck = pathChecker(cwRest, caRest)
                return deepCheck === -1 ? 1 : deepCheck
            }
            else
                return 0
        }
        else
            return 3
    else
        return caFirstElement ? 2 : -1
}

const menuItemList = [
    {
        type: "InstructionFeed",
        path: ["data", "instructions"],
        criterions: [1],
        text: "Add Instruction",
        title: "Add Instruction to the Slide",
        click: element => [...element, { icon: "question", steps: [{ text: "Instructions Here", action: { "text": "???g", type: "weight" } }] }]
    },
    {
        type: "InstructionFeed",
        path: ["data", "instructions", "xit", "steps"],
        criterions: [1],
        text: "Add Step",
        title: "Add Step to the Instruction",
        click: element => [...element, { text: "Instructions Here", action: { text: "???g", type: "weight" } }]
    },
    {
        type: "PrepDisplay",
        path: ["data", "prepItems"],
        criterions: [1],
        text: "Add PrepItem",
        title: "Add PrepItem to the Prep Diplay Side",
        click: element => [...element, {
            "onExpand": {
                "data": {
                    "backgroundMedia": {
                        "trim": {
                            "duration": 6,
                            "startTime": 3
                        },
                        "url": "https://res.cloudinary.com/dgakjrw8j/video/upload/v1632225991/Mushroom%20Risotto/Peeling_Garlic_uweqpi.mp4",
                        "mute": "true",
                        "offsetData": {
                            "heightZoom": "115%",
                            "widthZoom": "80%",
                            "left": "15%"
                        }
                    },
                    "title": "Prep: Garlic",
                    "instructions": [
                        {
                            "steps": [
                                {
                                    "text": "Peel"
                                }
                            ],
                            "icon": "cut"
                        }
                    ]
                },
                "id": uuid(),
                "type": "InstructionFeed"
            },
            "title": "New PrepItem",
            "weightOption": -1,
            "qty": "Quantity",
            "endProductDescription": "End Desc",
            "endProductImage": "https://res.cloudinary.com/dgakjrw8j/image/upload/v1630133802/Bechamel%20sauce%20Pasta/peeled-garlic-500x500-removebg-preview_nuxbib.png"
        }]
    }
]

function modifyBasedOnPath(originalObject, path, newElement) {
    if (path.length === 0)
        return newElement
    const [currentPathElement, ...restOfPath] = path
    if (Array.isArray(originalObject)) {
        return originalObject.map((a, i) => i === currentPathElement ? modifyBasedOnPath(a, restOfPath, newElement) : a)
    }
    return {
        ...originalObject,
        [currentPathElement]: modifyBasedOnPath(originalObject[currentPathElement], restOfPath, newElement)
    }
}

const onMenuClick = (items, { path }, element, newElement = () => { }) => {
    try {
        const pathElement = path.reduce((ret, pathCrum) => ret[pathCrum], element)
        const updateElementAtPath = newPathElement => newElement(modifyBasedOnPath(element, path, newPathElement))
        const newMenuItems = menuItemList.reduce((ret, menuItem) => {
            if (element?.type === menuItem.type) {
                const pathCheck = pathChecker(path, menuItem.path)
                if (menuItem.criterions.some(criterion => pathCheck === criterion)) {
                    return [...ret, {
                        text: menuItem.text,
                        title: menuItem.title,
                        click: () => updateElementAtPath(menuItem.click(pathElement))
                    }]
                }
            }
            return ret
        }, [...items, { type: "separator" }])
        return newMenuItems
    } catch {
        return items
    }
}

export default onMenuClick