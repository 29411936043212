import React, { useContext, useState } from "react";
import Box from "@mui/material/Box"
import BottomNavigation from "@mui/material/BottomNavigation"
import BottomNavigationAction from "@mui/material/BottomNavigationAction"
import BlurOnIcon from '@mui/icons-material/BlurOn'
import BuildIcon from '@mui/icons-material/Build';
import FilterListIcon from '@mui/icons-material/FilterList';
import AddCircleIcon from '@mui/icons-material/AddCircle'
import FlatwareIcon from '@mui/icons-material/Flatware'
import { FilterContext } from "../../utils/homepage/FilterContext";
import Filter from "../../utils/homepage/page/Filter";
import useRecipeListFromDB from "../../utils/RecipeBackEnd/loadRecipeList";
import {
  collectionName2, getname
} from "../../utils/RecipeBackEnd/recipe2Structure";
import makeStyles from '@mui/styles/makeStyles';

import PropTypes from 'prop-types';
import { Button, CircularProgress } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


const useStyles = makeStyles({
  root: {
    textTransform: "none",
    display: ({ showClearFilter }) => showClearFilter,
    zIndex: 1000,
    borderRadius: 4,
    padding: "10px 20px",
    color: "white",
    backgroundColor: "black",
    cursor: "pointer"
  },
});

const TabBars = ({ onFilterChange }) => {
  const { recipeList, loading } = useRecipeListFromDB(
    collectionName2,
    {},
    getname
  );
  const [filterOptions, setFilterOptions] = useContext(FilterContext);
  const [openFilterPage, setOpenFilterPage] = useState(false);
  const [showClearFilter, setShowClearFilter] = useState("none");
  const [value, setValue] = useState(3)
  const classes = useStyles({ showClearFilter });
  const history = useHistory()
  const activeRecipes = recipeList.filter((recipe) => recipe?.metadata?.active);

  const handleOpen = () => {
    setOpenFilterPage(true);
  };

  const handleClose = () => {
    setOpenFilterPage(false);
  };

  const handleClearFilter = () => {
    onFilterChange(activeRecipes)
    resetFilter()
    setShowClearFilter("none")
  }

  const resetFilter = () => {
    setFilterOptions({ ...filterOptions, categories: [], tags: [] })
  }

  const getTags = (field) => {
    const uniqueTags = []
    const temp = activeRecipes.map(recipe => recipe?.metadata[field]).filter(i => i !== undefined)
    temp.forEach(i => {
      i.forEach(v => {
        if (!uniqueTags.includes(v)) {
          uniqueTags.push(v)
        }
      })
    })
    return uniqueTags
  }

  const handleFilter = () => {
    const filteredData = activeRecipes.filter((list) => {
      const tags = list?.metadata?.tags || []
      const categories = list?.metadata?.categories || []
      const filterCategories = categories.find(i => filterOptions?.categories.includes(i))
      const filterTags = tags.find(i => filterOptions?.tags.includes(i))
      return tags.includes(filterTags) || categories.includes(filterCategories)
    })
    onFilterChange(filteredData)
    handleClose();
    setShowClearFilter("block")
  }

  if (loading) <CircularProgress />

  return (
    <Box position={'sticky'} bottom={0}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(e, newValue) => setValue(newValue)}
      >
        <BottomNavigationAction disabled label={"Home"} icon={<FlatwareIcon />} />
        <BottomNavigationAction label={"Self Clean"} icon={<BlurOnIcon color="primary" />} onClick={() => history.push('/experience2/oqAfLQMJ8z8hk9uikOlX')} />
        <BottomNavigationAction disabled label={"Add Recipe "} icon={<AddCircleIcon color='disabled' fontSize='large' />} />
        <BottomNavigationAction label={"Filters"} icon={<FilterListIcon color="primary" onClick={handleOpen} />} />
        <BottomNavigationAction label={"Manual Mode"} icon={<BuildIcon color="success" onClick={() => history.push('/experience2/drivehardware/')} />} />
      </BottomNavigation>
      <Filter open={openFilterPage} getSelectOptions={getTags} onClose={handleClose} resetFilter={resetFilter} handleFilter={handleFilter} />
      <Box
        width="100%"
        position="fixed"
        style={{ display: "flex", justifyContent: "center", left: '40%', bottom: 80, backgroundColor: "black", width: "25vw" }}
      >
        <Button className={classes.root} onClick={handleClearFilter}>CLEAR FILTERS</Button>
      </Box>
    </Box>
  );
};

export default TabBars
