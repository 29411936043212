import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import ActionButton from './ActionButton'

const actionSetStyleData = {
    leftSpace: "73%",
    topDistance: "50%",
    width: "22%",
    maxHeight: "40%"
}

const ActionButtonSet = ({ actionButtons = [], setReact1Settings, floatingBtnsFunctions = () => {} }) => {
    const { leftSpace, topDistance, width, maxHeight } = actionSetStyleData
    return actionButtons.length === 0 ? null : (
        <>
        {actionButtons.filter(({ type }) => type === "floating").map(({ type="floating", hardwareSettings = {}, onExpand = {}, text="i am a floating btn", functionArg=null, height, padding, positionX, positionY, width, action, visibility="hidden" }, index) => <ActionButton
            key={index}
            text={text}
            type={type}
            hardwareSettings={hardwareSettings}
            onExpand={onExpand}
            floatingBtnFunctionArg={functionArg}
            visibilityOfText={visibility}
            style={{ top: `${positionY}%`, left: `${positionX}%`, padding: padding, height: height, width: width }}
            action={action}
            floatingBtnFunction={floatingBtnsFunctions}
            >
        </ActionButton>)}
        <Box
            position="absolute"
            left={leftSpace}
            top={topDistance}
            width={width}
            height={maxHeight}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="flex-end"
        >
            {actionButtons.filter(({ type }) => type !== "r1" && type !== "floating").map(({ type = "slide", text, location, onExpand = {}, hardwareSettings = {} }) => <ActionButton
                key={text}
                text={text}
                location={location}
                onExpand={onExpand}
                type={type}
                hardwareSettings={hardwareSettings}
                setReact1Settings={setReact1Settings}
            />)}
        </Box>
        </>
    )
}

ActionButtonSet.propTypes = {
    actionButtons: PropTypes.array,
    setReact1Settings: PropTypes.func,
}

export default ActionButtonSet
