import {
  AppBar, Toolbar, IconButton, Typography, Drawer, Dialog, Container
} from "@mui/material"
import MenuIcon from '@mui/icons-material/Menu'

import SecureArea from "../components/authentication/SecureArea"
import React, { useState, useEffect, useContext } from "react"
import RecipeJsonDisplay from "../components/newRecipeJson/RecipeJsonDisplay"
import { initialObject } from "../components/newRecipeJson/DropdownOptions"
import useRecipeListFromDB from "../utils/RecipeBackEnd/loadRecipeList"
import DuplicateRecipe from "../components/newRecipeJson/DuplicateRecipe"
import RecipeEditor2 from "../components/newRecipeJson/RecipeEditor2"
import { v4 as uuid } from "uuid"
import { useParams } from "react-router-dom"
import { AuthContext } from "../firebase/context"
import { RecipeProvider } from "../components/newRecipeJson/RecipeContext"

const NewRecipeJson = () => {
  const { recipeId: urlRecipeId } = useParams()
  const [selectedRecipeId, setSelectedRecipeId] = useState(urlRecipeId)
  const userObject = useContext(AuthContext)
  const { metadata, steps } = initialObject
  const { recipeList, saveNew, loading, saving, refresh } = useRecipeListFromDB("newRecipeJson",
    {
      ...initialObject, metadata: {
        ...metadata,
        author: userObject?.user?.displayName
      },
      steps: [{ ...steps[0], id: uuid() }]
    }, r => r?.metadata.name)
  const [recipeListDrawerOpen, setRecipeListDrawerOpen] = useState(urlRecipeId ? false : true)
  const [duplicateOpen, setDuplicateOpen] = useState(false)
  const [duplicateRecipeDrawerOpen, setDuplicateRecipeDrawerOpen] = useState(false)
  useEffect(() => {
    if (selectedRecipeId)
      window.history.replaceState(null, "", "/newjson/" + selectedRecipeId)
  }, [selectedRecipeId])
  const onSelect = id => {
    setRecipeListDrawerOpen(false)
    setSelectedRecipeId(id)
  }


  function onDelete() {
    setSelectedRecipeId(null)
    refresh()
    setRecipeListDrawerOpen(true)
  }

  const handleDuplicateRecipe = () => {
    setDuplicateRecipeDrawerOpen(true)
  }

  return (
    <SecureArea adminOnly>
      <RecipeProvider>
      <AppBar position="static">
        <Container>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => setRecipeListDrawerOpen(old => !old)}
              size="large">
              <MenuIcon />
            </IconButton>
            <Typography variant="h6">
              Recipe Admin Experiance 2.0
            </Typography>
          </Toolbar>
        </Container>
      </AppBar>
      <Container>
        <RecipeEditor2
          recipeId={selectedRecipeId}
          onDeleteCallback={onDelete}
        />
      </Container>
      <Drawer
        anchor="left"
        open={recipeListDrawerOpen}
        onClose={() => setRecipeListDrawerOpen(false)}
      >
        <RecipeJsonDisplay
          idNameObject={recipeList.map((i) => { return { id: i.id, name: i?.metadata?.name, isActive: i?.metadata?.active } })}
          onClickRecipeName={id => onSelect(id)}
          saving={saving}
          loading={loading}
          onAddNewRecipe={() => saveNew(newId => setSelectedRecipeId(newId))}
          onDuplicateNewRecipe={() => handleDuplicateRecipe()}
          type="add"
        />
      </Drawer>
      <Drawer
        anchor="left"
        open={duplicateRecipeDrawerOpen}
        onClose={() => {
          setDuplicateRecipeDrawerOpen(false)
          setDuplicateOpen(false)
        }}
      >
        <RecipeJsonDisplay
          idNameObject={recipeList.map((i) => { return { id: i.id, name: i?.metadata?.name } })}
          onClickRecipeName={(id) => {
            setSelectedRecipeId(id)
            setDuplicateOpen(true)
          }}
          saving={saving}
          loading={loading}
          type="duplicate"
        />
      </Drawer>
      <Dialog style={{ height: 900 }} open={duplicateOpen} onClose={() => {
        setDuplicateOpen(false)
      }}>
        <DuplicateRecipe recipeId={selectedRecipeId} setDuplicateOpen={setDuplicateOpen} />
      </Dialog>
      </RecipeProvider>
    </SecureArea>
  );

};

export default NewRecipeJson;
