import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'
import CloseBox from './CloseBox'

const PostitNote = ({ text, location = "bottom-left", closeBox = true }) => {
    const getLocationParams = loc => {
        switch (loc) {
            case "bottom-left":
                return { top: "60%", left: "8%" }
            case "bottom-right":
                return { top: "60%", left: "70%" }
            case "center-left":
                return { top: "50%", left: "8%" }
            case "center-right":
                return { top: "40%", left: "70%" }
            case "top-right":
                return { top: "20%", left: "70%" }
            default:
                return { top: "60%", left: "8%" }
        }
    }
    const [showNote, setShowNote] = useState(true)
    const locationParams = getLocationParams(location)
    return showNote ? (
        <Box
            width="30vh"
            position="absolute"
            bgcolor="#ffc"
            minHeight="10em"
            padding="1em"
            boxShadow="5px 5px 7px rgba(33,33,33,.7)"
            style={{ transform: "rotate(6deg)" }}
            {...locationParams}
        >
            {closeBox ? <CloseBox onClose={() => setShowNote(false)} /> : <></>}
            <Typography variant="h4" style={{ fontFamily: "'Kalam', cursive" }}>{text}</Typography>
        </Box >
    ) : <></>
}

PostitNote.propTypes = {
    text: PropTypes.string,
    location: PropTypes.string,
    closeBox: PropTypes.bool
}

export default PostitNote
