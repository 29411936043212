import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, Dialog, DialogTitle, DialogActions, Button } from '@mui/material'
import ListView from './ListView'
import GridView from './GridView'

const IngredientCardUI = ({ image, name, qty, subdiscription = null, alternateButton = false, onClickHandler = () => { }, addToCartClick, view="grid", checkedBox = [], commonId, displayCheckBox=true }) => {
    const boxStyle = alternateButton ? {
        backgroundImage: `url("https://res.cloudinary.com/prodjour/image/upload/v1628087413/Marinara/BackGroundwithSwap_fssngz.png")`,
        zIndex: 0,
        borderRadius: 75,
        height: "100%"
    } : { }

    if (view === "grid") {
        return (
            <GridView
                image={image}
                name={name}
                qty={qty}
                subdiscription={subdiscription}
                boxStyle={boxStyle}
                alternateButton={alternateButton}
                onClickHandler={onClickHandler}
                addToCartClick={addToCartClick}
                checkedBox={checkedBox}
                commonId={commonId}
                displayCheckBox={displayCheckBox}
            />
        )
    }
    else {
        return (    
            <ListView 
                image={image}
                name={name}
                qty={qty}
                subdiscription={subdiscription}
                boxStyle={boxStyle}
                alternateButton={alternateButton}
                onClickHandler={onClickHandler}
                addToCartClick={addToCartClick}
            />
        )
    }
}

const IngredientCard = ({ ingredientsInfo, currentSelection, onSelect, addToCartClick, view, checkedBox, commonId }) => {
    const { image, name, qty, subdiscription = null } = ingredientsInfo.find(({ id }) => id === currentSelection)
    const alternatesExist = ingredientsInfo.length > 1
    const [alternateSelectionDialogOpen, setAlternateSelectionDialogOpen] = useState(false)

    const onClickHandler = (e) => {
        if (e.target.nodeName !== "INPUT")
            setAlternateSelectionDialogOpen(alternatesExist)
    }
    
    return <>
        <IngredientCardUI
            image={image}
            name={name}
            qty={qty}
            subdiscription={subdiscription}
            alternateButton={alternatesExist}
            onClickHandler={onClickHandler}
            addToCartClick={addToCartClick}
            view={view}
            checkedBox={checkedBox}
            commonId={commonId}
        />
        <Dialog
            open={alternateSelectionDialogOpen}
            onClose={() => setAlternateSelectionDialogOpen(false)}
            maxWidth="lg"
            fullWidth
        >
            <DialogTitle>Alternate Ingredients</DialogTitle>
            <Grid container justifyContent="center">
                {ingredientsInfo.map(ingredientInfo => <Grid item xs={6} md={4} key={ingredientInfo.id}>
                    <IngredientCardUI
                        id={ingredientInfo.id}
                        image={ingredientInfo.image}
                        name={ingredientInfo.name}
                        qty={ingredientInfo.qty}
                        subdiscription={ingredientInfo.subdiscription}
                        onClickHandler={() => {
                            onSelect(ingredientInfo.id)
                            setAlternateSelectionDialogOpen(false)
                        }}
                        addToCartClick={addToCartClick}
                        displayCheckBox={false}
                    />
                </Grid>
                )}
            </Grid>
            <DialogActions>
                <Button onClick={() => setAlternateSelectionDialogOpen(false)} color="primary">Cancel</Button>
            </DialogActions>
        </Dialog>
    </>;
}

IngredientCard.propTypes = {
    ingredientsInfo: PropTypes.arrayOf(PropTypes.object),
    currentSelection: PropTypes.string,
    onSelect: PropTypes.func,
}

export default IngredientCard
