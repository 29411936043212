import { Box, Typography } from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import TimerDisplayUI from './TimerDisplayUI' 

const imageStyle = { height: "13vh", width: "13vh" }

const TimerSlide = ({
    text, //test that does into the Bottom Box
    instruction, //Wait or Continue
    timer, //time displayed
    hardwareState,//gives the current hardware state
    autoSlide, // if the slide should automatically progress at end of timer
    completedSteps, // list of completed steps
    currentSlide, // index of the current slide that is live
    moveToNext,
}) => {
    const displayZero = completedSteps.includes(currentSlide - 1) || completedSteps.filter(i => i === currentSlide - 2 || i === currentSlide).length > 0
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100%"
        >
            <Box
                display="flex"
                alignItems="center"
            >
                {instruction === "Wait"  ?
                    <img style={imageStyle} src="https://res.cloudinary.com/akshadasingh/image/upload/v1638523706/time_gucqn5.png" /> :
                    <img style={imageStyle} src="https://res.cloudinary.com/akshadasingh/image/upload/v1638523705/right-arrow_nfzh14.png" />
                }
                <Typography variant="h1" flex="0 0 40%" style={{ fontWeight: 600, marginLeft: "2vh" }}>{instruction}</Typography>
            </Box>
            <TimerDisplayUI 
                r1Time={displayZero ? 0 : timer}
                status={hardwareState}
                completedSteps={completedSteps}
                currentSlide={currentSlide}
                autoSlide={autoSlide}
                moveToNextFn={moveToNext}
            />
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                style={{
                    backgroundColor: "#bee1f4",
                    padding: "5vh 2vh",
                    borderRadius: "2vh",
                    margin: "0 5vh 0 5vh"
                }}
            >
                <Typography variant="h4" align="center" style={{ wordBreak: "break-word" }}>{text}</Typography>
            </Box>
        </Box>
    )
}

export default TimerSlide