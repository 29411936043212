import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'
import Icon from '@mdi/react'
import { mdiScale } from '@mdi/js'
import PopUpDialog from '../PopUpDialog'
import WeightDisplay from './WeightDisplay'

const WeightUI = ({ weight, popUpStatus, text, setScaleSettings = () => { }, style = {}, img }) => {
    return (
        <>
            <Button
                variant="contained"
                color="primary"
                onClick={() => setScaleSettings({ text: "" })}
                style={{ height: "9vh", ...style }}
            >
                <Icon path={mdiScale} />
            </Button>
            <PopUpDialog
                dialogOpen={popUpStatus}
                onClickBack={() => setScaleSettings({})}
                timeout={650}
                componentInDialog={
                    <WeightDisplay reading={weight} text={text} img={img} />
                }
            />
        </>
    )
}

WeightUI.propTypes = {
    weight: PropTypes.number.isRequired,
    popUpStatus: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired
}

export default WeightUI
