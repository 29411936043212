import React, { createContext, useState } from 'react'

export const RecipeContext = createContext(); 

export const RecipeProvider = (props) => {
  const [recipe, setRecipe] = useState(null)
  return (
    <RecipeContext.Provider value={[recipe, setRecipe]} >
      {props.children}
    </RecipeContext.Provider>
  )
}
