import {useState, useEffect} from 'react'
import { v4 as uuid } from 'uuid'

const generateIngredientSlides = ({ list, visualization }) => {
    const contentSlides = visualization.filter(({ type }) => type === "directSlide" || type === "ContentSlide" )
        .map(({ data, inactive=false }) => { 
            return {
                id: uuid(),
                type: 'ContentSlide',
                data: data,
                inactive: inactive
            }
        })

    const ingredients6 = {
        id: uuid(),
        type: "Ingredients6",
        data: {ingredientsData: list}
    }

    return [ingredients6, ...contentSlides]
}

const getActiveSlides = (slides) => {
    if (slides) {
        const activeSlides = slides.filter(slide => !(slide?.inactive))
        return activeSlides
    }
    return []
}

const generateTimerSlide = (instruction="Continue", text="insert text", slideNext=false, timeDisplayed ) => {
    return {
        type: "TimerSlide",
        id: uuid(),
        data: {
            instruction,
            text,
            slideNext,
            timer: timeDisplayed, 
        }
    }
}

const generateProcessTimerSlide = (process) => {
    const slides = process.map(item => {
        let increment = 1
        return item.map((i, index)=> {
            if (i?.data?.actionButtons){
                const r1 = i.data.actionButtons.filter(i => i?.type === "r1").pop()
                if (r1){
                    const timeDisplayed = r1?.hardwareSettings.type === "basic" ? r1.hardwareSettings.data?.time : r1.hardwareSettings.data.reduce((prev, next) => ({ time: prev.time + next.time })).time
                    const slide = {type: "timerSlide", insertIndex: index+increment, newSlide: generateTimerSlide(r1?.timerInstruction, r1?.timerText, r1?.slideNext, timeDisplayed )}
                    increment = increment + 1
                    return slide
                }}
            return i
        })
    })
    const processCopy = process.map(i => [...i])

    const timerSlides = slides.map(item => 
        item.filter(i => i?.type === "timerSlide"))
    
    const processSlides = process.map((proc, index) => {
        if (timerSlides[index].length === 0)
            return proc
        else
             return [...Array(proc.length + timerSlides[index].length)].map((item, i) => {
                if (i === timerSlides[index][0]?.insertIndex)
                        return timerSlides[index].shift().newSlide
                return processCopy[index].shift()})})
    
    return processSlides
}

const generateProcessInfo = (process, baseIndex) => {
    const defaultProcessSlides = process.map(proc => getActiveSlides(proc?.visualization))
    const processSlidesLength = defaultProcessSlides.map(slides => slides.length)
    const processSlides = generateProcessTimerSlide(defaultProcessSlides)
    const processTitle = processSlides.map(i => i[0]?.data?.text )
    const processTitleIds = processSlides.map(i => i[0]?.id)
    const slideNewTitleList = processSlides.reduce((ret, slides) => {
        const changedSlides = [{...slides[0], data: {text: processTitle, processTitleIds: processTitleIds}}, ...slides.slice(1, slides.length)]
        return [...ret, ...changedSlides]}, [])

    const processSlideBarInfo = process.filter((proc, ind) => processSlidesLength[ind] !== 0).map((proc, index) => ({
        id: proc.id,
        label: proc.label,
        thumbnail: proc.thumbnail,
        slideIndex: baseIndex + processSlides.filter((pss, i) => i < index).reduce((sum, slds) => sum + slds.length, 0)
    }))
    return [processSlideBarInfo, slideNewTitleList]
}

const generateSlides = recipeData => {
    if (recipeData) {
        const openingSlides = getActiveSlides(recipeData?.mainPage?.visualization)          
        const healthSlides = getActiveSlides(recipeData?.healthInfo?.visualization)   
        const allIngredientSlides = generateIngredientSlides(recipeData?.ingredients)
        const ingredientSlides = getActiveSlides(allIngredientSlides)       
        const equipmentSlides = getActiveSlides(recipeData?.equipment?.visualization)         
        const summarySlides = getActiveSlides(recipeData?.summary?.visualization)             
        const processSlideBaseIndex = openingSlides.length + healthSlides.length + summarySlides.length
        const [processBarInfo, processSlides] = generateProcessInfo(recipeData?.process, processSlideBaseIndex)
        const slides = [...openingSlides, ...healthSlides, ...summarySlides, ...processSlides]

        return {
            slides: slides,
            ingredientSlides: ingredientSlides,
            equipmentSlides: equipmentSlides,
            processBarInfo: processBarInfo,
            metadata: recipeData?.metadata,
        }
    }
    return {}
}

const getNewIngredientsSlide = (ingredients, id, ingredientId) => {
    const ingredientsData = ingredients?.data?.ingredientsData
    if (ingredientsData) {
        const newIngredients = ingredientsData.map(i => {
            if (i.id === id) {
                return { ...i, default: ingredientId }
            }
            return i
        })
        return { ...ingredients, data: { ingredientsData: newIngredients }}           
    }
    else 
        return ingredients
}

const useRecipeSlides = (recipeJson = null) => {
    const [recipe, setRecipe] = useState(generateSlides(recipeJson))

    useEffect(() => setRecipe(generateSlides(recipeJson)), [recipeJson])

    const changeIngredientDefault = ({ id, ingredientId }) => { 
        if (recipe) {

            const ingredientsSlides = recipe?.ingredientSlides
            const newIngredientsSlides = ingredientsSlides.map( item => getNewIngredientsSlide(item, id, ingredientId))

            setRecipe(old => ({ ...old, ingredientSlides: newIngredientsSlides }))
        }
        else {
            return recipe
        }
    }

    return {
        slides: recipe?.slides,
        ingredientSlides: recipe?.ingredientSlides, 
        equipmentSlides: recipe?.equipmentSlides,
        processBarInfo: recipe?.processBarInfo,
        metadata: recipe?.metadata,
        allIngredients: recipe?.allIngredients,
        changeIngredientDefault: changeIngredientDefault,
    }

}

export default useRecipeSlides