import React from 'react'
import PropTypes from 'prop-types'
import { ButtonBase, Grid, Typography } from '@mui/material'

const iconMap = {
    scales: "https://res.cloudinary.com/prodjour/image/upload/v1628064561/icons/Scales_wlqjjk.svg",
    timer: "https://res.cloudinary.com/prodjour/image/upload/v1627442242/icons/Timer_qhqk4h.png",
    question: "https://res.cloudinary.com/prodjour/image/upload/v1627442242/icons/Question_eh9nc3.png",
    react1: "https://res.cloudinary.com/prodjour/image/upload/v1627442242/icons/Question_eh9nc3.png",
}

const shadowSetting = "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset"

const CallToActionButton = ({ iconType = "question", text, onClick = () => { } }) => {
    return (
        <ButtonBase onClick={onClick} >
            <Grid
                container
                spacing={1}
                alignItems="center"
                justifyContent="center"
                style={{
                    backgroundColor: "#FFA096",
                    borderRadius: 15,
                    boxShadow: shadowSetting,
                    padding: "2 0",
                    margin: 3
                }}
            >
                <Grid item><img width="40px" src={iconMap[iconType]} alt={iconType} /></Grid>
                {text ? <Grid item><Typography variant="h5">{text}</Typography></Grid> : <></>}
            </Grid>
        </ButtonBase >
    );
}

CallToActionButton.propTypes = {
    iconType: PropTypes.oneOf(Object.keys(iconMap)),
    text: PropTypes.string,
    onClick: PropTypes.func
}

export default CallToActionButton
