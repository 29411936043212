import { useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'
import useRecipeSlides from './recipeSlides'

const etaDefault = 55

const getETA = ({ slideIndex }, { slides, processBarInfo, metadata }) => {
    const firstProcessSlide = processBarInfo[0].slideIndex
    const totalSlides = slides.length - firstProcessSlide
    const totTim = metadata?.totalTime ? metadata.totalTime : etaDefault
    return slideIndex < firstProcessSlide ? totTim : (1 - ((slideIndex - firstProcessSlide) / totalSlides)) * totTim
}

const getProgressBarState = ({ slideIndex }, processBarInfo) => {
    return processBarInfo.reduce((ret, pdi) => pdi.slideIndex <= slideIndex ? pdi.id : ret, processBarInfo[0].id)
}

const useRecipeCore = (progressInfo, recipeJson = null) => {
    const { slides, ingredientSlides, equipmentSlides, processBarInfo, allIngredients, metadata, changeIngredientDefault } = useRecipeSlides(recipeJson)
    const [flameStatus, setFlameStatus] = useState("off")
    const [etaInMin, setEtaInMin] = useState(recipeJson?.metadata?.totaltime || etaDefault)
    const [sessionId, setSessionID] = useState(uuid())
    const [isStartR1Slide, setIsStartR1Slide] = useState(false)
    const [r1Instructions, setR1Instructions] = useState({})
    const effect = metadata?.effect ? metadata.effect : "slide"

    useEffect(() => {
        setSessionID(uuid())
    }, [recipeJson])

    useEffect(() => {
        const isR1Slide = index => {
            const actionButtons = slides[index].data?.actionButtons
            if (actionButtons) {
                const r1ActionButtons = actionButtons.filter(({ type }) => type === "r1").map((item) => {
                    if (!item.hardwareSettings?.cap) {
                        return { ...item, hardwareSettings: { ...item.hardwareSettings, cap: 'no-cap' } }
                    }
                    return item
                })
                return r1ActionButtons.length > 0 ? r1ActionButtons[0].hardwareSettings : false
            }
            return false
        }
        setFlameStatus(slides[progressInfo.slideIndex].data?.flame || "off")
        setEtaInMin(Math.round(getETA(progressInfo, { slides: slides, processBarInfo: processBarInfo, metadata: metadata })))
        setIsStartR1Slide(isR1Slide(progressInfo.slideIndex))
    }, [progressInfo, slides, processBarInfo, metadata])

    useEffect(() => {
        const r1Slides = slides.map((i, index) => {
            const actionBtns = i.data?.actionButtons
            if (actionBtns) {
                const r1Btns = actionBtns.filter(({ type }) => type === "r1")
                return r1Btns.length > 0 ? { [index]: { ...r1Btns[0].hardwareSettings, cap: r1Btns[0].hardwareSettings?.cap || "no-cap" } } : false
            }
            return false
        })
        const filteredR1 = r1Slides.filter(i => i).reduce((prev, next) => ({ ...prev, ...next }), {})
        setR1Instructions(filteredR1)
    }, [slides])

    const progressBarState = getProgressBarState(progressInfo, processBarInfo)
    return {
        slides: slides,
        flameStatus: flameStatus,
        etaInMin: etaInMin,
        ingredientSlides: ingredientSlides,
        equipmentSlides: equipmentSlides,
        processBarInfo: { processInfo: processBarInfo, progressState: progressBarState },
        sessionId: sessionId,
        isStartR1Slide: isStartR1Slide,
        allIngredients: allIngredients,
        changeIngredientDefault: changeIngredientDefault,
        r1Instructions: r1Instructions,
        effect: effect
    }
}

export default useRecipeCore