import React, { useReducer } from 'react'
import SecureArea from '../components/authentication/SecureArea'
import AppBottomBar from '../components/appBar/AppBottomBar'
import PopUpDialog from '../components/PopUpDialog'
import useRecipeCore from '../recipies/recipeCore'
import MultiSlideDisplay from '../components/dynamicSlide/MultiSlideDisplay'
import { RunningTimer } from './TimerContext'
import HideCursor from '../components/HideCursor'
import Box from '@mui/material/Box'
import Recenter from '../components/Recenter'

import { firebaseAnalytics } from '../firebase/config'
import { logEvent } from 'firebase/analytics'


const RecipeDisplay3 = ({ adminOnly, recipeJson }) => {
    const initialState = {
        swiperInstance: null,
        progressInfo: {
            slideIndex: 0,
            completedSteps: [],
            slidesTraversed: [],
        },
        react1Settings: {
            type: "basic",
            data: {
                time: 40,
                temperature: 20,
                speed: 2
            },
            cap: "no-cap",
        },
        hardwareState: "", //change
        popUpIngredient: false,
        popUpEquipment: false,
        selectedIngredientsId: [],
        popUpR1Settings: false,
        popUpWeight: false,
        scaleSettings: {
            text: "",
            img: "", //change
        },
    }

    const reducer = (state, action) => {
        switch (action.type) {
            case "TOGGLE-EQUIPMENT":
                return { ...state, popUpEquipment: !state.popUpEquipment }
            case "TOGGLE-INGREDIENT":
                return { ...state, popUpIngredient: !state.popUpIngredient }
            case "ADD-SELECTED-INGREDIENTS-ID":
                return { ...state, selectedIngredientsId: [...state.selectedIngredientsId, action.data] }
            case "REMOVE-SELECTED-INGREDIENTS-ID": {
                const newSelectedIngredients = state.selectedIngredientsId.filter(value => value !== action.data)
                return { ...state, selectedIngredientsId: newSelectedIngredients }
            }
            case "SET-R1SETTINGS":
                return { ...state, popUpR1Settings: true, react1Settings: { ...state.react1Settings, ...action.data } }
            case "SET-COMPLETEDSTEPS":
                return { ...state, progressInfo: { ...state.progressInfo, completedSteps: [...state.progressInfo.completedSteps, state.swiperInstance.activeIndex] } }
            case "SET-HARDWARESTATE":
                return { ...state, hardwareState: action.data }
            case "CLOSE-R1SETTINGS":
                return { ...state, popUpR1Settings: false }
            case "OPEN-R1SETTINGS":
                return { ...state, popUpR1Settings: true }
            case "SET-SWIPER":
                return { ...state, swiperInstance: action.data }
            case "ON-SLIDE-CHANGE": {
                const { activeIndex } = state.swiperInstance
                return { ...state, progressInfo: { ...state.progressInfo, slideIndex: activeIndex, slidesTraversed: [...state.progressInfo.slidesTraversed, activeIndex] } }
            }
            case "SET-SCALESETTINGS":
                return { ...state, popUpWeight: !state.popUpWeight, scaleSettings: { ...state.scaleSettings, ...action.data } }
            default:
                return { ...state }
        }
    }

    const reducerAnalyticsWrapper = (state, action) => {
        const newState = reducer(state, action)
        logEvent(firebaseAnalytics, "recipe_event", { name: action.type, slideIndex: newState.progressInfo.slideIndex })
        return newState
    }

    const [state, dispatch] = useReducer(reducerAnalyticsWrapper, initialState)

    const {
        slides,
        processBarInfo,
        equipmentSlides,
        ingredientSlides,
        changeIngredientDefault,
        isStartR1Slide,
        flameStatus,
        etaInMin,
        r1Instructions,
        effect
    } = useRecipeCore(state.progressInfo, recipeJson)

    const cookingPhaseData = {
        cookingInfo: processBarInfo?.processInfo,
        cookingState: processBarInfo?.progressState
    }

    // useRealTimeLogger(user, sessionId, recipeJson?.metadata?.name, progressInfo, "hardcode", react1Settings, true)
    const totalSlides = slides.length - 1

    const btnFunctions = (nameOfFunc, arg) => {
        switch (nameOfFunc) {
            case "moveByIndex": {
                state.swiperInstance?.slideTo(arg.slideIndex, 1000)
                break
            }
            case "slideNext": {
                state.swiperInstance?.slideNext(400)
                break
            }
            case "slideById": {
                slides.forEach(({ id }, index) => {
                    if (id === arg.slideId)
                        state.swiperInstance.slideTo(index, 400)
                })
                break
            }
            case "runR1": {
                const { time, temperature, speed, cap } = arg
                if (time && temperature && speed)
                    dispatch({ type: "SET-R1SETTINGS", data: { type: "basic", data: { time, speed, temperature }, cap: cap || "no-cap" } })
                break
            }
            default:
                return
        }
    }

    return (
        <SecureArea adminOnly={adminOnly}>
            <Box
                onMouseDown={e => {
                    e.stopPropagation()
                    if (e.button === 1)
                        isStartR1Slide ? dispatch({ type: "SET-R1SETTINGS", data: isStartR1Slide }) : btnFunctions("slideNext")
                }}>
                <HideCursor top={"calc(50% - 3.5vh)"} left={"calc(50% - 3.5vh)"} />
                <RunningTimer>
                    <MultiSlideDisplay
                        slides={slides}
                        dispatch={dispatch}
                        hardwareState={state.hardwareState}
                        progressInfo={state.progressInfo}
                        react1Settings={state.react1Settings}
                        btnFunctions={btnFunctions}
                        effect={effect}
                    />
                    <AppBottomBar
                        dispatch={dispatch}
                        cookingPhaseData={cookingPhaseData}
                        react1Settings={state.react1Settings}
                        isStartR1Slide={isStartR1Slide}
                        slideNext={() => btnFunctions("slideNext")}
                        moveByIndex={(slideIndex) => btnFunctions("moveByIndex", { slideIndex })}
                        popUpR1Settings={state.popUpR1Settings}
                        displayNext={totalSlides > state.swiperInstance?.activeIndex}
                        popUpWeight={state.popUpWeight}
                        scaleSettings={state.scaleSettings}
                        flameStatus={flameStatus}
                        etaInMin={etaInMin}
                    />
                </RunningTimer>
                <Recenter
                    btnFunctions={btnFunctions}
                    completedSteps={state.progressInfo.completedSteps}
                    currentSlideIndex={state.progressInfo.slideIndex}
                    allR1Steps={Object.keys(r1Instructions).map(i => +i)}
                />
                <PopUpDialog
                    dialogOpen={state.popUpIngredient}
                    onClickBack={() => dispatch({ type: "TOGGLE-INGREDIENT" })}
                    componentInDialog={<MultiSlideDisplay
                        slides={ingredientSlides}
                        typeSpecificInformation={{
                            addSelectedIngredients: (data) => dispatch({ type: "ADD-SELECTED-INGREDIENTS-ID", data }),
                            removeSelectedIngredients: (data) => dispatch({ type: "REMOVE-SELECTED-INGREDIENTS-ID", data }),
                            changeIngredientDefault: changeIngredientDefault,
                            selectedIngredientsId: state?.selectedIngredientsId,
                        }}
                    />}
                />
                <PopUpDialog
                    dialogOpen={state.popUpEquipment}
                    onClickBack={() => dispatch({ type: "TOGGLE-EQUIPMENT" })}
                    componentInDialog={<MultiSlideDisplay
                        slides={equipmentSlides}
                    />}
                />
            </Box>
        </SecureArea>
    )
}

export default RecipeDisplay3