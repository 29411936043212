import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { useParams } from "react-router-dom"
import RecipeDisplay3 from './RecipeDisplay3'
import useRecipeFromDB from '../utils/RecipeBackEnd/loadRecipe'
import IngredientsDisplay from '../components/IngredientsDisplay'
import Loader from './Loader'
import HomeIcon from '@mui/icons-material/Home';
import { AppBar, Box, StyledEngineProvider, Toolbar, Typography } from '@mui/material'
import useRecipeListFromDB from "../utils/RecipeBackEnd/loadRecipeList"
import IngredientsList from '../components/ingredients2/IngredientsList'
import { getAllRecipeIngredients } from '../utils/experience2/ingredientsUtil'
import { HardwareTask } from '../components/recipeSteps/HardwareTasks'
import SecureArea from '../components/authentication/SecureArea'
import { IngredientsFromDB, IngredientsContext } from '../components/ingredients2/IngredientsFromDB'
import { ThemeProvider } from '@mui/styles'
import ccTheme from '../theme/Experience2Theme'
import { useHistory } from 'react-router-dom'

const RecipeDispWrapper = ({ recipe, ingredientsOnly = false }) => {
    const { ingredients } = useContext(IngredientsContext)
    return (<>
        <PageTitle
            recipeName={recipe?.metadata?.name}
            description={recipe?.metadata?.description}
            url={recipe?.metadata?.url}
        />
        <IngredientsList
            ingredientsOnlyStyle={ingredientsOnly && "ingredients-only-margin"}
            items={getAllRecipeIngredients(recipe.steps)}
            ingredients={ingredients}
            ingredientsOnly
            height={50}
        />
    </>)
}

const PageTitle = ({ recipeName, description, url }) => {
    const history = useHistory()

    return (
        <AppBar sx={{
            width: "100%",
            backgroundColor: "#fefefe",
            color: "#000",
            position: "static",
        }}>
            <HomeIcon sx={{ margin: "15px" }} onClick={() => history.push('/recipelist')} />
            <Toolbar sx={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                <Typography textAlign="left">{recipeName} </Typography>
                <img style={{ objectFit: "cover" }} src={url} alt="" height={"100vh"} width={"100%"} />
                <Typography>{description}</Typography>
            </Toolbar>
        </AppBar>
    )
}

const RecipeDisp = ({ adminOnly = false, ingredientsOnly = false }) => {
    const recipeCollection = process.env.REACT_APP_VERCEL_DEPLOY_MODE === "PREVIEW" ? "recipeStaging" : "recipe2"
    const { recipeId: urlRecipeId } = useParams()
    const recipeId = urlRecipeId ? urlRecipeId : "NoiW4VhK70R0M8QDgNTu"
    const [recipeJSON, loading] = useRecipeFromDB(recipeId, recipeCollection)
    const { recipeList, loading: loading2 } = useRecipeListFromDB("newRecipeJson", {}, r => r?.metadata.name)
    const recipe = recipeList.find(i => i.id === urlRecipeId)
    if (loading2)
        return <Loader />
    if (ingredientsOnly)
        return (
            <HardwareTask>
                <SecureArea>
                    <IngredientsFromDB>
                        <StyledEngineProvider injectFirst>
                            <ThemeProvider theme={ccTheme}>
                                <RecipeDispWrapper recipe={recipe} ingredientsOnly={ingredientsOnly} />
                            </ThemeProvider>
                        </StyledEngineProvider>
                    </IngredientsFromDB>
                </SecureArea >
            </HardwareTask>)

    return <RecipeDisplay3
        adminOnly={adminOnly}
        recipeJson={recipeJSON}
    />

}

RecipeDisp.propTypes = {
    adminOnly: PropTypes.bool,
    ingredientsOnly: PropTypes.bool,
}

export default RecipeDisp
