import React, {createContext, useState} from 'react'

const TimerContext = createContext(null)

const RunningTimer = ({children}) => {
    const [currentTime, setCurrentTime] = useState(0)
    const [startTime, setStartTime] = useState(0)
    
    return (
        <TimerContext.Provider value={{currentTime: currentTime, setCurrentTime: setCurrentTime, startTime: startTime, setStartTime: setStartTime}} >
                {children}
        </TimerContext.Provider>
    )
}

export {TimerContext, RunningTimer}