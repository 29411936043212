import { noWeighingUnit } from "./typesVariable"

export const getWeighingScaleValue = (conversionArray, conversionUnit, value, defaultUnit) => {
    if (conversionArray)
        if (conversionUnit === defaultUnit) {
            return {
                value: `${value} ${defaultUnit}`,
                valueNumberType: Number(value),
                displayBtn: true
            }
        }
        else {
            const conversionFactor = conversionArray.find(i => i.unit === conversionUnit)?.factor
            const convertedVal = Math.ceil(value * Number(conversionFactor))
            return convertedVal && convertedVal > 1 ? {
                value: `${convertedVal} ${conversionUnit}`,
                valueNumberType: Number(convertedVal),
                defaultVal: `${value} ${defaultUnit}`,
                displayBtn: true
            } : {
                value: `${value} ${defaultUnit}`,
                valueNumberType: Number(value),
                displayBtn: false
            }
        }
    return {
        value: `${value} ${defaultUnit}`,
        valueNumberType: Number(value),
        displayBtn: !(noWeighingUnit.includes(defaultUnit))
    }
}

export const getDefaultIngredients = (items) => {
    const itemsWithAlternate = items?.ingredients
    return itemsWithAlternate ? itemsWithAlternate.find(i => i?.default) : items
}

const reorderPantry = (data) => {
    const newData = data.reduce((prev, next) => !(["tbsp", "tsp"].includes(next.unit)) ? [next, ...prev] : [...prev, next], [])
    return newData
}

export const getAllAllergens = (ingredients) => {
    if (ingredients.length > 0) {
        const allAllergens = ingredients.reduce((prev, next) => {
            const { allergens } = next
            if (allergens)
                return [...prev, ...allergens]
            else
                return prev
        }, [])
        const uniqueAllergens = allAllergens.reduce((prev, next) => {
            if (!prev.includes(next))
                return [...prev, next]
            else
                return prev
        }, [])
        return uniqueAllergens
    }
    return []
}

const getTag = (tags) => {
    if (tags) {
        if (tags.includes("vegetables")) return "vegetables"
        else if (tags.includes("powder")) return "powder"
        else if (tags.includes("whole")) return "whole"
        else if (tags.includes("spices")) return "spices"
        else if (tags.includes("processed")) return ""
    }
    return "pantry"
}

const createAlternateIngredients = (defaultIngredient, ingredients) => {
    const ingredient = ingredients[defaultIngredient.ingredientId]
    return {
        displayName: ingredient?.displayName,
        id: defaultIngredient.ingredientId,
        image: ingredient?.image,
    }
}

export const getIngredientsData = (item, ingredients) => {
    if (ingredients) {
        const defaultIngredient = getDefaultIngredients(item)
        const ingredient = ingredients[defaultIngredient.ingredientId]
        const prepId = defaultIngredient?.prep?.prepId
        const prep = prepId && ingredient?.preps ? ingredient.preps[prepId] : null
        const qty = defaultIngredient?.overruledAmount?.qty || defaultIngredient.amount.qty
        const unit = defaultIngredient?.overruledAmount?.unit || defaultIngredient.amount.unit
        const tags = ingredient?.tags || []
        return {
            id: defaultIngredient.ingredientId,
            displayName: ingredient?.displayName,
            prep: prep,
            image: ingredient?.image,
            unit: defaultIngredient.amount.unit,
            tags,
            allergens: ingredient?.allergens,
            nutrientInfo: ingredient?.nutrientInfo,
            defaultWeight: getWeighingScaleValue(ingredient?.conversion, "g", defaultIngredient.amount.qty, defaultIngredient.amount.unit, defaultIngredient?.scaleModel),
            alternateIngredients: item?.ingredients && item.ingredients.length > 1 ? item.ingredients.map(i => createAlternateIngredients(i, ingredients)) : null,
            weighScaleAmount: getWeighingScaleValue(ingredient?.conversion, "g", qty, unit, defaultIngredient?.scaleModel)
        }

    }
    return {}
}

export const getSortedIngredients = (items) => {
    let initialData = { vegetables: [], spices: { whole: [], powder: [] }, pantry: [] }

    items.forEach(i => {
        switch (getTag(i?.tags)) {
            case "vegetables": {
                if (!initialData.vegetables.find(j => j.id === i.id))
                    initialData.vegetables = [...initialData.vegetables, i]
                break;
            }
            case "spices":
            case "powder": {
                if (!initialData.spices.powder.find(j => j.id === i.id))
                    initialData.spices = { ...initialData.spices, powder: [...initialData.spices.powder, i] }
                break;
            }
            case "whole": {
                if (!initialData.spices.whole.find(j => j.id === i.id))
                    initialData.spices = { ...initialData.spices, whole: [...initialData.spices.whole, i] }
                break;
            }
            case "pantry": {
                if (!initialData.pantry.find(j => j.id === i.id))
                    initialData.pantry = [...initialData.pantry, i]
                break;
            }
        }
    })

    return initialData
}

export const getTotalMacros = (items) => {
    if (items.length > 0) {
        const macro = items.reduce((prev, next) => {
            const qty = next.defaultWeight.valueNumberType
            if (next.tags.includes("processed")) {
                return prev
            }
            else {
                const calorie = Math.round(next?.nutrientInfo?.calorie * qty / 100) || 0
                const fat = Math.round(next?.nutrientInfo?.fat * qty / 100) || 0
                const protein = Math.round(next?.nutrientInfo?.protein * qty / 100) || 0
                const carbs = Math.round(next?.nutrientInfo?.carbs * qty / 100) || 0
                return { calorie: calorie + prev.calorie, fat: fat + prev.fat, protein: protein + prev.protein, carbs: carbs + prev.carbs }
            }
        }, {
            calorie: 0,
            fat: 0,
            protein: 0,
            carbs: 0
        })
        return macro
    }
    return null
}

export const getAllRecipeIngredients = (data, ingredients) => {
    if (data && ingredients) {
        const filteredData = data.reduce((prev, next) => {
            if (next?.subSteps) {
                const subStepIngredients = next.subSteps.reduce((p, n) => {
                    if (n.type === "addIngredients") {
                        const items = n?.parameters?.items
                        const data = items.map(i => {
                            const ingredientData = getIngredientsData(i, ingredients)
                            return { stepId: next?.id, ...ingredientData }
                        })
                        const filteredData = data.filter(i => (!i.tags.includes("processed")))
                        return [...p, ...filteredData]
                    }
                    else if (n.type === "addIngredientsWithAlternate") {
                        const items = n?.parameters?.items
                        const data = items.map(i => {
                            const ingredientData = getIngredientsData(i, ingredients)
                            return { stepId: next?.id, ...ingredientData }
                        })
                        const filteredData = data.filter(i => (!i.tags.includes("processed")))
                        return [...p, ...filteredData]
                    }
                    return p
                }, [])
                return [...prev, ...subStepIngredients]
            }
            return prev
        }, [])
        return filteredData
    }
    return []
}

export const changeDefaultIngredient = (recipeData, stepId, ingredientId) => {
    const { steps } = recipeData
    const { subSteps } = steps.find(i => i?.id === stepId)
    const newSubStep = subSteps.map(i => {
        if (i.type === "addIngredientsWithAlternate") {
            const { items } = i.parameters
            const searchedItem = items.find(j => j.ingredients.find(k => k.ingredientId === ingredientId))
            const searchedItemIndex = items.findIndex(j => j.ingredients.find(k => k.ingredientId === ingredientId))
            const { ingredients } = searchedItem
            const newIngredients = ingredients.map(j => {
                if (j.ingredientId === ingredientId)
                    return { ...j, default: true }
                return { ...j, default: false }
            })
            return { ...i, parameters: { ...i.parameters, items: [...items.slice(0, searchedItemIndex), { ingredients: newIngredients }, ...items.slice(searchedItemIndex + 1, items.length)] } }
        }
        return i
    })
    const newSteps = steps.map(i => {
        if (i.id === stepId)
            return { ...i, subSteps: newSubStep }
        return i
    })
    return { ...recipeData, steps: newSteps }
}