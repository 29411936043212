import React, { createContext, useEffect, useState, useContext } from "react";
import useSound from "use-sound";
import useHardware from "../../utils/hardware/hardwareState"
import { GlobalSettingsContext } from "../../utils/globalSettings/globalSettingsContext"

const HardwareContext = createContext(null)

const HardwareTask = ({ children }) => {

    const { globalSettings } = useContext(GlobalSettingsContext)

    const { connectToHardware,
        stateOfHardware,
        triggerR1,
        connected,
        forceStop,
        weight,
        time,
        jarOn,
        lidOn,
        temperature,
        heating,
        dutyCycle,
        resumeR1,
        pauseR1,
        disconnect,
    } = useHardware("2.0", globalSettings.device)

    const [stepStartTime, setStepStartTime] = useState(undefined)
    const [playJarSoundWithBeep] = useSound("https://res.cloudinary.com/prodjour/video/upload/v1650892550/sounds/switch-click-and-beep-001a-11602_eozbjg.mp3")
    const [playJarSoundWithoutBeep] = useSound("https://res.cloudinary.com/prodjour/video/upload/v1650892550/sounds/projector-button-push-6258_s0ari9.mp3")
    const [playStartSound] = useSound("https://res.cloudinary.com/prodjour/video/upload/v1650892550/sounds/start-13691_wjcz9a.mp3")
    const [playSoundOnDisconnect] = useSound("https://res.cloudinary.com/akshadasingh/video/upload/v1654328229/pljzt-fxdmo_mp3cut.net_m0vrb3.wav")

    useEffect(() => {
        if (connected) {
            if (lidOn === "on")
                playJarSoundWithBeep()
            else
                playJarSoundWithoutBeep()
        }
        else {
            playSoundOnDisconnect()
        }
    }, [connected, jarOn, lidOn])

    useEffect(() => {
        if (stateOfHardware.status === "operation") {
            setStepStartTime(Date.now())
            playStartSound()
        }
        if (stateOfHardware.status === "idle") {
            playStartSound()
        }
    }, [stateOfHardware.status])

    return (
        <HardwareContext.Provider value={{
            connectToHardware,
            triggerR1, stateOfHardware,
            connected,
            forceStop,
            weight,
            time,
            stepStartTime,
            jarOn,
            lidOn,
            temperature,
            dutyCycle,
            heating,
            pauseR1,
            resumeR1,
            disconnect,
        }}>
            {children}
        </HardwareContext.Provider>
    )
}

export { HardwareContext, HardwareTask }