import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { Typography } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'

const Timer = ({ timerKey, duration, isPlaying = true, setCurrentTime = () => { }, size, style, timerStroke = false }) => {
    const renderTime = ({ remainingTime }) => {
        setCurrentTime(remainingTime)
        const getTimeString = time => {
            const timeSec = time % 60
            const timeSecString = timeSec < 10 ? "0" + timeSec.toString() : timeSec.toString()
            return Math.floor(time / 60) + ":" + timeSecString
        }
        return <Typography variant="h5" style={style}>{`-${getTimeString(remainingTime)}`}</Typography>
    }

    return (<CountdownCircleTimer
        key={timerKey}
        size={size}
        isPlaying={isPlaying}
        strokeWidth={timerStroke ? 5 : 0}
        trailStrokeWidth={timerStroke ? 5 : 0}
        trailColor="#CFCACA"
        colors={[["#121C74"]]}
        duration={duration}
        onComplete={() => { }}
    >
        {renderTime}
    </CountdownCircleTimer>)

}

Timer.protoTypes = {
    timerKey: PropTypes.number.isRequired,
    duration: PropTypes.number.isRequired,
    singleFocusTimer: PropTypes.bool,
    isPlaying: PropTypes.bool,
}

export default Timer