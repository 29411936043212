import { setSubStepStatusForTriggerR1, 
    getInactiveSubStepId, 
    updateLastStep, 
    addDataForExtraPrep, 
    changeProgressType, 
    setSubStepStatusOnIdle, 
    changeProgressTypeOnDisconnect, 
    editSettings, 
    setSubStepStatusForSkipStep,
    addRatingToLastSubstep, 
    getCurrentR1ButtonId, 
    setStatusForCurrentR1Button
} from "../../utils/experience2/stepsInstruction";

export const reducer = ({ recipeData, view, inFocusSubstepId }, { type, data }) => {
    switch (type) {
        case "SET_ACTIVE_INSTRUCTION_BY_ID": {
            const recipeD = recipeData.map(step => step.id === data.id ? { ...step, active: true } : { ...step, active: false })
            return { recipeData: recipeD, view, inFocusSubstepId }
        }
        case "SET_INFOCUS_SUBSTEP_ID": {
            const steps = data?.steps || recipeData
            return { recipeData: steps, view, inFocusSubstepId: data.subStepId }
        }
        case "SET_ACTIVE_INSTRUCTION_BY_INDEX": {
            const steps = data?.steps || recipeData
            const recipeD = steps.map((step, index) => index === data.index ? { ...step, active: true } : { ...step, active: false })
            return { recipeData: recipeD, view, inFocusSubstepId }
        }
        case "SET_INITIAL_STEPS_PROGRESS":
            return reducer({ recipeData, view }, { type: "SET_ACTIVE_INSTRUCTION_BY_INDEX", data: { index: 0, steps: data.steps } })
        case "TRIGGER_R1": {
            // Set the status="start" to the next button
            const setStatusForCorrectR1Button = setStatusForCurrentR1Button(recipeData, data.subStepId)
            const { newRecipeData, r1Settings } = setSubStepStatusForTriggerR1(setStatusForCorrectR1Button, data.subStepId)
            data.triggerR1({ data: r1Settings?.settings, type: "serial" })
            return { recipeData: newRecipeData, view, inFocusSubstepId: null }    
        }
        case "GO_TO_CURRENT_STEP": {
            const priorityIndex = recipeData.findIndex((i) => i.priority === "immediate")
            const priorityStep = recipeData.find(i => i.priority === "immediate")
            const currentActiveStep = reducer({ recipeData, view, inFocusSubstepId }, {type: "SET_ACTIVE_INSTRUCTION_BY_INDEX", data: { index: priorityIndex, steps: recipeData }})
            const focusSubStepId = getInactiveSubStepId(currentActiveStep.recipeData, priorityStep?.id) 
            return reducer({ recipeData, view, inFocusSubstepId }, {type: "SET_INFOCUS_SUBSTEP_ID", data: { steps: currentActiveStep.recipeData, subStepId: focusSubStepId }})
        }
        case "ON_SKIP_BUTTON": {
            const { r1Settings, newRecipeData} = setSubStepStatusForSkipStep(recipeData, data.subStepId)
            data.triggerR1({ data: r1Settings?.settings, type: "serial" })
            return  { recipeData: newRecipeData, view, inFocusSubstepId: null }
        }
        case "SET_INITIAL_DATA": {
            const addedActiveTagSteps = reducer({ recipeData, view }, { type: "SET_ACTIVE_INSTRUCTION_BY_INDEX", data: { index: 0, steps: data.steps } })
            const addedSetAsideTag = reducer({ recipeData, view }, { type: "UPDATE_RECIPE_DATA_WITH_SET_ASIDE_INFO", data: { steps: addedActiveTagSteps.recipeData } })
            const setStatusForCorrectR1Button = setStatusForCurrentR1Button(addedActiveTagSteps.recipeData)            
            const stepsWithRatingSubStep = addRatingToLastSubstep(setStatusForCorrectR1Button)
            return { ...addedSetAsideTag, view: data.subStepWidth, recipeData: stepsWithRatingSubStep }
        }
        case "ON_EDIT_SETTINGS": {
            const newRecipeData = editSettings(recipeData, data.subStepId, data.newSettings)
            return { recipeData: newRecipeData, view }
        }
        case "ON_SUBSTEPS_COMPLETION": {
            const newRecipeData = setSubStepStatusOnIdle(recipeData)
            return { recipeData: newRecipeData, view, inFocusSubstepId }
        }
        case "UPDATE_SUBSTEP_ON_CHANGING_STEP": {
            const steps = data?.steps || recipeData
            const newRecipeData = updateLastStep(steps)
            return { recipeData: newRecipeData, view, inFocusSubstepId }
        }
        case "UPDATE_RECIPE_DATA_WITH_SET_ASIDE_INFO": {
            const newRecipeData = addDataForExtraPrep(data.steps)
            return { recipeData: newRecipeData, view }
        }
        case "CHANGE_VIEW": {
            if (view === "wide") {
                return { recipeData, view: "default", inFocusSubstepId }
            }
            else {
                return { recipeData, view: "wide", inFocusSubstepId }
            }
        }
        case "CHANGE_PROGRESS_TO_PAUSED": {
            const newRecipeData = changeProgressType(recipeData, data.subStepId, "paused")
            return { recipeData: newRecipeData, view, inFocusSubstepId }
        }
        case "CHANGE_PROGRESS_TO_PAUSED_ON_DISCONNECT": {
            const newRecipeData = changeProgressTypeOnDisconnect(recipeData, "paused-on-disconnect")
            return { recipeData: newRecipeData, view, inFocusSubstepId }
        }
        case "CHANGE_PROGRESS_TO_IN_PROGRESS": {
            const newRecipeData = changeProgressType(recipeData, data.subStepId, "in-progress")
            data.resumeR1()
            return { recipeData: newRecipeData, view, inFocusSubstepId }
        }
        case "ON_STEP_CHANGE": {
            const changedActiveStepData = reducer({ recipeData, view, inFocusSubstepId }, { type: "SET_ACTIVE_INSTRUCTION_BY_ID", data: { id: data.stepId } })
            const updatedSubStep = reducer({ recipeData, view, inFocusSubstepId }, { type: "UPDATE_SUBSTEP_ON_CHANGING_STEP", data: { steps: changedActiveStepData.recipeData } })
            const updatedInFocusSubStepId = reducer({ recipeData, view, inFocusSubstepId }, { type: "SET_INFOCUS_SUBSTEP_ID", data: { steps: updatedSubStep.recipeData, subStepId: data.subStepId } })
            return updatedInFocusSubStepId
        }
        default:
            return { recipeData, view, inFocusSubstepId }
    }
}