import { Box, Button, Container, IconButton, Typography } from "@mui/material"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Drawer from "@mui/material/Drawer"
import React from "react"
import Categories from "../filterComponents/Categories"
import PropTypes from "prop-types"

const styles = {
  display: "flex",
  flexDirection: "column",
  width: '100vw'
};

const Filter = ({ open, onClose, getSelectOptions, handleFilter, resetFilter }) => {
  
  const handleClose = () => {
    onClose();
  }

  return (
    <Drawer onClose={handleClose} open={open}>
      <Container style={styles}>
        <Box style={{
          marginButtom: 1
        }}>
          <Box
            style={{
              display: 'flex',
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              paddingTop: 20
            }}
          >
            <IconButton size="medium" style={{paddingLeft: 0}} onClick={() => onClose()}>
              <ArrowBackIosIcon />
            </IconButton>
            <Button onClick={resetFilter} variant="text">Reset</Button>
          </Box>
          <Typography variant="h4" style={{fontWeight:'500', fontSize: 40}} >Filter</Typography>
        </Box>
        <Categories selectOptions={getSelectOptions("tags")} selectType={"tags"}/>
        <Categories selectOptions={getSelectOptions("categories")} selectType={"categories"}/>
        <Button
        variant="contained"
        style={{ backgroundColor: "#f57c00", color: "white", position: "fixed", bottom: 0, left: 0 }}
        size="large"
        fullWidth
        onClick={handleFilter}
      >
        Apply Filters
      </Button>
      </Container>
    </Drawer>
  )
}

Filter.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  filterOptions: PropTypes.func
}

export default Filter
