import React from "react";
import RecipeCard2 from "./RecipeCard2";

const Experience2Card = ({ onCardClick, name, totalTime, url, categories, tags, loading }) => {
    return (
        <RecipeCard2
            recipeImage={url}
            name={name}
            categories={categories}
            tags={tags}
            time={totalTime ? totalTime : 10}
            onClick={onCardClick}
            loading={loading}
        />)
}

export default Experience2Card