import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'

const titleStyleData = {
    topDistance: { high: 0, default: "5vh", low: "6vh" },
    height: "9vh",
    leftIndent: "9vh"
}

const TitleStrip = ({ slideTitle, location = "default" }) => {
    const { topDistance, height, leftIndent } = titleStyleData
    return (
        <Box
            position="absolute"
            top={topDistance[location]}
            width="100%"
            bgcolor="#BEE1F4"
            height={height}
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            style={{ opacity: 0.95 }}
        >
            <Box
                position="absolute"
                left={leftIndent}
            >
                <Typography variant="h3" style={{ fontSize: "6vh" }}>{slideTitle}</Typography>
            </Box>
        </Box>
    )
}

TitleStrip.propTypes = {
    slideTitle: PropTypes.string.isRequired,
    location: PropTypes.oneOf(Object.keys(titleStyleData.topDistance)),
}

export default TitleStrip
