import { IconButton, Typography, Grid } from "@mui/material"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import SearchIcon from "@mui/icons-material/Search"
import React, { useState } from "react"
import Search from "../../utils/homepage/page/Search"
import GlobalSettingsButton from "../../utils/globalSettings/GlobalSettingsButton"
const iconPath = process.env.PUBLIC_URL + '/icons/'

const styles = {
  width: "100%",
  backgroundColor: "#fefefe",
  color: "#000",
}

const Navbar = () => {
  const [openSearch, setOpenSearch] = useState(false);

  const handleOpen = () => {
    setOpenSearch(true);
  };

  const handleClose = () => {
    setOpenSearch(false);
  };

  return (
    <AppBar position="sticky" style={styles}>
      <Toolbar>
        <Grid container alignItems="center">
          <Grid item container xs={9}>
            <img src={`${iconPath}apple-icon-180.png`} height={35} alt="React Logo" />
            <Typography variant="h5">
              CookClub.ai
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton onClick={handleOpen} size="large">
              <SearchIcon />
            </IconButton>
          </Grid>
          <Grid item xs={1}>
            <GlobalSettingsButton />
          </Grid>
        </Grid>
      </Toolbar>
      <Search open={openSearch} onClose={handleClose} />
    </AppBar>
  );
};

export default Navbar;
