import { Stepper, Step, Box, StepLabel, Typography, StepConnector, Button } from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';
import withStyles from '@mui/styles/withStyles';
import React, { useContext, useRef, useEffect } from "react";
import useScrollFeature from "./ScrollFeature"
import PropTypes from "prop-types";
import { TimerProgressBar } from "./AccordianDisplay";
import { getCompletedStep, getIndexOfSteps, getInFocusSubStepId, getInProgressStep } from "../../utils/experience2/stepsInstruction";

const getActiveIconClass = (priority) => priority ? "active-icon" : "inactive-icon"
const scrollTopOffset = -205

const Connector = withStyles({
    line: {
        height: 0,
        border: 0,
    },
})(StepConnector);

const StepperIcon = ({ connectorClass, iconClass }) => {
    return (
        <>
            <Box className={connectorClass} />
            <Box className={iconClass} />
        </>
    )
}

const getStatusColor = (displayStepNames, subStepProgress) => {
    let className = "active-btn"
    if (!displayStepNames) {
        className = `${className} decrease-active-btn-width`
    }
    if (subStepProgress) {
        className = `${className} in-progress-color`
    }
    return className
}

const InProgressDisplay = ({ timerProps }) => {
    if (timerProps?.progress === "in-progress") {
        return (
            <TimerProgressBar totalTime={timerProps?.totalTime} />
        )
    }
    return <Typography variant="h6" style={{ color: "#00704A", fontWeight: 700 }}>In progress...</Typography>
}

const StepsLabel = ({ steps, recipeDispatch, displayStepNames, scroll, createChildrenRef, createParentRef }) => {
    function onClickStep(e, id) {
        e.stopPropagation()
        const inFocusSubstepId = getInFocusSubStepId(steps, id)
        recipeDispatch({ type: "ON_STEP_CHANGE", data: { stepId: id, subStepId: inFocusSubstepId } })
    }

    const getTimerProgress = (step) => {
        const validatedStep = step?.subSteps || []
        const inProgressSubstep = validatedStep.find(i => i.progress === "in-progress")
        const settings = inProgressSubstep?.parameters?.settings
        const totalTime = settings?.reduce((prev, curr) => prev + curr.time, 0)
        return { totalTime: totalTime, progress: inProgressSubstep?.progress }
    }

    const activeSubStepStatus = steps.find(i => i?.active).subSteps.find(i => ["in-progress", "paused"].includes(i?.progress))

    const scrollToStepOnClickRecenter = (e) => {
        const inProgressStep = getInProgressStep(steps)
        const completedStep = getCompletedStep(steps)
        if (inProgressStep) {
            const index = getIndexOfSteps(steps, inProgressStep.id)
            scroll(index, scrollTopOffset, "smooth")
            onClickStep(e, inProgressStep.id, index)
        }
        else if (completedStep) {
            const index = getIndexOfSteps(steps, completedStep.id)
            scroll(index, scrollTopOffset, "smooth")
            onClickStep(e, completedStep.id, index)
        }
        else {
            if (steps.length && steps[0].subSteps.length) {
                const { subSteps } = steps[0]
                const subStepId = subSteps[0].id
                const stepId = steps[0].id
                scroll(0, scrollTopOffset, "smooth")
                recipeDispatch({ type: "ON_STEP_CHANGE", data: { stepId: stepId, subStepId: subStepId } })
            }
            return
        }
    }

    return (
        <>
            <Box
                style={{ paddingTop: "205px", boxSizing: "border-box", overflowY: "hidden", height: "400vh", paddingBottom: "500vh", width: "90%" }}
                ref={el => createParentRef(el)}
                id="parent-ref"
            >
                <Box className={getStatusColor(displayStepNames, activeSubStepStatus)} />
                <Stepper orientation="vertical" connector={<Connector />} style={{ padding: "0 10px" }} >
                    {steps.map(({ name, status, id, active, priority }, index) =>
                        <Step
                            key={id}
                            ref={el => createChildrenRef(el, index)}
                        >
                            <StepLabel
                                StepIconComponent={() => <StepperIcon
                                    connectorClass={status === "completed" ? "step-line completed-line" :
                                        "step-line not-completed-line"}
                                    iconClass={status === "completed" ? `step-icon stepper-icon completed-stepper-icon` :
                                        `step-icon stepper-icon not-completed-stepper-icon ${getActiveIconClass(priority)}`}
                                />}
                            >
                                <Box
                                    className={active ? "stepper-button stepper-button-active-color" : "stepper-button stepper-button-inactive-color"}
                                    onClick={e => {
                                        onClickStep(e, id, index)
                                        scroll(index, scrollTopOffset, "smooth", "onClickStep")
                                    }}
                                >
                                    <Typography variant={active ? "caption" : "body2"}>{`Step ${index + 1}`}</Typography>
                                    {displayStepNames ? <Typography variant={active ? "button" : "body1"}>{name}</Typography> : null}
                                    {status === "in-progress" && <InProgressDisplay timerProps={getTimerProgress(steps[index])} />}
                                </Box>
                            </StepLabel>
                        </Step>)
                    }
                </Stepper >
            </Box >
            <Box width={displayStepNames ? "50vw" : "20vw"} style={{ display: "flex", justifyContent: "center", position: "fixed", bottom: 25, zIndex: 5, transition: "width 0.5s ease-in" }}>
                <Button onClick={scrollToStepOnClickRecenter} style={{ border: " 2px solid #043F98", backgroundColor: "#ffffff", borderRadius: 50, padding: "21px 70px" }}>
                    {displayStepNames ? <Typography variant="caption" style={{ color: "#043F98" }}>Recenter</Typography> : <Typography variant="caption" style={{ color: "#043F98" }}>R</Typography>}
                </Button>
            </Box>
        </>
    )
}

StepsLabel.propTypes = {
    completedCooking: PropTypes.bool,
    steps: PropTypes.array, //change to shape
    recipeDispatch: PropTypes.func
}

export default StepsLabel