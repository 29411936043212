import { Box, Typography, SvgIcon, Button, LinearProgress } from "@mui/material"
import React, { useContext, useState, useEffect } from "react"
import ConnectButton from "./ConnectButton"
import RecipeTimer from "./RecipeTimer"
import WeightBtn from "./WeightBtn"
import { ReactComponent as WeightIcon } from "../../svg/weighing-scale.svg"
import { ReactComponent as Blade } from '../../svg/blade.svg';
import { ReactComponent as DisconnectedJar } from '../../svg/jar-when-device-disconnected.svg';
import { ReactComponent as DisconnectedLid } from '../../svg/lid-when-device-disconnected.svg';
import { ReactComponent as LidInPlace } from '../../svg/lid-in-place.svg';
import { ReactComponent as LidNotInPlace } from '../../svg/lid-not-in-place.svg';
import { ReactComponent as JarInPlace } from '../../svg/jar-in-place.svg';
import { ReactComponent as JarNotInPlace } from '../../svg/jar-not-in-place.svg';
import { ReactComponent as ReactLogo } from '../../svg/reactLogo.svg';
import { HardwareContext } from "./HardwareTasks"
import { useHistory } from 'react-router-dom'

import TimeTemperaturePlotting from "./TimeTemperaturePlotting"

const temperatureASCII = "\u02DA"

const DottedLine = () => {
    return (<Box className="dotted-line-hardware-bar" />)
}

const LidIcon = () => {
    const { jarOn, lidOn, connected } = useContext(HardwareContext)
    if (!connected || lidOn === "loading")
        return (<SvgIcon component={DisconnectedLid} viewBox="0 0 43.529 10.805" style={{ fontSize: 72 }} />)
    else if (jarOn === "off" || lidOn === "off")
        return (<SvgIcon component={LidNotInPlace} viewBox="0 0 51.502 24" style={{ fontSize: 72 }} />)
    else if (lidOn === "on")
        return (<SvgIcon component={LidInPlace} viewBox="0 0 52.178 22.275" style={{ fontSize: 72 }} />)
    else
        return null
}

const JarIcon = () => {
    const { jarOn, connected } = useContext(HardwareContext)
    if (!connected || jarOn === "loading")
        return (<SvgIcon component={DisconnectedJar} viewBox="0 0 33.385 38.057" style={{ fontSize: 72 }} />)
    else if (jarOn === "on")
        return (<SvgIcon component={JarInPlace} viewBox="0 0 39.967 45.193" style={{ fontSize: 72 }} />)
    else
        return (<SvgIcon component={JarNotInPlace} viewBox="0 0 39.385 46.057" style={{ fontSize: 72 }} />)
}
const TemperatureIcon = () => {
    const { temperature, time, stateOfHardware, dutyCycle, heating } = useContext(HardwareContext)
    const [timerDataList, setTimerDataList] = useState([])
    const [dialogOpen, setDialogOpen] = useState(false)

    useEffect(() => {
        /* To Clear the previously stored array */
        if (stateOfHardware.status === "starting") {
            setTimerDataList([])
        } else {
            setTimerDataList([...timerDataList, { time, currentTime: new Date(), temperature, heating, dutyCycle }])
        }
        /* To record the temperature change */
        // if (stateOfHardware.status === "operation") {
        // }
    }, [stateOfHardware.status, temperature])

    return (
        <>
            <Button onClick={() => setDialogOpen(true)} sx={{ color: "black" }}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography>{`${temperature || 0}${temperatureASCII}C`}</Typography>
                    <LinearProgress value={dutyCycle} variant="determinate" color={heating ? "primary" : "secondary"} />
                </Box>
            </Button>
            <TimeTemperaturePlotting timerDataList={timerDataList} dialogOpen={dialogOpen} onClose={() => setDialogOpen(false)} />
        </>
    )
}

const TimerIcon = () => {
    return (<RecipeTimer size={70} fontSize={20} timerStroke={true} />)
}

const BladeIcon = () => {
    return (<Box
        style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        }}
    >
        <SvgIcon component={Blade} viewBox="0 0 23.643 23.066" style={{ fontSize: 30 }} />
        <Typography>0</Typography>
    </Box>)
}


const HardwareBar = ({ setHardwareStateMemo, hardwareStateMemo }) => {
    const history = useHistory()
    const onLogoClick = () => history.push("/")
    return (<Box
        style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 10,
            backgroundColor: "#dedede",
            height: "100%",
            padding: "20px 0",
            boxSizing: "border-box"
        }}>
        <Button onClick={onLogoClick}>
            <SvgIcon component={ReactLogo} viewBox="0 0 1131.47 1441" sx={{ fontSize: 35, color: "black" }} />
        </Button>
        <DottedLine />
        <LidIcon />
        <JarIcon />
        <DottedLine />
        <BladeIcon />
        <TimerIcon />
        <TemperatureIcon />
        <DottedLine />
        <WeightBtn iconSize={50} iconWidth="100%" icon={WeightIcon} />
        <ConnectButton setHardwareStateMemo={hardwareStateMemo?.btFirstConnect === false ? setHardwareStateMemo : () => { }} />
    </Box>)
}

export default HardwareBar