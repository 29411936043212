import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { resolveGSUrl } from '../firebase/utils'
import ReactPlayer from 'react-player'
import DynamicSlide from './dynamicSlide/DynamicSlide'
import { initSettings } from '../utils/settings'
import ActionButtonSet from './dynamicSlide/elements/ActionButtonSet'

const ContentSlide = ({ settings = initSettings, isCurrentSlide = false, loaded = true, slide, floatingBtnsFunctions = () => { }, actionButtons = [] }) => {
    const mediaFragment = () => {
        const startVid = "startTime" in slide ? ("#t=" + slide.startTime) : "#t=0"
        if ("duration" in slide)
            return startVid + "," + (slide.startTime + slide.duration)
        return startVid
    }
    const youTubeEmbbedLinkCreator = url => {
        const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);
        if (match && match[2].length === 11) {
            return "https://www.youtube.com/embed/" + match[2]
        }
        return false
    }
    const getFileExtension = file => file.split('.').pop()
    const getFileInfo = file => {
        const extension = getFileExtension(file)
        if (["mp4", "MP4", "avi"].includes(extension))
            return "video"
        if (["jpg", "JPG", "png", "PNG"].includes(extension))
            return "image"
        const youtubeEmbbed = youTubeEmbbedLinkCreator(file)
        if (youtubeEmbbed)
            return "youtube"
        return extension
    }
    const fileInfo = getFileInfo(slide.url)

    const [url, setUrl] = useState(slide.url)
    const player = useRef(null)

    const seek = (seekTime) => {
        player.current.seekTo(seekTime)
    }

    useEffect(() => {
        resolveGSUrl(slide.url).then(result => setUrl(result))
    }, [slide])

    if (!loaded || url.trim().split(":")[0] === 'gs')
        return <DynamicSlide
            type="SingleFocus"
            slideData={{ text: "Loading....." }}
        />
    if (["video", "youtube"].includes(fileInfo))
        return (
            <ReactPlayer
                ref={player}
                playing={settings.autoPlay && isCurrentSlide}
                url={url + mediaFragment()}
                controls
                width="100%"
                height="100%"
                playsinline
                volume={0.9}
                muted={settings.videoMuted}
                onProgress={(progress) => {
                    if ((Math.ceil(progress.playedSeconds) >= slide.startTime + slide.duration) && settings.videoLoop) {
                        seek(slide.startTime ? slide.startTime : 0)
                    }
                }}
                config={{
                    youtube: {
                        playerVars: {
                            start: slide.startTime ? slide.startTime : 0,
                            end: slide.duration ? slide.startTime + slide.duration : undefined
                        }
                    }
                }}
            />
        )
    return (
        <>
            <ActionButtonSet actionButtons={actionButtons} floatingBtnsFunctions={floatingBtnsFunctions} />
            <img src={url} alt="" width="100%" />
        </>
    )
}

ContentSlide.propTypes = {
    settings: PropTypes.object,
    loaded: PropTypes.bool,
    isCurrentSlide: PropTypes.bool,
    slide: PropTypes.object.isRequired
}

export default ContentSlide
