const iconSrcMap = {
    add: "https://res.cloudinary.com/prodjour/image/upload/c_crop,h_52,w_52,x_0,y_5/v1627442324/icons/Add_tozxha.png",
    question: "https://res.cloudinary.com/prodjour/image/upload/v1627442242/icons/Question_eh9nc3.png",
    cut: "https://res.cloudinary.com/prodjour/image/upload/v1627442242/icons/Cut_pbqspb.png",
    stir: "https://res.cloudinary.com/prodjour/image/upload/v1627442242/icons/Mix_svh3me.png",
    timer: "https://res.cloudinary.com/prodjour/image/upload/v1627442242/icons/Timer_qhqk4h.png",
    boil: "https://res.cloudinary.com/prodjour/image/upload/v1628097373/icons/Boil_ryncuq.png",
    tap: "https://res.cloudinary.com/prodjour/image/upload/v1628620323/icons/TapIcon_lxmibu.png",
    peel: "https://cdn4.iconfinder.com/data/icons/hotel-and-restaurant-2-7/50/147-512.png",
    blend: "https://res.cloudinary.com/prodjour/image/upload/v1628668260/icons/Blend_lhqqw8.png",
    blend1: "https://res.cloudinary.com/prodjour/image/upload/v1628668174/icons/BlendIcon_rmjuxn.png",
    strain: "https://image.flaticon.com/icons/png/512/527/527782.png",
    react1: "https://res.cloudinary.com/prodjour/image/upload/v1630327255/ReactLogos/react-icon-3pt_puizyn.png",
    r1: "https://res.cloudinary.com/prodjour/image/upload/v1630327255/ReactLogos/react-icon-3pt_puizyn.png",
    R1: "https://res.cloudinary.com/prodjour/image/upload/v1630327255/ReactLogos/react-icon-3pt_puizyn.png",
    introduce: "https://res.cloudinary.com/prodjour/image/upload/v1632472687/icons/Introduce_bug3dg.svg",
    tick: "https://res.cloudinary.com/akshadasingh/image/upload/v1641886683/tick-2_wp2aa9.png",
}

const getIconUrl = icon => iconSrcMap[icon] ? iconSrcMap[icon] : iconSrcMap.question



export const iconList = Object.keys(iconSrcMap)

export default getIconUrl