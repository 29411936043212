import React, { useState, useEffect } from 'react'
import { Box, Button, Card, Container, Grid, List, ListItem, TextField, Typography, Alert } from '@mui/material'
//import useSerialForHardware from '../utils/serialInterface'
import useBluetoothForHardware from '../utils/hardware/bluetoothInterface'
import {
    defaultServiceUUID,
    defaultCharacteristicUUIDWrite,
    defaultCharacteristicUUIDMain,
    defaultCharacteristicUUIDTemp,
    defaultCharacteristicUUIDJar,
    defaultCharacteristicUUIDWeight
} from './../utils/hardware/bleServiceAndCharUUIDs'

const SetTextBox = ({ label, value, onChange }) => {
    const [textValue, setTextValue] = useState(value)
    return (
        <Box display="flex">
            <TextField fullWidth label={label} value={textValue} onChange={e => setTextValue(e.target.value)} />
            <Button disabled={textValue === value} variant="contained" color="primary" onClick={() => onChange(textValue)}>Set</Button>
        </Box>
    )
}

const CharacteristicBox = ({ activeCharacteristicUUID, setActiveCharacteristicUUID, newNotification }) => {
    const [buffer, setBuffer] = useState([])
    useEffect(() => {
        const newBuffer = [...buffer, newNotification]
        if (newBuffer.length > 10) {
            const [dropvalue, ...truncatedBuffer] = newBuffer
            setBuffer(truncatedBuffer)
        }
        else
            setBuffer(newBuffer)
    }, [newNotification])
    return <Card>
        <SetTextBox label="Characteristic UUID" value={activeCharacteristicUUID} onChange={e => setActiveCharacteristicUUID(e)} />
        <List>
            {buffer.map((bufferItem, index) => <ListItem key={index}>
                <Typography>{bufferItem}</Typography>
            </ListItem>)}
        </List>
    </Card>
}

const TestHardwareConnect = () => {
    const [stringToSend, setStringToSend] = useState("S0000000000000$E")
    const [sendDisable, setSendDisable] = useState(false)
    const [activeServiceUUID, setActiveServiceUUID] = useState(defaultServiceUUID)
    const [activeCharacteristicUUID1, setActiveCharacteristicUUID1] = useState(defaultCharacteristicUUIDMain)
    const [activeCharacteristicUUID2, setActiveCharacteristicUUID2] = useState(defaultCharacteristicUUIDWeight)
    const [activeCharacteristicUUID3, setActiveCharacteristicUUID3] = useState(defaultCharacteristicUUIDTemp)
    const [activeCharacteristicUUID4, setActiveCharacteristicUUID4] = useState(defaultCharacteristicUUIDJar)
    const [activeWriteCharacteristicUUID, setActiveWriteCharacteristicUUID] = useState(defaultCharacteristicUUIDWrite)
    const [connect, write, hardwareString, connected, disconnect, value2, value3, value4] = useBluetoothForHardware(
        { useBuffer: false },
        activeServiceUUID,
        activeWriteCharacteristicUUID,
        activeCharacteristicUUID1,
        activeCharacteristicUUID2,
        activeCharacteristicUUID3,
        activeCharacteristicUUID4
    )
    //const [lineNumber, setLineNumber] = useState(0)
    const onSend = () => {
        setSendDisable(true)
        write(stringToSend)
        setTimeout(() => setSendDisable(false), 2000)
    }

    return (
        <Container>
            <Grid container direction="column" spacing={2}>
                <Grid item container>
                    <Button disabled={connected} variant="contained" color="primary" onClick={connect}>Connect</Button>
                    <Button disabled={!connected} variant="contained" color="secondary" onClick={disconnect}>Disconnect</Button>
                </Grid>
                <Grid item>
                    <SetTextBox label="Service UUID" value={activeServiceUUID} onChange={e => setActiveServiceUUID(e)} />
                </Grid>
                <Grid container spacing={1} item>
                    <Grid item xs={3}>
                        <CharacteristicBox
                            activeCharacteristicUUID={activeCharacteristicUUID1}
                            setActiveCharacteristicUUID={setActiveCharacteristicUUID1}
                            newNotification={hardwareString}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <CharacteristicBox
                            activeCharacteristicUUID={activeCharacteristicUUID2}
                            setActiveCharacteristicUUID={setActiveCharacteristicUUID2}
                            newNotification={value2}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <CharacteristicBox
                            activeCharacteristicUUID={activeCharacteristicUUID3}
                            setActiveCharacteristicUUID={setActiveCharacteristicUUID3}
                            newNotification={value3}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <CharacteristicBox
                            activeCharacteristicUUID={activeCharacteristicUUID4}
                            setActiveCharacteristicUUID={setActiveCharacteristicUUID4}
                            newNotification={value4}
                        />
                    </Grid>
                </Grid>
                <Grid item>
                    <Alert severity="warning">Important : If same value is sent - it will show up only once</Alert>
                </Grid>
                <Grid item>
                    <SetTextBox label=" WriteCharacteristic UUID" value={activeWriteCharacteristicUUID} onChange={e => setActiveWriteCharacteristicUUID(e)} />

                    <TextField label="String to Send" value={stringToSend} onChange={e => setStringToSend(e.target.value)} />
                    <Button
                        disabled={sendDisable || !connected}
                        variant="contained"
                        color="primary"
                        onClick={onSend}
                    >Send</Button>
                </Grid>
            </Grid>
        </Container >
    )
}

TestHardwareConnect.propTypes = {

}

export default TestHardwareConnect
