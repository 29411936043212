import React from 'react'
import ReactApexChart from "react-apexcharts"

const MacrosGraph = ({data, macros}) => {
  const {carbQty, fatQty, proteinQty, calorieQty} = macros 
  const value = carbQty + fatQty + proteinQty
  const options = {
      chart: {
          type: 'bar',
          height: 100,
          stacked: true,
          animations: {
              enabled: true,
              easing: 'easeinout',
              speed: 1000,
              animateGradually: {
                  enabled: true,
                  delay: 150
              },
              dynamicAnimation: {
                  enabled: true,
                  speed: 350
              }
          }
      },
      plotOptions: {
          bar: {
              horizontal: true,
              barHeight: '100%'
          },
          colors: ['#FF9488', '#FCE969', '#33B0CA']
      },
      dataLabels: {
          formatter: function (val, opts) {
              return val + 'g'
          },
          style: {
              fontSize: "25px",
              fontFamily: 'Montserrat',
              fontWeight: 'bold',
              colors: ["#000"]
          }
      },
      stroke: {
          width: 1,
          colors: ['#fff']
      },
      xaxis: {
          categories: [1],
          labels: {
              show: false,
          }
      },
      yaxis: {
          style: {
              fontSize: "40px"
          },
          max: value > 100 ? value + 100 : 100,
      },
      tooltip: {
          y: {
              formatter: function (val) {
                  return Math.ceil(val) + "g"
              },
          }
      },
      fill: {
          opacity: 1,
          type: "solid",
          colors: ['#FF9488', '#FCE969', '#33B0CA']
      },
      style: {
          fontSize: '12px',
          colors: ['#fff']
        },
      legend: {
          position: 'bottom',
          horizontalAlign: 'left',
          offsetX: 40,
          fontSize: "20px",
          fontFamily: 'Montserrat',
          fontWeight: 600,
          markers: {
              width: 20,
              height: 20,
              fillColors: ['#FF9488', '#FCE969', '#33B0CA']
          }
      }
  };
  const series =  [
  {
      name: 'Carbs',
      data: [carbQty],
      strokeColor: '#FF9488'
  }, 
  {
      name: 'Fats',
      data: [fatQty],
      strokeColor: '#FCE969'
  },    
  {
      name: 'Proteins',
      data: [proteinQty],
      strokeColor: '#33B0CA'
  }
  ]
  return (
      <ReactApexChart options={options} series={series} type="bar" height={150} width={800} /> 
  )
}

export default MacrosGraph