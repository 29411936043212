import { Box, Button, Container } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import useRecipeListFromDB from "../../RecipeBackEnd/loadRecipeList";
import { collectionName2, getname } from "../../RecipeBackEnd/recipe2Structure";
import ListCard from "../search/ListCard";
import History from "../searchComponents/History";
import PropTypes from "prop-types";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const styles = {
  display: "flex",
  flexDirection: "column",
  width: "100vw",
};

const Search = ({ open, onClose, displayType = "LINK_TO_RECIPE" }) => {
  const { recipeList, loading } = useRecipeListFromDB(
    collectionName2,
    {},
    getname
  );

  const [recipeCard, setRecipeCard] = useState(null);
  const handleClose = () => {
    onClose();
    setRecipeCard(null)
  };

  const activeRecipes = recipeList.filter((recipe) => recipe.metadata.active);

  const history = useHistory();
  const onCardClick = (recipeId) =>
    displayType === "INGREDIENTS_ONLY"
      ? history.push("/ingredients/" + recipeId)
      : history.push("/experience2/" + recipeId);

  return (
    <Drawer onClose={handleClose} open={open}>
      <Container style={styles}>
      <Box style={{display: "flex", alignItems: "center", margin: "20px 0" }}>
        <Autocomplete
            id="combo-box-demo"
            options={activeRecipes}
            onChange={(e, value) => {setRecipeCard(value)}}
            size="medium"
            getOptionLabel={(option) => option.metadata.name}
            style={{ width: "95vw", marginRight: 4, display: "inline-block" }}
            renderInput={(params) => <TextField {...params} label="Search recipes" variant="outlined" />}
          />
          <Button variant="text" color="primary" onClick={handleClose}>Cancel</Button>
      </Box>
        {!recipeCard
          ? ""
          : (
              <ListCard
                key={recipeCard.id}
                image={recipeCard?.metadata?.url}
                username={recipeCard?.metadata?.name}
                title={recipeCard?.metadata?.name}
                onClick={() => onCardClick(recipeCard?.id)}
              /> 
            )}
        <History />
      </Container>
    </Drawer>
  );
};

Search.propTypes = {
  open: PropTypes.bool, 
  onClose: PropTypes.func, 
  displayType: PropTypes.string
}

export default Search;
