import React from "react";
import StepsDescription from "./StepsDescription";
import PropTypes from 'prop-types';
import List from "./List";
import { titles } from "../../utils/experience2/typesVariable";
import MediaPlayer from "./MediaPlayer";
import { Box } from "@mui/material";

const CriticalType = ({ parameters, title, status, type }) => {
    return (<StepsDescription
        title={title || titles[type]}
        details={<Box
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px"
            }}
        >
            <List items={parameters?.items} />
            <MediaPlayer
                url={parameters?.url}
                muted={parameters?.muted}
                duration={parameters?.duration}
                startTime={parameters?.startTime}
                loop={parameters?.loop}
            />
        </Box >}
        button={null}
    />)
}

CriticalType.propTypes = {
    parameters: PropTypes.object,
    title: PropTypes.string,
    status: PropTypes.string,
    type: PropTypes.string
}

export default CriticalType