import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import { Dialog, ButtonBase, Slide, Typography } from '@mui/material'

const PopUpDialog = ({ dialogOpen, onClickBack, componentInDialog, timeout = 1200 }) => {
    return (
            <Dialog
                open={dialogOpen}
                maxWidth={false}
                fullScreen
                TransitionComponent={Slide}
                TransitionProps={{ direction: "up", timeout: timeout }}
            >
                <Box
                    height="100vh"
                    width="100%"
                >
                    {componentInDialog}
                    <ButtonBase onClick={onClickBack} style={{
                        width: "100%",
                        height: "10vh",
                        backgroundColor: "#3f51b5"
                    }}>
                        <Typography variant="h3" style={{ color: "white" }}>Back</Typography>
                    </ButtonBase>
                </Box >
            </Dialog >
    )

}

PopUpDialog.propTypes = {
    dialogOpen: PropTypes.bool.isRequired,
    setDialogOpen: PropTypes.func.isRequired,
    componentInDialog: PropTypes.element.isRequired,
    timeout: PropTypes.number
}

export default PopUpDialog