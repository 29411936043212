import React, { createContext, useState, useEffect } from 'react'
import { v4 as uuid } from 'uuid'

const defaultSettings = () => ({
    deviceId: uuid(),
    userCode: "Soup Nazi",
    device: "any"
})

export const GlobalSettingsContext = createContext()

export const GlobalSettingsProvider = ({ children }) => {
    const [globalSettings, setGlobalSettings] = useState(JSON.parse(localStorage.getItem("globalSettings")) || defaultSettings)

    useEffect(() => {
        localStorage.setItem("globalSettings", JSON.stringify(globalSettings))
    }, [globalSettings])

    function resetSettings() {
        setGlobalSettings(defaultSettings())
    }

    return <GlobalSettingsContext.Provider value={{ globalSettings, resetSettings, setGlobalSettings }}>{children}</GlobalSettingsContext.Provider>
}