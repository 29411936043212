import { Box, Typography } from '@mui/material'
import React from 'react'

const History = () => {
  return (
    <Box >
      <Typography variant='h6' style={{marginButtom: 2}}>History</Typography>
      <Typography variant='body1'>History Coming Soon ....</Typography>
    </Box>
  )
}

export default History