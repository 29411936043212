import { useState, useEffect } from "react"

const checkString = candidateString => candidateString.match(/^S[0-9]{14}E$/)

const useBluetoothChar = (service, characteristicUUID, subscrible = true, settings = { useBuffer: true }) => {
    const [value, setValue] = useState(undefined)
    const [readBuffer, setReadBuffer] = useState([])
    const [characteristic, setCharacteristic] = useState(null)

    useEffect(() => {
        const bufferlength = readBuffer.length
        if (readBuffer.length > 0 && readBuffer[bufferlength - 1] === "E") {
            const lastValueCandidate = readBuffer.reduce((ret, element) => ret + element, "")
            setReadBuffer([])
            if (checkString(lastValueCandidate))
                setValue(lastValueCandidate)
        }
    }, [readBuffer])

    useEffect(() => {
        function readValue({ target }) {
            const utf8decoder = new TextDecoder()
            const newValue = target.value
            const decodedValue = utf8decoder.decode(newValue)
            if (settings?.useBuffer)
                setReadBuffer(old => [...old, decodedValue.charAt(0)])
            else
                setValue(decodedValue)
        }
        async function getCharacteristic() {
            if (service && characteristicUUID && subscrible) {
                const char = await service.getCharacteristic(characteristicUUID)
                char.startNotifications().then(() => {
                    char.addEventListener('characteristicvaluechanged',
                        readValue)
                })
                setCharacteristic(char)
            }
        }
        getCharacteristic()
    }, [service, characteristicUUID])
    return { value }
}

export default useBluetoothChar