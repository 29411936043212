import { Chip, Grid, Skeleton } from "@mui/material"
import Typography from "@mui/material/Typography"
import React from "react"
import TimeWithClockIcon from "../TimeWithClockIcon"
import PropTypes from "prop-types"


const ListContent = ({ title, time, tags = [], categories=[], loading }) => {
    return (
        <Grid container>
            <Grid item xs>
                <Typography>{loading ? <Skeleton /> : title}</Typography>
            </Grid>
            {time &&
                <Grid item xs={3}>
                    <TimeWithClockIcon timeInMin={time} />
                </Grid>
            }
            <Grid container>
                <Grid item >
                    {tags.map((tag, i) => <Chip sx={{marginRight: 2}} color="primary" label={tag} key={i} />)}
                </Grid>
                <Grid item justifyItems={"space-around"}>
                    {categories.map((tag, i) => <Chip sx={{marginRight: 2}} color="success" label={tag} key={i} />)}
                </Grid>
            </Grid>
        </Grid >
    );
};

ListContent.propTypes = {
    title: PropTypes.string.isRequired,
    time: PropTypes.number.isRequired,
    tags: PropTypes.array
}

export default ListContent
