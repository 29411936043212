import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'
import JsonEditorDisplay from './JsonEditorDisplay';
import ArrayEditor from './ArrayEditor'
import { vizualizationSchema, ingredientSchema } from './schemas'
import onMenuClick from './contextMenuItems'

const generateIngredient6Slides = ({ list, visualization }) => {
    const slides = visualization.filter(({ type }) => type === "Ingredients6")
        .map(({ data }) => {
            return {
                data: {
                    ingredientsData: data.ingredientIds
                        .map((id) => list.find(listItem => listItem.id === id))
                },
                ingredientIds: data.ingredientIds
            }
        }
        )
    return (slides.length ? slides : null)
}

const PropertySeparateEdit = ({ jsonProperty='', value, onChange, property = "visualization" }) => {

    const [recipeJsonProps, setRecipeJsonProps] = useState([])
    useState(() => {
        if (jsonProperty === 'metadata') {
            setRecipeJsonProps(value)
        }
    })

    const { [property]: propVal, ...rest } = value
    const onPropertyChange = newPropVal => {
        onChange({ ...value, [property]: newPropVal })
    }
    const onRestChange = newRest => {
        propVal ? onChange({ [property]: propVal, ...newRest }) : onChange(newRest)
    }
    const onArrayElementChange = (change, index) => {
        onChange(value.map((element, i) => i === index ? change : element))
    }
    return (
        <Box
            display="flex"
            flexDirection="column"
            width="100%"
        >
            {propVal ? <Typography >{property}</Typography> : <></>}
            {propVal ? <ArrayEditor
                preview={property === "visualization"}
                value={propVal}
                slides={value?.visualization ? generateIngredient6Slides(value) : null}
                onChange={onPropertyChange}
                getTitle={(e, i) => {
                    const getTypeIcon = type => {
                        const typeMap = {
                            ContentSlide: "©",
                            InstructionFeed: "ℹ",
                            SingleFocus: "1️⃣",
                            PrepDisplay: "🔪",
                            Ingredients6: "🥦"
                        }
                        return Object.keys(typeMap).includes(type) ? typeMap[type] : "❓"
                    }
                    const getTypeString = e => {
                        if (e.type === "ContentSlide")
                            return e?.data?.url ? e.data.url.split("/").slice(-1)[0] : e.id
                        if (e.type === "InstructionFeed")
                            return e?.data?.title ? `${e.data.title} ${e.id.slice(-5)}` : e.id
                        if (e.type === "SingleFocus" || e.type === "ProcessTitleSlide")
                            return e?.data?.text ? e.data.text : e.id
                        if ("name" in e)
                            return e.name
                        if ("label" in e)
                            return e.label
                        if ("id" in e)
                            return e.id
                        return e.id
                    }
                    return `${getTypeIcon(e?.type)} ${getTypeString(e)}`
                }}
                elementSchema={property === "visualization" ? vizualizationSchema : ingredientSchema}
            /> : <></>}
            {Object.keys(rest).includes("list") ? <PropertySeparateEdit
                value={rest}
                property="list"
                onChange={onRestChange}
            /> : Object.keys(rest).length > 0 ? <>
                <Typography >Rest</Typography>
                <JsonEditorDisplay
                    recipeJsonProps={recipeJsonProps}
                    value={rest}
                    jsonProperty={jsonProperty}
                    onChange={onRestChange}
                    title="rest"
                    onCreateMenu={(items, node) => onMenuClick(items, node, rest, e => onArrayElementChange(e, 0))}
                />
             </>: <></>
            }
        </Box>
    )
}

PropertySeparateEdit.propTypes = {
    value: PropTypes.object,
    onChange: PropTypes.func,
    property: PropTypes.string,
}

export default PropertySeparateEdit
