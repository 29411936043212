import { Box, Typography } from "@mui/material";
import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { IngredientsContext } from "../ingredients2/IngredientsFromDB";
import PrepModal from "./PrepModal";
import PropTypes from 'prop-types'
import { logEvents } from "../../utils/logging";
import WeightBtn from "./WeightBtn";
import { getIngredientsData } from "../../utils/experience2/ingredientsUtil";

const DefaulItemDisplay = ({ label, prep, weighScale, nutrientInfo, img }) => {
    return (<>
        <Box display="flex" flexDirection="column">
            <Box display="flex" alignItems="center" style={{ gap: 5 }}>
                <Typography variant="caption">{label}</Typography>
                {weighScale?.defaultVal ? <Typography variant="body2" style={{ lineHeight: 0.5 }}>{`x ${weighScale.defaultVal}`}</Typography> : null}
            </Box>
            <Typography variant="body2" style={{ lineHeight: 0.5, color: "#585858" }}>{prep?.displayName}</Typography>
        </Box>
        {weighScale?.displayBtn ? <WeightBtn weight={weighScale?.value} btnClass="prep-ing-btn" nutrientInfo={nutrientInfo} img={img} /> : <Typography variant="body2" style={{ textAlign: "center", width: "30%" }}>{weighScale?.value}</Typography>}
    </>)
}

const List = ({ items = [] }) => {
    const { ingredients } = useContext(IngredientsContext)
    const [modalId, setModalId] = useState(null)
    const displayedIngredients = items.map(i => getIngredientsData(i, ingredients))

    return (<Box className="list">
        {displayedIngredients.map(({ id, displayName, weighScaleAmount, prep, prevPrep, image, nutrientInfo, img }) => <>
            <Box
                className="list-item"
                onClick={() => {
                    if (!prevPrep) {
                        const sessionId = sessionStorage.getItem("sessionId")
                        logEvents(sessionId)
                        setModalId(id)
                    }
                    return
                }}
            >
                <DefaulItemDisplay
                    key={id}
                    label={displayName}
                    prep={prep}
                    weighScale={weighScaleAmount}
                    nutrientInfo={nutrientInfo}
                    img={img}
                />
            </Box>
            <PrepModal
                ingredient={{ displayName, image }}
                weight={weighScaleAmount}
                prep={prep}
                open={modalId === id}
                onClose={() => setModalId(null)}
            />
        </>
        )}
    </Box>)
}

List.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        ingredientId: PropTypes.string,
        amount: PropTypes.shape({
            qty: PropTypes.number,
            unit: PropTypes.string
        }),
        prep: PropTypes.object //change to shape
    }))
}

export default List