const prepSchema = {
    type: "object",
    properties: {
        prepId: { type: "string" },
        displayName: { type: "string" },
        postPrepImage: { type: "string" },
        postPrepDescription: { type: "string" },
        howMedia: { type: "string" },
        startTime: { type: "number" },
        duration: { type: "number" },
        loop: { type: "boolean" },
        mute: { type: "boolean" }
    }
}

const conversionSchema = {
    type: "object",
    properties: {
        factor: { type: "string" },
        unit: { type: "string" },
    }
}

const nutrientInfo = {
    type: "object",
    properties: {
        protein: { type: "number" },
        carbs: { type: "number" },
        calorie: { type: "number" },
        fat: { type: "number" },
    }
}

export const schema = {
    type: "object",
    properties: {
        displayName: { type: "string", title: "Display Name", description: "This is the name that shows up in the UI" },
        alternateNames: { type: "array", items: { type: "string" } },
        image: { type: "string", title: "Display Image", description: "This is currently in use" },
        iconImage: { type: "string", title: "Graphic Image", description: "Graphic Version for later use" },
        alternateImage: { type: "array", items: { type: "string" } },
        preps: { type: "array", items: prepSchema },
        tags: { type: "array", items: { type: "string" } },
        defaultWeightUnit: { type: "string" },
        conversion: { type: "array", items: conversionSchema },
        allergens: { type: "array", items: { type: "string" } },
        nutrientInfo: nutrientInfo,
    }
}