import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'

const ETAGraphic = ({ etaInMin }) => {
    const currentDate = new Date()
    const etaDate = new Date(currentDate.getTime() + (etaInMin * 60 * 1000))
    const hours = etaDate.getHours()
    const minsNum = etaDate.getMinutes()
    const mins = minsNum >= 10 ? minsNum.toString() : "0" + minsNum.toString()
    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            flexDirection="column"
            marginLeft={3}
            marginRight={3}
        >
            <Typography variant="h5" style={{ color: "#FFFFFF" }}>{etaInMin} mins</Typography>
            <Typography variant="h6" style={{ color: "#FFFFFF" }}>ETA - {hours}:{mins}</Typography>
        </Box>
    )
}

ETAGraphic.propTypes = {
    etaInMin: PropTypes.number,
}

export default ETAGraphic
