import React, { createContext } from "react";
import { useGenerateIngredients } from "../recipeSteps/ingredientsGenerator";

const IngredientsContext = createContext(null)

const IngredientsFromDB = ({ children }) => {
    const { data: ingredients, loading } = useGenerateIngredients()

    return (
        <IngredientsContext.Provider value={{ ingredients, loading }}>
            {children}
        </IngredientsContext.Provider>
    )
}

export { IngredientsFromDB, IngredientsContext }