import React from 'react';
import { mdiPlusCircle } from '@mdi/js';
import Icon from '@mdi/react'
import { ButtonBase } from '@mui/material'

const AddStepBtn = ({ setHardwareSettings }) => {
    const onAdd = () => {
        setHardwareSettings(old => {
            const additionalSettings = { time: 20, temperature: 40, speed: 2 }
            if (old.type === "basic") {
                return { type: "serial", data: [old?.data, additionalSettings] }
            }
            return { ...old, data: [...old?.data, additionalSettings] }
        })
    }

    return (<ButtonBase onClick={onAdd} style={{ position: "absolute", width: 60, height: 50, right: 0, top: "34vh" }}>
        <Icon path={mdiPlusCircle} />
    </ButtonBase>)

}

export default AddStepBtn