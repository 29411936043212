import { Grid } from '@mui/material'
import React from 'react'
import AddIngredients from './AddIngredients'
import UrlUtilityRender from './UrlUtilityRender'

const CriticalStep = ({parameters, setParameters}) => {
  const onChange = (newVal) => {
    setParameters({...parameters, ...newVal})
  }

  return (
    <Grid container>
      <UrlUtilityRender parameters={parameters} setParameters={onChange} />
      <AddIngredients parameters={parameters} setParameters={onChange} />
    </Grid>
  )
}

export default CriticalStep