import { useEffect, useState } from 'react'
import firebaseApp from '../../firebase/config';
import { getFirestore, collection, addDoc, getDocs, setDoc, doc, deleteDoc, query, orderBy } from 'firebase/firestore'

const db = getFirestore(firebaseApp)

const useIngredientsDB = (collectionName = "ingredients") => {
    const [ingredients, setIngredients] = useState([]);
    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(true)
    const [refreshTrigger, setRefreshTrigger] = useState(true)

    function refresh() {
        setRefreshTrigger(!refreshTrigger)
    }

    useEffect(() => {
        setLoading(true)
        const fetchData = async () => {
            try {
                const data = await getDocs(query(collection(db, collectionName), orderBy("displayName")))
                const r = data.docs.map(doc => ({ ...doc.data(), id: doc.id }))
                setIngredients(r)
            }
            catch (err) {
                setError(err)
            }
            setLoading(false)
        }
        fetchData()
    }, [collectionName, refreshTrigger])

    const saveNewIngredient = async (newData, callback = () => { }) => {
        const docRef = collection(db, collectionName)
        setSaving(true)
        try {
            const data = await addDoc(docRef, newData)
            callback(data.id)
            refresh()
        }
        catch (err) {
            console.log(err)
            setError(err)
        }
        setSaving(false)
    }

    const updateIngredient = async (newData, id) => {
        const docRef = doc(db, collectionName, id)
        setSaving(true)
        try {
            await setDoc(docRef, newData)
            refresh()
        }
        catch (err) {
            console.log(err)
            setError(err)
        }
        setSaving(false)
    }

    const deleteIngredient = async (id) => {
        setSaving(true)
        const docRef = doc(db, collectionName, id)
        try {
            await deleteDoc(docRef)
            refresh()
        } catch (err) {
            setError(err)
        }
        setSaving(false)
    }
    return {
        ingredients, saveNewIngredient, updateIngredient, error, saving, deleteIngredient, loading
    }
}

export default useIngredientsDB