import { Box, Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import PropTypes from 'prop-types';
import React, { useContext, useState } from "react";
import SwiperCore, { FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import 'swiper/swiper-bundle.min.css';
import CardWrapper from "../../utils/homepage/CardWrapper";
import { FilterContext } from '../../utils/homepage/FilterContext';
import Filter from '../../utils/homepage/page/Filter';
import useRecipeListFromDB from '../../utils/RecipeBackEnd/loadRecipeList';
import { collectionName, getname } from '../../utils/RecipeBackEnd/recipe2Structure';

SwiperCore.use([FreeMode])

const useStyles = makeStyles({
  root: {
    textTransform: "none",
    display: ({showClearFilter}) => showClearFilter,
    zIndex: 10,
    borderRadius: 4,
    padding: "10px 20px",
    color: "white",
    backgroundColor: "black",
    cursor: "pointer"
  },
});


// Take the recipe props and add filteredData to it as arguments
const Categories = ({filterRecipeList}) => {
  const recipeCollection = process.env.REACT_APP_VERCEL_DEPLOY_MODE === "PREVIEW" ? "recipeStaging" : collectionName
  const { recipeList, loading } = useRecipeListFromDB(recipeCollection, {}, getname)
  const [openFilterPage, setOpenFilterPage] = useState(false)
  const [showClearFilter, setShowClearFilter] = useState("none")
  const [filterOptions, setFilterOptions] = useContext(FilterContext)
  const classes = useStyles({showClearFilter})
  
  const isRecipeActive = recipe => {
    const { metadata } = recipe
    if (!metadata) return false
    const { inactive } = metadata
    if (inactive) return false
    return true
}

const resetFilter = () => {
  setFilterOptions({...filterOptions, categories: [], time: 80})
  setFilterOptions((prevOptions) => ({
    ...prevOptions,
    categories: [],
    time: 80
  }))
}

  const handleOpen = () => {
    setOpenFilterPage(true)
  }

  const handleClose = () => {
    setOpenFilterPage(false)
  }

  // closes the snackbar
  const handleClearFilter = () => {
    filterRecipeList(mainRecipes)
    resetFilter()
    setShowClearFilter("none")
  }
  const activeRecipes = recipeList.filter(recipe => isRecipeActive(recipe))
  const mainRecipes = activeRecipes.filter(({ metadata }) => !(metadata?.experimental))
  const experimentalRecipes = activeRecipes.filter(({ metadata }) => metadata?.experimental)
  
  const getThumbnail = (recipeJson) => {
    if (recipeJson?.metadata?.thumbnail)
        return recipeJson.metadata.thumbnail
    if (recipeJson?.mainPage?.visualization[0].data?.url)
        return recipeJson.mainPage.visualization[0].data.url
    return "https://res.cloudinary.com/prodjour/image/upload/c_scale,h_" + (400) + "/v1621114765/TestPDF/Loading_fdzzw3.jpg"
  }

  const appetizersList = mainRecipes.filter(list => list.metadata.tags? list.metadata.tags[0] === 'Appetizers': null)
  const breakfastList = mainRecipes.filter(list => list.metadata.tags? list.metadata.tags[0] === 'Breakfast': null)
  const vegetarianList = mainRecipes.filter(list => list.metadata.tags? list.metadata.tags[0] === 'Vegetarian': null)
  const nonVegetarianList = mainRecipes.filter(list => list.metadata.tags? list.metadata.tags[0] === 'Non-vegetarian': null)
  const dessertsList = mainRecipes.filter(list => list.metadata.tags? list.metadata.tags[0] === 'Desserts': null)
  const noodlesList = mainRecipes.filter(list => list.metadata.tags? list.metadata.tags[0] === 'Noodles': null)
  const category = [appetizersList, breakfastList, vegetarianList, nonVegetarianList, noodlesList]

    // Handles the filtering of the recipe list
    const handleFilterCard = (title) => {
      let filter = category.filter(arr => !arr.length ? null : arr[0].metadata.tags.includes(title))
      filterRecipeList(filter[0])
      
        setFilterOptions((prevOptions) => ({
          ...prevOptions,
          categories: filter[0][0].metadata.tags? filter[0][0].metadata.tags[0]: [],
          time: 80
        }))
            
      setShowClearFilter("block")
    }

    const handleFilter = () => {
      const filteredData = mainRecipes.filter((list) => {
        if (!filterOptions.categories.length && filterOptions.time === 0) {
          return list
        } else if (!filterOptions.categories.length) {
          return list.metadata.totalTime <= filterOptions.time;
        } else if (filterOptions.time === 80) {
          filterOptions.categories.includes(
            list.metadata.tags ? list.metadata.tags[0] : null
          )
        }
        return (
          list.metadata.totalTime <= filterOptions.time &&
          filterOptions.categories.includes(
            list.metadata.tags ? list.metadata.tags[0] : null
          )
        )
      })
      filterRecipeList(filteredData)
      handleClose()
      setShowClearFilter("block")
    }

  return (
    <Box style={{marginBottom: 24}}>
      <Box 
        style={{
          display:'flex',
          flexDirection: "row",
          margin: 1,
          justifyContent: 'space-between'
        }}
      >
        <Typography variant="h6" >Categories</Typography>
        <Button onClick={handleOpen} style={{textTransform: 'none', color: '#FF8832'}} size="small" endIcon={<ChevronRightIcon />}>
          See all
        </Button>
      </Box>
      <Filter open={openFilterPage} onClose={handleClose} resetFilter={resetFilter} filterOptions={handleFilter}/>
      <Box>
        <Swiper
          freeMode={true}
          modules={[ FreeMode]}
          slidesPerView={2.5}
          spaceBetween={20}
        >
          
          {loading ? null : category.map((item, i) => (
            <SwiperSlide key={i}>
            <CardWrapper
              filter={handleFilterCard}
              title={item[0] ? item[0].metadata.tags[0]: null}
              recipeCount={item.length}
              key={item.recipeCount + i}
              image={getThumbnail(item[0])}
            /> 
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
      <Box
        width="100%"
        position="fixed" 
        style={{ display: "flex", justifyContent:"center",left: '40%', bottom: 80, backgroundColor: "black", width: "25vw" }}
      >
        <Button className={classes.root} onClick={handleClearFilter}>CLEAR FILTERS</Button>
      </Box>
      
    </Box>
  )
}

Categories.propTypes = {
  filterRecipeList: PropTypes.func.isRequired
}

export default Categories
