import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import InstructionFeed from './InstructionFeed'
import SingleFocus from './SingleFocus'
import ContentSlide from '../ContentSlide'
import PrepDisplay from './PrepDisplay'
import Ingredients6 from './Ingredients6'
import ProcessTitleSlide from './ProcessTitleSlide'
import TimerSlide from './elements/TimerSlide'

const dynamicSlideTypes = ["SingleFocus", "InstructionFeed", "Ingredients6", "ContentSlide", "PrepDisplay", "ProcessTitleSlide", "TimerSlide"]

const SlideTypeSwitcher = ({ id, type, slideData, isCurrent, react1Settings, hardwareState, progressInfo, typeSpecificInformation, dispatch, btnFunctions }) => {
    switch (type) {
        case "InstructionFeed":
            return <InstructionFeed
                title={slideData.title}
                instructions={slideData.instructions}
                isCurrentSlide={isCurrent}
                backgroundMedia={slideData.backgroundMedia}
                note={slideData.note}
                actionButtons={slideData.actionButtons}
                setReact1Settings={(data) => dispatch({ type: "SET-R1SETTINGS", data })}
                setScaleSettings={(data) => dispatch({ type: "SET-SCALESETTINGS", data })}
                stepCompleted={progressInfo?.slideIndex ? progressInfo?.slideIndex <= Math.max.apply(null, progressInfo.completedSteps) : undefined}
                floatingBtnsFunctions={btnFunctions}
            />
        case "SingleFocus":
            return <SingleFocus
                slideId={id}
                text={slideData?.text}
                processTitleIds={slideData?.processTitleIds || null}
                align={slideData?.align}
                actionButtons={slideData?.actionButtons}
                setReact1Settings={(data) => dispatch({ type: "SET-R1SETTINGS", data })}
                singleFocusTimer={slideData?.timer}
                floatingBtnsFunctions={btnFunctions}
                react1Settings={react1Settings}
                hardwareState={hardwareState}
                completedSteps={progressInfo?.completedSteps}
                currentSlide={progressInfo?.slideIndex}
            />
        case "ContentSlide":
            return <ContentSlide
                isCurrentSlide={isCurrent}
                slide={{ url: slideData.url }}
                floatingBtnsFunctions={btnFunctions}
                actionButtons={slideData?.actionButtons}
            />
        case "PrepDisplay":
            return <PrepDisplay
                prepItems={slideData.prepItems}
                dispatch={dispatch}
            />
        case "Ingredients6":
            return <Ingredients6
                ingredientsData={slideData?.ingredientsData}
                changeIngredientDefault={typeSpecificInformation?.changeIngredientDefault}
                addSelectedIngredients={typeSpecificInformation?.addSelectedIngredients}
                removeSelectedIngredients={typeSpecificInformation?.removeSelectedIngredients}
                selectedIngredientsId={typeSpecificInformation?.selectedIngredientsId}
            />
        case "ProcessTitleSlide":
            return <ProcessTitleSlide
                slideId={id}
                text={slideData?.text}
                processTitleIds={slideData?.processTitleIds}
                actionButtons={slideData?.actionButtons}
                floatingBtnsFunctions={btnFunctions}
            />
        case "TimerSlide":
            return <TimerSlide
                text={slideData?.text}
                instruction={progressInfo?.completedSteps.includes(progressInfo?.slideIndex - 1) &&
                    !(["operation", "cooldown", "starting"].includes(hardwareState)) ?
                    "Continue" : slideData?.instruction}
                autoSlide={slideData?.slideNext && isCurrent}
                moveToNext={() => btnFunctions("slideNext")}
                timer={slideData?.timer}
                hardwareState={hardwareState}
                completedSteps={progressInfo.completedSteps}
                currentSlide={progressInfo?.slideIndex}
            />
        default:
            return null
    }
}

const DynamicSlide = ({ slideData, type, isCurrent, id, react1Settings, hardwareState, progressInfo, typeSpecificInformation, dispatch, btnFunctions }) => {
    return (
        <Box
            height="90vh"
            width="160vh"
            position="relative"
            zIndex={0}
            overflow="hidden"
        >
            <SlideTypeSwitcher
                id={id}
                type={type}
                slideData={slideData}
                isCurrent={isCurrent}
                react1Settings={react1Settings}
                hardwareState={hardwareState}
                progressInfo={progressInfo}
                typeSpecificInformation={typeSpecificInformation}
                dispatch={dispatch}
                btnFunctions={btnFunctions}
            />
        </Box>
    )
}

DynamicSlide.propTypes = {
    slideData: PropTypes.object.isRequired,
    type: PropTypes.oneOf(dynamicSlideTypes),
    isCurrent: PropTypes.bool.isRequired,
    dispatch: PropTypes.func,
}

export default DynamicSlide