import { Box, Checkbox, TextField, Typography } from "@mui/material";
import React from "react";

const StringField = ({
  title,
  description,
  onChange,
  required = false,
  data,
  type = (i) => i,
}) => {
  return (
    <Box style={{ marginRight: 20 }}>
      <TextField
        variant="outlined"
        label={title}
        key={title}
        name={title}
        onChange={onChange}
        required={required}
        value={data}
        fullWidth
        helperText={description}
        size="small"
      />
    </Box>
  );
};

const NumberField = ({
  title,
  description,
  onChange,
  required = false,
  data,
  type = (i) => i,
}) => {
  return (
    <Box style={{ marginRight: 20 }}>
      <TextField
        variant="outlined"
        type="number"
        label={title}
        key={title}
        name={title}
        onChange={onChange}
        required={required}
        value={data}
        fullWidth
        helperText={description}
        size="small"
      />
    </Box>
  );
};

const BooleanField = ({ title, onChange, data, type = (i) => i }) => {
  return (
    <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
      <Typography>{title}</Typography>
      <Checkbox
        checked={data}
        onChange={onChange}
        inputProps={{ "aria-label": "primary checkbox" }}
      />
    </Box>
  );
};

const RenderInputField = ({ schemaProperties, objectData, onInputChange }) => {
  switch (schemaProperties.type) {
    case "string":
      return (
        <StringField
          data={objectData}
          title={schemaProperties.title}
          description={schemaProperties.description}
          onChange={onInputChange}
        />
      );
    case "number":
      return (
        <NumberField
          data={objectData}
          title={schemaProperties.title}
          description={schemaProperties.description}
          onChange={onInputChange}
        />
      );
    case "boolean":
      return (
        <BooleanField
          data={objectData}
          title={schemaProperties.title}
          description={schemaProperties.description}
          onChange={onInputChange}
        />
      );
    default:
      return null;
  }
};

export default RenderInputField;
