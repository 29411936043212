import React, { useState } from "react";
import PropTypes from 'prop-types';
import { Typography, SvgIcon, Box, Button } from "@mui/material";
import { ReactComponent as Blade } from '../../svg/blade.svg';
import { ReactComponent as Temperature } from '../../svg/temperature.svg';
import { ReactComponent as Time } from '../../svg/time.svg';
import { getTime } from "../../utils/experience2/stepsInstruction";
import PopUpModal from "./PopUpModal"
import HardwareDrive2 from "../../pages/HardwareDrive2";

const temperatureASCII = "\u02DA"

const SettingsDisplay = ({ settings = [], dispatch, subStepId, title, status }) => {
    const [settingsDialogOpen, setSettingsDialogOpen] = useState(false)
    const handleSettings = (settings) => {
        dispatch({type: "ON_EDIT_SETTINGS", data: {subStepId, newSettings: settings}})
        setSettingsDialogOpen(false)
    }
    return (<>
        {settings.map(({ time, temperature, speed }, index) => (
            <Box key={index} display="flex" justifyContent="space-around" width="100%" >
                <Button disabled={status==="in-progress"} sx={{color: "#000"}} onClick={() => setSettingsDialogOpen(true)} >
                    <Box display="flex" alignItems="center" style={{ gap: "10%" }}>
                        <SvgIcon component={Temperature} style={{ fontSize: 40 }} viewBox="0 0 23.014 29.873" />
                        <Typography sx={status==="in-progress" && {color: "#000"}} variant="body2" style={{ width: 90, textAlign: "center" }}>{`${temperature}${temperatureASCII}C`}</Typography>
                    </Box>
                    <Box display="flex" alignItems="center" style={{ gap: "10%" }}>
                        <SvgIcon component={Time} style={{ fontSize: 40 }} viewBox="0 0 27.646 27.646" />
                        <Typography sx={status==="in-progress" && {color: "#000"}} variant="body2" style={{ width: 90, textAlign: "center" }} >{getTime(time)}</Typography>
                    </Box>
                    <Box display="flex" alignItems="center" style={{ gap: "10%" }}>
                        <SvgIcon component={Blade} viewBox="0 0 23.643 23.066" style={{ fontSize: 40 }} />
                        <Typography sx={status==="in-progress" && {color: "#000"}} variant="body2" style={{ width: 50, textAlign: "center" }}>{speed}</Typography>
                    </Box>
                </Button>
            </Box>))
        }
        <PopUpModal open={settingsDialogOpen} onClose={() => setSettingsDialogOpen(false)}>
            <Typography variant="h4" sx={{textAlign: "center"}}>{title}</Typography>
            <HardwareDrive2 getSettings={(e, settings) => handleSettings(settings)} defaultSettings={settings}/>
        </PopUpModal>
    </>)
}

SettingsDisplay.propTypes = {
    settings: PropTypes.arrayOf(PropTypes.shape(
        {
            time: PropTypes.string,
            temperature: PropTypes.string,
            speed: PropTypes.string
        }
    ))
}

export default SettingsDisplay