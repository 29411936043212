import Slider from '@mui/material/Slider'
import { styled } from '@mui/material/styles'

const CustomSlider = (() => {
    const slider = styled(Slider)
    return slider(({ selected, styles }) => {
        return (selected ? {
            '& .MuiSlider-track': {
                height: 15,
                borderRadius: 8,
                ...styles
            },
            '& .MuiSlider-rail': {
                height: 11,
                border: "1.5px solid black",
                borderRadius: 8,
                backgroundColor: "white"
            },
            '& .MuiSlider-thumb': {
                height: 25,
                width: 25,
                color: styles?.color
            }
        } : {
            '& .MuiSlider-track': {
                height: 10,
                borderRadius: 4,
                ...styles
            },
            '& .MuiSlider-rail': {
                height: 7,
                border: "1.5px solid black",
                borderRadius: 4,
                backgroundColor: "white"
            },
            '& .MuiSlider-thumb': {
                height: 20,
                width: 20,
                color: styles?.color
            }
        })
    })
})()

export default CustomSlider