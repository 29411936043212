import { Card, CardMedia, Skeleton } from "@mui/material"
import React from "react"
import PropTypes from "prop-types"


const ListImage = ({ image, loading }) => {
  return (
    <Card >
      {loading ? <Skeleton variant="rectangular" height={200} animation="wave" /> :
        <CardMedia
          component="img"
          height={200}
          src={image}
          alt="recipe"
        />
      }
    </Card>
  );
};

ListImage.propTypes = {
  image: PropTypes.string.isRequired
}

export default ListImage;
//
