import { Box } from '@mui/material'
import React from 'react'

const TriangleBox = ({ height }) => {
    const style = {
        width:0,
        borderBottom: `${height}px solid #ff0000`,
        borderLeft: `${height/2}px solid transparent`,
        borderRight: `${height/2}px solid transparent`,
        marginTop: 0,  
    }

    return(
        <Box style={style} />
    )
}

export default TriangleBox