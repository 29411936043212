import { TextField, Grid, Select, MenuItem, Divider, Typography, Menu } from "@mui/material";
import React from "react";
import IdInput from "./customInputs/IdInput";
import AddIngredients from "./subStepTypes/AddIngredients";
import RunR1 from "./subStepTypes/RunR1";
import ImgUtilityRender from "./subStepTypes/ImgUtilityRender"
import UrlUtilityRender from "./subStepTypes/UrlUtilityRender";
import CriticalStep from "./subStepTypes/CriticalStep";
import RatingCollector from "./subStepTypes/RatingCollector";
import AddIngredientsWithAlternate from "./AddIngredientsWithAlternate";


const SubStepEditSwitcher = ({ type, parameters, setParameters, localRecipeJson }) => {
  switch (type) {
    case "addIngredients":
      return <AddIngredients parameters={parameters} setParameters={setParameters} />
    case "cut":
    case "saute":
    case "cook":
    case "boil":
    case "mix":
    case "roast":
    case "clean":
      return <RunR1 parameters={parameters} setParameters={setParameters} localRecipeJson={localRecipeJson} />
    case "criticalStep":
      return <CriticalStep parameters={parameters} setParameters={setParameters} />
    case "urlUtility":
      return <UrlUtilityRender parameters={parameters} setParameters={setParameters} />
    case "imgUtility":
      return <ImgUtilityRender parameters={parameters} setParameters={setParameters} />
    case "scrape":
    case "drain":
      return ""
    case "ratingCollector":
      return <RatingCollector parameters={parameters} setParameters={setParameters} />
    case "addIngredientsWithAlternate":
      return <AddIngredientsWithAlternate parameters={parameters} setParameters={setParameters} />
    default:
      return <Typography>{JSON.stringify(parameters)}</Typography>
  }
}

const SubStepRender = ({ subStepData, onSubStepChange, localRecipeJson = [] }) => {
  const { id, type, name, parameters } = subStepData
  const updateParameters = newParameters => onSubStepChange({ ...subStepData, parameters: newParameters })
  return <Grid container>
    <Grid item xs={4}>
      <IdInput id={id} setId={newId => onSubStepChange({ ...subStepData, id: newId })} />
    </Grid>
    <Grid item xs={4}>
      <Select
        value={type} label="Type"
        size="small" onChange={e => onSubStepChange({ ...subStepData, type: e.target.value })}
      >
        <MenuItem value="addIngredients">Add Ingredients</MenuItem>
        <MenuItem value="scrape">Scrape</MenuItem>
        <MenuItem value="cut">Cut</MenuItem>
        <MenuItem value="saute">Saute</MenuItem>
        <MenuItem value="cook">Cook</MenuItem>
        <MenuItem value="boil" >Boil</MenuItem>
        <MenuItem value="mix" >Mix</MenuItem>
        <MenuItem value="roast" >Roast</MenuItem>
        <MenuItem value="strain" >Strain</MenuItem>
        <MenuItem value="clean" >Clean</MenuItem>
        <MenuItem value="imgUtility" >ImgUtility</MenuItem>
        <MenuItem value="urlUtility" >UrlUtility</MenuItem>
        <MenuItem value="criticalStep" >CriticalStep</MenuItem>
        <MenuItem value="ratingCollector">RatingCollector</MenuItem>
        <MenuItem value="addIngredientsWithAlternate">AddIngredientsWithAlternate</MenuItem>
      </Select>
    </Grid>
    <Grid item xs={4}>
      <TextField
        value={name}
        onChange={(e) => onSubStepChange({ ...subStepData, name: e.target.value })}
        size="small" label="Sub Step Title"
      />
    </Grid>
    <Divider />
    <Grid item xs={12}>
      <SubStepEditSwitcher localRecipeJson={localRecipeJson} type={type} parameters={parameters} setParameters={updateParameters} />
    </Grid>
  </Grid >
};

export default SubStepRender;
