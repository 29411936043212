import { useState, useCallback, useEffect } from 'react'
import {
    defaultServiceUUID,
    defaultCharacteristicUUIDMain,
    defaultCharacteristicUUIDWrite
} from './bleServiceAndCharUUIDs'

import useBluetoothChar from './bleCharHook'

//FOr persistant BLE permissions
//https://docs.google.com/document/d/1RF4D-60cQJWR1LoQeLBxxigrxJwYS8nLOE0qWmBF1eo/edit


const useBluetoothForHardware = (
    settings = { useBuffer: true, autoConnect: "any" },
    serviceUUID = defaultServiceUUID,
    characteristicUUIDWrite = defaultCharacteristicUUIDWrite,
    characteristicUUID1 = defaultCharacteristicUUIDMain,
    characteristicUUID2,
    characteristicUUID3,
    characteristicUUID4
) => {
    const [device, setDevice] = useState(undefined)
    const [server, setServer] = useState(undefined)
    const [service, setService] = useState(undefined)
    const [characteristicWrite, setCharacteristicWrite] = useState(undefined)
    const { value: value1 } = useBluetoothChar(service, characteristicUUID1, true, settings)
    const { value: value2 } = useBluetoothChar(service, characteristicUUID2, true, settings)
    const { value: value3 } = useBluetoothChar(service, characteristicUUID3, true, settings)
    const { value: value4 } = useBluetoothChar(service, characteristicUUID4, true, settings)

    const disconnect = useCallback(() => {
        server?.disconnect()
        setDevice(null)
        setServer(null)
        setService(null)
    }, [server])

    //getting the device
    const connect = useCallback(() => {
        const { autoConnect } = settings
        const options = {
            optionalServices: [serviceUUID],
            filters: !autoConnect || autoConnect === "any" ? [{ namePrefix: "React" }] : [{ "name": settings.autoConnect }]
        }
        async function getDevice() {
            try {
                setDevice(await navigator.bluetooth.requestDevice(options))
            } catch (err) {
                console.warn("CookClub BT request Device has thrown an error", err)
            }
        }
        getDevice()
    }, [serviceUUID])

    const autoConnect = autoConnectDeviceName => {
        async function getDevice() {
            try {
                const availableDevices = await navigator.bluetooth.getDevices()
                const targetDevice = availableDevices.find(ad => ad.name === autoConnectDeviceName)
                const tryConnect = async (availableDevice) => {
                    const abortController = new AbortController()
                    try {
                        await availableDevice.watchAdvertisements({ signal: abortController.signal })
                        availableDevice.addEventListener('advertisementreceived', async (evt) => {
                            // Stop the scan to conserve power on mobile devices.
                            abortController.abort()
                            setDevice(evt.device)
                        }, { once: true })
                    }
                    catch { }
                }
                if (targetDevice)
                    tryConnect(targetDevice)
            } catch { }
        }
        getDevice()
    }
    //getting the server on getting device
    useEffect(() => {
        if (device)
            device.addEventListener('gattserverdisconnected', disconnect)
        async function getServer() {
            if (device && !server) {
                setServer(await device.gatt?.connect())
            }
        }
        getServer()
    }, [device, server, disconnect])
    //getting the service form the server
    useEffect(() => {
        async function getPrimaryService() {
            if (server) {
                setService(await server.getPrimaryService(serviceUUID))
            }
        }
        getPrimaryService()
    }, [server])

    useEffect(() => {
        async function getCharacteristicWrite() {
            if (service) {
                try {
                    const charW = await service.getCharacteristic(characteristicUUIDWrite)
                    setCharacteristicWrite(charW)
                } catch (err) {
                    console.error("BlueToothWrite Characteristic Fetch Failed:", err)
                }
            }
        }
        getCharacteristicWrite()
    }, [service, characteristicUUID1, characteristicUUID2, characteristicUUID3, characteristicUUID4, characteristicUUIDWrite])

    const writeToBluetooth = useCallback((text = "React") => {
        async function writeValueAsync() {
            try {
                const encoder = new TextEncoder()
                await characteristicWrite?.writeValue(encoder.encode(text))
            } catch (err) {
                console.error("BlueToothWrite Failed:", err)
            }
        }
        writeValueAsync()
    }, [characteristicWrite])
    const connected = device ? device.gatt.connected ? device.name : false : false
    if (!connected && settings?.autoConnect !== "any")
        autoConnect(settings.autoConnect)

    return [connect, writeToBluetooth, value1, connected, disconnect, value2, value3, value4]
}

export default useBluetoothForHardware