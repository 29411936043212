import { Typography, Box, Button } from "@mui/material";
import React from "react";
import "../css/experience2.css"
import ServingBtn from "./recipeSteps/ServingBtn";
import "../css/titlePage.css"

const TitleSlide = ({
    url = "",
    name,
    prepTime,
    cookTime,
    description,
    minServing = 1,
    maxServing = 4,
    serving = 2,
    setServing,
    categories = [],
    tags = [],
    onStartCooking = () => { },
    allergens,
    totalMacros
}) => {

    const changeServing = (type) => {
        switch (type) {
            case "increase":
                setServing(serving + 1)
                break;
            case "decrease":
                setServing(serving - 1)
                break;
        }
    }

    return (
        <Box
            sx={{ position: "relative", height: "100vh", display: "flex" }}
        >
            < Box
                sx={{
                    backgroundImage: `url(${url})`,
                    backgroundPosition: "right",
                    backgroundSize: "50% 100%",
                    backgroundRepeat: "no-repeat",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    display: "flex",
                    padding: "1% 2%",
                    boxSizing: "border-box",
                }}>
                <Box
                    sx={{
                        height: "80%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between"
                    }}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column"
                        }}>
                        <Box sx={{ display: "flex", gap: 2 }}>
                            {categories.map(i => <Typography variant="body2">{i}</Typography>)}|
                            {tags.map(i => <Typography variant="body2">{i}</Typography>)}
                        </Box>
                        <Typography variant="h2">{name}</Typography>
                        <Box width="50%">
                            <Typography variant="body2" >{description}</Typography>
                        </Box>
                    </Box>
                    <Box>
                        <Typography variant="body1">Estimated Time</Typography>
                        <Typography variant="h3">{`${prepTime + cookTime} mins`}</Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography variant="body1">Serves</Typography>
                        <Box className="serving-info">
                            <ServingBtn
                                changeServing={changeServing}
                                serving={serving}
                                disableIncrease={serving === maxServing}
                                disableDecrease={serving === minServing}
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            bottom: 20,
                            padding: "0 1%",
                            width: "100%",
                            position: "absolute",
                            alignItems: "flex-end",
                            justifyContent: "space-between",
                            boxSizing: "border-box",
                            right: 0
                        }}>
                        <Typography variant="body2" sx={{ width: "46%" }}>
                            {`Contains potential allergens- ${allergens.join(", ")}`}
                        </Typography>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, padding: "0 40px" }}>
                            <Box
                                sx={{
                                    display: "flex",
                                    color: "white",
                                    backgroundColor: "black",
                                    opacity: "0.6",
                                    borderRadius: 5,
                                    padding: "17px 0",
                                }}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        borderRight: "3px solid white",
                                        padding: "0 20px",
                                        alignItems: "center"
                                    }}
                                >
                                    <Typography variant="body1">Calories</Typography>
                                    <Typography variant="caption">{totalMacros?.calorie}</Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        borderRight: "3px solid white",
                                        padding: "0 20px",
                                        alignItems: "center"
                                    }}>
                                    <Typography variant="body1">Carbs</Typography>
                                    <Typography variant="caption">{`${totalMacros?.carbs}g`}</Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        borderRight: "3px solid white",
                                        padding: "0 20px",
                                        alignItems: "center"
                                    }}>
                                    <Typography variant="body1">Fats</Typography>
                                    <Typography variant="caption">{`${totalMacros?.fat}g`}</Typography>
                                </Box>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    sx={{ padding: "0 20px", alignItems: "center" }}>
                                    <Typography variant="body1">Proteins</Typography>
                                    <Typography variant="caption">{`${totalMacros?.protein}g`}</Typography>
                                </Box>
                            </Box>
                            <Button
                                onClick={onStartCooking}
                                color="primary"
                                variant="contained"
                                className="start-cooking-btn"
                            >
                                <Typography variant="button">Get Started</Typography>
                            </Button>
                        </Box>
                    </Box>
                </Box >
            </Box>
        </Box>)
}

export default TitleSlide