import React from 'react'
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types"

const ListCard = ({title, image, onClick = () => { }}) => {
  return (
    <Card style={{ marginBottom: 2}}>
          <CardActionArea style={{ display: "flex"  }} onClick={onClick}>
            <CardMedia
              height={64}
              style={{ width: 64, borderRadius: 2 }}
              component="img"
              src={image}  
            />
            <CardContent style={{flex: '1 0 auto', marginLeft: 2}}>
              <Typography variant='h6'>{title}</Typography>
            </CardContent>
          </CardActionArea>
        </Card>
  )
}

ListCard.propTypes =  {
  title: PropTypes.string,
  image: PropTypes.string,
  onClick: PropTypes.func
} 

export default ListCard