import React, { useContext, useState } from "react";
import { Rating, Box, Typography, IconButton, Button } from "@mui/material";
import AccordionDisplay from "./AccordianDisplay";
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import { AuthContext } from "../../firebase/context";
import { GlobalSettingsContext } from "../../utils/globalSettings/globalSettingsContext";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import RecommendRoundedIcon from '@mui/icons-material/RecommendRounded';
import useFirestore from "../../firebase/useFirebase";
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';

const customIconStyle = { fontSize: 100, maxWidth: "100%" }

const customIcons = {
    1: {
        icon: <SentimentVeryDissatisfiedIcon sx={customIconStyle} />,
    },
    2: {
        icon: <SentimentDissatisfiedIcon sx={customIconStyle} />,
    },
    3: {
        icon: <SentimentSatisfiedIcon sx={customIconStyle} />,
    },
    4: {
        icon: <SentimentSatisfiedAltIcon sx={customIconStyle} />,
    },
    5: {
        icon: <SentimentVerySatisfiedIcon sx={customIconStyle} />,
    },
};

const StartRating = ({ maxValue, onSelectRating, subStepId }) => {
    return (<Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Rating
            key={subStepId}
            name={subStepId}
            defaultValue={0}
            max={maxValue}
            icon={<StarIcon sx={{ fontSize: 100, maxWidth: "100%" }} />}
            emptyIcon={<StarBorderIcon sx={{ fontSize: 100, maxWidth: "100%" }} />}
            onChange={onSelectRating}
        />
    </Box>)
}

const HeartRating = ({ maxValue, onSelectRating, subStepId }) => {
    return (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <Rating
                key={subStepId}
                name={subStepId}
                defaultValue={0}
                max={maxValue}
                icon={<FavoriteIcon sx={{ fontSize: 100, maxWidth: "100%", color: "#ff3d47" }} />}
                emptyIcon={<FavoriteBorderIcon sx={{ fontSize: 100, maxWidth: "100%" }} />}
                onChange={onSelectRating}
            />
        </Box>
    )
}

const SentimentRating = ({ onSelectRating, subStepId }) => {
    return (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center", boxSizing: "border-box" }}>
            <Rating
                key={subStepId}
                name={subStepId}
                defaultValue={0}
                IconContainerComponent={({ value, ...rest }) => {
                    return (<Box {...rest}>
                        {customIcons[value].icon}
                    </Box>)
                }}
                onChange={onSelectRating}
                highlightSelectedOnly
            />
        </Box >)
}

const ThumbsRating = ({ onSelectRating, subStepId }) => {
    return (<Box
        key={subStepId}
    >
        <IconButton value={1} onClick={onSelectRating}>
            <RecommendRoundedIcon style={{ color: "#00FF00", fontSize: 100 }} />
        </IconButton>
        <IconButton style={{ transform: "rotate(180deg)" }} value={0} onClick={onSelectRating}>
            <RecommendRoundedIcon style={{ color: "#FF0000", fontSize: 100 }} />
        </IconButton>
    </Box >)
}

const RatingsDisplay = ({ iconType, ratingType = "", subStepId, displayText = "", setDisplayRatingText }) => {
    const { user } = useContext(AuthContext)
    const { id: recipeId } = useParams()
    const { globalSettings } = useContext(GlobalSettingsContext)
    const sessionId = sessionStorage.getItem("sessionId")
    const { saveNewData, data, updateData } = useFirestore("userRating")

    function onSelectRating(e) {
        const ratingVal = Number(e.currentTarget.value) || 0
        setDisplayRatingText(ratingVal)
        const recipeData = {
            userId: user.uid,
            recipeId,
            subStepId: subStepId,
            globalSettings,
            sessionId,
            ratingType,
            displayText,
            iconType
        }
        const existingData = data.find(i => i.userId === user.uid && i.subStepId === subStepId && i.recipeId === recipeId)
        if (existingData) {
            updateData({ ...recipeData, rating: [...existingData.rating, ratingVal] }, existingData.id)
        }
        else {
            saveNewData({ ...recipeData, rating: [ratingVal] })
        }
    }

    switch (iconType) {
        case "star5": {
            return (<StartRating maxValue={5} onSelectRating={onSelectRating} subStepId={subStepId} />)
        }
        case "star10": {
            return (<StartRating maxValue={10} onSelectRating={onSelectRating} subStepId={subStepId} />)
        }
        case "heart5": {
            return (<HeartRating maxValue={5} onSelectRating={onSelectRating} subStepId={subStepId} />)
        }
        case "heart10": {
            return (<HeartRating maxValue={10} onSelectRating={onSelectRating} subStepId={subStepId} />)
        }
        case "satisfaction": {
            return (<SentimentRating onSelectRating={onSelectRating} subStepId={subStepId} />)
        }
        case "thumbs": {
            return (<ThumbsRating onSelectRating={onSelectRating} subStepId={subStepId} />)
        }
        default: {
            return null
        }
    }
}

const ratingTextType = {
    text1: "hated it",
    text2: "not nice",
    text3: "was okay",
    text4: "liked it",
    text5: "loved it",
    default: null
}

const RatingCollectorType = ({ parameters, title, subStepId }) => {
    const [open, setOpen] = useState(true)
    const [displayRatingText, setDisplayRatingText] = useState(null)

    const changeRatingDisplayText = (rating) => {
        const { iconType } = parameters
        if (iconType === "star5" || iconType === "heart5" || iconType === "satisFaction") {
            setDisplayRatingText(ratingTextType[`text${rating}`])
        }
        else if (iconType === "star10" || iconType === "heart10") {
            setDisplayRatingText(ratingTextType[`text${Math.ceil(rating / 2)}`])
        }
        else if (iconType === "thumbs") {
            rating === 0 ? setDisplayRatingText(ratingTextType["text1"]) : setDisplayRatingText(ratingTextType["text5"])
        }
        else
            setDisplayRatingText(ratingTextType.default)
    }

    return (<AccordionDisplay
        title={title}
        openAccordion={open}
        setExpanded={() => setOpen(old => !old)}
        details={<>
            {parameters?.displayText && <Typography variant="h5">{parameters.displayText}</Typography>}
            {displayRatingText && <Typography>{displayRatingText}</Typography>}
            <RatingsDisplay
                iconType={parameters.iconType}
                ratingType={parameters?.ratingType}
                setDisplayRatingText={rating => changeRatingDisplayText(rating)}
                displayText={parameters?.displayText}
                subStepId={subStepId} />
        </>}
    />)
}

export default RatingCollectorType