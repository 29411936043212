import React from 'react'
import { Container, Typography } from '@mui/material'

const NotFound404 = () => {
    return (
        <Container>
            <Typography variant="h2">Error 404: Page not Found</Typography>
        </Container>
    )
}

NotFound404.propTypes = {

}

export default NotFound404
