import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import useRecipeListFromDB from '../../utils/RecipeBackEnd/loadRecipeList'

const DuplicateRecipe = ({ setJson, collection="recipe2", getNameFromRecipe }) => {
    const { recipeList, loading } = useRecipeListFromDB(collection, null, getNameFromRecipe)
    
    function createCopy (recipe) {
        const recipeWithSameName = recipeList.filter(i => i.metadata.name.includes(recipe.metadata.name)) 
        const newRecipeName = `${recipe.metadata.name} Copy ${recipeWithSameName.length}`
        const newRecipe = {...recipe, metadata: {...recipe.metadata, name: newRecipeName}}
        setJson(newRecipe)
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent={loading ? "center" : "flex-start"}
            style={{ width: "50vh", height: "50vh" }}>
            {loading ?
                <Typography
                    align="center"
                >Loading</Typography> :
                recipeList.map(i => <Button
                    key={i.id} value={i.id}
                    onClick={() => createCopy(i)}
                >{getNameFromRecipe(i)}
                </Button>)}
        </Box>
    )
}

DuplicateRecipe.propTypes = {
    setJson: PropTypes.func,
    collection: PropTypes.string,
    getNameFromRecipe: PropTypes.func
}

export default DuplicateRecipe
