import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { Button, Typography, Grid } from '@mui/material'
import HardwareCore from '../HardwareCore'
import PopUpDialog from '../PopUpDialog'
import WeightUI from './WeightUI'
import useHardware from '../../utils/hardware/hardwareState'
import Timer from './Timer'
import { TimerContext } from '../../pages/TimerContext'
import useLongPress from '../../utils/useLongPress'

const R1ButtonInners = ({ connected, status, lastTime }) => {
    const imageR1 = <img
        src="https://res.cloudinary.com/prodjour/image/upload/v1630327255/ReactLogos/react-icon-3pt_puizyn.png"
        alt="R1"
        width="50vh"
    />
    const { setCurrentTime } = useContext(TimerContext)
    const [timerKey, setTimerKey] = useState(0)
    useEffect(() => {
        if (status === "operation")
            setTimerKey(old => old + 1)
    }, [status])
    if (connected && ["operation", "starting", "cooldown"].includes(status)) {
        return (<Timer
            timerKey={timerKey}
            duration={lastTime ? lastTime : 0}
            isPlaying={status === "operation"}
            setCurrentTime={setCurrentTime}
            style={{ color: "white" }}
            size={0}
        />)
    }
    return imageR1
}

const HardwareGraphic = ({
    react1Settings,
    moveToNextSlide,
    isStartR1Slide = false,
    popUpR1Settings,
    popUpWeight,
    dispatch,
    scaleSettings,
}) => {
    const { connectToHardware,
        triggerR1,
        stateOfHardware, connected,
        forceStop, weight, time
    } = useHardware()

    const popUpTimeout = 700
    const connectOnClick = () => connectToHardware()
    const [bleSettingsPopUp, setBleSettingsPopUp] = useState(false)

    useEffect(() => dispatch({ type: "SET-HARDWARESTATE", data: (stateOfHardware.status) }), [stateOfHardware.status, dispatch])

    const onSendInstruction = (r1Settings, startingR1Time) => {
        triggerR1(r1Settings)
        setTimeout(() => dispatch({ type: "CLOSE-R1SETTINGS" }), startingR1Time * 1000)
        setTimeout(() => moveToNextSlide(), popUpTimeout * 0.85)
    }

    const longPressEvent = useLongPress(() => setBleSettingsPopUp(true), connected ? () => dispatch({ type: "OPEN-R1SETTINGS" }) : connectOnClick)

    return <>
        <Button
            variant="contained"
            color={connected ? "primary" : "secondary"}
            {...longPressEvent}
            style={{ height: "9vh" }}
            disabled={true}
        >
            <R1ButtonInners
                connected={connected}
                status={stateOfHardware.status}
                lastTime={stateOfHardware?.lastTime}
            />
        </Button>
        <WeightUI
            weight={weight}
            popUpStatus={popUpWeight}
            setScaleSettings={(data) => dispatch({ type: "SET-SCALESETTINGS", data })}
            text={scaleSettings?.text}
            img={scaleSettings?.img}
        />
        <PopUpDialog
            dialogOpen={popUpR1Settings}
            onClickBack={() => dispatch({ type: "CLOSE-R1SETTINGS" })}
            timeout={popUpTimeout}
            componentInDialog={
                <HardwareCore
                    connectToSerial={connectToHardware}
                    sendHardwareInstruction={onSendInstruction}
                    forceStopHardware={forceStop}
                    hardwareState={stateOfHardware}
                    connected={connected}
                    connectUI={false}
                    settings={react1Settings}
                    hardwareTimeLeft={time}
                    isStartR1Slide={isStartR1Slide}
                    setCompletedSteps={() => dispatch({ type: "SET-COMPLETEDSTEPS" })}
                />
            }
        />
        <PopUpDialog
            dialogOpen={bleSettingsPopUp}
            onClickBack={() => setBleSettingsPopUp(false)}
            componentInDialog={
                <Grid container style={{ height: "90vh" }} alignItems="center" justifyContent="center" direction="column">
                    <Typography variant="h3">{connected}</Typography>
                    <Button color="primary" onClick={connectOnClick} variant="filled">Connect Other</Button>
                </Grid>
            }
        />
    </>;
}

HardwareGraphic.propTypes = {
    react1Settings: PropTypes.instanceOf(Object).isRequired, //eventually use PropTypes.shape
    moveToNextSlide: PropTypes.func,
    singleFocusTimer: PropTypes.bool,
}

export default HardwareGraphic
