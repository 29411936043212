import {
  Box,
  Button,
  Input,
  Typography
} from "@mui/material"
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import React, { useState } from "react"
import useIngredientsDB from "../../utils/hardware/ingredientsDB"
import RecipeJsonEdit from "./RecipeJsonEdit"
import NonTypeObject from "./NonTypeObject"
import { subStepsType } from "./DropdownOptions"
import { allTypes } from "../../utils/experience2/typesVariable"
import { v4 as uuid } from "uuid"

export const AddBtn = ({ onClick = () => { } }) => {
  return <Button variant="contained" onClick={onClick}>Add New Step</Button>
}

export const PasteButton = ({ onPasteStep, propertyExists }) => {
  if (propertyExists.subSteps) {
    return <Button variant="outlined" color="primary" onClick={(e) => onPasteStep("step")}>Paste Step</Button>
  } else if (propertyExists.type) {
    return <Button variant="outlined" color="primary" onClick={(e) => onPasteStep("substep")}>Paste Substep</Button>
  } else if (propertyExists.time) {
    return <Button variant="outlined" color="primary" onClick={(e) => onPasteStep("setting")}>Paste Settings</Button>
  } else if (propertyExists.ingredientId) {
    return <Button variant="outlined" color="primary" onClick={(e) => onPasteStep("ingredient")}>Paste Ingredient</Button>
  } else return null

}

export const DropdownBtn = ({ onAddArrayElement, ingredients, arrType = "ingredientId", onClick = () => { } }) => {
  const [type, setType] = useState('')
  const ingredientField = ingredients.map((i) => {
    return { ingredientName: i.displayName, ingredientId: i.id, prepIds: i.preps };
  });


  const handleTypeChange = () => {
    switch (type) {
      case "addIngredients":
        onAddArrayElement(subStepsType[0], arrType)
        break
      case "saute":
        onAddArrayElement(subStepsType[1], arrType)
        break
      case "cut":
        onAddArrayElement(subStepsType[2], arrType)
        break
      case "scrape":
        onAddArrayElement(subStepsType[3], arrType)
        break
      case "cook":
        onAddArrayElement(subStepsType[4], arrType)
        break
      case "boil":
        onAddArrayElement(subStepsType[5], arrType)
        break
      case "mix":
        onAddArrayElement(subStepsType[6], arrType)
        break
      case "imgUtility":
        onAddArrayElement(subStepsType[7], arrType)
        break
      default: setType('')
    }
  }

  const handleAddElement = () => {
    arrType === "type" ?
      handleTypeChange()
      :
      onAddArrayElement((ingredients.find(i => i.displayName === (type))), arrType)

  }

  return (
    <FormControl style={{ display: "flex", flexDirection: "row" }}>
      <InputLabel id="demo-mutiple-name-label">
        {arrType === "ingredientId" ? "Add Ingredients" : "sub step type"}
      </InputLabel>
      <Select
        style={{ flex: 1 }}
        labelId="demo-mutiple-name-label"
        id="demo-mutiple-name"
        value={type}
        onChange={(e) => setType(e.target.value)}
        input={<Input />}
      >
        {arrType === "type"
          ? allTypes.map((name) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))
          : ingredientField.map(({ ingredientName }) => (
            <MenuItem key={ingredientName} value={ingredientName}>
              {ingredientName}
            </MenuItem>
          ))}
      </Select>
      <Button disabled={type === ""} onClick={handleAddElement} variant="contained" color="secondary">
        Add
      </Button>
    </FormControl>
  );
}

export const IngredientUtils = (id) => {
    const { ingredients, saving } = useIngredientsDB()
    const getIngredients = (id) => {
      const ingredient = ingredients.find(i => i.id === id)
      return ingredient ? ingredient.displayName : null
  }
}

export const onAddArrayElement = (onStepsChange, stepsData, defaultAddJson, index, type) => {
  const arr1 = stepsData.splice(0, index+1)
  const arr2 = stepsData.splice(0, stepsData.length)
  if (type === "step") {
    onStepsChange([...arr1, {
      ...defaultAddJson, id: uuid(),
      subSteps: [...defaultAddJson.subSteps.map(i => {return { ...i, id: uuid()}})] 
    }, ...arr2]);
  } else if (type === "substep") {
    onStepsChange([...arr1, {
      ...defaultAddJson, id: uuid(),
    }, ...arr2]);
  } else if (type === "setting") {
    onStepsChange([...arr1, defaultAddJson, ...arr2])
  }
}
