import React from 'react'
import PropTypes from 'prop-types'
import TitleStrip from './elements/TitleStrip'
import InstructionSet from './elements/InstructionSet'
import OffsetMedia from './elements/OffsetMedia'
import PostitNote from './elements/PostitNote'
import ActionButtonSet from './elements/ActionButtonSet'
import SingleFocus from './SingleFocus'

const InstructionFeed = ({ instructions,
    timerDispatch, title = null,
    titleStyleData, isCurrentSlide = false,
    backgroundMedia = null, note = null,
    actionButtons = [], setReact1Settings,
    centerText = null, setScaleSettings, stepCompleted, floatingBtnsFunctions }) => {
    return (
        <>
            {backgroundMedia ? <OffsetMedia
                playmedia={isCurrentSlide}
                url={backgroundMedia.url}
                offsetData={backgroundMedia?.offsetData}
                trim={backgroundMedia?.trim}
                mute={"mute" in backgroundMedia ? backgroundMedia.mute : false}
                loop={backgroundMedia?.loop ? backgroundMedia.loop : false}
            /> : <></>
            }
            {title ? <TitleStrip
                title={titleStyleData}
                slideTitle={title}
            /> : <></>
            }
            <InstructionSet instructions={instructions} timerDispatch={timerDispatch} setReact1Settings={setReact1Settings} setScaleSettings={setScaleSettings} stepCompleted={stepCompleted} />
            {note ? <PostitNote text={note.text} location={note.location} /> : <></>}
            <ActionButtonSet actionButtons={actionButtons} setReact1Settings={setReact1Settings} floatingBtnsFunctions={floatingBtnsFunctions} />
            {centerText ? <SingleFocus text={centerText} /> : <></>}
        </>
    )
}

InstructionFeed.propTypes = {
    instructions: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
    isCurrentSlide: PropTypes.bool,
    mediaUrl: PropTypes.string,
    note: PropTypes.object,
    actionButtons: PropTypes.arrayOf(PropTypes.object),
    timerDispatch: PropTypes.func,
    setReact1Settings: PropTypes.func,
}

export default InstructionFeed
