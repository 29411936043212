import React from "react";
import StepsDescription from "./StepsDescription";
import PropTypes from 'prop-types';
import R1Buttons from "./R1Buttons";
import { Box } from "@mui/material";
import RecipeTimer from "./RecipeTimer";
import { createSettings } from "../../utils/experience2/stepsInstruction";
import ImageUtilityBox from "./ImageUtilityBox";
import ParallelPrepDisplay from "./ParallelPrepDisplay";

const UtilityType = ({ parameters, title, status, type, dispatch, subStepId, servingSize }) => {
    const r1Settings = createSettings(parameters?.settings)

    return (
        <StepsDescription
            title={title}
            details={<>
                <ImageUtilityBox src={parameters?.url} style={parameters?.style} />
                {status === "in-progress" ? <>
                    <RecipeTimer
                        initialTime={r1Settings.totalTime}
                        active={status === "in-progress"}
                    />
                    <ParallelPrepDisplay {...parameters?.parallelPrep} dispatch={dispatch} />
                </> : null}
            </>}
            button={r1Settings?.settings.length ?
                <Box style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    <R1Buttons
                        dispatch={dispatch}
                        subStepId={subStepId}
                        inProgress={status === "in-progress"}
                        servingSize={servingSize}
                    />
                </Box> : null}
        />
    )
}

UtilityType.propTypes = {
    parameters: PropTypes.object,
    title: PropTypes.string,
    status: PropTypes.string,
    type: PropTypes.string,
    dispatch: PropTypes.func,
    stepId: PropTypes.string
}

export default UtilityType