import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'

const flameConfig = [{ id: "low", height: "50%" }, { id: "medium", height: "60%" }, { id: "high", height: "80%" }]

const FlameGraphic = ({ flameStatus = "off", heightOfBar }) => {
    return flameStatus === "off" ? <></> : (
        <Box
            position="fixed"
            height={heightOfBar}
            bottom={heightOfBar}
            display="flex"
            bgcolor="#7D891D"
            alignItems="flex-end"
            zIndex={6}
            borderRadius="0px 15px 0px 0px"
        >
            {flameConfig.map(({ id, height }) => <img
                src="https://res.cloudinary.com/prodjour/image/upload/v1627442242/icons/FlameOn_psdtdn.png"
                alt={id}
                key={id}
                height={height}
                style={id === flameStatus ? {} : { filter: "grayscale(100%)" }}
            />)}
        </Box>
    )
}

FlameGraphic.propTypes = {
    flameStatus: PropTypes.oneOf(["off", "low", "medium", "high"])
}

export default FlameGraphic
