import React from 'react'
import PropTypes from 'prop-types'
import DynamicSlide from './DynamicSlide'
import CaroselAbsraction from '../../utils/CaroselAbsraction'

const MultiSlideDisplay = ({
    slides, floatingBtnsFunctions,
    react1Settings = {}, hardwareState, progressInfo,
    typeSpecificInformation, dispatch = () => { },
    btnFunctions = () => { }, effect = "slide"
}) => {
    return (
        <>
            <CaroselAbsraction
                setSwiperInstance={(data) => dispatch({ type: "SET-SWIPER", data })}
                onSlideChange={(swiper) => dispatch({ type: "ON-SLIDE-CHANGE", swiper })}
                effect={effect}
                slides={slides.map(({ type, data, id }) => (isActive => <DynamicSlide
                    id={id}
                    type={type}
                    slideData={data}
                    isCurrent={isActive}
                    floatingBtnsFunctions={floatingBtnsFunctions}
                    react1Settings={react1Settings}
                    hardwareState={hardwareState}
                    progressInfo={progressInfo}
                    dispatch={dispatch}
                    btnFunctions={btnFunctions}
                    typeSpecificInformation={typeSpecificInformation}
                />))}>
            </CaroselAbsraction>
        </>
    )
}

MultiSlideDisplay.propTypes = {
    slides: PropTypes.array.isRequired,
    onSlideChange: PropTypes.func,
}

export default MultiSlideDisplay
