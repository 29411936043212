import React from "react";
import { Button, Typography } from "@mui/material";


const IngredientsClass = ({ value, text, onClickBtn, btnClass, display }) => {
    return (
        <>
            {display ? <Button
                value={value}
                onClick={onClickBtn}
                className={btnClass}
            >
                <Typography variant="body2">{text}</Typography>
            </Button> : null}
        </>
    )
}

export default IngredientsClass