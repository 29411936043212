import {
    DISPLAY_DESCRIPTION,
    DISPLAY_EXTRA_PREP,
    DISPLAY_TIMER_INSTRUCTION,
    DISPLAY_NON_TIMER_INSTRUCTION,
    DISPLAY_UTILITIES,
    URL_UTILITY,
    CRITICAL_STEP,
    RATING_COLLECTOR
} from "./displayTypes"
import {
    timerInstructions,
    description,
    imgUtility,
    extraPrep,
    nonTimerInstruction,
    noWeighingUnit,
    urlUtility,
    criticalStep,
    ratingCollector
} from "./typesVariable"

const lc = 2

export const getTime = (time) => {
    const secs = time % 60
    const strSecs = secs < 10 ? "0" + secs.toString() : secs.toString()
    const mins = Math.floor(time / 60)
    return `${mins.toString()}:${strSecs}`
}

export const getDuration = (status, initialTime, operationStartTime) => {
    const currTime = Math.floor((Date.now() - operationStartTime) / 1000);
    return (["operation", "cooldown"].includes(status)) ? Math.max(initialTime - currTime, 0) : initialTime || 0
}

export const createSettings = (r1Settings) => {
    if (r1Settings) {
        const checkValidity = r1Settings.find(i => i?.temperature === "" || i?.speed === "" || i?.time === "")
        if (!checkValidity) {
            const timeSum = r1Settings.reduce((prev, { time: next }) => prev + Number(next), 0)
            return {
                settings: r1Settings,
                totalTime: timeSum
            }
        }
        return undefined
    }
}

const createNewRecipeData = (recipeData, currStep, newSubSteps) => {
    const completed = newSubSteps.filter(i => i?.progress !== "completed").length === 0
    const newRecipeData = recipeData.reduce((prev, next) => {
        if (next.id === currStep.id && completed)
            return [...prev, { ...next, subSteps: newSubSteps, status: "completed", priority: "deprioritised" }]
        else if (next.id === currStep.id) {
            return [...prev, { ...next, subSteps: newSubSteps, status: "in-progress", priority: "immediate" }]
        }
        else if (prev.at(-1)?.priority === "deprioritised")
            return [...prev, { ...next, priority: "immediate" }]
        return [...prev, next]
    }, [])
    return newRecipeData

}

export const setSubStepStatusForTriggerR1 = (recipeData, subStepId) => {
    const currStep = recipeData.find(step => step.active)
    const { subSteps } = currStep
    const currSubStepIndex = subSteps.findIndex(i => i.id === subStepId)
    const newSubSteps = subSteps.map((i, index) => {
        if (index < currSubStepIndex && !timerInstructions.includes(i.type)) {
            return { ...i, progress: "completed" }
        }
        else if (i.id === subStepId) {
            return { ...i, progress: "in-progress" }
        }
        return i
    })
    const runningInstruction = subStepId && subSteps.find(i => i.id === subStepId)
    const instruction = runningInstruction?.parameters?.overruledSettings || runningInstruction.parameters.settings

    const settings = instruction && createSettings(instruction)

    const newRecipeData = createNewRecipeData(recipeData, currStep, newSubSteps)
    return { r1Settings: settings, newRecipeData }
}

export const getR1Buttons = (recipeData) => {
    const allR1Buttons = recipeData.reduce((prev, next) => {
        const { subSteps } = next
        const R1Buttons = subSteps.filter((i, index) => {
            return (timerInstructions.includes(i.type))
        })
        return [...prev, ...R1Buttons]
    }, [])
    return allR1Buttons
}

export const setStatusForCurrentR1Button = (recipeData, subStepId) => {
    const priorityStep = recipeData.find(i => i.priority === "immediate")
    const { subSteps } = priorityStep
    const subStepIndex = subSteps.findIndex(i => i.id === subStepId)
    const firstR1Button = subSteps.find(i => timerInstructions.includes(i.type))
    const allR1Buttons = getR1Buttons(recipeData)
    const newRecipeData = recipeData.map((step, index) => {
        const { subSteps } = step
        const indexOfStartStatus = allR1Buttons.findIndex(i => i.parameters?.status === "start")

        const newSubSteps = subSteps.map((i, index) => {
            const { parameters } = i
            // Set the first R1Button status="start"
            if (!subStepId && firstR1Button && index === subSteps.indexOf(firstR1Button) && recipeData.indexOf(step) === 0) {
                return { ...i, parameters: { ...parameters, status: "start" } }
            }
            // sets it to completed after completion
            if (i.id === allR1Buttons.find(v => v.id === i.id)?.id && parameters?.status === "start") {
                return { ...i, parameters: { ...parameters, status: "completed" } }
            }
            // set status="start" to the next R1Button 
            if (i.id === allR1Buttons.find(v => v.id === i.id)?.id && !parameters?.status && allR1Buttons.indexOf(i) === indexOfStartStatus + 1) {
                return { ...i, parameters: { ...parameters, status: "start" } }
            }
            return i
        })


        return { ...step, subSteps: newSubSteps }
    })
    return newRecipeData

}

export const setSubStepStatusForSkipStep = (recipeData, subStepId) => {
    const currStep = recipeData.find(step => step.active)
    const currStepIndex = recipeData.findIndex(step => step.active)
    const { subSteps } = currStep
    const subStepIndex = subSteps.findIndex(i => i.id === subStepId)

    const newRecipeData = recipeData.map((step, i) => {
        const { subSteps } = step
        const newSubSteps = subSteps.map((subStep, j) => {
            const { parameters } = subStep
            if (i < currStepIndex && timerInstructions.includes(subStep.type)) {
                return { ...subStep, parameters: { ...parameters, status: "completed" } }
            }
            if (i === currStepIndex && j < subStepIndex && timerInstructions.includes(subStep.type)) {
                return { ...subStep, parameters: { ...parameters, status: "completed" } }
            }
            if (i === currStepIndex && subStep.id === subStepId) {
                return { ...subStep, progress: "in-progress", parameters: { ...parameters, status: "completed" } }
            }
            return subStep
        })

        if (i < currStepIndex) {
            return { ...step, priority: "deprioritised", subSteps: newSubSteps }
        } else if (i === currStepIndex) {
            return { ...step, subSteps: newSubSteps, priority: "immediate", status: "in-progress" }
        }
        return step
    })

    const newR1Buttons = getR1Buttons(newRecipeData)
    const indexOfSkipStatus = newR1Buttons.filter(i => i.parameters?.status === "completed").length - 1
    const setStartStatus = newRecipeData.map((step, i) => {
        const { subSteps } = step
        const newSubSteps = subSteps.map((subStep, j) => {
            const { parameters } = subStep
            if (newR1Buttons.indexOf(subStep) === indexOfSkipStatus + 1) {
                return { ...subStep, parameters: { ...parameters, status: "start" } }
            }
            return subStep
        })
        return { ...step, subSteps: newSubSteps }
    })

    const runningInstruction = subStepId && subSteps.find(i => i.id === subStepId)
    const instruction = runningInstruction?.parameters?.settings
    const settings = instruction && createSettings(instruction)

    return { r1Settings: settings, newRecipeData: setStartStatus }
}

export const setSubStepStatusOnIdle = (recipeData) => {
    const currStep = recipeData.find(step => step?.status === "in-progress")
    const inProgressSteps = recipeData.filter(i => i.status === "in-progress")
    if (currStep) {
        const { subSteps } = currStep
        const newSubSteps = subSteps.map((i, index) => {
            if (i?.progress === "in-progress" || i?.progress === "paused") {
                return { ...i, progress: "completed" }
            }
            if (inProgressSteps.length > 1) {
                return { ...i, progress: "completed" }
            }
            return i
        })
        const newRecipeData = createNewRecipeData(recipeData, currStep, newSubSteps)
        return newRecipeData
    }
    return recipeData
}

export const updateLastStep = (recipeData) => {
    const currStep = recipeData.find(step => step?.status === "in-progress")
    let rD = recipeData
    const { subSteps } = currStep || { subSteps: [] }
    const notCompletedSteps = subSteps.filter(i => i?.progress !== "completed" && timerInstructions.includes(i.type))
    if (notCompletedSteps.length === 0 && currStep) {
        const updatedProgress = subSteps.map(i => {
            if (!(i?.progress))
                return { ...i, progress: "completed" }
            else
                return i
        })
        const newRecipeData = rD.map(i => {
            if (i.id === currStep.id && (currStep?.status === "in-progress")) {
                return { ...i, subSteps: updatedProgress, status: "completed", priority: "deprioritised" }
            }
            else if (i.id === currStep.id)
                return { ...i, subSteps: updatedProgress }
            return i
        })
        rD = newRecipeData
    }
    const completedSteps = rD.filter(step => step?.status === "completed").length
    if (completedSteps < recipeData.length && !(recipeData[completedSteps]?.priority))
        rD = rD.map((i, index) => index === completedSteps ? { ...i, priority: "immediate" } : i)
    return rD
}

export const addDataForExtraPrep = (recipeData) => {
    const extraPrepSteps = recipeData.reduce((prev, next) => {
        const filteredData = next.subSteps.filter(i => extraPrep.includes(i.type))
        if (filteredData) {
            return [...prev, ...filteredData]
        }
        return prev
    }, [])
    const newData = recipeData.map(i => {
        const newSubs = i.subSteps.map(j => {
            if (description.includes(j.type)) {
                const newItems = j.parameters.items.map(k => {
                    const filteredSetAside = extraPrepSteps.find(l => l?.parameters?.item?.ingredientId === k.ingredientId) // change l?.parameters?.item?.prepedIngredientId  
                    if (filteredSetAside) {
                        return { ...k, prevPrep: { redirectId: filteredSetAside.id, name: filteredSetAside?.parameters?.item?.prepedIngredientName } }
                    }
                    return k
                })
                return { ...j, parameters: { ...j.parameters, items: newItems } }
            }
            return j
        })
        return { ...i, subSteps: newSubs }
    })
    return newData
}

export const getDisplayType = (type) => {
    if (description.includes(type)) return DISPLAY_DESCRIPTION
    else if (nonTimerInstruction.includes(type)) return DISPLAY_NON_TIMER_INSTRUCTION
    else if (timerInstructions.includes(type)) return DISPLAY_TIMER_INSTRUCTION
    else if (imgUtility.includes(type)) return DISPLAY_UTILITIES
    else if (extraPrep.includes(type)) return DISPLAY_EXTRA_PREP
    else if (urlUtility === type) return URL_UTILITY
    else if (criticalStep === type) return CRITICAL_STEP
    else if (ratingCollector === type) return RATING_COLLECTOR
    else return undefined
}

export const addActiveToSubSteps = (recipeData) => {
    if (recipeData) {
        const [firstStep, ...rest] = recipeData
        const allSteps = [{ ...firstStep, active: true, priority: "immediate" }, ...rest]
        return allSteps
    }
    return null
}

export const changeProgressType = (recipeData, subStepId, type) => {
    const currStep = recipeData.find(step => step?.active)
    const { subSteps } = currStep
    const newSubSteps = subSteps.map(i => {
        if (i.id === subStepId) {
            return { ...i, progress: type }
        }
        return i
    })
    const newRecipeData = recipeData.map(i => {
        if (i.id === currStep.id)
            return { ...currStep, subSteps: newSubSteps }
        else
            return i
    })
    return newRecipeData
}

export const changeProgressTypeOnDisconnect = (recipeData, type) => {
    const currStep = recipeData.find(step => step?.status === "in-progress")
    if (currStep) {
        const { subSteps } = currStep
        const newSubSteps = subSteps.map(i => {
            if (i?.progress === "in-progress")
                return { ...i, progress: type }
            else
                return i
        })
        const newRecipeData = recipeData.map(i => {
            if (i.id === currStep.id)
                return { ...currStep, subSteps: newSubSteps }
            else
                return i
        })
        return newRecipeData
    }
    return recipeData
}

const getScaledVal = (baseValue, parameters, scaleType, servingSize) => {
    switch (scaleType) {
        case "linear": {
            const checkedScaleFactor = parameters?.scaleFactor || 0
            const checkedServingSize = servingSize || 0
            return Math.max(Number(baseValue) + (checkedServingSize - 2) * checkedScaleFactor, 0)
        }
        case "hardcode": {
            const { settings } = parameters
            return settings.find((i) => i.servingSize === servingSize)?.time || 0
        }
        default: {
            return baseValue
        }
    }
}

export const changeRecipeOnServing = (recipe, servingSize) => {
    if (recipe) {
        const { steps } = recipe
        const newSteps = steps.map(i => {
            const { subSteps } = i
            const newSubSteps = subSteps.map(j => {
                if (j.type === "addIngredients" || j.type === criticalStep) {
                    const { items } = j.parameters
                    const newItems = items.map(k => {
                        const { scaleModel } = k
                        const usedQty = getScaledVal(k.amount.qty, scaleModel?.parameters, scaleModel?.type, servingSize)
                        return { ...k, overruledAmount: { ...k.amount, qty: usedQty } }
                    })
                    return { ...j, parameters: { ...j.parameters, items: newItems } }
                }
                else if (j.type === "addIngredientsWithAlternate") {
                    const { items } = j.parameters
                    const newItems = items.map(k => {
                        const { ingredients } = k
                        const newIngredients = ingredients.map(l => {
                            if (l?.default) {
                                const { scaleModel } = l
                                const usedQty = getScaledVal(l.amount.qty, scaleModel?.parameters, scaleModel?.type, servingSize)
                                return { ...l, overruledAmount: { ...l.amount, qty: usedQty } }
                            }
                            return l
                        })
                        return { ingredients: newIngredients }
                    })
                    return { ...j, parameters: { ...j.parameters, items: newItems } }
                }
                else if (timerInstructions.includes(j.type) || imgUtility.includes(j.type)) {
                    const { settings } = j.parameters
                    const checkedSettings = createSettings(settings)?.settings
                    const newSettings = checkedSettings.map(l => {
                        const { scaleModel } = l
                        return { ...l, time: getScaledVal(l.time, scaleModel?.parameters, scaleModel?.type, servingSize) }
                    })
                    return { ...j, parameters: { ...j.parameters, overruledSettings: newSettings } }
                }
                else {
                    return j
                }
            })
            return { ...i, subSteps: newSubSteps }
        })
        return { ...recipe, steps: newSteps }
    }
    return recipe

}

export const editSettings = (steps, subStepId, newSettings) => {
    const currStep = steps.find(step => step.active)
    const { subSteps } = currStep
    const newSubSteps = subSteps.map(i => {
        if (i.id === subStepId)
            return { ...i, parameters: { ...i.parameters, settings: newSettings } }
        return i
    })
    const newSteps = steps.map(i => {
        if (i.id === currStep.id) {
            return { ...i, subSteps: newSubSteps }
        }
        return i
    })
    return newSteps
}

export const getInProgressStep = (recipeData) => {
    const currStep = recipeData.find(i => i?.status === "in-progress")
    return currStep
}


export const getIndexOfSteps = (steps, id) => {
    const index = steps.findIndex(i => i.id === id)
    return index
}

export const getCompletedStep = (recipeData) => {
    const currStep = recipeData.filter(i => i?.status === "completed")
    return currStep.at(-1)
}

export const getInFocusSubStepId = (steps, stepId) => {
    const step = steps.find(i => i?.id === stepId)
    if (step) {
        const { subSteps } = step
        const { completedSteps, inProgressSteps } = subSteps.reduce((prev, next) => {
            if (["in-progress", "paused", "paused-on-disconnect"].includes(next?.progress))
                return { ...prev, inProgressSteps: [...prev.inProgressSteps, next] }
            else if (next?.progress === "completed")
                return { ...prev, completedSteps: [...prev.completedSteps, next] }
            else
                return prev
        }, { inProgressSteps: [], completedSteps: [] })
        if (inProgressSteps.length > 0)
            return inProgressSteps.at(-1).id
        else if (completedSteps.length > 0)
            return completedSteps.at(-1).id
        else
            return subSteps.at(0).id
    }
    return undefined
}

export const setStartStatus = (recipeData, subStepId) => {
    const step = recipeData.find(i => i.priority === "immediate")
    const { subSteps } = step
    const newSubSteps = subSteps.map((i) => {
        const { parameters } = i
        if (i.id === subStepId && timerInstructions.includes(i.type)) {
            return { ...i, parameters: { ...parameters, status: "start" } }
        }
        return i
    })
    const newRecipeData = recipeData.map(i => {
        if (i.id === step.id) {
            return { ...step, subSteps: newSubSteps }
        }
        return i
    })
    return newRecipeData
}

export const getInactiveSubStepId = (steps, stepId) => {
    const step = steps.find(i => i?.id === stepId)
    if (step) {
        const { subSteps } = step
        const { completedSteps } = subSteps.reduce((prev, next) => {
            if (next?.progress === "completed") {
                return { ...prev, completedSteps: [...prev.completedSteps, next] }
            } else {
                return prev
            }
        }, { completedSteps: [] })

        const skippedBtnStatus = subSteps.find(i => i.parameters?.status === "resume")
        const skippedSubStepIndex = subSteps.indexOf(skippedBtnStatus)
        if (completedSteps.length > 0 && completedSteps.length < subSteps.length) {
            return subSteps.at(completedSteps.length).id
        } else if (skippedBtnStatus && subSteps.indexOf(skippedBtnStatus) < subSteps.length - 1) {
            return subSteps[skippedSubStepIndex + 1].id
        } else {
            return subSteps.at(0).id
        }
    }
}

export const getCurrentR1ButtonId = (recipeData, subStepId) => {
    const priorityStep = recipeData.find((i) => i.priority === "immediate")
    const { subSteps } = priorityStep
    const subStepIndex = subSteps.findIndex(i => i.id === subStepId)

    const runR1Button = subSteps.find((i, index) => {
        const { parameters } = i
        const R1ButtonSubStep = parameters?.hasOwnProperty("settings")
        return (index <= subStepIndex && R1ButtonSubStep && i.progress !== "completed")
    })
    return runR1Button?.id
}

export const addRatingToLastSubstep = (recipeData) => {
    const lastStep = recipeData.at(-1)
    const ratingCollectorType = [{
        type: "ratingCollector",
        id: "defultCookingExp",
        parameters: {
            iconType: "star5",
            displayText: "How was your cooking experience?",
            ratingType: "Cooking Experience"
        }
    },
    {
        type: "ratingCollector",
        id: "defultRecipeExp",
        parameters: {
            iconType: "star5",
            displayText: "How is the taste?",
            ratingType: "Recipe Taste"
        }
    }]

    const { subSteps } = lastStep
    const lastStepWithRatingCollector = [...subSteps, ...ratingCollectorType]
    const newRecipeData = recipeData.map(i => {
        if (i.id === lastStep.id) {
            return { ...i, subSteps: lastStepWithRatingCollector }
        }
        return i
    })
    return newRecipeData
}