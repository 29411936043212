import { Box, Button } from "@mui/material"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import JsonEditorComponent from "../../utils/JsonEditor/JsonEditorComponent"
import MetadataUI from "./MetadataUI"

const JsonEditorDisplay = ({
  value,
  onChange = () => {},
  saveButton = true,
  title = undefined,
  schema = {
    title: "Default Schema",
    description: "This is the default Schema of the property",
    type: "object",
  },
  recipeJsonProps,
  jsonProperty,
  onCreateMenu = () => {},
}) => {
  const [saveEnable, setSaveEnable] = useState(false);
  const [tempJson, setTempJson] = useState({ error: "nothing came in" });


  useEffect(() => {
    setTempJson(value)
    setSaveEnable(false)
  }, [value])

  // enabling the save button
  const onChangeJson = (newJson) => {
    setTempJson(newJson)
    setSaveEnable(true)
  }
  // Responsible for saving to the database
  const onSave = () => {
    onChange(tempJson)
    setSaveEnable(false)
  }

  // save metadata section to the firebase
  const saveMetadata = (newJson) => {
    onChange(newJson)
  }

  return (
    <Box display="flex" alignItems="stretch" width="100%">
      {jsonProperty === 'metadata' ? 
      <MetadataUI 
      recipeJsonProps={recipeJsonProps}
      saveMetadata={saveMetadata}
      /> :
        <JsonEditorComponent
          json={tempJson}
          onChangeJSON={onChangeJson}
          modes={["tree", "code"]}
          name={title}
          schema={schema}
          onValidationError={() => setSaveEnable(false)}
          onCreateMenu={onCreateMenu}
        /> 
      }
      {saveButton && jsonProperty !== "metadata"  ? (
        <Button
          variant="contained"
          color="primary"
          disabled={!saveEnable}
          onClick={onSave}
          style={{ marginLeft: "auto" }}
        >
          Save
        </Button>
      ) : (
        <></>
      )}
    </Box>
  );
};



JsonEditorDisplay.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onChange: PropTypes.func,
};

export default JsonEditorDisplay;
